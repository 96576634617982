import {
  isDetectionTask,
  MachineLearningTask,
  type PoseEstimationPoint,
} from '@kili-technology/types';

import { InputType } from '@/__generated__/globalTypes';
import { type KiliAnnotation } from '@/services/jobs/setResponse';
import { type AppThunk } from '@/store';
import {
  useStore,
  removeAllSelectedObjectIds,
  generateNewCreatingOrEditingObjectId,
} from '@/zustand';
import { useHistoryStore } from '@/zustand-history';

import { labelFramesFrameResponses } from '../../label-frames/selectors';
import { projectTreeFlat } from '../../project/selectors';
import { projectInputType, projectJobs } from '../../selectors';
import { type State } from '../../types';
import { removeAnnotation } from '../actions';
import {
  getAnnotationFromMidAndAnnotations,
  getKeyAnnotations,
  getMlTaskFromJobName,
} from '../helpers';
import { deleteAnnotationInFrameResponses } from '../helpers/deleteAnnotationInFrameResponses';
import { reCreateAnnotationInFrameResponses } from '../helpers/reCreateAnnotationInFrameResponses';
import { frameResponsesObjectsInfo, jobsAnnotations } from '../selectors';

export const deleteObject = ({
  jobName,
  mid,
  mlTask,
}: {
  jobName?: string;
  mid?: string;
  mlTask?: string;
} = {}): AppThunk => {
  return async (dispatch, getState) => {
    const state: State = getState();
    const annotations = jobsAnnotations(state) as KiliAnnotation[];
    const inputType = projectInputType(state);
    const { selectedObjectIds, creatingOrEditingObjectId } = useStore.getState().labelInterface;
    const jobs = projectJobs(state);
    const jobCategoryTree = projectTreeFlat(state);

    let midToDelete: string;
    if (mid) midToDelete = mid;
    else if (creatingOrEditingObjectId) midToDelete = creatingOrEditingObjectId;
    else {
      if (selectedObjectIds.length !== 1)
        throw new Error('Can only delete one annotation at a time with deleteObject.');
      [midToDelete] = selectedObjectIds;
    }

    const annotation = getAnnotationFromMidAndAnnotations(midToDelete, annotations);

    const jobNameOfMid = jobName || annotation?.jobName;
    if (!jobNameOfMid) return;

    const mlTaskOfMid = mlTask || getMlTaskFromJobName(jobs, jobNameOfMid);
    if (!mlTaskOfMid) return;

    if (isDetectionTask(mlTaskOfMid as MachineLearningTask) && annotation) {
      let objectParts: string[] = [];
      if ('allPoints' in annotation) {
        const poseEstimationPoints = (
          annotation as KiliAnnotation & { allPoints: PoseEstimationPoint[] }
        ).allPoints;
        objectParts = poseEstimationPoints.map(point => point.code);
      }

      dispatch(
        removeAnnotation({
          jobName: jobNameOfMid,
          mid: midToDelete,
          mlTask: mlTaskOfMid as MachineLearningTask,
          objectParts,
        }),
      );
    }

    if (midToDelete === creatingOrEditingObjectId) {
      generateNewCreatingOrEditingObjectId();
    }
    if (midToDelete === selectedObjectIds[0]) {
      removeAllSelectedObjectIds();
    }

    if (inputType === InputType.VIDEO && mlTaskOfMid === MachineLearningTask.OBJECT_DETECTION) {
      const frameJsonResponse = labelFramesFrameResponses(state);
      if (!frameJsonResponse) return;

      const keyAnnotations = getKeyAnnotations(
        jobNameOfMid,
        frameJsonResponse,
        jobCategoryTree,
        midToDelete,
      );
      const objectInfo = frameResponsesObjectsInfo(state)[midToDelete];

      const action = {
        name: 'deleteAnnotation',
        redo: () => {
          dispatch(deleteAnnotationInFrameResponses(midToDelete, jobNameOfMid));
        },
        undo: () => {
          dispatch(reCreateAnnotationInFrameResponses(keyAnnotations, objectInfo, midToDelete));
        },
      };

      dispatch(deleteAnnotationInFrameResponses(midToDelete, jobNameOfMid));
      useHistoryStore.getState().history.addAction(action);
    }
  };
};
