/* eslint no-param-reassign: ["error", { "props": false }] */
import { defaultPropagationSettings } from './label-interface/helpers';

import { type ZustandStore } from '.';

const migrateV0 = (persistedState: ZustandStore) => {
  Object.keys(persistedState.labelInterface.settings).forEach(key => {
    persistedState.labelInterface.settings[key].propagation = defaultPropagationSettings;
  });
};

export const migratePersistedState = (persistedState: ZustandStore, version: number) => {
  if (version === 0) {
    migrateV0(persistedState);
  }
};
