import { type JsonCategories as Categories } from '@kili-technology/types';

import { ANY_RELATION_VALUE } from './JobCategoryRelation';

import { NAME } from '../constants';
import { type RelationOption } from '../types';

export const getDropdownOptions = (choicesForRelationSteps: Categories) =>
  Object.entries(choicesForRelationSteps).map(([entityCode, entity]) => {
    const name: string = entity[NAME] ?? '';
    return {
      label: name,
      value: entityCode,
    };
  });

export const getDropdownValue = (options: RelationOption[], selectedCategory: unknown) =>
  options.reduce<string[]>((acc, { value }) => {
    if (
      (Array.isArray(selectedCategory) || typeof selectedCategory === 'string') &&
      selectedCategory.includes(value)
    ) {
      acc.push(value);
    }
    return acc;
  }, []);

export const getPayloadFromValue = (
  prevValues: string[],
  values: string[],
  items: RelationOption[],
) => {
  const payload: RelationOption[] = [];
  const wasAny = prevValues.includes(ANY_RELATION_VALUE);
  const isAny = !wasAny && values.includes(ANY_RELATION_VALUE);

  if (isAny) {
    return [
      {
        label: 'Any',
        value: ANY_RELATION_VALUE,
      },
    ];
  }

  values.forEach(value => {
    const foundItem = items.find(item => item.value === value);

    if (foundItem && value !== ANY_RELATION_VALUE) payload.push(foundItem);
  });

  return payload;
};
