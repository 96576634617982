import { type MutationTuple } from '@apollo/client';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { type WithStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useEffect, useState } from 'react';

import NotificationIcon from './NotificationIcon';
import { type styles } from './NotificationPopper.style';
import { type ReduxPropsType } from './NotificationPopper.wrap';
import NotificationRow from './NotificationRow';

import { type Notification, NotificationStatus } from '../../../__generated__/globalTypes';
import {
  type UpdatePropertiesInNotificationMutation,
  type UpdatePropertiesInNotificationMutationVariables,
} from '../../../graphql/notification/__generated__/mutations.graphql';

type PropsType = {
  canGoToNextPage: boolean;
  canGoToPreviousPage: boolean;
  fetchNotifications: () => void;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  notifications: Partial<Notification>[];
  updatePropertiesInNotification: MutationTuple<
    UpdatePropertiesInNotificationMutation,
    UpdatePropertiesInNotificationMutationVariables
  >[0];
} & ReduxPropsType &
  WithStyles<typeof styles>;

const NotificationPopper = (props: PropsType): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const {
    canGoToNextPage,
    canGoToPreviousPage,
    classes,
    fetchNotifications,
    goToNextPage,
    goToPreviousPage,
    notifications,
    updatePropertiesInNotification,
  } = props;
  const numberOfUnreadNotifications = notifications
    ? notifications.filter(notification => !notification?.hasBeenSeen).length
    : 0;

  const handleToggleNotification = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(!isOpen);
  };

  const handleCloseNotification = (event: React.MouseEvent<Document, MouseEvent>): void => {
    const eventTarget = event.target;
    if (!anchorEl || (eventTarget instanceof Node && anchorEl.contains(eventTarget))) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      fetchNotifications();
    }
  }, [isOpen, fetchNotifications]);

  useEffect(() => {
    if (!isOpen) return;

    const newlySeenNotifs = notifications.filter(notification => !notification?.hasBeenSeen);
    newlySeenNotifs.forEach(notification =>
      updatePropertiesInNotification({
        context: { clientName: 'V2' },
        variables: { data: { hasBeenSeen: true }, where: { id: notification?.id } },
      }),
    );
  }, [isOpen, notifications, updatePropertiesInNotification]);

  return (
    <>
      <NotificationIcon
        isBadgeInvisible={numberOfUnreadNotifications === 0}
        onClick={handleToggleNotification}
      />

      <Popper
        anchorEl={anchorEl}
        className={classes.popper}
        disablePortal
        open={isOpen}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleCloseNotification}>
                <MenuList>
                  {notifications.map(notification => (
                    <NotificationRow
                      key={`cypress-notification${notification.id}`}
                      message={notification?.message ?? 'Unknown notification'}
                      status={notification?.status ?? NotificationStatus.PENDING}
                      url={notification?.url ?? ''}
                    />
                  ))}

                  {notifications?.length === 0 && (
                    <MenuItem data-cy="cypress-notification">
                      <div className={classes.message}>No notification</div>
                    </MenuItem>
                  )}

                  <div className={classes.paging}>
                    <IconButton
                      data-testid="previous-button"
                      disabled={!canGoToPreviousPage}
                      onClick={goToPreviousPage}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                      data-testid="next-button"
                      disabled={!canGoToNextPage}
                      onClick={goToNextPage}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default NotificationPopper;
