import type React from 'react';
import { createTheme, type Theme } from '@material-ui/core/styles';
import { type PaletteOptions as PaletteOptionsType } from '@material-ui/core/styles/createPalette';
import { type TypographyOptions as TypographyOptionsType } from '@material-ui/core/styles/createTypography';

// eslint-disable-next-line import/no-absolute-path, import/no-unresolved
import ArchiaWoff from '/archia-webfont.woff';

//  radius
export const BORDER_RADIUS_SM = '2px';
export const BORDER_RADIUS = '4px';
export const BORDER_RADIUS_LG = '8px';
export const MODAL_BORDER_RADIUS = '10px';

// height
export const APP_BAR_HEIGHT = '50px';
export const APP_BAR_FILTER_HEIGHT = '53px';
export const LABEL_INTERFACE_BAR_HEIGHT = '50px';
export const INFO_BAR_HEIGHT = '20px';
export const APP_AND_INFO_BARS_HEIGHT = '70px'; // Sum of APP_BAR_HEIGHT and INFO_BAR_HEIGHT
export const INPUT_HEIGHT = '44px';
export const DEFAULT_BUTTON_HEIGHT = '35px';
export const LAST_SAVE_HEIGHT = '42px';
export const REVIEW_FILTER_BAR_HEIGHT = '48px';
export const SEARCH_BAR_HEIGHT = '42px';
export const TEXTAREA_HEIGHT = '80px';
export const VIDEO_TIMELINE_RANGE_OBJECT_HEIGHT = '20px';
export const DEFAULT_SLIDE_HEIGHT = '112px';
export const DEFAULT_SLIDE_HEADER_HEIGHT = 22;
export const EXPORT_DATA_MODAL_HEIGHT = 300;
export const EXPORT_DATA_ACCORDION_HEIGHT = 280;

// width
export const ISSUES_PANEL_LABEL_INTERFACE_WIDTH = 300;
export const EXPLORE_PANEL_WIDTH = 330;
export const EXPORT_DATA_CONTAINER_WIDTH = 570;
export const EXPORT_DATA_BUTTON_WIDTH = 73;
export const TIMELINE_NAME_WIDTH = 230;

// Colors
export const transparent = 'transparent';
export const successColor = 'green';
export const failureColor = 'red';
const undefinedColor = '#ff0000';

// opacity
export const ACTIVE_OPACITY = 0.87;
export const INACTIVE_OPACITY = 0.26;
export const EXPORT_DATA_OPACITY = 0.38;

export const kiliComponentColors = {
  alpha: {
    accent1: '#F7F9F5',
    accent2: undefinedColor,
    accent3: '#E1EBDB',
    accent4: undefinedColor,
    accent5: '#C5D8BA',
    accent6: undefinedColor,
    accent7: '#ACC69D',
    accent8: undefinedColor,
    accent9: '#88AC73',
  },
  beta: {
    accent1: '#EEE6FE',
    accent2: '#DECDFD',
    accent3: '#CDB5FD',
    accent4: undefinedColor,
    accent5: '#AD85FB',
    accent6: '#9561FA',
    accent7: '#7E3EF9',
    accent8: undefinedColor,
    accent9: '#5408E8',
  },
  delta: {
    accent1: '#DDF6FB',
    accent2: undefinedColor,
    accent3: '#86E5F0',
    accent4: undefinedColor,
    accent5: '#3BCADB',
    accent6: undefinedColor,
    accent7: undefinedColor,
    accent8: undefinedColor,
    accent9: undefinedColor,
  },
  epsilon: {
    accent1: '#E1F2FF',
    accent2: undefinedColor,
    accent3: undefinedColor,
    accent4: undefinedColor,
    accent5: '#76B0F7',
    accent6: undefinedColor,
    accent7: undefinedColor,
    accent8: undefinedColor,
    accent9: undefinedColor,
  },
  eta: {
    accent1: '#FED7D8',
    accent2: undefinedColor,
    accent3: '#F28D7E',
    accent4: undefinedColor,
    accent5: '#FA484A',
    accent6: undefinedColor,
    accent7: undefinedColor,
    accent8: undefinedColor,
    accent9: undefinedColor,
  },
  gamma: {
    accent1: '#FBF1D6',
    accent2: undefinedColor,
    accent3: '#FFD667',
    accent4: undefinedColor,
    accent5: '#ECB82A',
    accent6: undefinedColor,
    accent7: undefinedColor,
    accent8: undefinedColor,
    accent9: undefinedColor,
  },
  omega: {
    accent0: '#FFFFFF',
    accent1: '#F5F5F5',
    accent2: '#E9E9E9',
    accent3: '#DCDCDC',
    accent4: '#C0C0C0',
    accent5: '#AAAAAA',
    accent6: '#6F6F6F',
    accent7: '#5E5E5E',
    accent8: '#484848',
    accent9: '#191919',
  },
  zeta: {
    accent1: '#FFE3C7',
    accent2: undefinedColor,
    accent3: '#FFB161',
    accent4: undefinedColor,
    accent5: '#FF8200',
    accent6: undefinedColor,
    accent7: undefinedColor,
    accent8: undefinedColor,
    accent9: undefinedColor,
  },
};

const kiliGrey = kiliComponentColors.omega.accent6;

declare module '@material-ui/core/styles/createPalette' {
  interface KiliComponentPalette {
    accent1: Palette['primary'];
    accent2: Palette['primary'];
    accent3: Palette['primary'];
    accent4: Palette['primary'];
    accent5: Palette['primary'];
    accent6: Palette['primary'];
    accent7: Palette['primary'];
    accent8: Palette['primary'];
    accent9: Palette['primary'];
  }
  interface KiliComponentGrayPalette extends KiliComponentPalette {
    accent0: Palette['primary'];
  }
  interface Palette {
    alpha: KiliComponentPalette;
    beta: KiliComponentPalette;
    delta: KiliComponentPalette;
    epsilon: KiliComponentPalette;
    eta: KiliComponentPalette;
    gamma: KiliComponentPalette;
    omega: KiliComponentGrayPalette;
    zeta: KiliComponentPalette;
  }

  interface KiliComponentPaletteOptions {
    accent1: PaletteOptions['primary'];
    accent2: PaletteOptions['primary'];
    accent3: PaletteOptions['primary'];
    accent4: PaletteOptions['primary'];
    accent5: PaletteOptions['primary'];
    accent6: PaletteOptions['primary'];
    accent7: PaletteOptions['primary'];
    accent8: PaletteOptions['primary'];
    accent9: PaletteOptions['primary'];
  }

  interface KiliComponentGrayPaletteOptions extends KiliComponentPaletteOptions {
    accent0: PaletteOptions['primary'];
  }
  interface PaletteOptions {
    alpha: KiliComponentPaletteOptions;
    beta: KiliComponentPaletteOptions;
    delta: KiliComponentPaletteOptions;
    epsilon: KiliComponentPaletteOptions;
    eta: KiliComponentPaletteOptions;
    gamma: KiliComponentPaletteOptions;
    omega: KiliComponentGrayPaletteOptions;
    zeta: KiliComponentPaletteOptions;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    captionBold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    menu: React.CSSProperties;
    smallText: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyOptions {
    captionBold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    menu: React.CSSProperties;
    smallText: React.CSSProperties;
  }
}

declare module '@material-ui/core/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    caption: true;
    captionBold: true;
    h6Bold: true;
    menu: true;
    smallText: true;
  }
}

const palette = (colors: typeof kiliComponentColors): PaletteOptionsType => ({
  action: {
    disabledBackground: 'lightgrey',
  },
  alpha: {
    accent1: {
      main: 'colors.alpha.accent1',
    },
    accent2: {
      main: colors.alpha.accent2,
    },
    accent3: {
      main: colors.alpha.accent3,
    },
    accent4: {
      main: colors.alpha.accent4,
    },
    accent5: {
      main: colors.alpha.accent5,
    },
    accent6: {
      main: colors.alpha.accent6,
    },
    accent7: {
      main: colors.alpha.accent7,
    },
    accent8: {
      main: colors.alpha.accent8,
    },
    accent9: {
      main: colors.alpha.accent9,
    },
  },
  background: {
    default: colors.omega.accent0,
  },
  beta: {
    accent1: {
      main: colors.beta.accent1,
    },
    accent2: {
      main: colors.beta.accent2,
    },
    accent3: {
      main: colors.beta.accent3,
    },
    accent4: {
      main: colors.beta.accent4,
    },
    accent5: {
      main: colors.beta.accent5,
    },
    accent6: {
      main: colors.beta.accent6,
    },
    accent7: {
      main: colors.beta.accent7,
    },
    accent8: {
      main: colors.beta.accent8,
    },
    accent9: {
      main: colors.beta.accent9,
    },
  },
  delta: {
    accent1: {
      main: colors.delta.accent1,
    },
    accent2: {
      main: colors.delta.accent2,
    },
    accent3: {
      main: colors.delta.accent3,
    },
    accent4: {
      main: colors.delta.accent4,
    },
    accent5: {
      main: colors.delta.accent5,
    },
    accent6: {
      main: colors.delta.accent6,
    },
    accent7: {
      main: colors.delta.accent7,
    },
    accent8: {
      main: colors.delta.accent8,
    },
    accent9: {
      main: colors.delta.accent9,
    },
  },
  epsilon: {
    accent1: {
      main: colors.epsilon.accent1,
    },
    accent2: {
      main: colors.epsilon.accent2,
    },
    accent3: {
      main: colors.epsilon.accent3,
    },
    accent4: {
      main: colors.epsilon.accent4,
    },
    accent5: {
      main: colors.epsilon.accent5,
    },
    accent6: {
      main: colors.epsilon.accent6,
    },
    accent7: {
      main: colors.epsilon.accent7,
    },
    accent8: {
      main: colors.epsilon.accent8,
    },
    accent9: {
      main: colors.epsilon.accent9,
    },
  },
  eta: {
    accent1: {
      main: colors.eta.accent1,
    },
    accent2: {
      main: colors.eta.accent2,
    },
    accent3: {
      main: colors.eta.accent3,
    },
    accent4: {
      main: colors.eta.accent4,
    },
    accent5: {
      main: colors.eta.accent5,
    },
    accent6: {
      main: colors.eta.accent6,
    },
    accent7: {
      main: colors.eta.accent7,
    },
    accent8: {
      main: colors.eta.accent8,
    },
    accent9: {
      main: colors.eta.accent9,
    },
  },
  gamma: {
    accent1: {
      main: colors.gamma.accent1,
    },
    accent2: {
      main: colors.gamma.accent2,
    },
    accent3: {
      main: colors.gamma.accent3,
    },
    accent4: {
      main: colors.gamma.accent4,
    },
    accent5: {
      main: colors.gamma.accent5,
    },
    accent6: {
      main: colors.gamma.accent6,
    },
    accent7: {
      main: colors.gamma.accent7,
    },
    accent8: {
      main: colors.gamma.accent8,
    },
    accent9: {
      main: colors.gamma.accent9,
    },
  },
  omega: {
    accent0: {
      main: colors.omega.accent0,
    },
    accent1: {
      main: colors.omega.accent1,
    },
    accent2: {
      main: colors.omega.accent2,
    },
    accent3: {
      main: colors.omega.accent3,
    },
    accent4: {
      main: colors.omega.accent4,
    },
    accent5: {
      main: colors.omega.accent5,
    },
    accent6: {
      main: colors.omega.accent6,
    },
    accent7: {
      main: colors.omega.accent7,
    },
    accent8: {
      main: colors.omega.accent8,
    },
    accent9: {
      main: colors.omega.accent9,
    },
  },
  primary: {
    main: colors.beta.accent5,
  },
  secondary: {
    main: '#eeba00',
  },
  zeta: {
    accent1: {
      main: colors.zeta.accent1,
    },
    accent2: {
      main: colors.zeta.accent2,
    },
    accent3: {
      main: colors.zeta.accent3,
    },
    accent4: {
      main: colors.zeta.accent4,
    },
    accent5: {
      main: colors.zeta.accent5,
    },
    accent6: {
      main: colors.zeta.accent6,
    },
    accent7: {
      main: colors.zeta.accent7,
    },
    accent8: {
      main: colors.zeta.accent8,
    },
    accent9: {
      main: colors.zeta.accent9,
    },
  },
});

const typography: TypographyOptionsType = {
  body1: {
    fontSize: 16,
    lineHeight: '22px',
  },
  body2: {
    fontSize: 14,
    lineHeight: '20px',
  },
  button: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '17px',
  },
  caption: {
    fontSize: 12,
    lineHeight: '14px',
  },
  captionBold: {
    fontWeight: 600,
  },
  fontFamily: 'Archia',
  h1: {
    fontSize: 96,
    fontWeight: 600,
    lineHeight: '115px',
  },
  h2: {
    fontSize: 60,
    fontWeight: 600,
    lineHeight: '72px',
  },
  h3: {
    fontSize: 48,
    fontWeight: 600,
    lineHeight: '58px',
  },
  h4: {
    fontSize: 34,
    fontWeight: 600,
    lineHeight: '41px',
  },
  h5: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '29px',
  },
  h6: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '23px',
  },
  h6Bold: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '23px',
  },
  menu: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '16px',
  },
  smallText: {
    fontSize: '0.75rem',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '19px',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '17px',
  },
  useNextVariants: true,
} as TypographyOptionsType;

// return type of this function can be statically inferred by typechecker
// hence I don't bother with manually specifying return type

export const kiliTableStyles = (theme: Theme) => ({
  headerStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    lineHeight: theme.typography.subtitle2.lineHeight,
  },
  rowStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    lineHeight: theme.typography.body2.lineHeight,
  },
});

const defaultOverrides = (colors: typeof kiliComponentColors) => ({
  MuiButton: {
    root: {
      lineHeight: 'initial',
    },
  },
  MuiCheckbox: {
    colorSecondary: {
      '&$checked': {
        '&:hover': {
          backgroundColor: colors.beta.accent1,
        },
        color: colors.beta.accent5,
      },
      '&:hover': {
        backgroundColor: colors.beta.accent1,
      },
      color: kiliGrey,
    },
  },
  MuiCssBaseline: {
    '@global': {
      '@font-face': [archia],
    },
  },
  MuiInputBase: {
    root: {
      fontSize: '14px',
    },
  },
  MuiRadio: {
    colorSecondary: {
      '&$checked': {
        color: kiliGrey,
      },
      color: kiliGrey,
    },
  },
  MuiTooltip: {
    tooltip: {
      background: colors.beta.accent9,
      color: colors.omega.accent0,
      fontSize: '0.8em',
    },
  },
});

export const archia = {
  fontFamily: 'Archia',
  src: `
    url(${ArchiaWoff}) format('woff')
  `,
};

export const themeParameters = {
  overrides: defaultOverrides(kiliComponentColors),
  palette: palette(kiliComponentColors),
  typography,
};

export const theme = createTheme(themeParameters);

export const getTheme = (): Theme => {
  return theme;
};
