import { type EntityRelation, MachineLearningTask } from '@kili-technology/types';
import { batch } from 'react-redux';

import { type LabelInterfaceToggleAccordionPayload } from './types';

import { type KiliAnnotation, type KiliAnnotationProvider } from '../../services/jobs/setResponse';
import { updateEntitiesInAccordion } from '../../services/jsonInterface/accordion';
import { type AppThunk } from '../../store';
import { labelInterfaceUpdateField, replaceAllWithSelectedObjectId, useStore } from '../../zustand';
import { initializeState as jobInitializeState } from '../job/actions';
import { jobsAnnotations, jobsTextRelationAnnotations } from '../jobs/selectors';
import { projectJobs } from '../selectors';

export const updateAccordionWhenSwitchingPage = (): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const jobs = projectJobs(state);

    const jobNames = Object.keys(jobs).filter(jobName => {
      const mlTask = jobs?.[jobName]?.mlTask;

      const isChild = jobs?.[jobName]?.isChild;
      if (mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION && !isChild) {
        return true;
      }
      return false;
    });
    useStore.getState().labelInterface.closeCategoriesFromJobsInAccordion(jobNames);
  };
};

const getPayloadForEntityUpdate = (
  annotation: KiliAnnotation,
  relationAnnotations: KiliAnnotationProvider<EntityRelation>[],
  categoryCode: string,
) => {
  const annotationMid = annotation?.mid;
  const annotationsMidCodes = [
    {
      categoryCode,
      jobName: annotation.jobName,
      mid: annotationMid,
    },
  ];
  relationAnnotations.forEach(relation => {
    const entityCategory = relation?.categories?.[0].name;
    const startEntityMid = relation?.startEntities?.[0].mid;
    const endEntitiesMid = (relation?.endEntities ?? []).map(endEntity => endEntity?.mid);
    if (annotationMid === startEntityMid) {
      annotationsMidCodes.push({
        categoryCode: entityCategory,
        jobName: relation?.jobName,
        mid: relation?.mid,
      });
    }
    endEntitiesMid.forEach(endEntityMid => {
      if (annotationMid === endEntityMid) {
        annotationsMidCodes.push({
          categoryCode: entityCategory,
          jobName: relation?.jobName,
          mid: relation?.mid,
        });
      }
    });
  });
  return annotationsMidCodes;
};

const toggleLegacyAccordionForRelation = ({
  annotationMid,
  categoryCode,
}: LabelInterfaceToggleAccordionPayload): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const relationAnnotations = jobsTextRelationAnnotations(state);

    const annotations = jobsAnnotations(state);
    const annotation = annotations.find(value => value?.mid === annotationMid);
    if (!annotation) {
      return;
    }

    const entitiesToUpdate = getPayloadForEntityUpdate(
      annotation,
      relationAnnotations as KiliAnnotationProvider<EntityRelation>[],
      categoryCode,
    );

    const jobs = projectJobs(state);
    const newAccordion = updateEntitiesInAccordion(entitiesToUpdate, jobs);
    labelInterfaceUpdateField({
      path: 'accordionTree',
      value: newAccordion,
    });
  };
};

export const toggleAccordionForRelation = ({
  annotationMid,
  categoryCode,
}: LabelInterfaceToggleAccordionPayload): AppThunk => {
  return async dispatch => {
    batch(() => {
      dispatch(toggleLegacyAccordionForRelation({ annotationMid, categoryCode }));
      dispatch(jobInitializeState());
      replaceAllWithSelectedObjectId({ mid: annotationMid });
    });
  };
};

export const toggleAccordionForEntity = ({
  annotationMid,
  categoryCode,
}: LabelInterfaceToggleAccordionPayload): AppThunk => {
  return async dispatch => {
    dispatch(toggleLegacyAccordionForRelation({ annotationMid, categoryCode }));
  };
};
