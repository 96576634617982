import { type Tool } from '@kili-technology/types';
import { invert } from 'lodash/fp';

import { InputType } from '@/__generated__/globalTypes';
import { type ToolOptions } from '@/zustand/label-interface';

import { type ToolName } from './type';

const toolToToolName: Record<Tool | string, ToolName> = {
  marker: 'Point',
  polygon: 'Polygon',
  polyline: 'Line',
  pose: 'PoseEstimation',
  range: 'Range',
  rectangle: 'BoundingBox',
  relation: 'Relation',
  segmentation: 'Segmentation',
  semantic: 'Semantic',
  text: 'Text',
  vector: 'Vector',
};

export function decodeToolAsToolName(tool: Tool | string): ToolName | undefined {
  const output = toolToToolName[tool];
  if (output) {
    return output;
  }

  if (String(tool).includes('RELATION')) {
    return 'Relation';
  }

  return undefined;
}

export const decodeToolNameAsTool = (tool: ToolName): Tool => {
  const toolNameToTool = invert(toolToToolName);
  return toolNameToTool[tool] as Tool;
};

const ALL_INPUT_TYPES: InputType[] = [
  InputType.AUDIO,
  InputType.IMAGE,
  InputType.PDF,
  InputType.TEXT,
  InputType.VIDEO,
];

export const availableToolsByInputType: Record<ToolName, InputType[]> = {
  BoundingBox: [InputType.VIDEO, InputType.IMAGE, InputType.PDF],
  Delete: ALL_INPUT_TYPES,
  DisplaySettings: [InputType.IMAGE, InputType.VIDEO],
  Hand: [InputType.IMAGE, InputType.VIDEO],
  JobCategory: ALL_INPUT_TYPES,
  Line: [InputType.VIDEO, InputType.IMAGE],
  MeasureDistance: [InputType.IMAGE],
  Pagination: [InputType.PDF],
  Point: [InputType.VIDEO, InputType.IMAGE],
  Polygon: [InputType.VIDEO, InputType.IMAGE],
  PoseEstimation: [InputType.IMAGE],
  Range: [InputType.IMAGE],
  Relation: [InputType.IMAGE, InputType.PDF, InputType.TEXT],
  Rotation: [InputType.IMAGE, InputType.PDF],
  Segmentation: [InputType.VIDEO, InputType.IMAGE],
  Semantic: [InputType.VIDEO, InputType.IMAGE],
  Settings: [InputType.IMAGE, InputType.VIDEO, InputType.PDF, InputType.TEXT],
  Text: [InputType.PDF, InputType.TEXT],
  TextSettings: [InputType.TEXT, InputType.LLM_RLHF],
  Tracking: [InputType.VIDEO, InputType.IMAGE],
  UndoRedo: ALL_INPUT_TYPES,
  Vector: [InputType.VIDEO, InputType.IMAGE, InputType.PDF],
  Zoom: [InputType.IMAGE, InputType.VIDEO, InputType.PDF],
};

export const isToolGranted =
  (inputType: InputType) =>
  (toolName: ToolName): boolean =>
    availableToolsByInputType[toolName].includes(inputType);

type SettingKey = 'toggleable' | 'jobTools';

export const TOOL_SETTING: Record<SettingKey, ReadonlyArray<ToolName>> = {
  jobTools: [
    'Hand',
    'BoundingBox',
    'Line',
    'Point',
    'Polygon',
    'PoseEstimation',
    'Segmentation',
    'Semantic',
    'Range',
    'Vector',
    'Relation',
    'Text',
  ],
  toggleable: [
    'Zoom',
    'DisplaySettings',
    'TextSettings',
    'Tracking',
    'Settings',
    'Range',
    'Rotation',
    'UndoRedo',
    'Delete',
  ],
};

export const INITIAL_TOOLS: [ToolName, ToolOptions][] = [
  ['Hand', { isSelected: true, isVisible: true }],
  ['JobCategory', { isVisible: true }],
  ['MeasureDistance', { isVisible: true }],
  ['Text', {}],
  ['Zoom', { isVisible: true }],
  ['Vector', {}],
  ['Line', {}],
  ['Point', {}],
  ['BoundingBox', {}],
  ['Polygon', {}],
  ['Semantic', {}],
  ['Segmentation', {}],
  ['PoseEstimation', {}],
  ['Range', {}],
  ['Tracking', {}],
  ['Relation', {}],
  ['Pagination', { isVisible: true }],
  ['DisplaySettings', { isVisible: true }],
  ['TextSettings', { isVisible: true }],
  ['Settings', { isVisible: true }],
  ['Rotation', { isVisible: true }],
  ['UndoRedo', { isVisible: true }],
  ['Delete', { isVisible: true }],
];

export const DEFAULT_GEOSPATIAL_JSON_CONTENT = {
  bounds: [],
  height: 0,
  imageUrl: '',
  maxZoom: 0,
  minZoom: 0,
  tileLayerUrl: '',
  width: 0,
};
