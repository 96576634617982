import { css } from '@emotion/css';

export type MarkerBaseStyleProps = {
  border: number;
  borderRadius?: { type: string; value: number };
  color: string;
  height: number;
  marginLeft: number;
  marginTop: number;
  opacity: number;
  outline?: string;
};

export const useStyles = (props: MarkerBaseStyleProps) => {
  return {
    root: css`
      background-color: ${props.color};
      ${props.outline ? `outline: ${props.outline}` : `outline: initial`};
      box-sizing: content-box;
      height: ${props.height}px;
      margin-left: ${props.marginLeft}px;
      margin-top: ${props.marginTop}px;
      opacity: ${props.opacity};
      pointer-events: all;
      width: ${props.height}px;
      ${props.borderRadius
        ? `border-radius: ${props.borderRadius.value}${props.borderRadius.type}`
        : `border-radius: 50%`};
    `,
  };
};
