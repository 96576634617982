import _get from 'lodash/get';

import { EMPTY_CATEGORY_NAME, JSON_MALFORMED } from '../../constants/backendErrors';

const extractJsonMalformedError = (error: string): string => {
  if (error.includes(`[${EMPTY_CATEGORY_NAME}]`)) {
    return 'Category name cannot be empty';
  }
  const jsonMalformedBackEndError = `[${JSON_MALFORMED}]`;
  if (!error.includes(jsonMalformedBackEndError)) {
    return error;
  }
  const jsonMalformedError = _get(error.split(jsonMalformedBackEndError), 1, '');
  const jsonMalformedErrorInfo = _get(jsonMalformedError.split('|'), 0, `${JSON_MALFORMED} error.`);
  return jsonMalformedErrorInfo;
};

export default extractJsonMalformedError;
