import { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from '@/zustand';

const textCssVariables = ['--text-asset-line-height', '--text-asset-text-size'];

function getRootElement() {
  return document.querySelector(':root') as HTMLElement;
}

export function getVariable(name: string) {
  const root = getRootElement();
  const style = getComputedStyle(root);
  return style.getPropertyValue(name).trim();
}

function setVariable(name: string, value?: string) {
  getRootElement().style.setProperty(name, value ?? null);
}

function initVariable(name: string, defaultValue?: string) {
  const raw = getVariable(name);

  if (!raw && defaultValue) {
    setVariable(name, defaultValue);
    return defaultValue;
  }

  return raw;
}

function useUpdateEffect(
  effect: Parameters<typeof useEffect>[0],
  deps: Parameters<typeof useEffect>[1],
) {
  const isMounted = useRef(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return () => {};
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useCssVar(name: string, defaultValue?: string) {
  const [value, setValue] = useState(() => initVariable(name, defaultValue));

  useUpdateEffect(() => {
    setValue(initVariable(name));
  }, [name]);

  const set = useCallback<typeof setValue>(
    next => {
      const nextValue = typeof next === 'function' ? next(getVariable(name)) : next;
      setVariable(name, nextValue);
      if (textCssVariables.includes(name)) useStore.getState().labelRichText.setLastTextResized();
      setValue(next);
    },
    [name],
  );

  return [value, set] as const;
}
