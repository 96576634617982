import { gql } from '@apollo/client';

export const GQL_PROJECTS_GET = gql`
  query GetProjects($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    data: projects(where: $where, first: $first, skip: $skip) {
      id
      archivedAt
      createdAt
      inputType
      title
      updatedAt
      numberOfAssets
      numberOfRemainingAssets
      myRole {
        activated
        id
        starred
        role
        user {
          id
        }
      }
    }
    count: countProjects(where: $where)
    tags: listTagsByOrg {
      id
      checkedForProjects
    }
  }
`;

export const GQL_PROJECTS_FOR_PANEL_GET = gql`
  query GetProjectsForPanel($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    allProjectsArray: projects(where: $where, first: $first, skip: $skip) {
      id
      title
    }
  }
`;

export const GQL_LATEST_UPDATED_PROJECTS_GET = gql`
  query GetLatestUpdatedProjects($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    latestUpdatedProjectArray: latestUpdatedProjects(where: $where, first: $first, skip: $skip) {
      id
      title
    }
  }
`;

export const GQL_PROJECTS_INPUT_TYPES_GET = gql`
  query GetProjectInputTypes {
    data: listProjectInputTypes
  }
`;

export const GQL_USER_ACTION_METRICS = gql`
  query GetUsersActionLabelerMetrics(
    $where: ProjectWhere!
    $first: PageSize!
    $skip: Int!
    $granularity: Granularity!
    $startDate: DateTime!
    $metrics: [LabelerMetricInput!]
    $resolveAll: Boolean!
  ) {
    projects(where: $where, first: $first, skip: $skip) {
      id
      labelerMetrics(
        granularity: $granularity
        startDate: $startDate
        metrics: $metrics
        resolveAll: $resolveAll
      ) {
        userId
        __typename
        ... on ActionLabelerMetric {
          action
          value
        }
      }
    }
  }
`;
