import _round from 'lodash/round';

export const DEFAULT_VALUE = 'N/A';
export type NumberOrNa = number | typeof DEFAULT_VALUE;
export const PREDICTION_MODEL_DEFAULT_VALUE = 'Model';

export const formatMark = (rawMark: number | null | undefined, precision = 0): string => {
  const shouldReturnDefaultValue = rawMark === null || rawMark === undefined;
  if (shouldReturnDefaultValue) return DEFAULT_VALUE;
  const mark = rawMark as number;
  if (precision > 0) {
    return `${_round(mark * 100, precision)}%`;
  }
  return `${Math.round(mark * 100)}%`;
};
