import Button from '@kili-technology/button';
import Modal from '@kili-technology/modal';
import SingleChoiceAutocomplete from '@kili-technology/single-choice-autocomplete';
import TextArea from '@kili-technology/text-area';
import { useState } from 'react';

import useStyles from './ContactSupportModal.style';

interface PropsType {
  handleClose: () => void;
  handleSendMessage: (supportSubject: string, messageContent: string) => void;
  open: boolean;
}

const ContactSupportModal = ({ handleClose, handleSendMessage, open }: PropsType): JSX.Element => {
  const possibleSupportSubject = [
    'I have a product question',
    'I have a pricing question',
    'I want a demo',
    'I want to talk to sales',
  ];
  const [supportSubject, setSupportSubject] = useState<string>('I have a product question');
  const [messageContent, setMessageContent] = useState<string>('');
  const classes = useStyles();

  const onChangeTextField = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setMessageContent(event.target.value);
  };

  return (
    <Modal className={classes.modal} onClose={handleClose} open={open} title="Contact us">
      <div className={classes.container}>
        <SingleChoiceAutocomplete
          label="Why are you contacting us ?"
          menuItems={possibleSupportSubject.map(sub => ({ label: sub, value: sub }))}
          onItemSelect={item => setSupportSubject(item.inputValue)}
          value={supportSubject}
        />

        <TextArea
          label="More details about what you need"
          minHeight={94}
          name="message-content"
          onChange={onChangeTextField}
          placeholder="Your comment"
          value={messageContent}
        />

        <Button
          className={classes.sendMessage}
          data-cy="cypress-send-email"
          onClick={() => handleSendMessage(supportSubject, messageContent)}
          variant="primary"
        >
          Send message
        </Button>
      </div>
    </Modal>
  );
};

export default ContactSupportModal;
