import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const SIMPLE_LABEL_FRAGMENTS = gql`
  fragment LabelWithAssetInfos on Label {
    jsonResponse
  }
`;

export const ASSET_FRAGMENTS = (
  shouldDownloadJsonResponses: boolean,
  shouldDownloadAssetUrls: boolean,
): DocumentNode => gql`
  fragment AssetFragment on Asset {
    id
    consensusMark
    ${shouldDownloadAssetUrls ? 'content' : ''}
    createdAt
    duration
    externalId
    honeypotMark
    inferenceMark
    isHoneypot
    isProcessingAuthorized
    isUsedForConsensus
    ${shouldDownloadAssetUrls ? 'jsonContent' : ''}
    jsonMetadata
    labels {
      ...LabelInfos
    }
    ${shouldDownloadAssetUrls ? 'ocrMetadata' : ''}
    priority
    skipped
    status
    ${shouldDownloadAssetUrls ? 'thumbnail' : ''}
    updatedAt
    toBeLabeledBy {
        ...AssigneeInfos
    }
  }

  fragment LabelInfos on Label {
    id
    author {
      id
      email
      firstname
      lastname
    }
    createdAt
    honeypotMark
    isHoneypot
    isLatestLabelForUser 
    isSentBackToQueue 
    ${shouldDownloadJsonResponses ? 'jsonResponse' : ''}
    labelType
    modelName 
    numberOfAnnotations 
    reviewScore
    totalSecondsToLabel
  }

  fragment AssigneeInfos on ProjectUser {
    id
    user {
      id
      firstname
      lastname
      email
    }
  }
  
`;
