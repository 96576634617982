import { useMutation } from '@apollo/client';
import { cx, css } from '@emotion/css';
import Avatar from '@kili-technology/avatar';
import Button from '@kili-technology/button';
import { HandClickingClass } from '@kili-technology/cursors';
import { Account, Add, Bbox, Settings, BboxPartial, Community } from '@kili-technology/icons';
import Popper from '@kili-technology/popper';
import { MenuItem, MenuList, Typography } from '@material-ui/core';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import useStyles from './GenericRightBar.style';

import { LicenseType, OrganizationRole } from '../../../__generated__/globalTypes';
import LicenseModal from '../../../components/LicenseModal';
import LogoutButton from '../../../components/LogoutButton';
import { useLogoutButton } from '../../../components/LogoutButton/hooks';
import { DOC_BASE_URL, DocUrl, DOC_SDK_URL } from '../../../constants/docUrls';
import CONTACT_SUPPORT_URL from '../../../constants/genericUrls';
import { TOPBAR_POPPER_PROFILE_ZINDEX } from '../../../constants/zIndexes';
import { type SendEmailToSupportMutation } from '../../../graphql/user/__generated__/mutations.graphql';
import { GQL_SEND_EMAIL_TO_SUPPORT } from '../../../graphql/user/mutations';
import useUser from '../../../hooks/useUser';
import { RootRoute, rootRoutes } from '../../../pages/RootModule/RootPaths';
import { SegmentEvents } from '../../../pages/RootModule/helpers';
import { useHomePath } from '../../../pages/RootModule/hooks/useHomePath';
import organizations from '../../../pages/organization/OrganizationList/organizationMock.json';
import { ProjectListsRoutes } from '../../../pages/projects/ProjectLists/ProjectLists.route';
import {
  sendToSegment,
  updateField as updateFieldAction,
} from '../../../redux/application/actions';
import { type ApplicationUpdateFieldPayload } from '../../../redux/application/types';
import {
  organizationId as organizationIdSelector,
  organizationLicense,
  organizationName,
} from '../../../redux/organization/selectors';
import { userFirstname, userLastname } from '../../../redux/user/selectors';
import useFlag from '../../../services/flags/useFlag';
import ContactSupportModal from '../ContactSupportModal';
import HelpPopper from '../HelpPopper/HelpPopper';
import InviteMembersButton from '../InviteMembersButton';
import NotificationPopper from '../NotificationPopper';
import PendingRequestIcon from '../PendingRequestIcon/PendingRequestIcon';

export function useGenericRightBar(): {
  dispatchUpdateField: (payload: ApplicationUpdateFieldPayload) => unknown;
} {
  const dispatch = useDispatch();
  return {
    dispatchUpdateField: (payload: ApplicationUpdateFieldPayload) =>
      dispatch(updateFieldAction(payload)),
  };
}

export default function GenericRightBar(): JSX.Element {
  const { email, name, organizationRole } = useUser();
  const firstname = useSelector(userFirstname);
  const lastname = useSelector(userLastname);
  const companyName = useSelector(organizationName);
  const organizationId = useSelector(organizationIdSelector);
  const license = useSelector(organizationLicense);
  const isUsingMultiOrgManagementFlag = useFlag('multi_org_management');
  const isPaidLicense = license?.type === LicenseType.PAID;
  const HomePath = useHomePath();
  const { handleLogout } = useLogoutButton();

  const { dispatchUpdateField } = useGenericRightBar();
  const classes = useStyles();
  const history = useHistory();

  const isAdmin = organizationRole === OrganizationRole.ADMIN;
  const displayNewOrg = isUsingMultiOrgManagementFlag && !isAdmin;

  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const [sendEmailToSupport] = useMutation<SendEmailToSupportMutation>(GQL_SEND_EMAIL_TO_SUPPORT);

  if (!name || !firstname || !lastname || !companyName) {
    return (
      <div
        className={css`
          margin-right: 20px;
        `}
      >
        Loading...
      </div>
    );
  }

  const goHome = (): void => {
    history.push(HomePath);
  };

  const handleToggleMenu = () => {
    setAccountMenuOpen(!accountMenuOpen);
  };

  const handleMyAccount = () => {
    const { href } = global.location;
    dispatchUpdateField({ path: 'originalUrl', value: href });
    history.push(rootRoutes.ROOT_ACCOUNT_ROUTE.path);
  };

  const handleMyOrganization = () => {
    if (organizationId) {
      history.push(rootRoutes.ROOT_ORGANIZATION_ROUTE.path);
    } else {
      handleLogout();
    }
  };

  const handleDemoProject = () => {
    const demoProjectsPath = generatePath(rootRoutes[RootRoute.PROJECT_LISTS].path, {
      listName: ProjectListsRoutes.DEMO_PROJECTS,
    });
    history.push(demoProjectsPath);
  };

  const handleGettingStarted = () => {
    dispatch(sendToSegment({ event: SegmentEvents.DOCUMENTATION_OPENED }));
    window.open(`${DOC_BASE_URL}${DocUrl.GettingStarted}`, '_blank');
  };

  const handleDocs = () => {
    window.open(`${DOC_BASE_URL}${DocUrl.IntroductionToKiliTechnology}`, '_blank');
  };

  const handleApiDocs = () => {
    dispatch(sendToSegment({ event: SegmentEvents.DOCUMENTATION_OPENED }));
    window.open(DOC_SDK_URL, '_blank');
  };

  const handleContactSupport = () => {
    if (isPaidLicense) {
      dispatch(sendToSegment({ event: SegmentEvents.CONTACT_SUPPORT_CLICKED }));
      window.open(CONTACT_SUPPORT_URL, '_blank');
    } else setSupportModalOpen(true);
  };

  const handleUpgradeButtonOnClick = () => {
    setAccountMenuOpen(false);
    setIsLicenseModalOpen(true);
    dispatch(sendToSegment({ event: SegmentEvents.UPGRADE_PLAN_CLICKED }));
  };

  const handleSendMessage = (supportSubject: string, messageContent: string) => {
    sendEmailToSupport({
      variables: { data: { content: messageContent, subject: supportSubject } },
    });
    setSupportModalOpen(false);
  };

  const shouldDisplayUserName = email !== name;
  return (
    <>
      <div className={classes.container}>
        <HelpPopper
          handleApiDocs={handleApiDocs}
          handleContactSupport={handleContactSupport}
          handleDemoProject={handleDemoProject}
          handleDocs={handleDocs}
          handleGettingStarted={handleGettingStarted}
          isPaidLicense={isPaidLicense}
        />
        <InviteMembersButton />
        <PendingRequestIcon />
        <NotificationPopper />
        <Button
          aria-haspopup="true"
          aria-owns={accountMenuOpen ? 'menu-list-grow' : undefined}
          className={`${classes.userMenuListGrow} ${HandClickingClass}`}
          data-cy="topbar-account-button"
          onClick={handleToggleMenu}
          onKeyDown={e => e.preventDefault()}
          ref={anchorRef}
          variant="ghost"
        >
          <Avatar
            email={email}
            firstName={firstname}
            lastName={lastname}
            style={{ color: 'var(--color-omega-accent-9)' }}
          />
          <div className={classes.userMenuListContainer}>
            {shouldDisplayUserName && <div className="subtitle_2">{name}</div>}
            <div className={cx(classes.userCompany, 'caption', 'ellipsis')}>{companyName}</div>
          </div>
          <ArrowDown style={{ color: 'var(--color-omega-accent-9)' }} />
        </Button>
        <Popper
          anchorEl={anchorRef.current}
          className={classes.popperContainer}
          onClose={() => setAccountMenuOpen(false)}
          open={accountMenuOpen}
          placement="bottom-end"
          style={{ height: 'auto', width: '230px', zIndex: TOPBAR_POPPER_PROFILE_ZINDEX }}
        >
          <div className={classes.userOpenContainer}>
            <Avatar email={email} firstName={firstname} lastName={lastname} />
            <div className="subtitle-2">{name}</div>
            <div className={cx(classes.userOpenCompany, 'caption', 'ellipsis')}>{companyName}</div>
          </div>
          {!isPaidLicense && (
            <>
              <div className={classes.userMenuListOpen}>
                <Typography className={classes.userLicenseText} variant="caption">
                  License
                </Typography>
              </div>
              <div className={classes.userLicense}>
                <Community size={24} />
                <Typography className={classes.userLicenseTypeText} variant="subtitle1">
                  Free
                </Typography>
                <Button data-cy="upgrade-plan-button" onClick={handleUpgradeButtonOnClick}>
                  View plans
                </Button>
              </div>
              <hr className={classes.userMenuTitle} />
            </>
          )}
          <MenuList>
            <MenuItem className={`${classes.userMenuItem} ${HandClickingClass}`} onClick={goHome}>
              <Bbox className={classes.userMenuListItem} />
              <Typography variant="body2">My projects</Typography>
            </MenuItem>
            <MenuItem
              className={`${classes.userMenuItem} ${HandClickingClass}`}
              onClick={handleDemoProject}
            >
              <BboxPartial className={classes.userMenuListItem} />
              <Typography variant="body2">Demo projects</Typography>
            </MenuItem>
            {isAdmin && (
              <MenuItem
                className={`${classes.userMenuItem} ${HandClickingClass}`}
                data-cy="my-organization"
                onClick={handleMyOrganization}
              >
                <Settings className={classes.userMenuListItem} />
                <Typography variant="body2">Manage organization</Typography>
              </MenuItem>
            )}
            {displayNewOrg && (
              <div>
                <hr className={classes.userMenuTitle} />
                <MenuItem
                  className={`${css`
                    padding: var(--space-2) var(--space-4);
                  `} ${HandClickingClass}`}
                  disabled
                >
                  <Typography variant="body2">Switch organization</Typography>
                </MenuItem>
                <div className={classes.organizationAvatar}>
                  {organizations.map(organization => (
                    <div className="organization-item" key={organization.name}>
                      <Avatar
                        email={organization.name}
                        firstName={organization.name}
                        lastName=""
                        variant="small"
                      />
                      <Typography variant="body2">{organization.name}</Typography>
                    </div>
                  ))}
                </div>

                <MenuItem className={classes.userMenuItem} data-cy="my-account" onClick={() => {}}>
                  <Add
                    className={`${css`
                      margin-right: 11px;
                    `} ${HandClickingClass}`}
                  />
                  <Typography variant="body2">New organization</Typography>
                </MenuItem>
              </div>
            )}
            <hr className={classes.userMenuTitle} />
            <MenuItem
              className={`${classes.userMenuItem} ${HandClickingClass}`}
              data-cy="my-account"
              onClick={handleMyAccount}
            >
              <Account className={classes.userMenuListItem} />
              <Typography variant="body2">My account</Typography>
            </MenuItem>
            <LogoutButton enableLogOutText />
          </MenuList>
        </Popper>
      </div>
      <ContactSupportModal
        handleClose={() => {
          setSupportModalOpen(false);
        }}
        handleSendMessage={handleSendMessage}
        open={supportModalOpen}
      />
      <LicenseModal handleClose={() => setIsLicenseModalOpen(false)} open={isLicenseModalOpen} />
    </>
  );
}
