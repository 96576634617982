/* eslint-disable import/order */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { store } from './store';
import { userEmail } from './redux/user/selectors';
import { userId } from './redux/selectors';
import { projectID } from './redux/project/selectors';
import { isDevelopmentEnvironment, isUsingDatadog } from './config';
import { type State } from './redux/types';

type DatadogType =
  | 'asset'
  | 'auth0'
  | 'graphql'
  | 'javascript'
  | 'network'
  | 'performance'
  | 'version';

export const sendToDatadog = (error: Error, info: unknown, type: DatadogType): void => {
  const state = store.getState() as State;
  console.error('[DATADOG]', error, info, state);

  if (isUsingDatadog()) {
    const messageContext = {
      assetId: state.assetLabel.assetId,
      info,
      labelId: state.assetLabel.label?.id,
      projectId: projectID(state),
      type,
      userEmail: userEmail(state),
      userId: userId(state),
    };
    datadogLogs.logger.error(error.message, messageContext, error);
  }
};

export const sendErrorToDatadog = (error: Error, context: Record<string, unknown>): void => {
  if (isDevelopmentEnvironment()) {
    console.error('[DATADOG ERROR]', error, context);
  }
  if (isUsingDatadog()) {
    datadogRum.addError(error, context);
  }
};

export const sendActionToDatadog = (actionName: string, payload: object): void => {
  if (isUsingDatadog()) {
    datadogRum.addAction(actionName, payload);
  }
};

export const trackPerformance =
  (maxActualTime: number, error: Error) =>
  (
    id: string,
    phase: string,
    actualTime: number,
    baseTime: number,
    startTime: number,
    commitTime: number,
  ): void => {
    if (actualTime < maxActualTime) return;
    const info = {
      actualTime,
      baseTime,
      commitTime,
      id,
      phase,
      startTime,
    };
    sendToDatadog(error, info, 'performance');
  };
