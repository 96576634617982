import { KiliQAReviewScore, KiliCloudStorage, KiliPlugins } from '@kili-technology/artworks';

export const BOOK_URL = 'https://kili-technology.com/book-a-demo';

export const FEATURES_KILI = [
  {
    icon: <KiliQAReviewScore />,
    text: 'Programmatic QA & review score',
  },
  {
    icon: <KiliCloudStorage />,
    text: 'Cloud storage',
  },
  {
    icon: <KiliPlugins />,
    text: 'Plugins',
  },
];
