import { type LabelImageSemanticSlice, type LabelImageSemanticSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: LabelImageSemanticSliceValues = {
  drawOverExistingObjects: false,
  firstInteractiveSegmentationMid: null,
  isDeletingInteractiveSegmentationMarker: false,
  isFetchingAnnotation: false,
  previousSemanticMode: 'view',
  segmentationMode: 'point',
  selectedSemanticObjectId: null,
  semanticMode: 'view',
};

const log = createLogger('labelImageSemantic');

export const createLabelImageSemanticSlice: ZustandSlice<LabelImageSemanticSlice> = set => ({
  ...initialState,
  initialize: () =>
    set(
      state => {
        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;
          (state.labelImageSemantic[keyInitialState] as unknown) = value;
        });
      },
      false,
      log('initialize'),
    ),
  setSegmentationMode: segmentationMode =>
    set(
      state => {
        state.labelImageSemantic.segmentationMode = segmentationMode;
      },
      false,
      log('setSegmentationMode', segmentationMode),
    ),
  setSemanticMode: semanticMode =>
    set(
      state => {
        state.labelImageSemantic.previousSemanticMode = state.labelImageSemantic.semanticMode;
        state.labelImageSemantic.semanticMode = semanticMode;
      },
      false,
      log('setSemanticMode', semanticMode),
    ),
});

export * from './types';
