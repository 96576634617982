import { cx } from '@emotion/css';
import { Check } from '@kili-technology/icons';
import React, { type FC } from 'react';

import classes from './LicenseModalColumn.styles';

type LicenseModalColumnProps = {
  contentFeatures: string[];
  contentHeader?: string;
  headerDescription: string;
  headerTitleElement: React.ReactElement;
  pricingElement: React.ReactElement;
};

const LicenseModalColumn: FC<LicenseModalColumnProps> = ({
  contentFeatures,
  contentHeader,
  headerDescription,
  headerTitleElement,
  pricingElement,
}) => {
  return (
    <div className={classes.rootColumn}>
      <div className={classes.header}>
        {headerTitleElement}
        <p className={cx('subtitle-2', classes.headerDescription)}>{headerDescription}</p>
      </div>

      <div className={classes.pricing}>{pricingElement}</div>

      <div className={classes.content}>
        {contentHeader && (
          <p className={cx('subtitle-2', classes.contentHeader)}>{contentHeader}</p>
        )}

        {contentFeatures.map(feature => (
          <div className={classes.featureLine} key={feature}>
            <Check className={classes.checkmark} color="var(--color-alpha-accent-9)" />
            <p className="body-2">{feature}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LicenseModalColumn;
