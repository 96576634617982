import DEFAULT_FLAGS from './defaultFlags.json';

export default function getInitialFlags() {
  return Object.fromEntries(
    Object.entries(DEFAULT_FLAGS).map(([flag, flagValue]) => {
      const flagEnvironmentKey = `FLAG_${flag.toUpperCase()}`;
      // eslint-disable-next-line no-underscore-dangle
      const isFlagInEnvironmentVariables = flagEnvironmentKey in window._env_;
      if (isFlagInEnvironmentVariables) {
        return [
          flag,
          {
            // eslint-disable-next-line no-underscore-dangle
            enabled: (window._env_?.[flagEnvironmentKey] ?? '').toLowerCase() === 'true',
          },
        ];
      }
      return [flag, flagValue];
    }),
  );
}
