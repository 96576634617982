import Button from '@kili-technology/button';
import { AddMember as InviteMembersIcon } from '@kili-technology/icons';
import React, { type FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generatePath, useHistory } from 'react-router-dom';

import useStyles from './InviteMembersButton.style';

import { ProjectRole } from '../../../__generated__/globalTypes';
import { rootRoutes } from '../../../pages/RootModule/RootPaths';
import { projectUserRole } from '../../../redux/project-user/selectors';
import useIsInProject from '../hooks/useIsInProject';

const InviteMembersButton: FC = () => {
  const { push } = useHistory();
  const { id: projectId } = useParams<{ id: string }>();
  const classes = useStyles();
  const { isInProject } = useIsInProject();
  const userRoleInProject = useSelector(projectUserRole);

  if (
    !isInProject ||
    !userRoleInProject ||
    ![ProjectRole.ADMIN, ProjectRole.TEAM_MANAGER].includes(userRoleInProject)
  )
    return null;

  const path = [
    generatePath(rootRoutes.ROOT_PROJECT_ROUTE.path, {
      id: projectId,
    }),
    'menu',
    'settings',
    'members',
  ].join('/');

  const handleClick = () => {
    push(path);
  };

  return (
    <Button
      className={classes.button}
      data-cy="add-members-button"
      data-testid="add-members-button"
      isSquare
      onClick={handleClick}
      onKeyDown={e => e.preventDefault()}
      variant="secondary-ghost"
    >
      <InviteMembersIcon fill="var(--color-omega-accent-6)" />
    </Button>
  );
};

export default InviteMembersButton;
