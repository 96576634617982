import { type ApolloClient, type InMemoryCache } from '@apollo/client';
import { withApollo as originalWithApollo } from '@apollo/client/react/hoc';
import _set from 'lodash/fp/set';
import type React from 'react';
import { type AnyAction } from 'redux';

type DoubleNestedKeyOf<ObjectType extends Record<string, unknown>> = {
  [Key in keyof ObjectType & string]: ObjectType[Key] extends Record<string, unknown>
    ? Key | `${Key}.${keyof ObjectType[Key] & string}`
    : Key;
}[keyof ObjectType & string];

type TypeOfValueAtNestedPath<T, Path extends string> = Path extends keyof T
  ? T[Path]
  : Path extends `${infer K}.${infer R}`
  ? K extends keyof T
    ? TypeOfValueAtNestedPath<T[K], R>
    : unknown
  : unknown;

export type UpdateFieldPayloadGenerics<
  State extends Record<string, unknown>,
  Path extends string,
> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  path: Path extends `${infer K}.${infer R}` ? DoubleNestedKeyOf<State> : Path;
  value: TypeOfValueAtNestedPath<State, Path>;
};

export type UpdateFieldAction<State extends Record<string, unknown>> = <
  SubState extends State,
  Path extends string,
>(
  _: UpdateFieldPayloadGenerics<SubState, Path>,
) => AnyAction;

export const updateFieldGenerics =
  <State extends Record<string, unknown>>() =>
  (slice: UpdateFieldAction<State>): UpdateFieldAction<State> =>
    slice;

export const UPDATE_FIELD_GENERICS =
  <State extends Record<string, unknown>>() =>
  <Path extends string>(
    state: State,
    action: {
      payload: UpdateFieldPayloadGenerics<State, Path>;
    },
  ): State => {
    return _set(action.payload.path, action.payload.value, state);
  };

export declare type WithApolloClient<P> = P & {
  client: ApolloClient<InMemoryCache>;
};

export function withApollo<TProps>(
  WrappedComponent: React.ComponentType<WithApolloClient<TProps>>,
): React.ComponentClass<Omit<TProps, 'client'>> {
  // eslint-disable-next-line
  /* @ts-ignore */
  return originalWithApollo(WrappedComponent);
}

export type Optional<T> = T | null | undefined;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export type ArrayElementType<T> = T extends (infer E)[] ? E : T;
