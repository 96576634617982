import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import _get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';

import NotificationPopper from './NotificationPopper.style';
import { type ReduxPropsType } from './NotificationPopper.wrap';

import {
  type UpdatePropertiesInNotificationMutation,
  type UpdatePropertiesInNotificationMutationVariables,
} from '../../../graphql/notification/__generated__/mutations.graphql';
import {
  type NotificationsQuery,
  type NotificationsQueryVariables,
} from '../../../graphql/notification/__generated__/queries.graphql';
import {
  type NotificationCreatedOrUpdatedSubscription,
  type NotificationCreatedOrUpdatedSubscriptionVariables,
} from '../../../graphql/notification/__generated__/subscription.graphql';
import { GQL_UPDATE_PROPERTIES_IN_NOTIFICATION } from '../../../graphql/notification/mutations';
import { GQL_NOTIFICATIONS_GET_AND_COUNT } from '../../../graphql/notification/queries';
import { ON_NOTIFICATION_CREATED_OR_UPDATED } from '../../../graphql/notification/subscription';

const GqlNotificationPopper = (props: ReduxPropsType): JSX.Element => {
  const [page, setPage] = useState(0);
  const { userID } = props;

  const variables = useMemo(
    () => ({
      first: 5,
      skip: page * 5,
      where: { user: { id: userID } },
      whereCount: { hasBeenSeen: false, user: { id: userID } },
    }),
    [page, userID],
  );

  const [getNotifications, { data }] = useLazyQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(GQL_NOTIFICATIONS_GET_AND_COUNT, {
    context: { clientName: 'V2' },
  });
  const [updatePropertiesInNotification] = useMutation<
    UpdatePropertiesInNotificationMutation,
    UpdatePropertiesInNotificationMutationVariables
  >(GQL_UPDATE_PROPERTIES_IN_NOTIFICATION);

  useSubscription<
    NotificationCreatedOrUpdatedSubscription,
    NotificationCreatedOrUpdatedSubscriptionVariables
  >(ON_NOTIFICATION_CREATED_OR_UPDATED, {
    onData: () => {
      getNotifications({
        context: { clientName: 'V2' },
        variables,
      });
    },
    variables: { userID },
  });

  const fetchNotifications = useCallback(
    () =>
      getNotifications({
        context: {
          clientName: 'V2',
        },
        variables,
      }),
    [getNotifications, variables],
  );

  const notifications = _get(data, 'data', []) || [];
  const totalCount = _get(data, 'totalCount', 0) || 0;

  useEffect(() => {
    fetchNotifications();
  }, [page, fetchNotifications]);

  return (
    <NotificationPopper
      {...props}
      canGoToNextPage={(page + 1) * 5 < totalCount}
      canGoToPreviousPage={page > 0}
      fetchNotifications={fetchNotifications}
      goToNextPage={() => setPage(page + 1)}
      goToPreviousPage={() => setPage(page - 1)}
      notifications={notifications}
      updatePropertiesInNotification={updatePropertiesInNotification}
    />
  );
};

export default GqlNotificationPopper;
