import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type ProjectUserState } from './types';

const sliceProjectUser = createSlice({
  initialState,
  name: 'project-user',
  reducers: {
    PROJECT_USER_FETCHED(state) {
      return { ...state, fetched: true };
    },
    PROJECT_USER_INITIALIZE() {
      return initialState;
    },
    PROJECT_USER_UPDATE(state, action: PayloadAction<ProjectUserState>) {
      if (!action.payload) {
        return initialState;
      }
      return { ...action.payload, fetched: true };
    },
  },
});

export const { PROJECT_USER_FETCHED, PROJECT_USER_INITIALIZE, PROJECT_USER_UPDATE } =
  sliceProjectUser.actions;

export default sliceProjectUser.reducer;
