import { gql } from '@apollo/client';

export const USER_FRAGMENTS = gql`
  fragment UserInfos on User {
    activated
    email
    firstname
    hasCompletedLabelingTour
    hubspotSubscriptionStatus
    id
    isServiceAccount
    lastname
    organizationRole
    rights
    status
    matchingDomainOrganizations {
      id
      name
      matchingDomain
    }
  }
`;

export const API_KEY_FRAGMENTS = gql`
  fragment ApiKeyInfos on ApiKey {
    createdAt
    expiryDate
    id
    name
    revoked
  }
`;
