import { connect } from 'react-redux';

import IdleTimer from './IdleTimer';

import { addNotification, clearNotification } from '../../redux/application/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  addNotification,
  clearNotification,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(IdleTimer);
