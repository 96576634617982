import { createStyles, type StyleRules, type Theme, withStyles } from '@material-ui/core/styles';

import NotificationIcon from './NotificationIcon';

export const styles = (theme: Theme): StyleRules<'notificationIcon'> =>
  createStyles({
    notificationIcon: {
      color: theme.palette.omega.accent5.main,
    },
  });

export default withStyles(styles)(NotificationIcon);
