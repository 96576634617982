import { type EntityAnnotation } from '@kili-technology/types';
import _get from 'lodash/get';
import { Editor, type Path, Text } from 'slate';

import { CONTENT } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { type SlateAnnotation } from '../../components/asset-ui/Text/RichText/helpers/decorations';
import { getGlobalOffsetFromAnnotation } from '../../redux/asset-label/helpers';

export const smallestMidIndex = (annotations: SlateAnnotation[]): number =>
  annotations.reduce((smallestIndexYet: number, _, index: number) => {
    return _get(annotations, [index, CONTENT], '').length <
      _get(annotations, [smallestIndexYet, CONTENT], '').length
      ? index
      : smallestIndexYet;
  }, 0);

export const getPathsFromAnnotation = (editor: Editor, annotation: SlateAnnotation): Path[] => {
  const range = {
    anchor: annotation.beginPoint,
    focus: annotation.endPoint,
  };
  if (
    !Editor.hasPath(editor, _get(annotation, ['beginEntry', 1])) ||
    !Editor.hasPath(editor, _get(annotation, ['endEntry', 1]))
  ) {
    return [];
  }
  const nodes = Array.from(
    Editor.nodes(editor, {
      at: range,
      match: node => Text.isText(node),
    }),
  );
  const paths = nodes.map(nodeEntry => nodeEntry[1]);
  return paths;
};

export const getKeyFromPath = (path: Path): string =>
  'key'.concat(path.map(n => n.toString()).join(''));

export const getBatchedAnnotationFromGlobalAnnotation = (
  annotation: EntityAnnotation,
): EntityAnnotation => {
  const { beginOffset, content, beginId } = annotation;
  const newBeginId = 'main/[0]';
  const newEndId = 'main/[0]';
  let newBeginOffset = beginOffset;

  // we need to convert old format anotations to new format
  if (beginId !== newBeginId) {
    newBeginOffset = getGlobalOffsetFromAnnotation(beginId, beginOffset);
  }
  const endOffset = newBeginOffset + content.length;
  return {
    ...annotation,
    beginId: newBeginId,
    beginOffset: newBeginOffset,
    endId: newEndId,
    endOffset,
  };
};
