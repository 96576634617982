import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type UserState } from './types';

const sliceUser = createSlice({
  initialState,
  name: 'user',
  reducers: {
    USER_INITIALIZE_STATE() {
      return initialState;
    },
    USER_UPDATE(state, action: PayloadAction<UserState>) {
      return action.payload;
    },
    USER_UPDATE_FIELD(state, action) {
      const { path, value } = action.payload;
      return { ...state, [path]: value };
    },
  },
});

export const { USER_INITIALIZE_STATE, USER_UPDATE, USER_UPDATE_FIELD } = sliceUser.actions;

export default sliceUser.reducer;
