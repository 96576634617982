import { type AnnotationFragment } from '@/graphql/annotations/__generated__/fragments.graphql';
import { GQL_FRAGMENT_ANNOTATION } from '@/graphql/annotations/fragments';
import { type Annotation } from '@/graphql/annotations/types';
import {
  getApolloClient,
  getCacheIdFromGraphQLObject,
  type GraphQLObject,
} from '@/graphql/helpers';

export function getAnnotationFromCache(cacheIdOrGraphQLObject: string | GraphQLObject): Annotation {
  const client = getApolloClient();

  const cacheId =
    typeof cacheIdOrGraphQLObject === 'string'
      ? cacheIdOrGraphQLObject
      : getCacheIdFromGraphQLObject(cacheIdOrGraphQLObject);

  const annotation = client.readFragment<AnnotationFragment>({
    fragment: GQL_FRAGMENT_ANNOTATION,
    id: cacheId,
  });
  if (annotation == null) {
    throw new Error(`No annotation with id ${cacheId} found in cache`);
  }
  return annotation;
}
