import { type JSX } from '@duetds/date-picker/custom-element';
import { type DuetDatePickerChangeEvent } from '@duetds/date-picker/dist/types/components/duet-date-picker/duet-date-picker';
import { cx } from '@emotion/css';
import React, { useEffect, useRef, useState } from 'react';

import useStyles from './DatePicker.style';

type DuetDatePickerWithValueAsDate = JSX.DuetDatePicker & DuetDatePickerChangeEvent;

const useListener = (
  ref: React.RefObject<HTMLDuetDatePickerElement>,
  eventName: string,
  handler: (event: CustomEvent<DuetDatePickerWithValueAsDate>) => void,
) => {
  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      element.addEventListener(eventName, handler as EventListener);
      return () => element.removeEventListener(eventName, handler as EventListener);
    }
    return () => null;
  }, [eventName, handler, ref]);
};

type DatePickerProps = {
  className?: string;
  dataCy?: string;
  direction?: 'left' | 'right';
  errorMessage?: string;
  onChange?: (error: boolean, value: string) => void;
  onDuetChange: (event: CustomEvent<DuetDatePickerWithValueAsDate>) => void;
  valid?: boolean;
  value?: Date;
};

const ISO_FORMAT = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
const DATE_PICKER_ESTIMATED_CLOSE_DELAY = 300;

export const parseDate = (date: string, format: RegExp = ISO_FORMAT): Date | undefined => {
  const matches = date.match(format);

  if (!matches) return undefined;

  const yearInt = parseInt(matches[1], 10);
  const monthInt = parseInt(matches[2], 10);
  const dayInt = parseInt(matches[3], 10);

  if (yearInt < 0 || monthInt <= 0 || dayInt <= 0) return undefined;
  if (monthInt > 12) return undefined;
  const daysInMonth = new Date(yearInt, monthInt, 0).getDate();
  if (dayInt > daysInMonth) return undefined;

  return new Date(yearInt, monthInt - 1, dayInt);
};

const DatePicker: React.FC<DatePickerProps> = ({
  className,
  dataCy = 'date-picker',
  direction = 'right',
  errorMessage,
  onChange,
  onDuetChange,
  value,
  valid = true,
}) => {
  const ref = useRef<HTMLDuetDatePickerElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useListener(ref, 'duetChange', onDuetChange);

  useListener(ref, 'duetClose', () => {
    // The date-picker is considered close when the closing animation is done.
    setTimeout(() => {
      setIsOpen(false);
    }, DATE_PICKER_ESTIMATED_CLOSE_DELAY);
  });

  useListener(ref, 'duetOpen', () => {
    setIsOpen(true);
  });

  let dateString = '';

  if (value) {
    const year = value.getFullYear().toString();
    const month = `0${(value.getMonth() + 1).toString()}`.slice(-2);
    const date = `0${value.getDate().toString()}`.slice(-2);
    dateString = `${year}-${month}-${date}`;
  }

  useEffect(() => {
    const dateAdapter = {
      format(date: Date) {
        const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        return newDate;
      },
      parse(date = '') {
        if (onChange) onChange(false, date);

        const newDate = parseDate(date);
        if (newDate) {
          if (onChange) onChange(true, date);
          return newDate;
        }

        return undefined;
      },
    };
    if (ref?.current) {
      ref.current.dateAdapter = dateAdapter;
      ref.current.onkeydown = e => e.stopPropagation();
    }
  }, [onChange]);

  const styles = useStyles();
  return (
    <>
      <duet-date-picker
        class={cx(className, styles.datePicker, !valid && styles.datePickerInvalid)}
        data-cy={dataCy}
        direction={direction}
        ref={ref}
        style={{ overflow: isOpen ? 'unset' : 'hidden' }}
        value={dateString}
      />
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </>
  );
};

export default DatePicker;
