/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';

import { dateToFormat } from '../helpers/xdate';

const defaultLng = 'en';

export const resources = {
  en: {
    translation: en,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLng,
    interpolation: {
      escapeValue: false,
      format: (value: any, format: any) => {
        if (format === 'uppercase') return value.toUpperCase();
        if (value instanceof Date) return dateToFormat(value, format);
        return value;
      },
    },
    resources,
    supportedLngs: [defaultLng],
  });

export default i18n;

export const getTranslation = i18n.t;
