import { createSelector } from 'reselect';

import { type OrganizationState } from './types';

import { GenericSegmentEvents, type SegmentEvents } from '../../pages/RootModule/helpers';
import { createGenericSelectorsFlag } from '../helpers/flag';
import {
  projectID as projectIdSelector,
  projectTitle as projectTitleSelector,
} from '../project/selectors';
import { projectUserRole as projectUserRoleSelector } from '../project-user/selectors';
import { projectInputType as projectInputTypeSelector } from '../selectors';
import { type State } from '../types';
import { userEmail } from '../user/selectors';

export const { isError: isOrganizationError, isLoading: isOrganizationLoading } =
  createGenericSelectorsFlag('organization');

export const organizationState = createSelector(
  [(state: State) => state.organization],
  organization => organization,
);

export const organizationId = createSelector([organizationState], organization => organization?.id);

export const organizationName = (state: State): OrganizationState['name'] =>
  state.organization?.name;

export const organizationAddress = (state: State): OrganizationState['address'] =>
  state.organization?.address;

export const organizationZipCode = (state: State): OrganizationState['zipCode'] =>
  state.organization?.zipCode;

export const organizationCity = (state: State): OrganizationState['city'] =>
  state.organization?.city;

export const organizationCountry = (state: State): OrganizationState['country'] =>
  state.organization?.country;

export const organizationLicense = createSelector(
  [organizationState],
  organization => organization?.license ?? undefined,
);

export const organizationLicenseType = createSelector([organizationLicense], license => {
  if (!license) return undefined;
  return license.type;
});

const organizationIsAllowedToSubmitNewLabel = createSelector(
  [organizationState],
  organization => organization?.isAllowedToSubmitNewLabels ?? true,
);

export const organizationCannotSubmitNewLabelsFromLicense = createSelector(
  [organizationIsAllowedToSubmitNewLabel],
  isAllowedToSubmitNewLabels => !isAllowedToSubmitNewLabels,
);

export const organizationEmails = createSelector(
  [organizationState],
  organization => organization?.emails,
);

type PropsTypeForSegment = {
  event: SegmentEvents;
  propertiesForSegment?: Record<string, unknown>;
};

const getPropsForSegment = (state: State, ownProps: PropsTypeForSegment) =>
  ownProps.propertiesForSegment ?? {};

const getSegmentEvent = (state: State, ownProps: PropsTypeForSegment) => ownProps.event;

const genericDataPropertiesToSegment = createSelector(
  [organizationId, organizationName, organizationLicenseType, userEmail, getPropsForSegment],
  (id, name, licenseType, email, properties) => {
    const emailDomain = email.split('@')[1];
    return {
      ...properties,
      email,
      emailDomain,
      organizationId: id,
      organizationLicenseType: licenseType,
      organizationName: name,
    };
  },
);

const projectDataPropertiesToSegment = createSelector(
  [
    genericDataPropertiesToSegment,
    projectIdSelector,
    projectTitleSelector,
    projectUserRoleSelector,
    projectInputTypeSelector,
  ],
  (genericData, projectId, projectName, projectRole, projectInputType) => {
    return { ...genericData, projectID: projectId, projectInputType, projectName, projectRole };
  },
);

export const dataForSegment = createSelector(
  [genericDataPropertiesToSegment, projectDataPropertiesToSegment, getSegmentEvent],
  (genericData, projectData, event) => {
    const isAGenericEvent = Object.values(GenericSegmentEvents).includes(
      event as GenericSegmentEvents,
    );
    return isAGenericEvent ? genericData : projectData;
  },
);

export const dataForScreeb = createSelector(
  [organizationId, organizationName, organizationLicenseType],
  (id, name, licenseType) => {
    if (!id || !name || !licenseType) return null;
    return {
      organizationId: id,
      organizationName: name,
      plan: licenseType,
    };
  },
);
