import { createSelector } from 'reselect';

import { type ProjectUserState } from './types';

import { ProjectRole } from '../../__generated__/globalTypes';
import { isProjectAnonymizedSelector } from '../project/selectors';
import { type State } from '../types';

export const projectUser = (state: State): ProjectUserState | null => state.projectUser ?? null;

export const projectUserRole = (state: State): ProjectRole | null => {
  return state.projectUser?.role ?? null;
};

export const projectUserIsActivated = (state: State): boolean => {
  return state.projectUser?.activated ?? false;
};

export const projectUserUserId = createSelector(
  [projectUser],
  projectUserValue => projectUserValue?.user?.id ?? '',
);

export const projectUserIsReviewer = (state: State): boolean =>
  projectUserIsActivated(state) &&
  [ProjectRole.REVIEWER, ProjectRole.TEAM_MANAGER, ProjectRole.ADMIN].includes(
    projectUserRole(state) as ProjectRole,
  );

export const projectUserIsTeamManager = (state: State): boolean =>
  projectUserIsActivated(state) &&
  [ProjectRole.TEAM_MANAGER, ProjectRole.ADMIN].includes(projectUserRole(state) as ProjectRole);

export const projectUserIsLabeler = (state: State): boolean =>
  ProjectRole.LABELER.valueOf() === projectUserRole(state) && projectUserIsActivated(state);

export const projectUserIsAdmin = (state: State): boolean =>
  ProjectRole.ADMIN.valueOf() === projectUserRole(state) && projectUserIsActivated(state);

export const projectUserIsFetched = createSelector(
  [projectUser],
  projectUserValue => projectUserValue?.fetched === true,
);

export const shouldAnonymizeDataForProjectUser = createSelector(
  [isProjectAnonymizedSelector, projectUserRole],
  (isProjectAnonymized, currentProjectUserRole) => {
    const isLabelerOrReviewer =
      currentProjectUserRole === ProjectRole.LABELER ||
      currentProjectUserRole === ProjectRole.REVIEWER;

    return isLabelerOrReviewer && isProjectAnonymized;
  },
);
