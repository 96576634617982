import { type ApolloClient, type InMemoryCache } from '@apollo/client';

import { type AssetGateway } from './assetGateway';

import {
  type GetAssetQueryVariables,
  type GetAssetQuery,
} from '../../graphql/asset/__generated__/queries.graphql';
import { GQL_DATASET_GET_ASSET } from '../../graphql/asset/queries';

class ApolloAssetGateway implements AssetGateway {
  constructor(private client: ApolloClient<InMemoryCache>) {
    this.client = client;
  }

  read(queryCriteria: GetAssetQueryVariables): Promise<{ data: GetAssetQuery; loading: boolean }> {
    return this.client.query<GetAssetQuery>({
      context: { clientName: 'V2' },
      fetchPolicy: 'cache-first',
      query: GQL_DATASET_GET_ASSET,
      variables: queryCriteria,
    });
  }
}

export default ApolloAssetGateway;
