import { type Draft } from 'immer';
import { type WritableDraft } from 'immer/dist/types/types-external';

import computeCountToUpdate from './helpers';
import { initialState } from './initialState';
import { projectQueueSelection } from './selectors';
import {
  type PrioritizeAssetsPayload,
  type ProjectQueueCounts,
  type ProjectQueueSelectCounters,
  type ProjectQueueSlice,
  type ProjectQueueSliceValues,
} from './types';

import { type ZustandSlice, type ZustandStore } from '..';
import { type Status } from '../../__generated__/globalTypes';
import { GQL_PRIORITIZE_ASSETS } from '../../graphql/asset/mutations';
import createLogger from '../helpers';

const log = createLogger('project-queue');

const setStateValues = (
  state: WritableDraft<ZustandStore>,
  sliceValues:
    | ProjectQueueSliceValues
    | Partial<ProjectQueueCounts>
    | ProjectQueueSelectCounters = initialState,
) => {
  Object.entries(sliceValues).forEach(([key, value]) => {
    (state.projectQueue[key as keyof typeof sliceValues] as unknown) = value;
  });
};

export const createProjectQueueSlice: ZustandSlice<ProjectQueueSlice> = (set, getState) => ({
  ...initialState,
  initialize: () => set(setStateValues, false, log('initialize')),

  prioritizeAssets: async (payload: PrioritizeAssetsPayload) => {
    const { client, priority, callback, where = {}, projectID } = payload;
    const projectQueueWhere = projectQueueSelection(getState());

    await client.mutate({
      context: { clientName: 'V2' },
      mutation: GQL_PRIORITIZE_ASSETS,
      variables: {
        priority,
        where: {
          ...where,
          ...projectQueueWhere,
          project: { id: projectID },
        },
      },
    });

    callback();
  },

  resetProjectInfos: payload =>
    set(state => setStateValues(state, payload), false, log('resetProjectInfos')),

  selectOrUnselectAllAssets: (selectAll?: boolean) =>
    set(
      state => {
        const { areAllAssetsSelected } = state.projectQueue;
        setStateValues(state, {
          ...initialState,
          areAllAssetsSelected: selectAll ?? !areAllAssetsSelected,
        });
      },
      false,
      'selectOrUnselectAllAssets',
    ),

  selectOrUnselectAsset: (assetId: string, status?: Status, isHoneypot?: boolean | null) =>
    set(
      state => {
        const incrementForCount = setUpdateSelectionState(state, assetId);
        const countToUpdate = computeCountToUpdate(
          state.projectQueue,
          incrementForCount,
          status,
          isHoneypot,
        );

        setStateValues(state, countToUpdate);
      },
      false,
      log('selectOrUnselectAsset', assetId),
    ),
  updateCounts: payload =>
    set(
      state => {
        Object.entries(payload).forEach(([key, value]) => {
          (state.projectQueue[key as keyof ProjectQueueCounts] as unknown) = value;
        });
      },
      false,
      log('updateCounts', payload),
    ),

  updateProjectQueueField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.projectQueue as Draft<ProjectQueueSliceValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
  updateSelectionState: (assetId: string) =>
    set(
      state => setUpdateSelectionState(state, assetId),
      false,
      log('updateSelectionState', assetId),
    ),
});

function setUpdateSelectionState(state: WritableDraft<ZustandStore>, assetId: string) {
  const { selectedAssetIds, areAllAssetsSelected, unselectedAssetIds } = state.projectQueue;

  const isSelectingAnAsset = unselectedAssetIds.includes(assetId);

  if (areAllAssetsSelected && isSelectingAnAsset) {
    state.projectQueue.unselectedAssetIds = unselectedAssetIds.filter(
      unselectedAssetId => unselectedAssetId !== assetId,
    );
    return 1;
  }

  if (areAllAssetsSelected) {
    state.projectQueue.unselectedAssetIds = [...unselectedAssetIds, assetId];
    return -1;
  }

  const isUnselectingAnAsset = selectedAssetIds.includes(assetId);
  if (isUnselectingAnAsset) {
    state.projectQueue.selectedAssetIds = selectedAssetIds.filter(
      selectedAssetId => selectedAssetId !== assetId,
    );
    return 0;
  }

  state.projectQueue.selectedAssetIds = [...selectedAssetIds, assetId];
  return 0;
}
