import { type LabelRichTextSlice, type LabelRichTextSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: LabelRichTextSliceValues = {
  incrementRenderAll: 0,
  lastTextResized: undefined,
  textNodesRenderTrigger: {},
};

const log = createLogger('labelRichText');

export const createLabelRichTextSlice: ZustandSlice<LabelRichTextSlice> = set => ({
  ...initialState,
  increaseIncrementRenderAll: () =>
    set(state => {
      state.labelRichText.incrementRenderAll += 1;
    }),
  initialize: () =>
    set(state => {
      Object.entries(initialState).forEach(([key, value]) => {
        const keyInitialState = key as keyof typeof initialState;
        (state.labelRichText[keyInitialState] as unknown) = value;
      });
    }),
  setLastTextResized: () =>
    set(
      state => {
        state.labelRichText.lastTextResized = Date.now();
      },
      false,
      log('setLastTextResized'),
    ),
  updateNodeRender: payload =>
    set(
      state => {
        state.labelRichText.textNodesRenderTrigger = payload;
      },
      false,
      log('updateNodeRender', payload),
    ),
});

export * from './types';
