import { type BlankKeyAnnotation } from '@/graphql/annotations/types';

export const isBlankKeyAnnotation = (input: unknown): input is BlankKeyAnnotation => {
  return (
    typeof input === 'object' &&
    !!input &&
    '__typename' in input &&
    input.__typename === 'BlankKeyAnnotation'
  );
};
