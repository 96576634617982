import {
  type Annotation,
  isDetectionTask,
  type JobAnnotation,
  MachineLearningTask,
} from '@kili-technology/types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import _set from 'lodash/set';
import _uniq from 'lodash/uniq';

import { initialState } from './initialState';
import {
  cleanRelationsFromAnnotationWhichChangedClass,
  deleteRelationAfterObjectDeletion,
} from './relations';
import {
  type JobsAddAnnotationPayload,
  type JobsChangeAnnotationClassPayload,
  type JobsCopyAnnotationPayload,
  type JobsRemoveAnnotationPayload,
  type JobsSetClassificationCleanPayload,
  type JobsSetResponseAtPageLevelPayload,
  type JobsSetResponsePayload,
  type JobsSetResponsesPayload,
  type JobsUpdateAnnotationsPayload,
} from './types';

import { ANNOTATIONS } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { type KiliAnnotation } from '../../services/jobs/setResponse';
import { MID_SPLIT_POINT_STRING } from '../../services/poseEstimation';
import { responseFromResponsesToSet } from '../helpers';

const sliceJobs = createSlice({
  initialState,
  name: 'jobs',
  reducers: {
    JOBS_ADD_ANNOTATION(state, action: PayloadAction<JobsAddAnnotationPayload>) {
      const { mlTask, annotation } = action.payload;
      const { jobName } = annotation;
      if (
        mlTask === MachineLearningTask.ASSET_ANNOTATION ||
        mlTask === MachineLearningTask.CLASSIFICATION ||
        mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION ||
        mlTask === MachineLearningTask.TRANSCRIPTION ||
        mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION ||
        mlTask === MachineLearningTask.RANKING
      )
        return state;
      const annotations = state?.[mlTask]?.[jobName]?.[ANNOTATIONS];
      if (!annotations) {
        _set(state, [mlTask, jobName, ANNOTATIONS], [annotation]);
      } else {
        (annotations as KiliAnnotation[]).push(annotation);
      }
    },
    JOBS_CHANGE_ANNOTATION_CLASS(state, action: PayloadAction<JobsChangeAnnotationClassPayload>) {
      const {
        categoryCode,
        jobName,
        mid,
        mlTask,
        previousJobName,
        newCategoryItemJobNames,
        relationsCategoriesToRemoveObjectFrom,
      } = action.payload;
      if (!isDetectionTask(mlTask)) {
        return state;
      }
      const getNewAnnotationFromOld = (annotation: KiliAnnotation): KiliAnnotation => ({
        ...annotation,
        categories: [{ name: categoryCode }],
        jobName,
      });
      const stateTask = state[mlTask];
      const stateTranscription = state[mid];
      if (!stateTask) return state;
      if (previousJobName !== jobName) {
        const previousJobResponse = { ...stateTask?.[previousJobName] };
        const newJobResponse = { ...stateTask?.[jobName] };
        const previousJobAnnotations = (previousJobResponse?.[ANNOTATIONS] ||
          []) as KiliAnnotation[];
        const previousJobNewAnnotations = previousJobAnnotations.filter(
          annotation => annotation.mid !== mid,
        );
        const newMidAnnotations = previousJobAnnotations
          .filter(annotation => annotation.mid === mid)
          .map(annotation => getNewAnnotationFromOld(annotation));
        const newJobCurrentAnnotations = (newJobResponse?.[ANNOTATIONS] || []) as KiliAnnotation[];
        const newJobAnnotations = newJobCurrentAnnotations.concat(newMidAnnotations);
        if (!stateTask[jobName]) {
          stateTask[jobName] = { [ANNOTATIONS]: [] };
        }
        if (!stateTask[previousJobName]) {
          stateTask[previousJobName] = { [ANNOTATIONS]: [] };
        }
        (stateTask[jobName] as JobAnnotation)[ANNOTATIONS] = newJobAnnotations;
        (stateTask[previousJobName] as JobAnnotation)[ANNOTATIONS] = previousJobNewAnnotations;
      }
      const jobResponse = { ...stateTask?.[jobName] };
      const previousJobAnnotations = (jobResponse?.[ANNOTATIONS] || []) as KiliAnnotation[];
      const newJobAnnotations = previousJobAnnotations.map(annotation => {
        if (annotation.mid !== mid) return annotation;
        return getNewAnnotationFromOld(annotation);
      });
      if (!stateTask[jobName]) {
        stateTask[jobName] = { [ANNOTATIONS]: [] };
      }
      (stateTask[jobName] as JobAnnotation)[ANNOTATIONS] = newJobAnnotations;

      if (stateTranscription && stateTranscription?.TRANSCRIPTION && newCategoryItemJobNames) {
        const currentAnno = Object.keys(stateTranscription.TRANSCRIPTION);
        currentAnno.forEach(anno => {
          if (!stateTranscription.TRANSCRIPTION?.[anno].text) {
            delete state[mid]?.TRANSCRIPTION;
          }
        });
        let newTranscriptions = {};
        currentAnno.forEach(item => {
          newTranscriptions = {
            ...newTranscriptions,
            ...newCategoryItemJobNames.reduce((acc, cur) => {
              return cur.oldJobName === item
                ? {
                    ...acc,
                    [cur.newJobName]: { ...stateTranscription?.TRANSCRIPTION?.[item] },
                  }
                : { ...acc };
            }, {}),
          };
        });
        stateTranscription.TRANSCRIPTION = newTranscriptions;
      }

      if (stateTranscription && stateTranscription?.CLASSIFICATION && newCategoryItemJobNames) {
        const currentAnno = Object.keys(stateTranscription.CLASSIFICATION);
        currentAnno.forEach(anno => {
          if (!stateTranscription.CLASSIFICATION?.[anno]) {
            delete state[mid]?.CLASSIFICATION;
          }
        });
        let newClassifications = {};

        currentAnno.forEach(item => {
          newClassifications = {
            ...newClassifications,
            ...newCategoryItemJobNames.reduce((acc, cur) => {
              return cur.oldJobName === item
                ? {
                    ...acc,
                    [cur.newJobName]: { ...stateTranscription?.CLASSIFICATION?.[item] },
                  }
                : { ...acc };
            }, {}),
          };
        });
        stateTranscription.CLASSIFICATION = newClassifications;
      }

      cleanRelationsFromAnnotationWhichChangedClass(
        state,
        mid,
        relationsCategoriesToRemoveObjectFrom,
      );
    },
    JOBS_COPY_ANNOTATION(state, action: PayloadAction<JobsCopyAnnotationPayload>) {
      const { annotationToCopyMid, newAnnotationMid } = action.payload;
      if (!!state[annotationToCopyMid] && !state[newAnnotationMid]) {
        state[newAnnotationMid] = state[annotationToCopyMid];
      }
    },
    JOBS_INITIALIZE() {
      return initialState;
    },
    JOBS_REMOVE_ANNOTATION(state, action: PayloadAction<JobsRemoveAnnotationPayload>) {
      const { mlTask, jobName, mid, objectParts } = action.payload;
      if (
        mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION ||
        mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION
      ) {
        return state;
      }

      if (!state[mlTask]) {
        return state;
      }

      const stateTask = state[mlTask];
      if (!stateTask) return state;

      (stateTask[jobName] as JobAnnotation)[ANNOTATIONS] = (stateTask[jobName] as JobAnnotation)[
        ANNOTATIONS
      ].filter(annotation => annotation.mid !== mid);

      delete state[mid];
      objectParts.forEach(
        objectPart => delete state[`${mid}${MID_SPLIT_POINT_STRING}${objectPart}`],
      );
      deleteRelationAfterObjectDeletion(state, mid);
    },
    JOBS_REMOVE_MIDS(state, { payload }: PayloadAction<string[]>) {
      payload.forEach(mid => delete state[mid]);
    },
    JOBS_SET_CLASSIFICATION_RESPONSE(
      state,
      action: PayloadAction<JobsSetClassificationCleanPayload>,
    ) {
      const { parentMid: mid, jobName, jobResponse, jobsToClean } = action.payload;
      if (mid) {
        if (!state?.[mid]) {
          state[`${mid}`] = { [MachineLearningTask.CLASSIFICATION]: { [jobName]: jobResponse } };
        } else {
          state[`${mid}`] = {
            ...state[`${mid}`],
            [MachineLearningTask.CLASSIFICATION]: {
              ...(state[`${mid}`]?.[MachineLearningTask.CLASSIFICATION] ?? {}),
              [jobName]: jobResponse,
            },
          };
        }
        jobsToClean.forEach(jobToClean => {
          const { mlTask, jobName: jobNameToClean } = jobToClean;
          delete state?.[mid]?.[mlTask]?.[jobNameToClean];
        });
      } else {
        if (!state.CLASSIFICATION) {
          state.CLASSIFICATION = { [jobName]: jobResponse };
        }
        state.CLASSIFICATION[jobName] = jobResponse;
      }

      jobsToClean.forEach(jobToClean => {
        const { mlTask, jobName: jobNameToClean } = jobToClean;
        delete state?.[mlTask]?.[jobNameToClean];
      });
    },
    JOBS_SET_CURRENT_FRAME_RESPONSE(state, action: PayloadAction<JobsSetResponsesPayload>) {
      const { responsesToSet } = action.payload;
      return responseFromResponsesToSet(responsesToSet, {});
    },
    JOBS_SET_RESPONSE(state, action: PayloadAction<JobsSetResponsePayload>) {
      const mid = action.payload.parentMid;
      let { jobResponse } = action.payload;
      const { mlTask } = action.payload;
      if (
        mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION ||
        mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION
      ) {
        return state;
      }
      if (ANNOTATIONS in jobResponse) {
        jobResponse = {
          ...jobResponse,
          [ANNOTATIONS]: (jobResponse as JobAnnotation)?.[ANNOTATIONS].map(
            (annotation: Annotation) => ({
              ...annotation,
              jobName: action.payload.jobName,
              mlTask: action.payload.mlTask,
            }),
          ),
        };
      }
      if (mid) {
        return {
          ...state,
          [mid]: {
            ...state?.[mid],
            [mlTask]: {
              ...state?.[mid]?.[mlTask],
              [action.payload.jobName]: jobResponse,
            },
          },
        };
      }
      return {
        ...state,
        [mlTask]: {
          ...state?.[mlTask],
          [action.payload.jobName]: jobResponse,
        },
      };
    },
    JOBS_SET_RESPONSES(state, action: PayloadAction<JobsSetResponsesPayload>) {
      const { responsesToSet, shouldUseInitialState } = action.payload;
      const startingState = !shouldUseInitialState ? state : initialState;
      return responseFromResponsesToSet(responsesToSet, startingState);
    },
    JOBS_SET_RESPONSE_AT_PAGE_LEVEL(
      state,
      action: PayloadAction<JobsSetResponseAtPageLevelPayload>,
    ) {
      const { jobName, jobResponse, mlTask, pageNumber } = action.payload;
      if (
        mlTask !== MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION &&
        mlTask !== MachineLearningTask.PAGE_LEVEL_CLASSIFICATION
      ) {
        return state;
      }
      const newIds = _uniq([...(state?.[mlTask]?.[jobName]?.allIds ?? []), pageNumber.toString()]);

      return {
        ...state,
        [mlTask]: {
          ...state?.[mlTask],
          [jobName]: {
            ...state?.[mlTask]?.[jobName],
            allIds: newIds,
            byId: {
              ...state?.[mlTask]?.[jobName]?.byId,
              [pageNumber]: jobResponse,
            },
          },
        },
      };
    },
    JOBS_UPDATE_ANNOTATIONS(state, action: PayloadAction<JobsUpdateAnnotationsPayload>) {
      const { mlTask, annotations } = action.payload;

      if (annotations.length === 0 || !isDetectionTask(mlTask)) return;

      const { jobName } = annotations[0];

      const previousStateAnnotations: Annotation[] = state[mlTask]?.[jobName]?.annotations ?? [];

      const updatedAnnotationMids = new Set(annotations.map(a => a.mid));
      const annotationMidsToIsInserted = new Map<string, boolean>();

      const newAnnotations = previousStateAnnotations
        .map(prevAnnotation => {
          if (annotationMidsToIsInserted.get(prevAnnotation.mid)) return undefined;
          if (updatedAnnotationMids.has(prevAnnotation.mid)) {
            annotationMidsToIsInserted.set(prevAnnotation.mid, true);
            return annotations
              .filter(a => a.mid === prevAnnotation.mid)
              .map(a => ({
                ...prevAnnotation,
                ...a,
              }));
          }
          return prevAnnotation;
        })
        .filter(a => !!a)
        .concat(
          [...updatedAnnotationMids.values()]
            .filter(mid => !annotationMidsToIsInserted.get(mid))
            .map(mid => annotations.filter(a => a.mid === mid))
            .flat(),
        )
        .flat() as KiliAnnotation[];

      (state[mlTask] as Record<string, JobAnnotation>) = {
        ...state[mlTask],
        ...{ [jobName]: { annotations: newAnnotations } },
      };
    },
  },
});

export const JOBS_JUMP = 'JOBS_JUMP';

export const {
  JOBS_ADD_ANNOTATION,
  JOBS_CHANGE_ANNOTATION_CLASS,
  JOBS_COPY_ANNOTATION,
  JOBS_INITIALIZE,
  JOBS_REMOVE_ANNOTATION,
  JOBS_REMOVE_MIDS,
  JOBS_SET_CLASSIFICATION_RESPONSE,
  JOBS_SET_RESPONSE,
  JOBS_SET_RESPONSES,
  JOBS_SET_CURRENT_FRAME_RESPONSE,
  JOBS_SET_RESPONSE_AT_PAGE_LEVEL,
  JOBS_UPDATE_ANNOTATIONS,
} = sliceJobs.actions;

export const UNDOABLE_JOBS_ACTIONS = [
  JOBS_ADD_ANNOTATION.type,
  JOBS_CHANGE_ANNOTATION_CLASS.type,
  JOBS_COPY_ANNOTATION.type,
  JOBS_INITIALIZE.type,
  JOBS_REMOVE_ANNOTATION.type,
  JOBS_REMOVE_MIDS.type,
  JOBS_SET_CLASSIFICATION_RESPONSE.type,
  JOBS_SET_RESPONSE.type,
  JOBS_SET_RESPONSES.type,
  JOBS_SET_RESPONSE_AT_PAGE_LEVEL.type,
  JOBS_UPDATE_ANNOTATIONS.type,
];
export default sliceJobs.reducer;
