import { gql } from '@apollo/client';

import {
  ORGANIZATION_FRAGMENTS,
  ORGANIZATION_LICENSE_FRAGMENTS,
  ORGANIZATION_METRICS_FRAGMENTS,
  ORGANIZATION_USAGE_METRICS_FRAGMENTS,
} from './fragments';

export const GQL_ORGANIZATION_GET = gql`
  query GetOrganization($where: OrganizationWhere!, $first: PageSize!, $skip: Int!) {
    data: organizations(where: $where, first: $first, skip: $skip) {
      ...OrganizationInfos
      ...OrganizationLicense
    }
    emails: listEmailsInOrganization(where: $where)
  }
  ${ORGANIZATION_FRAGMENTS}
  ${ORGANIZATION_LICENSE_FRAGMENTS}
`;

export const GQL_ORGANIZATION_METRICS_GET = gql`
  query GetOrganizationWithMetrics($where: OrganizationWhere!, $input: GetUsageOverTimeInput!) {
    organizations(where: $where, first: 1, skip: 0) {
      usageOverTime(input: $input) {
        usageMetrics {
          granularity
          details {
            date
            metrics {
              numberOfAnnotations
              numberOfLabeledAssets
              secondsSpentOnLabeling
            }
          }
          total {
            metrics {
              numberOfAnnotations
              numberOfLabeledAssets
              secondsSpentOnLabeling
            }
            period {
              start
              end
            }
          }
        }
        membersAdded {
          granularity
          details {
            value
            date
          }
          total {
            value
            period {
              start
              end
            }
          }
        }
      }
    }
  }
`;

export const GQL_ORGANIZATION_GET_MEMBERS = gql`
  query GetOrganizationMembers($where: OrganizationWhere!, $first: PageSize!, $skip: Int!) {
    data: organizations(where: $where, first: $first, skip: $skip) {
      users {
        email
        firstname
        lastname
        activated
      }
    }
  }
`;

export const GQL_ORGANIZATION_GET_METRICS = gql`
  query GetOrganizationMetrics($where: OrganizationMetricsWhere!) {
    data: organizationMetrics(where: $where) {
      ...OrganizationMetrics
    }
  }
  ${ORGANIZATION_METRICS_FRAGMENTS}
`;

export const GQL_ORGANIZATION_GET_USAGE = gql`
  query GetOrganizationUsageMetrics($where: OrganizationWhere!) {
    organizationData: organizations(where: $where, first: 1, skip: 0) {
      ...OrganizationUsageMetrics
      ...OrganizationLicense
    }
  }
  ${ORGANIZATION_USAGE_METRICS_FRAGMENTS}
  ${ORGANIZATION_LICENSE_FRAGMENTS}
`;
