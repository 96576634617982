import {
  type ObjectDetectionAnnotationValue,
  type Vertice,
  type VideoObjectDetectionKeyAnnotation,
} from '@/__generated__/globalTypes';

import { type FactoryProperties, type AnnotationFactoryProperties } from './types';

import { getVideoKeyAnnotationId } from '../helpers/getVideoKeyAnnotationId';

export function createVertice({ x, y }: FactoryProperties<Vertice>): Vertice {
  return { __typename: 'Vertice', x, y };
}

export type ObjectDetectionAnnotationValueFactoryProperties = Omit<
  FactoryProperties<ObjectDetectionAnnotationValue>,
  'vertices'
> & {
  vertices: FactoryProperties<Vertice>[][][];
};

export function createObjectDetectionAnnotationValue(
  properties: ObjectDetectionAnnotationValueFactoryProperties,
): ObjectDetectionAnnotationValue {
  const { id, isPrediction, vertices } = properties;
  return {
    __typename: 'ObjectDetectionAnnotationValue',
    id,
    isPrediction,
    vertices: vertices.map(v1 => v1.map(v2 => v2.map(createVertice))),
  };
}

export type VideoObjectDetectionKeyAnnotationFactoryProperties = AnnotationFactoryProperties<
  VideoObjectDetectionKeyAnnotation,
  Omit<ObjectDetectionAnnotationValueFactoryProperties, 'id'>
>;

export function createVideoObjectDetectionKeyAnnotation(
  properties: VideoObjectDetectionKeyAnnotationFactoryProperties,
  annotationId: string,
): VideoObjectDetectionKeyAnnotation {
  const { annotationValue, frame } = properties;
  const { isPrediction, vertices } = annotationValue;
  const id = getVideoKeyAnnotationId(annotationId, properties.frame);
  return {
    __typename: 'VideoObjectDetectionKeyAnnotation',
    annotationValue: createObjectDetectionAnnotationValue({ id, isPrediction, vertices }),
    frame,
    id,
  };
}
