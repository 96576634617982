import PageError from '@kili-technology/pages/dist/page-error/index';
import React, { PureComponent } from 'react';

import { sendToDatadog } from '../../datadog';

type Props = {
  children: React.ReactNode;
};

type State = {
  error?: Error;
};

export class ErrorBoundary extends PureComponent<Props, State> {
  state: State = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: unknown): void {
    sendToDatadog(error, info, 'javascript');
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <PageError email="support@kili-technology.com" />;
    }

    return children;
  }
}
