type CreateScientificNotationPower = {
  negative: string;
  positive: string;
};

const roundNumberToDecimalPoint = (number: number, decimalPoint = 2): number => {
  const createScientificNotationPower = (): CreateScientificNotationPower => {
    const base = `e_${decimalPoint}`;
    return {
      negative: base.replace('_', '-'),
      positive: base.replace('_', '+'),
    };
  };

  const scientificNotationPower = createScientificNotationPower();
  const { negative, positive } = scientificNotationPower;

  return +(Math.round(Number(number + positive)) + negative);
};

export default roundNumberToDecimalPoint;
