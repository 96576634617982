import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type ProjectState } from './types';

import { type User } from '../../__generated__/globalTypes';

const sliceProject = createSlice({
  initialState,
  name: 'project',
  reducers: {
    PROJECT_APPEND(state, action: PayloadAction<ProjectState>) {
      return { ...state, ...action.payload };
    },
    PROJECT_INITIALIZE() {
      return initialState;
    },
    PROJECT_UPDATE(state, action: PayloadAction<ProjectState>) {
      return { ...state, ...action.payload };
    },
    PROJECT_UPDATE_ANONYMIZATION(state, action: PayloadAction<boolean>) {
      return { ...state, isAnonymized: action.payload };
    },
    PROJECT_UPDATE_AUTHOR(state, action: PayloadAction<User>) {
      return { ...state, author: action.payload };
    },
    PROJECT_UPDATE_INSTRUCTIONS(state, action: PayloadAction<string>) {
      return { ...state, instructions: action.payload };
    },
  },
});

export const {
  PROJECT_APPEND,
  PROJECT_INITIALIZE,
  PROJECT_UPDATE,
  PROJECT_UPDATE_ANONYMIZATION,
  PROJECT_UPDATE_AUTHOR,
  PROJECT_UPDATE_INSTRUCTIONS,
} = sliceProject.actions;

export default sliceProject.reducer;
