import { type Jobs, type MachineLearningTask } from '@kili-technology/types';

interface Node {
  categoryCode?: string;
  children: Node[];
  jobName: string;
  mlTask: MachineLearningTask;
}

export type FlatNode = Omit<Node, 'children'>;

interface Tree {
  rootNodes: Node[];
}

export type FlatTree = Record<string, Record<string, FlatNode[]>>;

function buildLocalTree(jobs: Jobs, jobName: string, categoryCode: string): Node {
  const categoryChildren = jobs?.[jobName]?.content?.categories?.[categoryCode]?.children ?? [];
  return {
    categoryCode,
    children: categoryChildren
      .map(childJobName => {
        const childJobCategories = Object.keys(jobs?.[childJobName]?.content?.categories ?? {});
        if (childJobCategories.length > 0) {
          return childJobCategories.map(code => buildLocalTree(jobs, childJobName, code));
        }
        return [{ children: [], jobName: childJobName, mlTask: jobs?.[childJobName].mlTask }];
      })
      .flat(),
    jobName,
    mlTask: jobs?.[jobName].mlTask,
  };
}

export default function buildOntologyTree(jobs: Jobs): Tree {
  const rootJobs = Object.entries(jobs).filter(
    ([__, job]) => !job.isChild && (job?.isVisible ?? true),
  );
  const rootNodes = rootJobs.reduce<Node[]>((currentRootNodes, rootJob) => {
    const [jobName, job] = rootJob;
    const jobCategories = job?.content?.categories ?? {};
    return currentRootNodes.concat(
      Object.keys(jobCategories).map(categoryCode => buildLocalTree(jobs, jobName, categoryCode)),
    );
  }, []);
  return { rootNodes };
}

function getChildNodes(node: Node): FlatNode[] {
  const { children, ...flatNode } = node;
  const childNodes = children.map(getChildNodes).flat();
  return [{ ...flatNode } as FlatNode].concat(childNodes);
}

function updateFlatTree(flatTree: FlatTree, node: Node): void {
  const { jobName, categoryCode, children } = node;
  const nodes = getChildNodes(node);
  const newNodes = (flatTree?.[jobName]?.[categoryCode ?? jobName] ?? []).concat(nodes);
  children.forEach(childNode => updateFlatTree(flatTree, childNode));
  // eslint-disable-next-line no-param-reassign
  if (!flatTree[jobName]) flatTree[jobName] = {};
  // eslint-disable-next-line no-param-reassign
  flatTree[jobName][categoryCode ?? jobName] = newNodes;
}

export function buildFlatOntology(tree: Tree): FlatTree {
  const flatTree: Record<string, Record<string, FlatNode[]>> = {};
  tree.rootNodes.forEach(node => {
    updateFlatTree(flatTree, node);
  });
  return flatTree;
}
