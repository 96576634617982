import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type LastSavedJson } from './types';

const sliceAutosave = createSlice({
  initialState,
  name: 'autosave',
  reducers: {
    AUTOSAVE_INITIALIZE(state) {
      return { ...initialState, lastSavedJson: state.lastSavedJson };
    },
    AUTOSAVE_UPDATE_LAST_SAVED_JSON(state, action: PayloadAction<LastSavedJson>) {
      return {
        ...state,
        lastSavedJson: action.payload,
      };
    },
  },
});

export const { AUTOSAVE_UPDATE_LAST_SAVED_JSON } = sliceAutosave.actions;

export default sliceAutosave.reducer;
