import difference from '@turf/difference';
import { type Feature, type MultiPolygon, type Polygon, type Properties } from '@turf/turf';
import union from '@turf/union';
import L from 'leaflet';

import { getLatLngsFromJSON } from './getLatLngsFromJSON';

import { intersects } from '../../Components/Freedraw/leaflet-freedraw';
import layersByMid from '../../ImageAnnotations/LayerMap';

const addSemanticLayer = (layer: L.Polygon, mid: string): L.Layer[] => {
  const { drawOver } = layer.options;
  const newLayers: L.Layer[] = [];
  const currentLayers = layersByMid.get(mid);
  const otherLayers = [...layersByMid.values()]
    .flat()
    .filter(
      otherLayer =>
        otherLayer instanceof L.Polygon &&
        otherLayer?.kiliOptions &&
        otherLayer?.kiliOptions.type === 'semantic' &&
        otherLayer.kiliOptions.mid !== mid,
    );

  // Subtract other layers
  let layerDrawnGeoJson = layer.toGeoJSON();
  otherLayers.forEach(otherLayer => {
    if (drawOver) return;
    const otherLayerGeoJson = otherLayer.toGeoJSON();
    // @ts-expect-error turf inconsistency
    layerDrawnGeoJson = difference(layerDrawnGeoJson, otherLayerGeoJson);
  });

  if (currentLayers) {
    currentLayers.forEach(currentLayer => {
      const layerGeoJson = currentLayer.toGeoJSON();
      if (!intersects(layerDrawnGeoJson, layerGeoJson)) {
        newLayers.push(currentLayer);
        return;
      }
      // @ts-expect-error turf inconsistency
      layerDrawnGeoJson = union(layerDrawnGeoJson, layerGeoJson);
    });
  }

  if (!layerDrawnGeoJson) return newLayers;
  if (layerDrawnGeoJson.geometry && String(layerDrawnGeoJson.geometry.type) === 'MultiPolygon') {
    const coords = layerDrawnGeoJson.geometry.coordinates;
    if (coords.length === 0) {
      return newLayers;
    }
    coords.forEach(coord => {
      if (Array.isArray(coord)) {
        newLayers.push(new L.Polygon(L.GeoJSON.coordsToLatLngs(coord, 1)));
      }
    });
  } else {
    const mergedLayerLatLngs = getLatLngsFromJSON(layerDrawnGeoJson);
    const newPolygonLayer = new L.Polygon(mergedLayerLatLngs);
    newLayers.push(newPolygonLayer);
  }

  return newLayers;
};

const subtractSemanticLayer = (layer: L.Polygon, mid: string): L.Layer[] => {
  const currentLayers = layersByMid.get(mid);
  if (!currentLayers) return [];
  const geoJsonToSubtract = layer.toGeoJSON();

  const newLayers: L.Layer[] = [];
  const differences: (Feature<Polygon | MultiPolygon, Properties> | null)[] = [];
  currentLayers.forEach(currentLayer => {
    const layerGeoJson = currentLayer.toGeoJSON();
    if (!intersects(geoJsonToSubtract, layerGeoJson)) {
      newLayers.push(currentLayer);
      return;
    }
    // @ts-expect-error turf inconsistency
    const diff = difference(layerGeoJson, geoJsonToSubtract);
    differences.push(diff);
  });

  differences.forEach(diff => {
    if (!diff) return;
    if (diff && diff.geometry.type === 'MultiPolygon') {
      const coords = diff.geometry.coordinates;
      coords.forEach(coord => {
        const latlngsCoords = L.GeoJSON.coordsToLatLngs(coord, 1);
        const newPolygonLayerPiece = new L.Polygon(latlngsCoords);
        newLayers.push(newPolygonLayerPiece);
      });
    } else {
      const latLngs = L.GeoJSON.coordsToLatLngs(diff.geometry.coordinates, 1);
      const newPolygonLayerPiece = new L.Polygon(latLngs);
      newLayers.push(newPolygonLayerPiece);
    }
  });
  return newLayers;
};

const drawSemanticLayer = (layer: L.Polygon, mid: string): L.Layer[] => {
  const { semanticMode } = layer.options;
  if (semanticMode === 'add') {
    return addSemanticLayer(layer, mid);
  }
  if (semanticMode === 'substract') {
    return subtractSemanticLayer(layer, mid);
  }
  return [layer];
};

export default drawSemanticLayer;
