import { gql } from '@apollo/client';

import { ORGANIZATION_FRAGMENTS, ORGANIZATION_LICENSE_FRAGMENTS } from './fragments';

export const GQL_UPDATE_PROPERTIES_IN_ORGANIZATION = gql`
  mutation UpdatePropertiesInOrganization($where: OrganizationWhere!, $data: OrganizationData!) {
    updatePropertiesInOrganization(where: $where, data: $data) {
      ...OrganizationInfos
      ...OrganizationLicense
    }
  }
  ${ORGANIZATION_FRAGMENTS}
  ${ORGANIZATION_LICENSE_FRAGMENTS}
`;

export const GQL_UPDATE_PROPERTIES_IN_ORGANIZATION_WITHOUT_LICENSE = gql`
  mutation UpdatePropertiesInOrganizationWithoutLicense(
    $where: OrganizationWhere!
    $data: OrganizationProperties!
  ) {
    updatePropertiesInOrganizationWithoutLicense(where: $where, data: $data) {
      ...OrganizationInfos
      ...OrganizationLicense
    }
  }
  ${ORGANIZATION_FRAGMENTS}
  ${ORGANIZATION_LICENSE_FRAGMENTS}
`;

export const GQL_CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($data: CreateOrganizationData!) {
    createOrganization(data: $data) {
      ...OrganizationInfos
      ...OrganizationLicense
    }
  }
  ${ORGANIZATION_FRAGMENTS}
  ${ORGANIZATION_LICENSE_FRAGMENTS}
`;
