import { type Jobs, MachineLearningTask } from '@kili-technology/types';
import _pick from 'lodash/pick';
import { createSelector } from 'reselect';

import { getCategoryAnnotations, getCategoryIdentity, getObjects } from './helpers';
import { type JobCategoryPropsType } from './types';

import {
  applicationSearchVisibility,
  applicationShortcuts,
} from '../../../../../../../../redux/application/selectors';
import { type Shortcut } from '../../../../../../../../redux/application/types';
import {
  jobCurrentCategoryCode,
  jobCurrentJobName,
} from '../../../../../../../../redux/job/selectors';
import { createDeepSelectorFunction, projectJobs } from '../../../../../../../../redux/selectors';
import { type State } from '../../../../../../../../redux/types';
import { categoryIsHidden } from '../../../helpers';
import {
  categoryCodesSelectedInJob,
  categoryCodesSelectedInJobAtPageLevel,
  jobAnnotations,
} from '../JobRenderer/selectors';

const getCategoryCode = (state: State, ownProps: JobCategoryPropsType) => ownProps.category[0];

const getJobName = (state: State, ownProps: JobCategoryPropsType) => ownProps.job[0];

const getCategoryIdentityFromOwnProps = (state: State, ownProps: JobCategoryPropsType) =>
  getCategoryIdentity(ownProps);

const getCategoryChildrenJobsList = (state: State, ownProps: JobCategoryPropsType): string[] => {
  return ownProps.category?.[1].children ?? [];
};

export const categoryAnnotations = createSelector(
  [getCategoryCode, jobAnnotations],
  (categoryCode, annotations) => getCategoryAnnotations(categoryCode, annotations),
);

export const categoryObjectsSelector = createSelector([categoryAnnotations], annotations =>
  getObjects(annotations),
);

export const computeNumberOfObjectsSelector = createSelector(
  [categoryObjectsSelector, applicationSearchVisibility],
  (annotations, visibility): number =>
    annotations.filter(({ mid }) => visibility?.Objects?.[mid] ?? true).length,
);

const jobShortcuts = createSelector(
  [getCategoryIdentityFromOwnProps, applicationShortcuts],
  (categoryIdentity, shortcuts): Shortcut[] => {
    return shortcuts?.[categoryIdentity.jobName] || [];
  },
);

export const categoryShortcutSelector = createSelector(
  [getCategoryIdentityFromOwnProps, jobShortcuts],
  (categoryIdentity, shortcuts): string => {
    return (
      shortcuts.find(o => o.categoryCode === categoryIdentity.categoryCode)?.key?.toUpperCase() ??
      ''
    );
  },
);

export const categoryChildrenJobsSelector = createDeepSelectorFunction()(
  [projectJobs, getCategoryChildrenJobsList],
  (jobs, categoryChildrenJobsList): Jobs =>
    _pick(
      jobs,
      Object.keys(jobs).filter(jobName => categoryChildrenJobsList.includes(jobName)),
    ),
);

export const isClassificationCategoryChecked = createSelector(
  [getCategoryIdentityFromOwnProps, categoryCodesSelectedInJob],
  (categoryIdentity, selectedCategoriesOfJob) => {
    const { categoryCode } = categoryIdentity;
    return selectedCategoriesOfJob.includes(categoryCode);
  },
);
export const isClassificationCategoryAtPageLevelChecked = createSelector(
  [getCategoryIdentityFromOwnProps, categoryCodesSelectedInJobAtPageLevel],
  (categoryIdentity, selectedCategoriesOfJob) => {
    const { categoryCode } = categoryIdentity;
    return selectedCategoriesOfJob.includes(categoryCode);
  },
);

export const isCategoryChecked = createSelector(
  [
    getCategoryIdentityFromOwnProps,
    isClassificationCategoryChecked,
    isClassificationCategoryAtPageLevelChecked,
  ],
  (categoryIdentity, isCategoryAtDocumentLevelChecked, isCategoryAtPageLevelChecked) => {
    const { mlTask } = categoryIdentity;
    if (mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION) {
      return isCategoryAtPageLevelChecked;
    }
    if (mlTask === MachineLearningTask.CLASSIFICATION) {
      return isCategoryAtDocumentLevelChecked;
    }
    return false;
  },
);

export const getParentMidFromProps = (state: State, ownProps: { parentMid?: string }): string =>
  ownProps.parentMid ?? '';

export const isCategoryVisibleSelector = createSelector(
  [getCategoryCode, getJobName, getParentMidFromProps, applicationSearchVisibility],
  (categoryCode, jobName, parentMid, visibility) =>
    !categoryIsHidden(visibility, categoryCode, jobName, parentMid),
);

export const isJobSelected = createSelector(
  [getJobName, jobCurrentJobName],
  (jobName, currentJobName) => jobName === currentJobName,
);
export const isObjectCategorySelectedSelector = createSelector(
  [isJobSelected, getCategoryCode, jobCurrentCategoryCode],
  (jobSelected, categoryCode, currentCategoryCode) => {
    return jobSelected && categoryCode === currentCategoryCode;
  },
);
