import {
  type Annotation,
  type AnnotationCategory as Category,
  type Jobs,
} from '@kili-technology/types';

import { CATEGORIES, MID } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';

export interface CategoriesOfJobs {
  categoryCode: string;
  categoryName: string;
  jobCode: string;
  shortcut?: string;
}

export const findAllCategoriesInJobs = ({ jobs }: { jobs: Jobs }): CategoriesOfJobs[] => {
  const allCategoriesInJobs: CategoriesOfJobs[] = [];
  Object.entries(jobs).forEach(([jobCode, job]) => {
    const { content } = job;
    if (job?.isModel) {
      return;
    }
    const jobCategories = content?.categories ?? {};
    Object.entries(jobCategories).forEach(([categoryCode, category]) => {
      allCategoriesInJobs.push({
        categoryCode,
        categoryName: category.name,
        jobCode,
        shortcut: category.shortcut,
      });
    });
  });
  return allCategoriesInJobs;
};

export const getCategoryCodeFromAnnotation = (annotation: {
  categories: { name: string }[];
  mid: string;
}): string => annotation?.categories?.[0]?.name ?? '';

export const getCategoriesFromMid = (entityMid: string, annotations: Annotation[]): Category[] => {
  return annotations.reduce<Category[]>((acc, annotation) => {
    if (annotation[MID] === entityMid) {
      return acc.concat((annotation?.[CATEGORIES] ?? []) as Category[]);
    }
    return acc;
  }, []);
};

type ShouldSelectNewObjectPayload = {
  shouldNotCreateNewObjectId: boolean;
  skipCreateNewObject?: boolean;
};

export const shouldSelectNewObject = (payload: ShouldSelectNewObjectPayload): boolean => {
  const { shouldNotCreateNewObjectId, skipCreateNewObject } = payload;

  return !skipCreateNewObject && !shouldNotCreateNewObjectId;
};
