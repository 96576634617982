import { type ProjectQueueState } from './types';

import { type ZustandStore } from '../index';

export const projectQueue = (state: ZustandStore): ProjectQueueState => state.projectQueue;

export const projectQueueCounts = ({
  projectQueue: {
    counterHoneypot,
    counterLabeled,
    counterOngoing,
    counterReviewed,
    counterTodo,
    counterToReview,
    countHoneypot,
    countLabeled,
    countOngoing,
    countReviewed,
    countToReview,
    countTodo,
  },
}: ZustandStore) => ({
  countHoneypot: (countHoneypot ?? 0) + counterHoneypot,
  countLabeled: (countLabeled ?? 0) + counterLabeled,
  countOngoing: (countOngoing ?? 0) + counterOngoing,
  countReviewed: (countReviewed ?? 0) + counterReviewed,
  countToReview: (countToReview ?? 0) + counterToReview,
  countTodo: (countTodo ?? 0) + counterTodo,
});

export type ProjectQueueSelection = { idNotIn: string[] } | { idIn: string[] };

export const projectQueueSelection = (state: ZustandStore): ProjectQueueSelection => {
  const { areAllAssetsSelected, selectedAssetIds, unselectedAssetIds } = state.projectQueue;
  return areAllAssetsSelected ? { idNotIn: unselectedAssetIds } : { idIn: selectedAssetIds };
};
