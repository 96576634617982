import { type VideoClassificationKeyAnnotation } from '@/__generated__/globalTypes';

import { createClassificationAnnotationValue } from './createClassificationAnnotation';
import { type AnnotationFactoryProperties } from './types';

import { getVideoKeyAnnotationId } from '../helpers/getVideoKeyAnnotationId';

export function createVideoClassificationKeyAnnotation(
  properties: AnnotationFactoryProperties<VideoClassificationKeyAnnotation>,
  annotationId: string,
): VideoClassificationKeyAnnotation {
  const { annotationValue, frame } = properties;
  const { categories, isPrediction } = annotationValue;
  const id = getVideoKeyAnnotationId(annotationId, properties.frame);
  return {
    __typename: 'VideoClassificationKeyAnnotation',
    annotationValue: createClassificationAnnotationValue({ categories, id, isPrediction }),
    frame,
    id,
  };
}
