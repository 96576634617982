import { type VideoKeyAnnotation } from '@/graphql/annotations/types';

const VIDEO_KEY_ANNOTATION_TYPENAMES: readonly VideoKeyAnnotation['__typename'][] = [
  'VideoClassificationKeyAnnotation',
  'VideoObjectDetectionKeyAnnotation',
  'VideoTranscriptionKeyAnnotation',
];

export function isVideoKeyAnnotation(input: unknown): input is VideoKeyAnnotation {
  return (
    !!input && VIDEO_KEY_ANNOTATION_TYPENAMES.includes((input as VideoKeyAnnotation).__typename)
  );
}
