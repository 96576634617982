export const OCR = 'OCR';
export const CLASSIFICATION = 'CLASSIFICATION';
export const MAX = 'max';
export const MIN = 'min';
export const MAX_ZOOM = 24;
export const MAXIMUM_NUMBER_OF_FRAMES_FOR_TRACKING = 100;
export const MIN_ZOOM = 4;
export const ROTATION_JOB = 'ROTATION_JOB';
export const ANNOTATION_NAMES_JOB = 'ANNOTATION_NAMES_JOB';
export const ASSET_ANNOTATION = 'ASSET_ANNOTATION';
