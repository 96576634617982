import { HandClickingClass } from '@kili-technology/cursors';
import { LogOut } from '@kili-technology/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { classes } from './LogoutButton.style';
import { useLogoutButton } from './hooks';

interface LogoutButtonProps {
  customClassName?: string;
  enableLogOutText?: boolean;
}

const LogoutButton = ({ enableLogOutText, customClassName = '' }: LogoutButtonProps) => {
  const { t } = useTranslation();
  const { handleLogout } = useLogoutButton();

  return (
    <MenuItem
      className={`${
        enableLogOutText ? classes.menuItem : ''
      } ${HandClickingClass} ${customClassName}`}
      id="cypress-signout"
      onClick={handleLogout}
    >
      <LogOut className={classes.logoutIcon} />
      {enableLogOutText ? (
        <Typography variant="body2">{t('general.logout')}</Typography>
      ) : (
        <>{t('general.signout')}</>
      )}
    </MenuItem>
  );
};

export default LogoutButton;
