import { gql } from '@apollo/client';

export const GQL_GET_ANNOTATIONS = gql`
  query GetAnnotations($where: AnnotationWhere!) {
    annotations(where: $where) {
      id
      job
      path
      labelId
      ... on ClassificationAnnotation {
        annotationValue {
          categories
          id
          isPrediction
        }
      }
      ... on VideoAnnotation {
        frames {
          start
          end
        }
        keyAnnotations {
          id
          frame
          annotationValue {
            id
            isPrediction
            ... on ObjectDetectionAnnotationValue {
              vertices {
                x
                y
              }
            }
            ... on ClassificationAnnotationValue {
              categories
            }
            ... on TranscriptionAnnotationValue {
              text
            }
          }
        }
      }
      ... on VideoObjectDetectionAnnotation {
        name
        category
        mid
      }
      ... on RankingAnnotation {
        annotationValue {
          id
          isPrediction
          orders {
            elements
            rank
          }
        }
      }
      ... on TranscriptionAnnotation {
        annotationValue {
          id
          isPrediction
          text
        }
      }
    }
  }
`;

export const GQL_CACHE_GET_ANNOTATIONS_LIGHT = gql`
  query GetAnnotationsLight($where: AnnotationWhere!) {
    annotations(where: $where) {
      id
      job
      labelId
      path
      ... on VideoObjectDetectionAnnotation {
        name
        category
        mid
      }
    }
  }
`;
