import { gql } from '@apollo/client';

export const GQL_NOTIFICATIONS_GET_AND_COUNT = gql`
  query Notifications(
    $first: PageSize!
    $skip: Int!
    $where: NotificationWhere!
    $whereCount: NotificationWhere!
  ) {
    data: notifications(where: $where, skip: $skip, first: $first) {
      createdAt
      hasBeenSeen
      id
      message
      status
      url
      userID
    }
    count: countNotifications(where: $whereCount)
    totalCount: countNotifications(where: $where)
  }
`;
