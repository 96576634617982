/* eslint-disable @typescript-eslint/naming-convention */

const DEVELOPMENT_FLAGS = {
  com_org_usage_and_license_ui: () => true,
  com_project_creation_limits: () => false,
  data_analytics_review_score: () => true,
  data_delete_locks: (email: string) => email === 'test+collab@kili-technology.com',
  data_support_mailer_free_user: (email: string) => email === 'discovery+admin@kili-technology.com',
  lab_splitted_json_response: () => true,
  lab_video_konvas_v1: (email: string) => email.includes('test+admin+konvas@kili-technology.com'),
  lops_auth_email_verification: () => false,
  lops_compliance_tags_on_create_project: (email: string) =>
    email.includes('test+admin+1@kili-technology.com'),
  lops_cs_allowed_projects: () => true,
  lops_cs_filtering: () => true,
  lops_cs_filtering_azure: (email: string) => email === 'test+admin+1@kili-technology.com',
  lops_cs_s3_compatible: () => true,
  mlx_similar_objects: (email: string) => email.includes('test+admin+1@kili-technology.com'),
  multi_org_management: (email: string) => email.includes('test+fx@kili-technology.com'),
};

export default DEVELOPMENT_FLAGS;
