import create from 'zustand';

import { type VideoParamsV2 } from './Overlays/types';

interface State {
  setVideoParams: (assetId: string, newParams: VideoParamsV2 | undefined) => void;
  videoParams: Record<string, VideoParamsV2 | undefined>;
}

const useVideoParams = create<State>(set => ({
  setVideoParams: (assetId: string, newVideoParams: VideoParamsV2 | undefined) =>
    set(state => {
      return { videoParams: { ...state.videoParams, [assetId]: newVideoParams } };
    }),
  videoParams: {},
}));

export default useVideoParams;
