import { type ImageVertices, type PoseEstimationPoint, Tool } from '@kili-technology/types';
import type L from 'leaflet';

import './KiliPoseEstimation.css';

import Pose from './BasePose';
import { PoseEstimation } from './KiliPoseEstimation';

import { memoizedGetColorForCategories } from '../../../../../helpers';
import type KiliFeatureGroup from '../KiliFeatureGroup';
import { getLatLngsFromLayer, getLayerOptionsFromAnnotation, layerWithHandlers } from '../helpers';
import { type KiliOptions, type PoseAnnotation } from '../types';

export const getKiliPose = (layer: Pose, options: KiliOptions): PoseEstimation => {
  const poseLayer = new PoseEstimation(layer, options);
  return layerWithHandlers(poseLayer);
};

export const annotationToKiliPose = (
  annotation: PoseAnnotation,
  leafletOptions: { featureGroup?: KiliFeatureGroup; map: L.Map },
): PoseEstimation | undefined => {
  const { map, featureGroup } = leafletOptions;
  const layerOptions = featureGroup
    ? { ...getLayerOptionsFromAnnotation(annotation), group: featureGroup }
    : getLayerOptionsFromAnnotation(annotation);
  const { points: annotationPoints, jobName, categories } = annotation;
  const polyline = annotationPoints.map(point => point.point) as ImageVertices[];
  const latlngs = getLatLngsFromLayer(
    {
      ...annotation,
      polyline,
      type: Tool.POLYLINE,
    },
    map,
  ) as L.LatLng[];
  if (!latlngs) {
    return undefined;
  }
  const annotationPointCodes = annotationPoints.map(point => point.code);
  const allPoints = (annotation.allPoints ?? []) as PoseEstimationPoint[];
  const pointIndicesDrawn = [...Array(allPoints.length).keys()].filter(index =>
    annotationPointCodes.includes(allPoints.at(index)?.code ?? ''),
  );

  const color = memoizedGetColorForCategories(categories, jobName);
  const isFinished = pointIndicesDrawn.at(-1) === allPoints.length - 1;
  const pose = new Pose(latlngs, {
    allPoints,
    color,
    isFinished,
    isPose: true,
    pointIndicesDrawn,
  });
  const kiliLayer = getKiliPose(pose, { ...layerOptions, allPoints, map, pointIndicesDrawn });
  return kiliLayer;
};
