export enum LoadingFlag {
  /* eslint-disable no-bitwise */
  IsReady = 0,
  IsLoading = 1 << 0,
  IsError = 1 << 1,
  /* eslint-enable no-bitwise */
}

export type FlagState = { flags: LoadingFlag };

export type GenericState<T> = FlagState & T;
