import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';

import { type Maybe } from '../__generated__/globalTypes';
import i18n from '../i18n/i18n';

dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

/** A copy of dayjs library */
export const xdate = dayjs;

export const dateToIso = (value: Date): string => xdate(value).toISOString();

export const datePattern = {
  D_MMM: 'D MMM',
  MMM: 'MMM',
  yearMonthDayDash: 'YYYY-MM-DD',
  yearMonthDayDashWithTime: 'YYYY-MM-DD HH:mm:ss',
  yearMonthDaySlash: 'YYYY/MM/DD',
  yearMonthDaySlashWithTime: 'YYYY/MM/DD HH:mm:ss',
};

export const dateToFormat = (
  value?: Maybe<string> | string | Date | number,
  pattern = datePattern.yearMonthDaySlash,
): string => {
  const finalPattern = pattern;
  if (pattern?.includes(datePattern.yearMonthDaySlash)) {
    finalPattern?.replace(datePattern.yearMonthDaySlash, i18n.t(`dateFormat.YYYY-MM-DD`));
  }
  if (pattern?.includes(datePattern.yearMonthDayDash)) {
    finalPattern?.replace(datePattern.yearMonthDayDash, i18n.t(`dateFormat.YYYY-MM-DD`));
  }

  return dayjs(value || new Date()).format(finalPattern);
};
