import {
  type ObjectDetectionAnnotationValue,
  type VideoObjectDetectionAnnotation,
} from '@/__generated__/globalTypes';
import { type VideoKeyAnnotation } from '@/graphql/annotations/types';
import { broadcastQueriesAfterUpdatingCache, getCacheIdFromGraphQLObject } from '@/graphql/helpers';
import { useStore } from '@/zustand';
import { type SelectedFrames } from '@/zustand/label-frame';
import { useHistoryStore } from '@/zustand-history';

import { findAnnotationInCache } from './cache/findAnnotationInCache';
import { getAnnotationValueForFrame } from './common';
import { createVideoObjectDetectionKeyAnnotation } from './data/factories/createVideoObjectDetectionKeyAnnotation';
import { isVideoObjectDetectionAnnotation } from './data/validators/isVideoObjectDetectionAnnotation';

import { addKeyAnnotationsInCache, deleteKeyAnnotationsInCache } from '../actions';

type KeyFrameListFromSelectedFramesType = {
  frame: number;
  keyAnnotation: VideoKeyAnnotation | undefined;
}[];

const getKeyFrameListFromSelectedFrames = (
  annotation: VideoObjectDetectionAnnotation,
  selectedFrames: Partial<SelectedFrames>,
): KeyFrameListFromSelectedFramesType | undefined => {
  if (!annotation.keyAnnotations || !selectedFrames.min || !selectedFrames.max) return;

  const keyFrameList: KeyFrameListFromSelectedFramesType = [];

  for (let i = selectedFrames.min; i <= selectedFrames.max; i += 1) {
    const foundKeyFrame = annotation.keyAnnotations.find(
      keyAnnotation => keyAnnotation.frame === i,
    );
    keyFrameList.push({ frame: i, keyAnnotation: foundKeyFrame });
  }

  return keyFrameList;
};

const toggleKeyFrameOnSelectedFramesSplit = (
  videoAnnotationForMid: VideoObjectDetectionAnnotation,
  selectedFrames: Partial<SelectedFrames>,
) => {
  const keyFrameListFromFrameInterval = getKeyFrameListFromSelectedFrames(
    videoAnnotationForMid,
    selectedFrames,
  );

  if (!keyFrameListFromFrameInterval) return;

  keyFrameListFromFrameInterval.forEach(({ frame, keyAnnotation }) => {
    if (!videoAnnotationForMid.keyAnnotations) return;
    if (keyAnnotation) {
      deleteKeyAnnotationsInCache(getCacheIdFromGraphQLObject(videoAnnotationForMid), [
        keyAnnotation,
      ]);
    } else {
      const newKeyAnnotation = createVideoObjectDetectionKeyAnnotation(
        {
          annotationValue: getAnnotationValueForFrame({
            frame,
            jobName: videoAnnotationForMid.job,
            keyAnnotations: videoAnnotationForMid.keyAnnotations,
          }) as ObjectDetectionAnnotationValue,
          frame,
        },
        videoAnnotationForMid.id,
      );
      addKeyAnnotationsInCache(getCacheIdFromGraphQLObject(videoAnnotationForMid), [
        newKeyAnnotation,
      ]);
    }
  });
};

export const toggleKeyFrameSplit = (mid: string, selectedFrames?: Partial<SelectedFrames>) => {
  const videoAnnotationForMid = findAnnotationInCache(
    (annotation): annotation is VideoObjectDetectionAnnotation =>
      isVideoObjectDetectionAnnotation(annotation) && annotation.mid === mid,
  );
  if (!videoAnnotationForMid || !videoAnnotationForMid.keyAnnotations) return;

  if (!selectedFrames) return;

  toggleKeyFrameOnSelectedFramesSplit(videoAnnotationForMid, selectedFrames);

  broadcastQueriesAfterUpdatingCache();
};

export const toggleKeyFrameOnCurrentFrameSplitWithHistory = (mid: string) => {
  const { selectedFrames } = useStore.getState().labelFrame;

  const previousAnnotationOfMid = findAnnotationInCache(
    (annotation): annotation is VideoObjectDetectionAnnotation =>
      isVideoObjectDetectionAnnotation(annotation) && annotation.mid === mid,
  );
  if (!previousAnnotationOfMid) {
    throw new Error(`No annotation of mid ${mid} found`);
  }

  if (!selectedFrames?.min || !selectedFrames?.max) return;

  const previousKeyAnnotationsAtFrame = getKeyFrameListFromSelectedFrames(
    previousAnnotationOfMid,
    selectedFrames,
  );

  const action = {
    name: 'toggleKeyFrameOnCurrentFrameSplit',
    redo: () => {
      toggleKeyFrameSplit(mid, selectedFrames);
    },
    undo: () => {
      const annotationOfMid = findAnnotationInCache(
        (annotation): annotation is VideoObjectDetectionAnnotation =>
          isVideoObjectDetectionAnnotation(annotation) && annotation.mid === mid,
      );

      if (!annotationOfMid) {
        throw new Error(`No annotation of mid ${mid} found`);
      }
      if (!previousKeyAnnotationsAtFrame) return;

      previousKeyAnnotationsAtFrame.forEach(({ frame, keyAnnotation }) => {
        const keyAnnotationAtFrame = annotationOfMid.keyAnnotations?.find(
          kAnnotation => kAnnotation.frame === frame,
        );

        if (keyAnnotation) {
          addKeyAnnotationsInCache(getCacheIdFromGraphQLObject(annotationOfMid), [keyAnnotation]);
        }
        if (keyAnnotationAtFrame) {
          deleteKeyAnnotationsInCache(getCacheIdFromGraphQLObject(annotationOfMid), [
            keyAnnotationAtFrame,
          ]);
        }
      });

      broadcastQueriesAfterUpdatingCache();
    },
  };

  action.redo();

  useHistoryStore.getState().history.addAction(action);
};
