import { Typography } from '@material-ui/core';
import React from 'react';

import useStyles from './CustomTitle.style';

type PropsType = {
  title?: string;
};

const ProjectsPanel: React.FC<PropsType> = props => {
  const { title } = props;
  const classes = useStyles();

  if (!title) return null;

  return (
    <Typography className={classes.title} variant="subtitle2">
      {title}
    </Typography>
  );
};

export default ProjectsPanel;
