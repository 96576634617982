/* eslint-disable no-underscore-dangle */
import L from 'leaflet';

L.Edit.Vector = L.Edit.Poly.extend({
  _initHandlers() {
    this._verticesHandlers = [];
    for (let i = 0; i < this.latlngs.length; i += 1) {
      this._verticesHandlers.push(
        new L.Edit.VectorVertices(this._poly, this.latlngs[i], this._poly.options.poly),
      );
    }
  },
});

L.Edit.VectorVertices = L.Edit.PolyVerticesEdit.extend({
  _initMarkers() {
    if (!this._markerGroup) {
      this._markerGroup = new L.LayerGroup();
    }
    this._markers = [];

    const latlngs = this._defaultShape();
    let i;
    let len;
    let marker;

    for (i = 0, len = latlngs.length; i < len; i += 1) {
      marker = this._createMarker(latlngs[i], i);
      marker.on('click', this._onMarkerClick, this);
      marker.on('contextmenu', this._onContextMenu, this);
      this._markers.push(marker);
    }
  },
});
