import { createStyles, type StyleRules, type Theme, withStyles } from '@material-ui/core/styles';

import BaseTopBar from './BaseTopBar';

import { TOPBAR_BASE_CONTAINER_ZINDEX } from '../../constants/zIndexes';
import { APP_BAR_HEIGHT } from '../../theme';

export const styles = (theme: Theme): StyleRules<'container' | 'subContainer'> =>
  createStyles({
    container: {
      background: theme.palette.omega.accent0.main,
      borderBottom: `1px solid ${theme.palette.omega.accent2.main}`,
      display: 'flex',
      height: APP_BAR_HEIGHT,
      justifyContent: 'space-between',
      minHeight: APP_BAR_HEIGHT,
      overflowX: 'auto',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      zIndex: TOPBAR_BASE_CONTAINER_ZINDEX,
    },
    subContainer: {
      '&:nth-child(2)': {
        justifyContent: 'center',
      },
      '&:nth-child(3)': {
        justifyContent: 'right',
      },
      alignItems: 'center',
      boxSizing: 'content-box',
      display: 'flex',
      textTransform: 'none',
    },
  });

export default withStyles(styles)(BaseTopBar);
