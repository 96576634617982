export const readTextFileAsync = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result?.toString() ?? '');
    };

    reader.onerror = reject;

    if (file) {
      reader.readAsText(file);
    }
  });
};
