import { css } from '@emotion/css';

import { POPPER_DATE_PICKET_ZINDEX } from '../../constants/zIndexes';

const useStyles = () => {
  return {
    datePicker: css`
      --color-primary: var(--color-beta-accent-5);
      --duet-color-border: var(--color-omega-accent-3);
      --duet-color-button: #f5f5f5;
      --duet-color-overlay: rgba(0, 0, 0, 0.8);
      --duet-color-placeholder: #666;
      --duet-color-primary: var(--color-primary);
      --duet-color-surface: var(--color-omega-accent-0);
      --duet-color-text: #333;
      --duet-color-text-active: var(--color-omega-accent-0);
      --duet-font-bold: 600;
      --duet-font-normal: 400;
      --duet-radius: 4px;
      --duet-z-index: ${POPPER_DATE_PICKET_ZINDEX};

      &:hover {
        --duet-color-border: var(--color-beta-accent-5);
      }

      input:focus {
        --duet-color-primary: var(--color-beta-accent-5);
        box-shadow: none;
      }
    `,
    datePickerInvalid: css`
      --duet-color-border: var(--color-eta-accent-5, hsla(359, 95%, 63%, 1));
    `,
    errorMessage: css`
      color: var(--color-eta-accent-5, hsla(359, 95%, 63%, 1));
      font-size: 12px;
      font-weight: 400;
      margin: var(--space-0);
      margin-left: var(--space-2);
    `,
  };
};

export default useStyles;
