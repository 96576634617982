import { type BadgeLayer } from '../KiliLeafletLayers/types';

const badgeLayers = new Map<string, BadgeLayer[]>();

export const hideBadgeLayers = (mid: string) => {
  const relationLayers = badgeLayers.get(mid);
  relationLayers?.forEach(layer => {
    layer.setOpacity(0);
  });
};

export const showBadgeLayers = (mid: string) => {
  const relationLayers = badgeLayers.get(mid);
  relationLayers?.forEach(layer => {
    layer.setOpacity(1);
  });
};

export const clearBadgeLayers = (mid: string) => {
  const relationLayers = badgeLayers.get(mid);
  relationLayers?.forEach(layer => layer.remove());
  badgeLayers.delete(mid);
};

export const clearAllBadgeLayers = () => {
  badgeLayers.forEach(relationLayers => {
    relationLayers?.forEach(layer => layer.remove());
  });
  badgeLayers.clear();
};

export const addBadgeLayers = (mid: string, badgeLayer: BadgeLayer) => {
  const relationLayers = badgeLayers.get(mid) ?? [];

  relationLayers.push(badgeLayer);
  badgeLayers.set(mid, relationLayers);
};

export default badgeLayers;
