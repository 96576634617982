import {
  type Annotation,
  type ImageVertices,
  LabelVersion,
  MachineLearningTask,
  Tool,
} from '@kili-technology/types';
import { generateHash } from '@kili-technology/utilities';
import type L from 'leaflet';
import _uniqWith from 'lodash/uniqWith';

import type Pose from '@/components/asset-ui/Image/LeafletMapv2/KiliLeafletLayers/KiliPoseEstimation/BasePose';
import { smartTrack } from '@/components/asset-ui/Image/LeafletMapv2/helpers';
import { jobCurrentCategories, jobCurrentJobName } from '@/redux/job/selectors';
import {
  jobsCurrentJobTools,
  creatingOrEditingObjectDetectionAnnotation,
} from '@/redux/jobs/selectors';
import { type State } from '@/redux/types';
import { type KiliAnnotation } from '@/services/jobs/setResponse';
import { store } from '@/store';
import { generateNewCreatingOrEditingObjectId, useStore } from '@/zustand';

import { getNewLayersMidFromDrawnLayer } from './helpers/getNewLayersMidFromDrawnLayer';

import getAnnotationFromLayer from '../../components/asset-ui/Image/LeafletMapv2/helpers/coordinatesConverter';
import fitLayerToBounds from '../../components/asset-ui/Image/LeafletMapv2/helpers/fitToBounds';

export const isValidAnnotation = ({
  type,
  boundingPoly,
}: KiliAnnotation & {
  boundingPoly?: {
    normalizedVertices: ImageVertices[];
  }[];
}) => {
  const uniquesPoly = boundingPoly?.map(d => ({
    ...d,
    normalizedVertices: _uniqWith(d.normalizedVertices, (v1, v2) => v1.x === v2.x && v1.y === v2.y),
  }));
  return (
    (type !== Tool.RECTANGLE && type !== Tool.POLYGON) ||
    (type === Tool.RECTANGLE &&
      uniquesPoly?.every(({ normalizedVertices }) => normalizedVertices?.length === 4)) ||
    (type === Tool.POLYGON &&
      uniquesPoly?.every(({ normalizedVertices }) => normalizedVertices?.length > 2))
  );
};

export const getOptionsFromState = (state: State, layer: L.Layer) => {
  const currentJobName = jobCurrentJobName(state);
  const currentJobTools = jobsCurrentJobTools(state);
  const { creatingOrEditingObjectId } = useStore.getState().labelInterface;
  const currentAnnotation = creatingOrEditingObjectDetectionAnnotation(state, {
    creatingOrEditingObjectId,
  }) as Annotation;
  const nextSelectedObjectId = creatingOrEditingObjectId || generateHash();
  const selectedCategories = jobCurrentCategories(state);
  const { jobName: layerJobName, categories: layerCategories, isPose } = (layer as Pose).options;
  let jobName = currentJobName;
  let categories = selectedCategories;
  let type = currentJobTools?.[0];
  if (isPose && !jobName && layerJobName && layerCategories) {
    jobName = layerJobName;
    categories = layerCategories;
    type = Tool.POSE;
  }
  return {
    categories,
    jobName,
    labelVersion: LabelVersion.DEFAULT,
    mid: nextSelectedObjectId,
    mlTask: MachineLearningTask.OBJECT_DETECTION,
    selectedAnnotation: currentAnnotation,
    type,
  };
};

export const labelImageTrackAnnotationFromLayer = (
  event: L.DrawEvents.Created,
  map: L.Map,
  isProcessingAuthorized?: boolean,
): AbortController | null => {
  if (!isProcessingAuthorized) return null;

  const state = store.getState();
  const { layer } = event;
  const options = getOptionsFromState(state, layer);
  fitLayerToBounds(layer, map);
  // @ts-expect-error Type '"Polygon"' is not assignable to type '"MultiLineString"
  const newLayersMid = getNewLayersMidFromDrawnLayer(layer, options.mid);
  const newAnnotations = newLayersMid
    .map(getAnnotationFromLayer(map, options))
    .filter(newAnnotation => !!newAnnotation && isValidAnnotation(newAnnotation));

  const abortController = smartTrack(newAnnotations[0]);
  generateNewCreatingOrEditingObjectId();
  return abortController;
};
