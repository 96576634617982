import { getApolloClient } from '@/graphql/helpers';
import { getLabelId } from '@/redux/asset-label/helpers/getLabelId';

import { type GetAnnotationsLightQuery } from '../../__generated__/queries.graphql';
import { GQL_CACHE_GET_ANNOTATIONS_LIGHT } from '../../queries';

export type LightAnnotation = NonNullable<GetAnnotationsLightQuery['annotations']>[number];

export function getLightAnnotationsFromCache(): LightAnnotation[] {
  const appoloClient = getApolloClient();
  const labelId = getLabelId();
  let queryResult: GetAnnotationsLightQuery | null = null;

  try {
    queryResult = appoloClient.cache.readQuery<GetAnnotationsLightQuery>({
      query: GQL_CACHE_GET_ANNOTATIONS_LIGHT,
      variables: { where: { labelId } },
    });
  } catch {
    // Query not in cache
  }

  return queryResult?.annotations ?? [];
}
