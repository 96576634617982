import {
  createStyles,
  type StyleRules,
  type Theme,
  withStyles,
} from '@material-ui/core/styles/index';

import ProjectsPanelPage from './ProjectsPanelPage';

export const styles = (
  theme: Theme,
): StyleRules<
  | 'gridContainer'
  | 'list'
  | 'listItem'
  | 'listSubheader'
  | 'noProjectFound'
  | 'projectRenderer'
  | 'searchBar'
> =>
  createStyles({
    gridContainer: {
      paddingLeft: '0px',
    },
    list: {
      height: '396px',
      overflowY: 'scroll',
      paddingBottom: '0px',
    },
    listItem: {
      display: 'flex',
      paddingLeft: '0px',
    },
    listSubheader: {
      backgroundColor: 'white',
      color: theme.palette.omega.accent4.main,
      display: 'flex',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '48px',
      padding: '0px',
    },
    noProjectFound: {
      alignItems: 'center',
      color: theme.palette.omega.accent4.main,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textTransform: 'initial',
      whiteSpace: 'nowrap',
    },
    projectRenderer: {
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textTransform: 'initial',
      whiteSpace: 'nowrap',
    },
    searchBar: {
      backgroundColor: theme.palette.omega.accent1.main,
      marginBottom: theme.spacing(2),
    },
  });

export default withStyles(styles)(ProjectsPanelPage);
