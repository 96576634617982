import { type RegisterSlice, type RegisterSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: RegisterSliceValues = {
  isRegistering: false,
};

const log = createLogger('register');

export const createRegisterSlice: ZustandSlice<RegisterSlice> = set => ({
  ...initialState,
  initialize: () =>
    set(
      state => {
        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;

          (state.register[keyInitialState] as unknown) = value;
        });
      },
      false,
      log('initialize'),
    ),
  setIsRegistering: isRegistering =>
    set(
      state => {
        state.register.isRegistering = isRegistering;
      },
      false,
      log('setIsRegistering', isRegistering),
    ),
});
