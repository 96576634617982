import { configureStore } from '@reduxjs/toolkit';
import { type Action, type Middleware, combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { type ThunkAction } from 'redux-thunk';
import undoable from 'redux-undo';

import { InputType } from './__generated__/globalTypes';
import { shouldAddDevTools } from './config';
import applicationReducer from './redux/application/slice';
import assetLabelReducer from './redux/asset-label/slice';
import authenticationReducer from './redux/authentication/slice';
import autosaveReducer from './redux/autosave/slice';
import datasetReducer from './redux/dataset/slice';
import jobReducer from './redux/job/slice';
import jobsReducer, { JOBS_JUMP, UNDOABLE_JOBS_ACTIONS } from './redux/jobs/slice';
import { type JobsState } from './redux/jobs/types';
import labelFramesReducer from './redux/label-frames/slice';
import organizationReducer from './redux/organization/slice';
import projectReducer from './redux/project/slice';
import projectUserReducer from './redux/project-user/slice';
import projectVersionReducer from './redux/project-version/slice';
import projectsReducer from './redux/projects/slice';
import rolesReducer from './redux/roles/slice';
import { type State } from './redux/types';
import userReducer from './redux/user/slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
};

export const rootReducer = combineReducers({
  application: applicationReducer,
  assetLabel: assetLabelReducer,
  authentication: authenticationReducer,
  autosave: autosaveReducer,
  dataset: datasetReducer,
  job: jobReducer,
  jobs: undoable<JobsState>(jobsReducer, {
    filter: (action, _currentState, _previousHistory) => {
      return (
        // A noHistory boolean can be added to identify actions that should not be undoable
        // (for example we don't want to keep in history the addition of a start entity for a relation)
        !action?.payload?.noHistory &&
        action?.inputType !== InputType.VIDEO &&
        UNDOABLE_JOBS_ACTIONS.includes(action?.type)
      );
    },
    jumpType: JOBS_JUMP,
    limit: 30,
  }),
  labelFrames: labelFramesReducer,
  organization: organizationReducer,
  project: projectReducer,
  projectUser: projectUserReducer,
  projectVersion: projectVersionReducer,
  projects: projectsReducer,
  roles: rolesReducer,
  user: userReducer,
});

type RootState = ReturnType<typeof rootReducer>;

const addProjectInputTypeToUndoableJobsActions: Middleware<RootState> = store => next => action => {
  if (!!action && UNDOABLE_JOBS_ACTIONS.includes(action.type)) {
    const state = store.getState();
    const projectInputType = state.project.inputType;
    // eslint-disable-next-line no-param-reassign
    action.inputType = projectInputType;
  }
  return next(action);
};

export const store = configureStore({
  devTools: shouldAddDevTools ? { maxAge: 100, name: 'redux' } : false,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(addProjectInputTypeToUndoableJobsActions),
  reducer: persistReducer(persistConfig, rootReducer),
});

const innerPersistor = persistStore(store);
// Uncomment the following to make Redux state not persistent
// innerPersistor.purge();
export const persistor = innerPersistor;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, unknown, Action<string>>;
