import { useRouteMatch } from 'react-router-dom';

interface IsInProject {
  isInProject: boolean;
  isInProjectList: boolean;
}
export default function useIsInProject(): IsInProject {
  const { path } = useRouteMatch();
  const isInProject = path.includes('/label/projects/:id');
  const isInProjectList = path.includes('/label/lists/');
  return {
    isInProject,
    isInProjectList,
  };
}
