import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type SetSelectedAssetsPayload } from './types';

const sliceLabeledAssets = createSlice({
  initialState,
  name: 'labeledAssets',
  reducers: {
    DATASET_INITIALIZE() {
      return initialState;
    },
    DATASET_SET_SELECTED_ASSETS(state, action: PayloadAction<SetSelectedAssetsPayload>) {
      return {
        ...state,
        selectedAssets: action.payload,
      };
    },
  },
});

export const { DATASET_INITIALIZE, DATASET_SET_SELECTED_ASSETS } = sliceLabeledAssets.actions;

export default sliceLabeledAssets.reducer;
