/* eslint-disable no-underscore-dangle */
import L, { LatLng, type LeafletMouseEvent, Point } from 'leaflet';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import type React from 'react';

import { type KiliDrawOptions } from './types';

import { ctrlOrMetaKey } from '../../../../../constants/shortcuts';
import { LEAFLET_DRAW_SEMANTIC_TRACER_ZINDEX } from '../../../../../constants/zIndexes';
import { APPLICATION_UPDATE_FIELD } from '../../../../../redux/application/slice';
import { store } from '../../../../../store';
import { useStore, type ZustandStoreValues } from '../../../../../zustand';
import {
  ADD,
  EPSILON,
  FREE_DRAW_POLYGON_CLASSNAME,
  INITIAL_POSITION,
  pointNearFirstPoint,
  removePointsCloseToFirstPoint,
  SUBTRACT,
  VIEW,
} from '../Components/Freedraw/leaflet-freedraw';

const RIGHT_CLICK = 2;

const getSimplified = (tolerance: number, latlngs: L.LatLng[] = []) => {
  if (latlngs.length && tolerance) {
    // latlng to x/y
    const points = latlngs.map((a: L.LatLng) => {
      return new Point(a.lat, a.lng);
    });

    // Simplified points (needs x/y keys)
    const simplified = L.LineUtil.simplify(points, tolerance).map(a => new Point(a.x, a.y));

    // x/y back to latlng
    return simplified.map(a => new LatLng(a.x, a.y));
  }

  return latlngs;
};

const isInitialTracer = (tracerLatLngs: LatLng[]) => {
  return tracerLatLngs.length === 1 && tracerLatLngs?.[0].lat === 0 && tracerLatLngs?.[0].lng === 0;
};

const Semantic = L.Polygon.extend({});

L.Edit.Semantic = L.Edit.Polyline;

L.Draw.Semantic = L.Draw.Polygon.extend({
  Semantic,

  addHooks() {
    if (this.hooksAdded) this.removeHooks();
    this.lastPosition = INITIAL_POSITION;
    this.markerStart = new L.Marker([0, 0], L.Util.extend({}, this.options.markerStart));

    this.Polygon = new L.Polygon([[0, 0]], this.options.polygon);

    this.tracer = new L.Polyline([[0, 0]], L.Util.extend({}, this.options.polyline));
    this.forecastLine = new L.Polyline(
      [[0, 0]],
      L.Util.extend(
        { dashArray: '5, 5', dashOffset: '0' },
        this.options.semanticMode === ADD
          ? { ...this.options.polyline }
          : this.options.subtractPolyLine,
      ),
    );

    this.setMode(this.options.semanticMode);

    const keyDownHandler = this.handleKeyDown.bind(this);
    this.keyDownHandler = keyDownHandler;
    L.DomEvent.on(document.body, 'keydown', keyDownHandler);
    L.DomEvent.on(document.body, 'mouseleave pointerleave', this.mouseUpLeave.bind(this));
    L.DomEvent.on(document.body, 'pointerdown', () => {
      this.isDrawing = true;
    });
    L.DomEvent.on(document.body, 'pointerup', (event: unknown) => {
      this.isDrawing = false;
      this.pointerUp(event);
    });
    this._map.doubleClickZoom.disable();
    this._map
      .on('mousedown touchstart', this.mouseDown, this)
      .on('mousemove pointermove', this.mouseMove, this);
    this.hooksAdded = true;
    this.unsubscribe = useStore.subscribe(
      (state: ZustandStoreValues) => state.labelImageSemantic.semanticMode,
      val => {
        this.setMode(val);
      },
    );
  },

  addPolygon(latlngs: LatLng[]) {
    this.merge(latlngs);
  },

  closePolygon(latlngs: L.LatLng[]) {
    const firstPoint: L.LatLng | undefined = latlngs?.[0];
    if (!firstPoint) return;
    const [mapLat, mapLng] = this.getZoomedMapLatLng(this._map);
    const pointIsNearFirstPoint = pointNearFirstPoint(EPSILON, mapLat, mapLng, firstPoint);
    const simplifiedLatlngs = removePointsCloseToFirstPoint(latlngs, pointIsNearFirstPoint);
    this.stopDrawAndUpdatePolygon(simplifiedLatlngs);
  },

  createKiliPolygon(latlngs: L.LatLng[][], addAsIndividualPolygon = true) {
    if (addAsIndividualPolygon) {
      latlngs.forEach(latlngPolygon => {
        const poly = this.Polygon
          ? this.Polygon.setLatLngs([latlngPolygon])
          : new L.Polygon([latlngPolygon], this.options.polygon);
        L.Draw.Feature.prototype._fireCreatedEvent.call(this, poly);
      });
    } else {
      const poly = this.Polygon
        ? this.Polygon.setLatLngs(latlngs)
        : new L.Polygon(latlngs, this.options.polygon);
      L.Draw.Feature.prototype._fireCreatedEvent.call(this, poly);
    }
  },

  drawStartedEvents(onoff = 'on') {
    const map = this._map;

    if (!map) return;

    map[onoff]('mousemove touchmove', this.mouseMoveWhenDrawing, this);
    map[onoff]('mouseup touchend', this.mouseUpLeave, this);
  },

  getTracerOptions() {
    if (this.mode === ADD) {
      return this.options.polyline;
    }
    if (this.mode === SUBTRACT) {
      return this.options.subtractPolyLine;
    }
    return {};
  },

  getZoomedMapLatLng(map: L.Map) {
    if (!map) {
      return [1, 1];
    }

    const bounds = this._map.getBounds();
    const northWest = bounds.getNorthWest();
    const southEast = bounds.getSouthEast();

    return [northWest.lat - southEast.lat, northWest.lng - southEast.lng];
  },

  handleCancelLastSegment() {
    if (!this.tracer || ![ADD, SUBTRACT].includes(this.mode)) {
      return;
    }
    const currentLatLngs = this.tracer.getLatLngs();

    if (currentLatLngs.length < 2) return;

    currentLatLngs.pop();

    this.tracer.setLatLngs(currentLatLngs);

    if (!this.forecastLine) return;

    const currentMousePosition = this.forecastLine.getLatLngs()?.at?.(-1);
    this.updateForecastLine(currentLatLngs?.at?.(-1), currentMousePosition);
  },

  handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Escape') {
      this.stopDraw();
      this.setMode(VIEW);
      useStore.getState().labelImageSemantic.setSemanticMode(VIEW);
    }
    const currentLatLngs = this.tracer?.getLatLngs?.() || [];
    if (ctrlOrMetaKey(event) && event.key === 'z') {
      if (currentLatLngs.length === 1) {
        this.resetForecastLine();
        this.resetMarkerStart();
        this.resetTracer();
        if (this.polygon) {
          this._map.removeLayer(this.polygon);
        }
      } else {
        this.handleCancelLastSegment();
      }
    }
  },

  hasPointOutFirstMarker() {
    const latlngs = getSimplified(this.options.simplify_tolerance, this.tracer.getLatLngs());
    if (latlngs.length <= 1) return false;
    const [mapLat, mapLng] = this.getZoomedMapLatLng(this._map);
    return !!latlngs.find(
      coord => !pointNearFirstPoint(EPSILON, mapLat, mapLng, latlngs?.[0])(coord),
    );
  },

  holdDraw() {
    this.creating = false;
    this.drawStartedEvents('off');
    this.setMapPermissions('enable');
    this.forecastLine.addTo(this._map);
  },

  initialize(map: L.DrawMap, options: KiliDrawOptions) {
    L.Draw.Polygon.prototype.initialize.call(this, map, options);

    this.setCanUndo = (canUndo: boolean) => {
      store.dispatch(APPLICATION_UPDATE_FIELD({ path: 'canUndo', value: canUndo }));
    };

    if (_isEmpty(this.options)) {
      return;
    }

    // Save the type so super can fire, need to do this as cannot do this.TYPE :(
    // @ts-expect-error Semantic doesn't exist in L.Draw namespace
    this.type = L.Draw.Semantic.TYPE;

    this.isDrawing = false;

    this.defaultPreferences = {
      doubleClickZoom: map.doubleClickZoom.enabled,
      dragging: map.dragging.enabled,
      scrollWheelZoom: map.scrollWheelZoom.enabled,
    };
  },

  isNearFirstPoint(event: LeafletMouseEvent) {
    const latlngs = getSimplified(this.options.simplify_tolerance, this.tracer.getLatLngs());
    const currentPoint = event?.latlng ?? this.lastPosition;
    const firstPoint = latlngs?.[0];
    const [mapLat, mapLng] = this.getZoomedMapLatLng(this._map);
    return pointNearFirstPoint(EPSILON, mapLat, mapLng, firstPoint)(currentPoint);
  },

  merge(latlngs: LatLng[]) {
    const { drawOverExistingObjects } = useStore.getState().labelImageSemantic;
    const newPolygonLayer = new L.Polygon(latlngs, {
      ...this.options.polygon,
      drawOver: drawOverExistingObjects,
      isSemantic: true,
      semanticMode: 'add',
    });
    L.Draw.Feature.prototype._fireCreatedEvent.call(this, newPolygonLayer);
  },

  mouseDown(event: LeafletMouseEvent) {
    if (!event || !this.tracer) return;
    const { originalEvent } = event;
    originalEvent.preventDefault();

    if (
      this.creating ||
      (this.mode !== ADD && this.mode !== SUBTRACT) ||
      originalEvent.button === RIGHT_CLICK ||
      originalEvent.ctrlKey ||
      originalEvent.shiftKey
    ) {
      // 1. no mouse down if already creating
      // 2. no mouse down if not in correct mode
      // 3. prevent right click
      // 4. allows ctrl key to toggle view mode
      // 5. allows shift key to box zoom
      return;
    }

    const tracerOptions = this.getTracerOptions();
    this.tracer.setStyle(tracerOptions);

    this.tracer.addTo(this._map);

    const latlngs = getSimplified(this.options.simplify_tolerance, this.tracer.getLatLngs());
    if (isInitialTracer(latlngs)) {
      this.markerStart.setLatLng(event.latlng);
      this.markerStart.addTo(this._map);
      this.tracer.setLatLngs([event.latlng]);
    } else {
      this.tracer.addLatLng(event.latlng);
    }
    this.lastPosition = event.latlng;

    this.startDraw();
    this.setCanUndo?.(false);
  },

  mouseMove(event: LeafletMouseEvent) {
    if (!this.tracer || (this.mode !== ADD && this.mode !== SUBTRACT)) {
      return;
    }

    const latlngs = this.tracer.getLatLngs();
    if (isInitialTracer(latlngs)) {
      return;
    }
    const lastLatLng = latlngs?.at?.(-1);
    this.lastPosition = event.latlng;

    this.updateForecastLine(lastLatLng, event.latlng);
  },

  mouseMoveWhenDrawing(event: LeafletMouseEvent) {
    event.originalEvent.preventDefault();
    if (!this.tracer || _isEqual(this.tracer.getLatLngs().slice(-1)[0], event.latlng)) {
      return;
    }
    this.tracer.addLatLng(event.latlng);
  },

  mouseUpLeave(event: unknown) {
    if (!this.tracer) {
      return;
    }
    const latlngs = getSimplified(this.options.simplify_tolerance, this.tracer.getLatLngs());
    const nearFirstPoint = this.isNearFirstPoint(event);
    const hasPointOutFirstMarker = this.hasPointOutFirstMarker();

    // User did not stop near first point
    if (
      (this.mode === ADD || this.mode === SUBTRACT) &&
      (!nearFirstPoint || !hasPointOutFirstMarker)
    ) {
      this.holdDraw();
      return;
    }
    this.closePolygon(latlngs);
  },

  options: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    concave_polygons: true,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    merge_polygons: true,
    polygon: {
      className: FREE_DRAW_POLYGON_CLASSNAME,
      fillOpacity: 0.2,
      noClip: true,
      smoothFactor: 0,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    simplify_tolerance: 0,
  },

  pointerUp(event: unknown) {
    if (!this.tracer) {
      return;
    }
    const hasPointOutFirstMarker = this.hasPointOutFirstMarker();
    const latlngs = getSimplified(this.options.simplify_tolerance, this.tracer.getLatLngs());
    const nearFirstPoint = this.isNearFirstPoint(event);
    if (!nearFirstPoint || !hasPointOutFirstMarker) return;
    this.closePolygon(latlngs);
  },

  removeHooks() {
    const objectsToDelete = ['tracer', 'forecastLine', 'Polygon', 'markerStart'];

    const keyDownHandlerToRemove = this.keyDownHandler || this.handleKeyDown.bind(this);
    L.DomEvent.off(document.body, 'keydown', keyDownHandlerToRemove);
    L.DomEvent.off(document.body, 'mouseleave pointerleave', this.mouseUpLeave.bind(this));
    L.DomEvent.off(document.body, 'pointerdown', () => {
      this.isDrawing = true;
    });
    L.DomEvent.off(document.body, 'pointerup', (event: unknown) => {
      this.isDrawing = false;
      this.pointerUp(event);
    });

    objectsToDelete.forEach(object => {
      if (!this[object]) return;
      this._map.removeLayer(this[object]);
      delete this[object];
    });
    this._map.doubleClickZoom.enable();
    this._map
      .off('mousedown touchstart', this.mouseDown, this)
      .off('mousemove pointermove', this.mouseMove, this);

    this.setMapPermissions('enable');
    this.unsubscribe();
  },

  resetForecastLine() {
    if (this.forecastLine) {
      // Remove forecastLine polyline by setting empty points
      this.forecastLine.setLatLngs([[0, 0]]);
      this._map.removeLayer(this.forecastLine);
    }
  },

  resetMarkerStart() {
    if (this.markerStart) {
      // Remove markerStart marker by setting empty points
      this.markerStart.setLatLng([0, 0]);
      this._map.removeLayer(this.markerStart);
    }
  },

  resetTracer() {
    if (this.tracer) {
      // Remove tracer polyline by setting empty points
      this.tracer.setLatLngs([[0, 0]]);
      this._map.removeLayer(this.tracer);
    }
  },

  setMapClass() {
    const map = this._map._container;
    const util = L.DomUtil;
    const { removeClass } = util;

    removeClass(map, 'leaflet-fhs-add');
    removeClass(map, 'leaflet-fhs-subtract');
    removeClass(map, 'leaflet-fhs-delete');
    removeClass(map, 'leaflet-fhs-view');

    util.addClass(map, `leaflet-fhs-${this.mode}`);
  },

  setMapPermissions(method: 'enable' | 'disable') {
    const map = this._map;
    const preferences = this.defaultPreferences;

    if (!map) return;

    map?.dragging?.[method]?.();
    map?.scrollWheelZoom?.[method]?.();

    if (method === 'enable') {
      // Inherit the preferences assigned to the map instance by the developer.

      if (!!preferences && !preferences?.dragging) {
        map?.dragging?.disable();
      }

      if (!!preferences && !preferences?.scrollWheelZoom) {
        map?.scrollWheelZoom?.disable();
      }
    }
  },

  setMode(mode = VIEW) {
    const formattedMode = mode.toLowerCase();

    this.mode = formattedMode;

    if (formattedMode === SUBTRACT) {
      this.tracer.setStyle({
        color: this.options.subtractPolyLine.color,
        zIndex: LEAFLET_DRAW_SEMANTIC_TRACER_ZINDEX,
      });
    } else if (formattedMode === ADD) {
      this.tracer.setStyle({
        color: this.options.polyline.color,
        zIndex: LEAFLET_DRAW_SEMANTIC_TRACER_ZINDEX,
      });
    }

    if (!this._map) {
      return;
    }

    if (formattedMode === ADD || formattedMode === SUBTRACT) {
      this._map.dragging.disable();
    } else {
      this._map.dragging.enable();
    }

    this.setMapClass();
  },

  startDraw() {
    this.creating = true;
    this.isDrawing = true;
    this.drawStartedEvents('on');
    this.setMapPermissions('disable');
    this._map.removeLayer(this.forecastLine);
  },

  statics: {
    TYPE: 'semantic',
  },

  stopDraw() {
    this.creating = false;
    this.resetForecastLine();
    this.resetMarkerStart();
    this.resetTracer();

    this.drawStartedEvents('off');
    this.setMapPermissions('enable');
    this.setCanUndo?.(true);
  },

  stopDrawAndUpdatePolygon(latlngs: L.LatLng[]) {
    this.stopDraw();

    // User has failed to drag their cursor enough to create a valid polygon (triangle)
    if (latlngs.length < 3) return;

    // Convert tracer polyline into polygon
    if (this.options.concave_polygons) {
      latlngs.push(latlngs[0]);
    }
    if (this.mode === ADD) {
      this.addPolygon(latlngs);
    } else if (this.mode === SUBTRACT) {
      this.subtractPolygon(latlngs, true);
    }
  },

  subtract(polygonToSubtract: L.Polygon) {
    const newPolygonLayer = new L.Polygon(polygonToSubtract.getLatLngs(), {
      ...this.options.polygon,
      isSemantic: true,
      semanticMode: 'substract',
    });
    L.Draw.Feature.prototype._fireCreatedEvent.call(this, newPolygonLayer);
  },

  subtractPolygon(latlngs: LatLng[], force: boolean) {
    const simplifiedLatlngs = force
      ? latlngs
      : getSimplified(this.options.simplify_tolerance, latlngs);
    const newPolygon = new L.Polygon(simplifiedLatlngs);
    this.subtract(newPolygon);
  },

  updateForecastLine(fromPosition: L.LatLngExpression, toPosition: L.LatLngExpression) {
    this.forecastLine.setLatLngs([fromPosition, toPosition]);
  },
});
