import {
  type JsonCategories as Categories,
  type JsonCategory as Category,
  type Job,
  type Jobs,
  type PoseEstimationPoint,
} from '@kili-technology/types';
import fp from 'lodash/fp';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';

import { jobsAnnotations } from '../../redux/jobs/selectors';
import { projectJobs } from '../../redux/selectors';
import { store } from '../../store';
import { labelInterfaceUpdateField, useStore } from '../../zustand';

export type AccordionTree = Record<string, JobAccordionTree>;

type PointAccordionTree = {
  children?: AccordionTree;
  value: boolean;
};

export type ObjectAccordionTree = {
  children?: AccordionTree;
  points?: Record<string, PointAccordionTree>;
  value: boolean;
};

export type CategoryAccordionTree = {
  children?: AccordionTree;
  objects?: Record<string, ObjectAccordionTree>;
  value: boolean;
};

type JobAccordionTree = {
  categories?: Record<string, CategoryAccordionTree>;
  value: boolean;
};

export const CATEGORIES = 'categories';
export const CHILDREN = 'children';
export const OBJECTS = 'objects';
export const POINTS = 'points';
export const VALUE = 'value';
export const CONTENT = 'content';

function getCategoryAccordionTree(categories: Categories) {
  return Object.keys(categories).reduce((acc, code) => {
    return {
      ...acc,
      [code]: {
        objects: {},
        value: false,
      },
    };
  }, {} as Record<string, CategoryAccordionTree>);
}

function getChildrenAccordion(childrenJobs: string[]) {
  return childrenJobs.reduce<Record<string, object>>((acc, newChild) => {
    return { ...acc, [newChild]: { value: true } };
  }, {});
}

export function getInitialAccordion(jobs: Jobs) {
  return Object.keys(jobs).reduce((acc, jobName) => {
    const job = jobs[jobName];
    if (job.isChild) return acc;

    return {
      ...acc,
      [jobName]: {
        categories: getCategoryAccordionTree(job.content?.categories ?? {}),
        value: true,
      },
    };
  }, {} as AccordionTree);
}

export const getNewAccordionAfterCategoryClick = (
  [, job]: [string, Job],
  [categoryCode, category]: [string, Category],
  accordion: AccordionTree,
  id: string[],
  shouldCloseClickedCategory = false,
  shouldCloseObjects = true,
): AccordionTree => {
  const categoryChildren = category?.children ?? [];
  const currentCategoryAccordion = _get(accordion, id, {}) as CategoryAccordionTree;
  const newCategoryAccordion = {
    ...currentCategoryAccordion,
    children: categoryChildren.reduce((acc, childJobName) => {
      return {
        ...acc,
        [childJobName]: {
          ...currentCategoryAccordion?.children?.[childJobName],
          value: !shouldCloseClickedCategory,
        },
      };
    }, {}),
    objects: Object.keys(currentCategoryAccordion?.objects ?? {}).reduce((acc, mid) => {
      const objectAccordion = currentCategoryAccordion?.objects?.[mid] ?? {};
      return { ...acc, [mid]: { ...objectAccordion, value: !shouldCloseObjects } };
    }, {}),
    value: !shouldCloseClickedCategory,
  };
  const jobId = id.slice(0, id.length - 2);
  const jobAccordion = _get(accordion, jobId, {}) as JobAccordionTree;
  const newJobAccordionCategories = {
    [categoryCode]: newCategoryAccordion,
    ...Object.keys(job?.content?.categories ?? {}).reduce((acc, jobCategoryCode) => {
      if (jobCategoryCode === categoryCode) return acc;

      return {
        ...acc,
        [jobCategoryCode]: { ...jobAccordion?.categories?.[jobCategoryCode], value: false },
      };
    }, {}),
  };
  const newJobAccordion = { categories: newJobAccordionCategories, value: true };
  return fp.set(jobId)(newJobAccordion, accordion);
};

export const openCategoryInAccordion = (
  [_, job]: [string, Job],
  categoryCode: string,
  accordion: AccordionTree,
  jobId: string[],
): AccordionTree => {
  const currentJobAccordion = _get(accordion, jobId, {}) as JobAccordionTree;
  const newJobAccordion = {
    ...currentJobAccordion,
    categories: Object.keys(job.content?.categories ?? {}).reduce((acc, code) => {
      const category = job?.content?.categories?.[code];
      const children = category?.children ?? [];
      return {
        ...acc,
        [code]: {
          ...(currentJobAccordion?.categories?.[code] ?? {}),
          children: children.reduce((a, childJobName) => {
            return {
              ...a,
              [childJobName]: { value: true },
            };
          }, {}),
          value: code === categoryCode,
        },
      };
    }, {}),
    value: true,
  };

  return fp.set(jobId)(newJobAccordion, accordion);
};

export const computeNewAccordionWithObjectOpen = (
  jobName: string,
  categoryCode: string,
  category: Category,
  mid: string,
  accordion: AccordionTree,
): AccordionTree => {
  return Object.fromEntries(
    Object.entries(accordion).map(([accordionJobName, jobAccordion]) => {
      const isCategoryInJobAccordion = accordionJobName === jobName;
      const categoryChildren = category?.children ?? [];
      const categoryPoints = category?.points ?? [];
      const newJobAccordionCategories = Object.fromEntries(
        Object.entries(jobAccordion?.categories ?? {}).map(
          ([accordionCategoryCode, accordionCategory]) => {
            const isObjectCategory =
              isCategoryInJobAccordion && accordionCategoryCode === categoryCode;
            const newCategoryAccordion = {
              objects: isObjectCategory
                ? {
                    [mid]: {
                      ...(accordionCategory?.objects?.[mid] ?? {}),
                      children: Object.fromEntries(
                        categoryChildren.map(childJobName => [
                          childJobName,
                          {
                            ...(accordionCategory?.objects?.[mid]?.children?.[childJobName] ?? {}),
                            categories: {},
                            value: true,
                          },
                        ]),
                      ),
                      points: Object.fromEntries(
                        categoryPoints.map(point => {
                          const { code, children } = point;
                          return [
                            code,
                            {
                              ...(accordionCategory?.objects?.[mid]?.points?.[code] ?? {}),
                              children: Object.fromEntries(
                                (children ?? []).map(pointChildJobName => {
                                  const accordionChildJob =
                                    accordionCategory?.objects?.[mid]?.points?.[point.code]
                                      ?.children?.[pointChildJobName] ?? {};
                                  return [pointChildJobName, { ...accordionChildJob, value: true }];
                                }),
                              ),
                              value: true,
                            },
                          ];
                        }),
                      ),
                      value: true,
                    },
                  }
                : {},
              value: isObjectCategory,
            };
            return [accordionCategoryCode, newCategoryAccordion];
          },
        ),
      );
      const shouldOpenJob = isCategoryInJobAccordion;
      const newJobAccordion = {
        categories: newJobAccordionCategories,
        value: shouldOpenJob || jobAccordion.value,
      };
      return [accordionJobName, newJobAccordion];
    }),
  );
};

export const updateEntitiesInAccordion = (
  entities: { categoryCode: string; jobName: string; mid: string }[],
  jobs: Jobs,
): AccordionTree => {
  let newAccordion = getInitialAccordion(jobs);
  const entitiesGroupedByJob = _groupBy(entities, entity => entity.jobName);
  Object.entries(entitiesGroupedByJob).forEach(([jobName, entitiesOfJob]) => {
    const entitiesGroupedByCategory = _groupBy(entitiesOfJob, entity => entity.categoryCode);
    Object.entries(entitiesGroupedByCategory).forEach(([categoryCode, entitiesOfCategory]) => {
      const childrenJobs = jobs?.[jobName]?.content?.categories?.[categoryCode]?.children ?? [];
      const accordionMids = Object.fromEntries(
        entitiesOfCategory.map(entity => [
          entity.mid,
          {
            children: getChildrenAccordion(childrenJobs),
            value: true,
          },
        ]),
      );
      newAccordion = fp.set([jobName, CATEGORIES, categoryCode, OBJECTS])(
        accordionMids,
        newAccordion,
      );
      newAccordion = fp.set([jobName, CATEGORIES, categoryCode, VALUE])(true, newAccordion);
    });
    newAccordion = fp.set([jobName, VALUE])(true, newAccordion);
  });
  return newAccordion;
};

export const togglePointAccordion = (
  point: PoseEstimationPoint,
  pointId: string[],
  accordion: AccordionTree,
): AccordionTree => {
  const currentPointAccordion = _get(accordion, pointId, {}) as PointAccordionTree;
  const pointValuePath = pointId.concat(VALUE);
  const children = point.children ?? [];
  const isOpeningPoint = !_get(accordion, pointValuePath, false);
  const newPointAccordion = {
    ...currentPointAccordion,
    children: Object.fromEntries(
      children.map(jobName => {
        const accordionJob = _get(accordion, pointId.concat(...[CHILDREN, jobName]));
        return [jobName, { ...accordionJob, value: isOpeningPoint }];
      }),
    ),
    value: isOpeningPoint,
  };
  const newAccordion = fp.set(pointId)(newPointAccordion, accordion);
  return newAccordion;
};

export const toggleObjectAccordion = (
  category: [string, Category],
  objectId: string[],
  accordion: AccordionTree,
): AccordionTree => {
  const currentObjectAccordion = _get(accordion, objectId, {}) as ObjectAccordionTree;
  const objectValuePath = objectId.concat(VALUE);
  const children = category[1]?.children ?? [];
  const points = category[1]?.points ?? [];
  const isOpeningObject = !_get(accordion, objectValuePath, false);
  const newObjectAccordion = {
    ...currentObjectAccordion,
    children: Object.fromEntries(
      children.map(jobName => {
        const accordionJob = _get(accordion, objectId.concat(...[CHILDREN, jobName]));
        return [jobName, { ...accordionJob, value: isOpeningObject }];
      }),
    ),
    points: Object.fromEntries(
      points.map(point => {
        return [
          point.code,
          {
            children: Object.fromEntries(
              (point.children ?? []).map(pointChildJobName => {
                const accordionPointJob = _get(
                  accordion,
                  objectId.concat(...[POINTS, point.code, CHILDREN, pointChildJobName]),
                );
                return [pointChildJobName, { ...accordionPointJob, value: isOpeningObject }];
              }),
            ),
            value: isOpeningObject,
          },
        ];
      }),
    ),
    value: isOpeningObject,
  };
  const newAccordion = fp.set(objectId)(newObjectAccordion, accordion);
  return newAccordion;
};

export const setCategoryAccordion = (
  path: string[],
  [, category]: [string, Category],
  annotationMids: string[],
  accordion: AccordionTree,
  expand: boolean,
): AccordionTree => {
  const children = category?.children ?? [];
  const points = category?.points ?? [];
  const isExpanded = !expand;
  const isOpeningCategory = expand;
  const currentCategoryAccordion = _get(accordion, path, { value: false }) as CategoryAccordionTree;
  const newCategoryAccordion = {
    children: Object.fromEntries(
      children.map(jobName => [
        jobName,
        {
          ..._get(accordion, path.concat(...[CHILDREN, jobName])),
          value: isOpeningCategory,
        },
      ]),
    ),
    objects: isOpeningCategory
      ? Object.fromEntries(
          annotationMids.map(mid => {
            const objectId = path.concat(...[OBJECTS, mid]);
            return [
              mid,
              {
                ..._get(accordion, objectId),
                children: Object.fromEntries(
                  children.map(jobName => [
                    jobName,
                    {
                      ..._get(accordion, objectId.concat(...[CHILDREN, jobName])),
                      value: isOpeningCategory,
                    },
                  ]),
                ),
                points: Object.fromEntries(
                  points.map(point => {
                    return [
                      point.code,
                      {
                        children: Object.fromEntries(
                          (point.children ?? []).map(pointChildJobName => {
                            const accordionPointJob = _get(
                              accordion,
                              objectId.concat(...[POINTS, point.code, CHILDREN, pointChildJobName]),
                            );
                            return [
                              pointChildJobName,
                              { ...accordionPointJob, value: isOpeningCategory },
                            ];
                          }),
                        ),
                        value: isOpeningCategory,
                      },
                    ];
                  }),
                ),
                value: true,
              },
            ];
          }),
        )
      : currentCategoryAccordion?.objects,
    value: !isExpanded,
  };
  const newAccordion = fp.set(path)(newCategoryAccordion, accordion);
  return newAccordion;
};

export const toggleCategoryAccordion = (
  path: string[],
  category: [string, Category],
  annotationMids: string[],
  accordion: AccordionTree,
): AccordionTree => {
  const categoryValuePath = path.concat(VALUE);
  const isExpanded: boolean = _get(accordion, categoryValuePath, false);
  return setCategoryAccordion(path, category, annotationMids, accordion, !isExpanded);
};

export const openObjectInAccordion = ({
  mid,
  jobName,
  categoryCode,
}: {
  categoryCode: string;
  jobName: string;
  mid: string;
}) => {
  const accordion = useStore.getState().labelInterface.accordionTree;
  const jobs = projectJobs(store.getState());
  const category = jobs?.[jobName]?.content?.categories?.[categoryCode] ?? { name: categoryCode };
  const newAccordion = computeNewAccordionWithObjectOpen(
    jobName,
    categoryCode,
    category,
    mid,
    accordion,
  );
  labelInterfaceUpdateField({
    path: 'accordionTree',
    value: newAccordion,
  });
};

export const openAccordionForObjectMid = (mid: string) => {
  const state = store.getState();
  const midAnnotations = jobsAnnotations(state).find(annotation => annotation?.mid === mid);
  if (!midAnnotations) {
    return;
  }
  const { jobName, categories } = midAnnotations;
  const categoryCode = categories?.[0]?.name;
  openObjectInAccordion({ categoryCode, jobName, mid });
};
