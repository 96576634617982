import { type MachineLearningTask } from '@kili-technology/types';

export const RICH_TEXT_CONTAINER_ID = 'richTextContainer';
export const PDF_CONTAINER_ID = 'pdfContainer';

export interface LabelInterfaceToggleAccordionPayload {
  annotationMid: string;
  categoryCode: string;
}

export interface LabelInterfaceToggleModelInteractiveSegmentationPayload {
  isSegmentationToolSelected: boolean;
  segmentationMode: 'point' | 'box';
}

export interface LabelInterfaceSelectChunkPayload {
  category: string;
  mid?: string;
}

export interface LabelInterfaceHandleEscapeKeyPayload {
  currentMlTask: MachineLearningTask;
  poseEstimationObjectId: string | null;
  wasDrawingNewPose: boolean;
}
