import {
  type Annotation,
  type EntityRelation,
  type FrameJsonResponse,
  type Job,
  type JsonResponse,
  MachineLearningTask,
  type Tool,
} from '@kili-technology/types';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import {
  buildJsonResponse,
  getImageAnnotations,
  getDetectionTaskAnnotations,
  getImageRelationAnnotations,
  getObjectDetectionJobs,
  getDetectionTaskJobs,
  getObjectRelationJobs,
} from './helpers';
import {
  type AnnotationResponses,
  type NamedEntitiesRecognitionJobs,
  type NamedEntitiesRelationJobs,
  type Responses,
  type ResponsesTasks,
} from './types';

import { InputType } from '../../__generated__/globalTypes';
import {
  ANNOTATIONS,
  CATEGORIES,
  CONTENT,
  MID,
  ML_TASK,
} from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { memoizedGetChildrenJob, memoizedGetInstructionsForJob } from '../../components/helpers';
import { getObjects } from '../../pages/projects/label/LabelDialog/LabelInterface/JobsColumn/components/JobCategory/helpers';
import { getBatchedAnnotationFromGlobalAnnotation } from '../../services/assets/text';
import {
  getObjectInfoFromResponses,
  getVideoObjectsFromFramesResponses,
} from '../../services/assets/video';
import { type KiliAnnotation } from '../../services/jobs/setResponse';
import sortByJobAndMid, { sortByOrderInPDF } from '../../services/jobs/sortAnnotations';
import { annotationIsPoseEstimation } from '../../services/poseEstimation';
import { jobCurrentJobName } from '../job/selectors';
import { convertFrameResponsesToJsonResponse } from '../label-frame/helpers';
import { labelFramesFrameResponses } from '../label-frames/selectors';
import {
  createDeepSelectorFunction,
  projectInputType,
  projectIsVideo,
  projectJobs,
  projectJsonInterface,
} from '../selectors';
import { type State } from '../types';

export const DEFAULT_LIST = [];
export const DEFAULT_OBJECT = {};

export const createDeepEqualSelector = createDeepSelectorFunction();

export const jobsResponses = createSelector<State, ResponsesTasks, Responses>(
  [state => (state?.jobs as AnnotationResponses)?.present ?? {}],
  jobs => jobs as Responses,
);

export const jobsResponsesPast = createSelector<State, ResponsesTasks, Responses[]>(
  [state => (state?.jobs as AnnotationResponses)?.past ?? {}],
  jobsPast => jobsPast as Responses[],
);

export const jobsResponsesFuture = createSelector<State, ResponsesTasks, Responses[]>(
  [state => (state?.jobs as AnnotationResponses)?.future ?? {}],
  jobsFuture => jobsFuture as Responses[],
);

export const jobsJsonResponseClassic = createSelector(
  [projectJobs, jobsResponses, projectInputType],
  (jobs, responses, inputType): JsonResponse | undefined => {
    const fatherJobsList = Object.keys(jobs).filter(jobName => !_get(jobs, [jobName, 'isChild']));
    if (inputType === InputType.VIDEO) {
      return undefined;
    }
    const jsonResponse = buildJsonResponse(jobs, responses, fatherJobsList);
    return jsonResponse;
  },
);

export const jobsUnsortedCategoriesOfAnnotation = createSelector(
  [jobsResponses, (state: State, mid: string) => mid],
  (responses, mid: string) => {
    return responses[mid]?.CLASSIFICATION;
  },
);

export const jobsTranscriptionOfAnnotation = createSelector(
  [jobsResponses, projectJsonInterface, (state: State, annotation: Annotation) => annotation],
  (responses, jsonInterface, annotation: Annotation) => {
    if (!annotation.jobName) return undefined;
    const transcriptionChilds = (
      jsonInterface.jobs[annotation.jobName ?? ''].content?.categories?.[
        annotation.categories[0]?.name
      ]?.children ?? []
    ).filter(
      childrenName => jsonInterface.jobs[childrenName].mlTask === MachineLearningTask.TRANSCRIPTION,
    );
    const res = transcriptionChilds.map(childrenName => {
      return {
        content: responses[annotation.mid]?.TRANSCRIPTION?.[childrenName]?.text ?? '',
        jobName: childrenName,
        parentMid: annotation.mid,
        placeholder: memoizedGetInstructionsForJob(childrenName),
      };
    });

    if (res[0]) return res[0];

    // we need to check in JV if the job has a child which is a transcription
    const firstTranscriptionJob = memoizedGetChildrenJob(
      annotation.jobName || '',
      annotation.categories[0]?.name,
    );
    if (firstTranscriptionJob) {
      return {
        content: '',
        jobName: firstTranscriptionJob,
        parentMid: annotation.mid,
        placeholder: memoizedGetInstructionsForJob(firstTranscriptionJob),
      };
    }
    return undefined;
  },
);

export const jobsJsonResponseFrame = createSelector(
  [projectJobs, projectInputType, labelFramesFrameResponses],
  (jobs, inputType, frameResponses): FrameJsonResponse => {
    if (inputType === InputType.VIDEO) {
      const jsonResponse = {};
      Object.entries(frameResponses).forEach(([frame, responsesFrame]) => {
        // O(n^5)
        if (responsesFrame && Object.keys(responsesFrame).length > 0) {
          const builtJsonResponse = convertFrameResponsesToJsonResponse(jobs, responsesFrame);
          if (builtJsonResponse && Object.keys(builtJsonResponse).length > 0) {
            _set(jsonResponse, frame, builtJsonResponse);
          }
        }
      });
      return jsonResponse as FrameJsonResponse;
    }
    return {} as FrameJsonResponse;
  },
);

export const jobsJsonResponse = createSelector(
  [jobsJsonResponseClassic, jobsJsonResponseFrame, projectIsVideo],
  (responseClassic, responseFrame, isVideo) => {
    return isVideo ? responseFrame : responseClassic;
  },
);

export const frameResponsesFrameObjects = createSelector(
  [jobsJsonResponseFrame, projectJobs],
  getVideoObjectsFromFramesResponses,
);

export const ANNOTATION_ORDER: Record<
  | MachineLearningTask.OBJECT_DETECTION
  | MachineLearningTask.TRANSCRIPTION
  | MachineLearningTask.CLASSIFICATION,
  number
> = {
  [MachineLearningTask.CLASSIFICATION]: 10,
  [MachineLearningTask.OBJECT_DETECTION]: 1,
  [MachineLearningTask.TRANSCRIPTION]: 20,
};

export const frameResponsesFrameObjectMidsAndNames = createSelector(
  [frameResponsesFrameObjects],
  elements => {
    const formatedElementList = elements.map(element => ({
      mid: element.mid,
      name: element.name,
      order:
        ANNOTATION_ORDER[
          element.mlTask as
            | MachineLearningTask.OBJECT_DETECTION
            | MachineLearningTask.CLASSIFICATION
            | MachineLearningTask.TRANSCRIPTION
        ],
    }));
    return sortBy(formatedElementList, ['order', 'mid']);
  },
);

export const frameResponsesObjectsInfo = createSelector(
  [jobsJsonResponseFrame, projectJobs],
  getObjectInfoFromResponses,
);

export const jobsObjectDetectionsJobs = createDeepEqualSelector([jobsResponses], responses =>
  getObjectDetectionJobs(responses),
);

export const jobsDetectionTasksJobs = createDeepEqualSelector([jobsResponses], responses =>
  getDetectionTaskJobs(responses),
);

export const jobsSortedAnnotations = createSelector(
  [projectJobs, projectInputType, jobsResponses],
  (jobs, inputType, responses) => {
    if (!inputType) {
      return [];
    }

    switch (inputType) {
      case InputType.PDF:
        return sortByOrderInPDF(responses, jobs);
      default:
        return sortByJobAndMid(responses, jobs);
    }
  },
);

export const jobsObjectDetectionAnnotations = createDeepEqualSelector(
  [jobsObjectDetectionsJobs],
  objectDetectionsJobs => getImageAnnotations(objectDetectionsJobs),
);

export const jobsDetectionTaskAnnotations = createDeepEqualSelector(
  [jobsDetectionTasksJobs],
  detectionsTaskJobs => getDetectionTaskAnnotations(detectionsTaskJobs),
);

export const creatingOrEditingObjectDetectionAnnotation = createSelector(
  [
    jobsObjectDetectionAnnotations,
    (_: State, { creatingOrEditingObjectId }: { creatingOrEditingObjectId: string | null }) =>
      creatingOrEditingObjectId,
  ],
  (imageAnnotations, creatingOrEditingObjectId) =>
    imageAnnotations.find(imageAnnotation => creatingOrEditingObjectId === imageAnnotation.mid),
);

export const selectObjectDetectionTaskSelectedAnnotations = createSelector(
  [
    jobsObjectDetectionAnnotations,
    (_: State, { selectedObjectIds }: { selectedObjectIds: string[] }) => selectedObjectIds,
  ],
  (imageAnnotations, selectedObjectIds) =>
    imageAnnotations.filter(imageAnnotation => selectedObjectIds.includes(imageAnnotation.mid)),
);

export const selectDetectionTaskSelectedAnnotations = createSelector(
  [
    jobsDetectionTaskAnnotations,
    (_: State, { selectedObjectIds }: { selectedObjectIds: string[] }) => selectedObjectIds,
  ],
  (detectionTaskAnnotations, selectedObjectIds) =>
    detectionTaskAnnotations.filter(detectionTaskAnnotation =>
      selectedObjectIds.includes(detectionTaskAnnotation.mid),
    ),
);

export const selectJobsSelectedObject = createDeepEqualSelector(
  [
    jobsObjectDetectionAnnotations,
    (_: State, { objectId }: { objectId: string | null }) => objectId,
  ],
  (annotations, objectId) => {
    return annotations.find(annotation => annotation?.mid === objectId) as KiliAnnotation;
  },
);

export const poseEstimationLastPoint = createDeepEqualSelector(
  [selectJobsSelectedObject],
  currentAnnotation => {
    if (!currentAnnotation) {
      return null;
    }
    if (!annotationIsPoseEstimation(currentAnnotation)) {
      return null;
    }
    const pointsOfAnnotation = currentAnnotation?.points || [];
    if (pointsOfAnnotation.length < 1) {
      return null;
    }
    const lastPoint = pointsOfAnnotation[pointsOfAnnotation.length - 1];
    return lastPoint;
  },
);

export const jobsNamedEntitiesRelationJobs = createDeepEqualSelector(
  [jobsResponses],
  responses =>
    _get(
      responses,
      MachineLearningTask.NAMED_ENTITIES_RELATION,
      DEFAULT_OBJECT,
    ) as NamedEntitiesRelationJobs,
);

const jobObjectRelationJobs = createDeepEqualSelector([jobsResponses], responses =>
  getObjectRelationJobs(responses),
);

export const jobsTextRelationAnnotations = createDeepEqualSelector(
  [jobsNamedEntitiesRelationJobs],
  namedEntitiesRelationJobs => {
    const namedEntitiesRelationJobsNames = Object.keys(namedEntitiesRelationJobs);
    if (namedEntitiesRelationJobsNames.length === 0) return DEFAULT_LIST as EntityRelation[];
    return _flatten(
      namedEntitiesRelationJobsNames.map(
        namedEntitiesRelationJobsName =>
          namedEntitiesRelationJobs?.[namedEntitiesRelationJobsName]?.[ANNOTATIONS] || DEFAULT_LIST,
      ),
    ) as EntityRelation[];
  },
);

export const selectJobsTextRelationAnnotationsWithoutHidden = createDeepEqualSelector(
  [
    jobsTextRelationAnnotations,
    (_: State, { hiddenObjectIds }: { hiddenObjectIds: string[] }) => hiddenObjectIds,
  ],
  (textAnnotations, hiddenMids) => {
    return textAnnotations.filter(annotation => {
      return !hiddenMids?.includes(annotation.mid);
    });
  },
);

export const jobsImageRelationAnnotations = createDeepEqualSelector(
  [jobObjectRelationJobs],
  objectsRelationJobs => getImageRelationAnnotations(objectsRelationJobs),
);

const jobsNamedEntitiesRecognitionJobs = createDeepEqualSelector(
  [jobsResponses],
  responses =>
    (responses?.[MachineLearningTask.NAMED_ENTITIES_RECOGNITION] || {
      [ANNOTATIONS]: [],
    }) as NamedEntitiesRecognitionJobs,
);

export const jobsTextAnnotations = createDeepEqualSelector(
  [jobsNamedEntitiesRecognitionJobs],
  namedEntitiesRecognitionJobs => {
    const namedEntitiesRecognitionJobsNames = Object.keys(namedEntitiesRecognitionJobs);
    if (namedEntitiesRecognitionJobsNames.length === 0) return DEFAULT_LIST;
    return namedEntitiesRecognitionJobsNames.flatMap(
      namedEntitiesRecognitionJobsName =>
        namedEntitiesRecognitionJobs[namedEntitiesRecognitionJobsName][ANNOTATIONS] || [],
    );
  },
);

export const jobsTextAnnotationsWithoutHidden = createDeepEqualSelector(
  [
    jobsTextAnnotations,
    (_: State, { hiddenObjectIds }: { hiddenObjectIds: string[] }) => hiddenObjectIds,
  ],
  (textAnnotations, hiddenMids) => {
    return textAnnotations.filter(annotation => !hiddenMids?.includes(_get(annotation, MID)));
  },
);

export const selectJobsTextAnnotationsSentToSplit = createSelector(
  [jobsTextAnnotationsWithoutHidden],
  textAnnotations => (isRichText: boolean) => {
    if (isRichText) return textAnnotations;
    return textAnnotations.map(getBatchedAnnotationFromGlobalAnnotation);
  },
);

export const jobsWithAnnotations = createDeepEqualSelector(
  [
    jobsNamedEntitiesRecognitionJobs,
    jobsNamedEntitiesRelationJobs,
    jobsObjectDetectionsJobs,
    jobObjectRelationJobs,
  ],
  (
    namedEntitiesRecognitionJobs,
    namedEntitiesRelationJobs,
    objectDetectionsJobs,
    objectRelationJobs,
  ) => {
    return {
      ...namedEntitiesRecognitionJobs,
      ...namedEntitiesRelationJobs,
      ...objectDetectionsJobs,
      ...objectRelationJobs,
    };
  },
);

export const jobsClassificationSelectedCategories = createSelector(
  [jobCurrentJobName, projectJobs, jobsResponses],
  (currentJobName, jobs, responses) => {
    const currentJob = _get(jobs, currentJobName);
    const currentMlTask = _get(currentJob, ML_TASK);
    if (currentMlTask !== MachineLearningTask.CLASSIFICATION) return [];
    return _get(responses, [MachineLearningTask.CLASSIFICATION, currentJobName, CATEGORIES], []);
  },
);

export const jobsCurrentJobTools = createSelector(
  [jobCurrentJobName, projectJobs],
  (currentJobName, jobs) => {
    return _get(jobs, `${currentJobName}.tools`, DEFAULT_LIST) as Tool[];
  },
);

export const jobsCurrentMlTask = createSelector(
  [jobCurrentJobName, projectJobs],
  (currentJobName, jobs) => {
    const currentJob = _get(jobs, currentJobName);
    const currentMlTask = _get(currentJob, ML_TASK);
    return currentMlTask;
  },
);

export const jobsSpeechToTextCategories = createSelector([projectJobs], jobs => {
  let categories = {};
  Object.values(jobs).forEach((job: Job) => {
    if (_get(job, ML_TASK) === MachineLearningTask.SPEECH_TO_TEXT)
      categories = { ...categories, ..._get(job, [CONTENT, CATEGORIES], {}) };
  });

  return categories;
});

export const jobsAnnotations = createDeepEqualSelector([jobsWithAnnotations], jobs => {
  if (!jobs) return [];
  return Object.values(jobs)
    .map(job => job?.[ANNOTATIONS] ?? [])
    .flat() as KiliAnnotation[];
});

const getObjectChildrenResponse = (state: State, mid: string) => {
  return mid;
};

export const jobsObjectChildrenResponse = createSelector(
  [getObjectChildrenResponse, jobsResponses],
  (mid, jobs) => jobs?.[mid],
);

const jobsAssetAnnotationsJobs = createDeepEqualSelector(
  [jobsResponses],
  responses => responses?.ASSET_ANNOTATION,
);

export const jobsRotation = createDeepEqualSelector(
  [jobsAssetAnnotationsJobs],
  assetAnnotations => assetAnnotations?.ROTATION_JOB?.rotation ?? 0,
);

export const jobsAnnotationTags = createSelector(
  [projectJobs, jobsSortedAnnotations],
  (jobs, sortedAnnotations) => {
    let annotationTags: Record<string, { index: string; tag: string }> = {};
    const annotationsByJob = _groupBy(sortedAnnotations, annotation => annotation.jobName);
    Object.entries(annotationsByJob).forEach(([jobName, jobAnnotations]) => {
      const annotationByJobByCategory = _groupBy(
        jobAnnotations,
        annotation => annotation.categories?.[0]?.name,
      );
      Object.entries(annotationByJobByCategory).forEach(([categoryCode, categoryAnnotations]) => {
        const objects = getObjects(categoryAnnotations);
        annotationTags = {
          ...annotationTags,
          ...Object.fromEntries(
            objects.map((sortedObject, index) => [
              sortedObject.mid,
              {
                index: index.toString() ?? '',
                tag: jobs?.[jobName]?.content?.categories?.[categoryCode]?.name ?? '',
              },
            ]),
          ),
        };
      });
    });
    return annotationTags;
  },
);

export const jobsAnnotationTagOfMid = createSelector(
  [jobsAnnotationTags, (_: State, mid: string) => mid],
  (annotationTags, mid) =>
    annotationTags?.[mid] ? `${annotationTags[mid].tag} ${annotationTags[mid].index}` : '',
);

export const hasAnnotationsOrRelationsSelected = createSelector(
  [
    jobsObjectDetectionAnnotations,
    jobsImageRelationAnnotations,
    jobsTextAnnotations,
    jobsTextRelationAnnotations,
    (_: State, { selectedObjectIds }: { selectedObjectIds: string[] }) => selectedObjectIds,
  ],
  (imageAnnotations, imageRelations, textAnnotations, textRelations, selectedObjectIds) =>
    !!selectedObjectIds.length &&
    [...imageAnnotations, ...imageRelations, ...textAnnotations, ...textRelations]
      .map(annotation => annotation?.mid)
      .some(mid => selectedObjectIds.includes(mid)),
);

export const getJobsByMlTask = createSelector([projectJobs], jobs => {
  const jobByMlTaskAndToolList: Record<string, Record<string, Job>> = {};
  Object.entries(jobs).forEach(([jobName, job]) => {
    const mlTaskAndToolName = job.tools ? `${job.mlTask}-${job.tools[0]}` : `${job.mlTask}`;
    jobByMlTaskAndToolList[mlTaskAndToolName] = {
      ...jobByMlTaskAndToolList[mlTaskAndToolName],
      [jobName]: job,
    };
  });

  return (mlTask: MachineLearningTask, tool?: Tool) => {
    const mlTaskAndToolName = tool ? `${mlTask}-${tool}` : `${mlTask}`;
    return jobByMlTaskAndToolList[mlTaskAndToolName];
  };
});
