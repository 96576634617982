/**
 * Write here only constants defining z-indexes.
 * No z-indexes must be hardcoded in the styles.
 * Constant name must start with the type of the component (e.g. POPPER, MODAL, etc.).
 * Constant names must end with ZINDEX.
 */

export const NULL_ZINDEX = 0;
export const PDF_OVERLAP_TEXT_HIGHLIGHT_ZINDEX = 1;
export const POPPER_CONTAINER_ZINDEX = 1;
export const POPPER_ISSUE_ZINDEX = 1;
export const POPPER_ORGANIZATION_ZINDEX = 1;
export const MARKER_TAG_TRANSCRIPTION_ZINDEX = 1;
export const PDF_SELECTED_TEXT_HIGHLIGHT_ZINDEX = 2;
export const TOPBAR_POPPER_PROFILE_ZINDEX = 2;
export const POPPER_IMAGE_STEP_ZINDEX = 1;
export const TOPBAR_BASE_CONTAINER_ZINDEX = 2;
export const POPPER_ANNOTATION_COUNTER_ZINDEX = 2;
export const MODAL_ADD_USER_ZINDEX = 2;
export const PDF_SELECTED_OVERLAP_TEXT_HIGHLIGHT_ZINDEX = 3;
export const SELECT_PAGE_LEVEL_ZINDEX = 3;
export const POPPER_PDF_PAGINATION_ZINDEX = 3;
export const POPPER_ANNOTATION_WIZARD_PDF_ZINDEX = 3;
export const TOPBAR_OVERLOAD_ZINDEX = 10;
export const SELECT_BODY_ZINDEX = 100;
export const POPPER_INTEGRATION_ZINDEX = 300;
export const POPPER_SLIDER_ZINDEX = 350;
export const MODAL_SIMILAR_OBJECT_ZINDEX = 400;
export const MODAL_CHANGE_CLASS_ZINDEX = 400;
export const TIMELINE_POPUP_ZINDEX = 400;
export const TAG_ZINDEX_OFFSET = 500;
export const THUMBNAIL_OVERLAY_ZINDEX = 500;
export const POPPER_PROJECT_QUEUE_ZINDEX = 501;
export const POPPER_DATE_PICKET_ZINDEX = 600;
export const MARKER_ISSUE_ZINDEX = 763;
export const LOADER_BACKGROUND_ZINDEX = 998;
export const LOADER_ZINDEX = 999;
export const OVERLAY_EDIT_ANNOTATION_ZINDEX = 1000;
export const POPUP_PROPAGATION_INPUT_ZINDEX = 1000;
export const MENU_MORE_DATA_CONNECTION_ZINDEX = 1000;
export const DIALOG_VERSION_CHANGE_ZINDEX = 1000;
export const POPPER_ANNOTATION_WIZARD_TEXT_ZINDEX = 1000;
export const TOPBAR_POPPER_ZINDEX = 1001;
export const TOOLBAR_HELP_POPUP_ZINDEX = 1200;
export const TOPBAR_LEFT_ZINDEX = 1200;
export const POPPER_NOTIFICATION_ZINDEX = 1200;
export const POPPER_TOOLBAR_LABELING_ZINDEX = 1201;
export const POPPER_ANNOTATION_WIZARD_CHANGE_CLASS_ZINDEX = 1301;
export const MENU_METADATA_ZINDEX = 9997;
export const POPPER_BATCH_ZINDEX = 10_000;
export const POPPER_SHORTCUT_BUTTON_ZINDEX = 10_000;
export const POPPER_FILTER_ZINDEX = 10_000;
export const POPPER_CONFIRMATION_ZINDEX = 10_001;
export const TOOLTIP_POPPER_ZINDEX = 10_001;
export const METADATA_POPUP_ZINDEX = 10_001;

// Timeline range are used only for video annotations
export const TIMELINE_READ_HEAD_ZINDEX = 1;
export const TIMELINE_EMPTY_CONTAINER_ZINDEX = 3;
export const TIMELINE_RANGE_COMPONENT_BASE_ZINDEX = 1000;
export const TIMELINE_RANGE_NAME_ZINDEX = 1001;
export const TIMELINE_RANGE_BORDER_RESIZER_ZINDEX = 1002;
export const TIMELINE_RANGE_RESIZER_ZINDEX = 10_000;

// Leaflet CSS z-indexes values
// .leaflet-draw-tooltip z-index: 6
// .leaflet-map-pane canvas z-index: 100
// .leaflet-tile-pane z-index: 200
// .leaflet-map-pane svg z-index: 200
// .leaflet-overlay-pane z-index: 400
// .leaflet-pane z-index: 400
// .leaflet-shadow-pane z-index: 500;
// .leaflet-marker-pane z-index: 600
// .leaflet-tooltip-pane z-index: 650
// .leaflet-popup-pane z-index: 700
export const LEAFLET_GPS_COPY_ZINDEX = 800;
// .leaflet-control z-index: 800
// .leaflet-zoom-box z-index: 800
export const LEAFLET_DRAW_SEMANTIC_TRACER_ZINDEX = 999;
export const LEAFLET_CURSOR_EDIT_ZINDEX = 1000;
// .leaflet-bottom z-index: 1000
// .leaflet-top z-index: 1000
export const LEAFLET_OVERLAY_CONTEXTUAL_ZINDEX = 1199;
export const LEAFLET_CROSSHAIR_ZINDEX = 10_000;
export const LEAFLET_CURSOR_EDIT_ROTATION_ZINDEX = 10_000;

// PDF CSS z-indexes values
// .PdfHighlighter__highlight-layer z-index: 2
export const RELATION_CONTAINER_ZINDEX = 2;
// .annotationLayer z-index: 3
// .PdfHighlighter__tip-container z-index: 6
export const RELATION_LABEL_ZINDEX = 10;

// Text specific z-indexes
// RELATION_CONTAINER_ZINDEX: 2
// RELATION_LABEL_ZINDEX: 10
