import {
  type GrantedTools,
  type LabelInterfaceInitializePayload,
  type LabelInterfaceSettingsKeyParams,
  type PoseEstimationSettings,
  type PropagationSettings,
  TagMode,
  type TagSettings,
  type RelationSettings,
  type ToolThenClassSettings,
} from './types';

import {
  decodeToolAsToolName,
  INITIAL_TOOLS,
  isToolGranted,
} from '../../components/asset-ui/Image/LeafletMapv2/constant';
import {
  type AccordionTree,
  type ObjectAccordionTree,
  type CategoryAccordionTree,
} from '../../services/jsonInterface/accordion';

export const defaultTagSettings: TagSettings = {
  displayMode: TagMode.ON_HOVER,
  prevDisplayMode: TagMode.ON_HOVER,
  showNestedClassification: true,
  showNestedTranscription: true,
};

export const defaultRelationSettings: RelationSettings = {
  showRelation: true,
};

export const defaultToolThenClassSettings: ToolThenClassSettings = {
  showToolThenClass: false,
};

export const defaultPoseEstimationSettings: PoseEstimationSettings = {
  showLines: true,
};

export const defaultPropagationSettings: PropagationSettings = {
  isEnabled: true,
  isFullVideo: false,
  value: 100,
};

export const getLabelInterfaceSettingsKey = ({
  projectId,
  userId,
}: LabelInterfaceSettingsKeyParams) => `${userId}_${projectId}`;

export const getInitialGrantedTools = ({
  inputType,
  projectTools,
}: LabelInterfaceInitializePayload): GrantedTools => {
  const isGranted = isToolGranted(inputType);
  const grantedToolOptions = INITIAL_TOOLS.filter(([name]) => isGranted(name));

  return grantedToolOptions.reduce<GrantedTools>((acc, [name, options]) => {
    const isVisible =
      options.isVisible || !!projectTools.find(v => decodeToolAsToolName(v) === name);
    acc[name] = { ...options, isVisible };
    return acc;
  }, {});
};

const findElementInSubChildren = (
  objectAccordion: ObjectAccordionTree | CategoryAccordionTree,
  categoryCode: string,
  jobCode: string,
) => {
  return Object.entries(objectAccordion?.children ?? {})
    .map(
      ([childrenJobCode, childrenJob]): (Record<string, CategoryAccordionTree> | undefined)[] => {
        if (childrenJobCode === jobCode) {
          // eslint-disable-next-line no-param-reassign
          if (!childrenJob.categories) childrenJob.categories = {};
          return [childrenJob.categories];
        }
        // may be deeper
        return Object.entries(childrenJob.categories ?? {})
          .map(([_childrenCategoryCode, childrenCategory]) => {
            return findElementInSubChildren(childrenCategory, categoryCode, jobCode)?.filter(
              d => !!d,
            );
          })
          .filter(d => !!d)
          .flat();
      },
    )
    .flat();
};

/**
 * Go through all Accordion tree, to find the element that match category
 * @param mid
 * @param parent
 * @param accordionTree
 */
export const findElementInAccordion = (
  mid: string,
  categoryCode: string,
  jobCode: string,
  accordionTree: AccordionTree,
) => {
  const res = Object.entries(accordionTree)
    .map(([_mainJobCode, mainJob]) => {
      return Object.entries(mainJob.categories ?? {}).map(([_mainCategoryCode, categoryTree]) => {
        if (!categoryTree?.objects?.[mid]) return null;
        return findElementInSubChildren(categoryTree.objects[mid], categoryCode, jobCode);
      });
    })
    .flat(2)
    .filter(d => !!d)[0];
  return res;
};
