import { type WithStyles } from '@material-ui/core';
import React from 'react';

import { type styles } from './BaseTopBar.style';

type PropsType = {
  children?: React.ReactNode;
  className?: string;
  leftSideComponent: React.ReactNode;
  rightSideComponent: React.ReactNode;
};

const BaseTopBar: React.FC<WithStyles<typeof styles> & PropsType> = props => {
  const { className = '', classes, rightSideComponent, leftSideComponent, children } = props;

  return (
    <div className={`${classes.container} ${className}`} data-cy="base-top-bar">
      <div className={classes.subContainer}>{leftSideComponent}</div>
      {children && <div className={classes.subContainer}>{children}</div>}
      <div className={classes.subContainer}>{rightSideComponent}</div>
    </div>
  );
};

export default BaseTopBar;
