import { gql } from '@apollo/client';

import { LOCK_FRAGMENTS } from './fragments';

export const GQL_APPEND_TO_LOCKS = gql`
  mutation RenewLock($data: RenewLockData!, $where: AssetWhere!) {
    renewLock(data: $data, where: $where) {
      ...LockInfos
    }
  }
  ${LOCK_FRAGMENTS}
`;

export const GQL_DELETE_LOCKS = gql`
  mutation DeleteLocks($input: DeleteLocksInput!) {
    deleteLocks(input: $input)
  }
`;
