import { type ImageVertices } from '@kili-technology/types';
import L, { Polyline } from 'leaflet';

import type KiliFeatureGroup from './KiliFeatureGroup';
import { KiliWrapper } from './KiliWrapper';
import { getLatLngsFromLayer, getLayerOptionsFromAnnotation, layerWithHandlers } from './helpers';
import { areObjectsLocked } from './helpers/areObjectsLocked';
import { type PolylineAnnotation, type KiliOptions, type VectorAnnotation } from './types';

import { GeometryTools } from '../../../../../constants/tools';
import { getAllowedAnnotationPointsWithinImage } from '../helpers/geometry';

const ARROWHEADS_OPTIONS = {
  fill: true,
  isArrowHead: true,
  size: '15px',
};

class KiliPolyline extends KiliWrapper(Polyline) {
  constructor(latLngBounds: L.LatLng[], options: KiliOptions, isVector?: boolean) {
    super(latLngBounds, options);
    super.initializeKili(options);
    this.updateEditMarkerIcon();

    if (areObjectsLocked()) {
      this.editing = undefined;
      return;
    }

    // @ts-expect-error KiliPolyline not assignable to Poly
    this.editing = isVector ? new L.Edit.Vector(this) : new L.Edit.Poly(this);
  }

  fitToBounds() {
    const latlngs = this.getLatLngs() as L.LatLng[];
    const latlngsWithinImage = getAllowedAnnotationPointsWithinImage({
      latlngs,
      map: this.map,
      type: this.kiliOptions?.type,
    });
    const noChangeNecessary = latlngs
      .map((latlng, index) => latlng.equals(latlngsWithinImage[index], 1e-3))
      .reduce((prev, curr) => prev && curr, true);
    if (!noChangeNecessary) {
      this.setLatLngs(latlngsWithinImage);
    }
    return noChangeNecessary;
  }
}

export const getKiliPolyline = (
  latLngBounds: L.LatLng[],
  options: KiliOptions,
  isVector?: boolean,
): KiliPolyline => {
  const polyline = new KiliPolyline(latLngBounds, options, isVector);
  return layerWithHandlers(polyline);
};

export const annotationToKiliPolyline = (
  annotation: PolylineAnnotation | VectorAnnotation,
  leafletOptions: { featureGroup?: KiliFeatureGroup; map: L.Map },
  isVector?: boolean,
): KiliPolyline => {
  const { map, featureGroup } = leafletOptions;
  const layerOptions = getLayerOptionsFromAnnotation(annotation);
  const bounds = getLatLngsFromLayer(annotation, map) as L.LatLng[];
  const kiliLayer = getKiliPolyline(
    bounds,
    {
      ...layerOptions,
      group: featureGroup,
      map,
    },
    isVector,
  );
  return kiliLayer;
};

export const annotationToKiliVector = (
  annotation: VectorAnnotation,
  leafletOptions: { featureGroup?: KiliFeatureGroup; map: L.Map },
): KiliPolyline => {
  // @ts-expect-error leaflet doesn't know arrowheads method
  return annotationToKiliPolyline(annotation, leafletOptions, true).arrowheads(ARROWHEADS_OPTIONS);
};

export const getKiliVector = (
  latLngBounds: L.LatLng[] | L.LatLng[][],
  options: KiliOptions,
): KiliPolyline => {
  // @ts-expect-error leaflet doesn't know arrowheads method
  return getKiliPolyline(latLngBounds, options).arrowheads(ARROWHEADS_OPTIONS);
};

export const getLayerFromPolyline = (
  polyline: ImageVertices[] | undefined,
  map: L.Map,
): L.Polyline => {
  const coordinates = (polyline || []).map(point => map.projectPoint(point) || [0, 0]);
  return L.GeoJSON.geometryToLayer({
    geometry: {
      coordinates,
      type: GeometryTools.GEO_JSON_POLYLINE,
    },
    properties: {},
    type: 'Feature',
  }) as L.Polyline;
};

export default KiliPolyline;
