import {
  type ImageBoundingPoly,
  type ImageVertices,
  type ObjectAnnotation,
  type ObjectAnnotationMarker,
  MachineLearningTask,
} from '@kili-technology/types';
import L from 'leaflet';
import _get from 'lodash/get';

import {
  type SetObjectDetectionResponse,
  type SetPoseEstimationResponse,
} from '../../redux/jobs/types';
import { type KiliAnnotation } from '../jobs/setResponse';

export const rotateVertex = (point: ImageVertices): ImageVertices => ({
  x: 1 - point.y,
  y: point.x,
});

const inverseRotateVertex = (point: ImageVertices): ImageVertices => ({
  x: point.y,
  y: 1 - point.x,
});

type Direction = 'clockwise' | 'anticlockwise';

const rotatePoint = <T extends ObjectAnnotationMarker>(
  rotateFunction: (point: ImageVertices) => ImageVertices,
  pointAnnotation: T,
): T => {
  const newPoint = rotateFunction(_get(pointAnnotation, 'point', { x: 0, y: 0 }));
  return {
    ...pointAnnotation,
    point: newPoint,
  };
};

export const rotateAnnotation = (
  annotation: ObjectAnnotation,
  direction: Direction = 'clockwise',
): ObjectAnnotation => {
  const rotateFunction = direction === 'clockwise' ? rotateVertex : inverseRotateVertex;
  if ('boundingPoly' in annotation && !!annotation?.boundingPoly?.length) {
    const newBoundingPoly = _get(annotation, 'boundingPoly', []).map(
      (polygon: ImageBoundingPoly) => {
        const newVertices = _get(polygon, 'normalizedVertices').map(rotateFunction);
        return { normalizedVertices: newVertices };
      },
    );
    return {
      ...annotation,
      boundingPoly: newBoundingPoly,
    };
  }
  if ('polyline' in annotation && !!annotation?.polyline?.length) {
    const newPolyline = _get(annotation, 'polyline', []).map(rotateFunction);
    return {
      ...annotation,
      polyline: newPolyline,
    };
  }
  if ('points' in annotation) {
    const points = annotation.points || [];
    const newPoints = points.map(point => rotatePoint(rotateFunction, point));
    return {
      ...annotation,
      points: newPoints,
    };
  }
  return rotatePoint(rotateFunction, annotation as ObjectAnnotationMarker);
};

export const rotateAnnotationByAngle = (
  angle: number,
  annotation: ObjectAnnotation,
  direction: Direction = 'anticlockwise',
): ObjectAnnotation => {
  const rotationTimes = Math.floor(angle / 90) % 4;
  if (rotationTimes === 0) {
    return annotation;
  }
  return [...Array(rotationTimes)].reduce(
    (rotatedAnnotation, _) => rotateAnnotation(rotatedAnnotation, direction),
    annotation,
  );
};

export const rotateAnnotations = (
  annotationsToRotate: KiliAnnotation[],
  setObjectDetectionResponse: SetObjectDetectionResponse,
  setPoseEstimationResponse: SetPoseEstimationResponse,
): void => {
  const rotatedJobs: Record<string, ObjectAnnotation[]> = {};
  const jobNameToMLTask: Record<string, string> = {};
  annotationsToRotate.forEach(annotation => {
    const rotatedAnnotation = rotateAnnotation(annotation);

    const jobName = annotation?.jobName || '';
    const mlTask = annotation?.mlTask || '';
    jobNameToMLTask[jobName] = mlTask;
    if (jobName in rotatedJobs) {
      rotatedJobs[jobName].push(rotatedAnnotation);
    } else {
      rotatedJobs[jobName] = [rotatedAnnotation];
    }
  });
  Object.entries(rotatedJobs).forEach(([jobName, annotations]) => {
    const mlTaskOfJobName = jobNameToMLTask[jobName];
    if (mlTaskOfJobName === MachineLearningTask.POSE_ESTIMATION) {
      setPoseEstimationResponse({ jobName, jobResponse: { annotations } });
    } else {
      setObjectDetectionResponse({ jobName, jobResponse: { annotations } });
    }
  });
};

const getPointsOfRotation = (effectiveBounds: number[][], rotationType: number) => {
  const topLeft = [effectiveBounds[1][0], effectiveBounds[0][1]];
  const topRight = [effectiveBounds[1][0], effectiveBounds[1][1]];
  const bottomLeft = [effectiveBounds[0][0], effectiveBounds[0][1]];
  const bottomRight = [effectiveBounds[0][0], effectiveBounds[1][1]];

  const point0 = [topLeft, topRight, bottomLeft];
  const point90 = [topRight, bottomRight, topLeft];
  const point180 = [bottomRight, bottomLeft, topRight];
  const point270 = [bottomLeft, topLeft, bottomRight];
  if (rotationType === 0) {
    return point0;
  }
  if (rotationType === 1) {
    return point90;
  }
  if (rotationType === 2) {
    return point180;
  }
  return point270;
};

export const getBoundEdgesFromRotation = (
  effectiveBounds: number[][],
  rotationType: number,
): [L.LatLng, L.LatLng, L.LatLng] => {
  const pointsOfRotation = getPointsOfRotation(effectiveBounds, rotationType);

  const topLeftBound = new L.LatLng(pointsOfRotation[0][0], pointsOfRotation[0][1]);
  const topRightBound = new L.LatLng(pointsOfRotation[1][0], pointsOfRotation[1][1]);
  const bottomLeftBound = new L.LatLng(pointsOfRotation[2][0], pointsOfRotation[2][1]);
  return [topLeftBound, topRightBound, bottomLeftBound];
};
