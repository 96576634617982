const INITIAL_MARKER_SIZE = 20;

const MarkerCircle = (): JSX.Element => {
  return (
    <div
      id="pose-marker"
      style={{
        border: '1px solid black',
        borderRadius: '50%',
        boxShadow: 'white 0px 0px 0px 1px, white 0px 0px 0px 1px inset',
        cursor: 'crosshair',
        height: INITIAL_MARKER_SIZE,
        left: 'calc(50% - 0.5px)',
        opacity: 1,
        position: 'absolute',
        top: 'calc(50% + 0.5px)',
        transform: 'translate(-50%, -50%)',
        width: INITIAL_MARKER_SIZE,
      }}
    />
  );
};

export default MarkerCircle;
