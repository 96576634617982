import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Right } from '../../../__generated__/globalTypes';
import { addNotification } from '../../../redux/application/actions';
import { type AddNotificationPayload } from '../../../redux/application/types';
import { organizationId } from '../../../redux/organization/selectors';
import { userRights } from '../../../redux/user/selectors';

export const LICENSE_LIMIT_NOTIFICATION: AddNotificationPayload = {
  message: 'You reached the license limit. Please contact your administrator.',
  persist: true,
  variant: 'info',
};

export function useSendLicenseLimitNotification() {
  const userRightsValue = useSelector(userRights);
  const organizationIdValue = useSelector(organizationId);
  const dispatch = useDispatch();

  useEffect(() => {
    const shouldSeeLicenseValue = userRightsValue.includes(Right.SEE_LICENSE_BANNER);
    if (!organizationIdValue) return;
    if (shouldSeeLicenseValue) {
      dispatch(addNotification(LICENSE_LIMIT_NOTIFICATION));
    }
  }, [dispatch, organizationIdValue, userRightsValue]);
}
