import {
  type BaseObjectAnnotation,
  type AnnotationCategory as Category,
  type ImageBoundingPoly,
  type ImageVertices,
  type LabelVersion,
  type MachineLearningTask,
  type ObjectAnnotation,
  type ObjectAnnotation1D,
  type ObjectAnnotation2D,
  type ObjectAnnotationMarker,
  type OcrMetadata,
  type PoseEstimationAnnotation,
  type PoseEstimationPoint,
  Tool,
} from '@kili-technology/types';
import {
  type Control,
  type LatLngExpression,
  type PathOptions,
  type PolylineOptions,
} from 'leaflet';
import type L from 'leaflet';

import type KiliFeatureGroup from './KiliFeatureGroup';
import type KiliMarker from './KiliMarkerLayer/KiliMarkerLayer';
import type KiliPolygon from './KiliPolygonLayer';
import type KiliPolyline from './KiliPolylineLayer';
import { type PoseEstimation as KiliPoseEstimation } from './KiliPoseEstimation/KiliPoseEstimation';
import type KiliRectangle from './KiliRectangleLayer';
import type KiliRelationArrowLayer from './KiliRelationArrowLayer';

import { type InputType } from '../../../../../__generated__/globalTypes';
import {
  type KiliAnnotation,
  type KiliAnnotationProvider,
} from '../../../../../services/jobs/setResponse';

export type Geometry = {
  boundingPoly?: ImageBoundingPoly[];
  point?: ImageVertices;
  polyline?: ImageVertices[];
};

type PoseAnnotationHelpers = {
  allPoints?: PoseEstimationPoint[];
  points?: (Partial<PoseEstimationPoint> & { point?: ImageVertices })[];
};

export type KiliAnnotationWithGeometry = KiliAnnotation & Geometry & PoseAnnotationHelpers;

type DirectOpacityLayer = {
  setOpacity(opacity: number): void;
};

type StyleLayer = {
  setStyle(param: { opacity: number }): void;
};

export type HiddableLayer = L.Layer & {
  editing?: {
    disable(): boolean;
    enable(): boolean;
    enabled(): boolean;
  };
  kiliOptions?: {
    categories?: Category[];
    isSelected?: boolean;
    jobName?: string;
    map: L.Map;
    mid: string;
    type?: Tool;
  };
  resetEdit?(): void;
} & (DirectOpacityLayer | StyleLayer);

export type KiliLayer =
  | KiliRectangle
  | KiliPolygon
  | KiliPolyline
  | KiliMarker
  | KiliPoseEstimation;

export type KiliRelationLayer = KiliRelationArrowLayer;

export type BadgeLayer = L.Marker & {
  kiliOptions?: {
    isBadgeOnPoint?: boolean;
    isSelected?: boolean;
    mid: string;
  };
  relationOptions?: {
    objectMid: string;
    objectUpperRightCorner: LatLngExpression;
    relationMid: string;
  };
};

export type LayerEditable = L.Layer & {
  editing: L.Edit.CircleMarker | L.Edit.Rectangle | L.Edit.Poly | L.Edit.PoseEstimation;
};

export const isLayerEditable = (layer: L.Layer): layer is LayerEditable => 'editing' in layer;

export const isGroupLayer = (layer: L.Layer): layer is L.LayerGroup => {
  return !!(layer as L.LayerGroup).eachLayer;
};

export const isPoseEstimation = (layer: KiliLayer): layer is KiliPoseEstimation => {
  return !!(layer as KiliPoseEstimation).markerGroup;
};

export const isKiliLayer = (layer: L.Layer): layer is KiliLayer => 'kiliOptions' in layer;

export const isBadgeLayer = (layer: L.Layer): layer is BadgeLayer => 'relationOptions' in layer;

export const isHiddableLayer = (layer: L.Layer): layer is HiddableLayer =>
  'kiliOptions' in layer && ('setOpacity' in layer || 'setStyle' in layer);

export const isObjectAnnotationRectangle = (
  annotation: KiliAnnotationProvider<ObjectAnnotation>,
): annotation is KiliAnnotationProvider<ObjectAnnotation2D> => annotation?.type === Tool.RECTANGLE;

export const isObjectAnnotationPolygon = (
  annotation: ObjectAnnotation,
): annotation is ObjectAnnotation2D => annotation?.type === Tool.POLYGON;

export const isObjectAnnotationPolyline = (
  annotation: ObjectAnnotation,
): annotation is ObjectAnnotation1D => annotation?.type === Tool.POLYLINE;

export const isObjectAnnotationSemantic = (
  annotation: ObjectAnnotation,
): annotation is ObjectAnnotation2D => annotation?.type === Tool.SEMANTIC;

export const isObjectAnnotationVector = (
  annotation: ObjectAnnotation,
): annotation is ObjectAnnotation1D => annotation?.type === Tool.VECTOR;

export const isObjectAnnotationMarker = (
  annotation: ObjectAnnotation,
): annotation is ObjectAnnotation2D => annotation?.type === Tool.MARKER;

export const isObjectRelation = (relation: ArrowRelation): relation is ArrowRelation =>
  relation?.type === 'arrow';

export const isObjectAnnotationPose = (
  annotation: ObjectAnnotation,
): annotation is PoseEstimationAnnotation => annotation?.type === Tool.POSE;

export interface KiliOptions extends PolylineOptions {
  allPoints?: PoseEstimationPoint[];
  categories: Category[];
  color?: string;
  dashArrayProperty?: string;
  dataCy?: string;
  endPoint?: L.LatLng;
  group?: KiliFeatureGroup;
  inputType: InputType;
  isEditing?: boolean;
  isInteractiveSegmentationMarker?: boolean;
  isNegativeInteractiveSegmentationMarker?: boolean;
  isPoseEstimationMarker?: boolean;
  isSelected?: boolean;
  jobName: string;
  labelVersion?: LabelVersion;
  map: L.Map;
  mid: string;
  mlTask: MachineLearningTask;
  pointIndicesDrawn?: number[];
  shapeId?: string;
  startPoint?: L.LatLng;
  transcription?: unknown;
  type: Tool;
}

export type LeafletAnnotation<T extends BaseObjectAnnotation> = T & {
  isInteractiveSegmentationMarker?: boolean;
  isNegativeInteractiveSegmentationMarker?: boolean;
  jobName: string;
  labelVersion?: LabelVersion;
  type: Tool;
};

type LeafletAnnotation2D = LeafletAnnotation<ObjectAnnotation2D> & {
  inputType: InputType;
  type: Tool;
};
type LeafletPoseAnnotation = LeafletAnnotation<PoseEstimationAnnotation> & {
  inputType: InputType;
  polyline?: ImageVertices[];
  type: Tool;
};
export type RectangleAnnotation = LeafletAnnotation2D & { type: Tool.RECTANGLE };
export type PolygonAnnotation = LeafletAnnotation2D & { type: Tool.POLYGON };
export type PolylineAnnotation = LeafletAnnotation<ObjectAnnotation1D> & {
  inputType: InputType;
  type: Tool.POLYLINE;
};
export type PoseAnnotation = LeafletPoseAnnotation & { type: Tool.POSE } & PoseAnnotationHelpers;
export type SemanticAnnotation = LeafletAnnotation2D & {
  inputType: InputType;
  type: Tool;
};
export type VectorAnnotation = LeafletAnnotation<ObjectAnnotation1D> & {
  inputType: InputType;
  type: Tool.VECTOR;
};
export type MarkerAnnotation = LeafletAnnotation<ObjectAnnotationMarker> & {
  inputType: InputType;
  type: Tool;
};
export type ArrowRelation = {
  // A 1 -> N relation will be represented by N arrows with different arrowId but same mid
  arrowId: string;
  relation: {
    color: string;
    end: { mid: string; position: L.LatLng };
    mid: string;
    start: { mid: string; position: L.LatLng };
  };
  type: string;
};

export type LayerAnnotation2D = PolygonAnnotation | RectangleAnnotation | SemanticAnnotation;
export type LayerAnnotation1D = PolylineAnnotation | VectorAnnotation | PoseAnnotation;

export type LayerAnnotation0D = MarkerAnnotation;
export type LayerAnnotation = LayerAnnotation2D | LayerAnnotation1D | LayerAnnotation0D;

export const layerIsRectangle = (
  annotation: LayerAnnotation,
): annotation is RectangleAnnotation => {
  return annotation.type === Tool.RECTANGLE;
};

export const layerIsPolygon = (annotation: LayerAnnotation): annotation is PolygonAnnotation => {
  return annotation.type === Tool.POLYGON;
};

export const layerIsPolyline = (annotation: LayerAnnotation): annotation is PolylineAnnotation => {
  return annotation.type === Tool.POLYLINE;
};

export type LayerOptions = {
  boundingPoly?: ImageBoundingPoly[];
  categories: Category[];
  dashArrayProperty: string;
  inputType: InputType;
  isInteractiveSegmentationMarker?: boolean;
  isNegativeInteractiveSegmentationMarker?: boolean;
  jobName: string;
  mid: string;
  mlTask: MachineLearningTask;
  polyline?: ImageVertices[];
  transcription?: unknown;
  type: Tool;
};

export interface KiliDrawOptions extends Control.DrawOptions {
  pose?: unknown;
  selectedPathOptions?: PathOptions;
  semantic?: unknown;
}

export interface KiliMap extends L.Map {
  ocrMetadata?: OcrMetadata;
}
