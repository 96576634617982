const MAX_FRAMES_PLAYED_PER_SECOND = 60;
export const DEFAULT_FRAMES_PLAYED_PER_SECOND = 24;
const MIN_FRAMES_PLAYED_PER_SECOND = 0.1;
const STEPS_005 = 18;
const STEPS_05 = 8;
export const STEPS_TOTAL = STEPS_005 + STEPS_05 + MAX_FRAMES_PLAYED_PER_SECOND - 5;
export const DEFAULT_SLIDER_VALUE =
  STEPS_TOTAL - (MAX_FRAMES_PLAYED_PER_SECOND - DEFAULT_FRAMES_PLAYED_PER_SECOND);
export const MARKS = [
  {
    label: `${MIN_FRAMES_PLAYED_PER_SECOND}`,
    value: 0,
  },
  {
    label: `${1}`,
    value: STEPS_005,
  },
  {
    label: `${MAX_FRAMES_PLAYED_PER_SECOND}`,
    value: STEPS_TOTAL,
  },
];

export const calculateFramesPlayedPerSecondDisplayed = (value: number): number => {
  if (value < STEPS_005) {
    return (
      MIN_FRAMES_PLAYED_PER_SECOND +
      value / (STEPS_005 + Math.floor(MIN_FRAMES_PLAYED_PER_SECOND / 0.05))
    );
  }
  if (value >= STEPS_005 && value <= STEPS_005 + STEPS_05) {
    return 1 + (value - STEPS_005) / 2;
  }
  return 5 + (value - STEPS_005 - STEPS_05);
};
