import { gql } from '@apollo/client';

export const GQL_PROJECT_USERS_MEMBERS = gql`
  query ProjectUsersMembers($where: ProjectUserWhere!, $skip: Int!, $first: PageSize!) {
    data: projectUsers(where: $where, skip: $skip, first: $first) {
      activated
      id
      isAuthor
      user {
        activated
        id
        firstname
        lastname
        email
        organizationId
        organizationRole
      }
      role
      status
      deletedAt
    }
    count: countProjectUsers(where: $where)
  }
`;

export const GQL_PROJECT_USERS_ROLES = gql`
  query ProjectUsersRoles($where: ProjectUserWhere!, $skip: Int!, $first: PageSize!) {
    data: projectUsers(where: $where, skip: $skip, first: $first) {
      activated
      id
      role
      user {
        id
        firstname
        lastname
        email
        organizationRole
      }
      status
      deletedAt
    }
    count: countProjectUsers(where: $where)
  }
`;

export const GQL_PROJECT_USER_ROLES_SIMPLIFIED = gql`
  query ProjectUserRolesSimplified($where: ProjectUserWhere!, $skip: Int!, $first: PageSize!) {
    data: projectUsers(where: $where, skip: $skip, first: $first) {
      activated
      id
      user {
        id
        firstname
        lastname
        email
        organizationRole
      }
      role
      status
    }
    count: countProjectUsers(where: $where)
  }
`;
