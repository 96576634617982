import { type ObjectAnnotation, type ObjectAnnotation2D, Tool } from '@kili-technology/types';
import * as d3 from 'd3';
import { largestRect } from 'd3plus-shape';
import { dequal } from 'dequal';
import L from 'leaflet';

import { MAX_MARGIN } from '../ImageAnnotations/constants';

export type LatLngGeometry = L.LatLng | L.LatLng[] | L.LatLng[][] | L.LatLng[][][];

const arePointsEqual = (latlngA: L.LatLng, latlngB: L.LatLng) =>
  latlngA.equals(latlngB, MAX_MARGIN);

const isGeometryPoint = (geometry: LatLngGeometry): geometry is L.LatLng =>
  !Array.isArray(geometry);

const isGeometryPointArr = (geometry: LatLngGeometry): geometry is L.LatLng[] =>
  Array.isArray(geometry) && !!geometry.length && !Array.isArray(geometry[0]);

const isGeometryPointArrArr = (geometry: LatLngGeometry): geometry is L.LatLng[][] =>
  Array.isArray(geometry) &&
  !!geometry.length &&
  Array.isArray(geometry[0]) &&
  !!geometry[0].length &&
  !Array.isArray(geometry[0][0]);

const isGeometryPointArrArrArr = (geometry: LatLngGeometry): geometry is L.LatLng[][][] =>
  Array.isArray(geometry) &&
  !!geometry.length &&
  Array.isArray(geometry[0]) &&
  !!geometry[0].length &&
  Array.isArray(geometry[0][0]) &&
  !!geometry[0][0].length &&
  !Array.isArray(geometry[0][0][0]);

export const areGeometriesEqual = (latlngsA: LatLngGeometry, latlngsB: LatLngGeometry): boolean => {
  if (isGeometryPoint(latlngsA) && isGeometryPoint(latlngsB)) {
    return arePointsEqual(latlngsA, latlngsB);
  }
  if (
    (isGeometryPointArr(latlngsA) && isGeometryPointArr(latlngsB)) ||
    (isGeometryPointArrArr(latlngsA) && isGeometryPointArrArr(latlngsB)) ||
    (isGeometryPointArrArrArr(latlngsA) && isGeometryPointArrArrArr(latlngsB))
  ) {
    if (latlngsA.length !== latlngsB.length) {
      return false;
    }
    return latlngsA
      .map((latlng, index) => areGeometriesEqual(latlng, latlngsB[index]))
      .reduce((prev, curr) => prev && curr, true);
  }
  return true;
};

export const getAreaFromAnnotation = (annotation: ObjectAnnotation) => {
  switch (annotation.type) {
    case Tool.POLYGON:
    case Tool.RECTANGLE:
    case Tool.SEMANTIC: {
      const positiveLatLng = (
        annotation as ObjectAnnotation2D
      )?.boundingPoly?.[0]?.normalizedVertices?.map(c => L.latLng(c.y, c.x));
      if (!positiveLatLng || !Array.isArray(positiveLatLng)) return 0;

      const positiveArea = L.GeometryUtil.geodesicArea(positiveLatLng);
      return positiveArea;
    }
    case Tool.POLYLINE:
    case Tool.VECTOR:
    case Tool.MARKER:
    case Tool.POSE:
    default:
      return 0;
  }
};

export const getAllowedAnnotationPointsWithinImage = ({
  map,
  latlngs,
  type,
}: {
  latlngs: L.LatLng[];
  map: L.Map;
  type?: Tool;
}): L.LatLng[] => {
  if (!map || !map.projectPointOnOverlay) return latlngs;
  const isRectangle = type === Tool.RECTANGLE;
  const latLngsWithinImage = latlngs.map(point => map.projectPointOnOverlay(point));
  if (isRectangle) {
    const shapeChanged = !dequal(latLngsWithinImage, latlngs);
    const latDifference = Math.abs(latlngs[0].lat - latlngs[1].lat);
    const lngDifference = Math.abs(latlngs[0].lng - latlngs[1].lng);
    const notRotated = latDifference < 1e-9 || lngDifference < 1e-9;
    if (!shapeChanged || notRotated) {
      return latLngsWithinImage;
    }
    const polygon = latLngsWithinImage.map(point => [point.lat, point.lng]);
    const rect = largestRect(polygon, {
      angle: d3.range(-90, 91, 1),
      maxAspectRatio: 1000,
      nTries: 500,
      tolerance: 0,
    });
    const { points: rectPoints }: { points: number[][] } = rect;
    return rectPoints.slice(0, 4).map(point => L.latLng(point[0], point[1]));
  }
  return latLngsWithinImage;
};

export const getProjection = (
  line: {
    a: number;
    aPoint: { x: number; y: number };
    b: number;
    bPoint: { x: number; y: number };
    c: number;
    diviseur: number;
    equation: string;
  },
  ptH: L.Point,
) => {
  if (line.b === 0) {
    // x does not change
    return new L.Point(line.aPoint.x, ptH.y);
  }
  if (line.diviseur === 0) {
    // y does not change
    return new L.Point(ptH.x, line.bPoint.y);
  }
  const E = -1 / line.b;
  const alpha = E + 1 / E;
  const newYH = (ptH.x + E * ptH.y + line.c) / alpha;
  const newXH = (1 - E / alpha) * (ptH.x + E * ptH.y) - (E / alpha) * line.c;
  return new L.Point(newXH, newYH);
};
