import { type ImageVertices } from '@kili-technology/types';
import { dequal } from 'dequal';
import L, { LatLng } from 'leaflet';
import _cloneDeep from 'lodash/cloneDeep';

import { type VideoObjectDetectionAnnotation } from '@/__generated__/globalTypes';
import { responseContainsKeyFrame } from '@/components/asset-ui/Frame/helpers';
import { type ValidEPSG } from '@/components/asset-ui/Image/Geospatial/constants';
import { getBounds } from '@/components/asset-ui/Image/LeafletMapv2/Overlays/KiliRasterOverlays/helpers';
import {
  getLatLngsWithinImage,
  getProjectorFromLatLngToAnnotation,
} from '@/components/asset-ui/Image/helpers';
import { type VideoParams } from '@/components/asset-ui/VideoV2/types';
import { sendToDatadog } from '@/datadog';
import { findAnnotationsInCache } from '@/graphql/annotations/helpers/cache/findAnnotationsInCache';
import { isVideoAnnotation } from '@/graphql/annotations/helpers/data/validators/isVideoAnnotation';
import { isVideoObjectDetectionAnnotation } from '@/graphql/annotations/helpers/data/validators/isVideoObjectDetectionAnnotation';
import { type FrameResponses } from '@/redux/label-frames/types';

import { type KiliAnnotation } from '../jobs/setResponse';

const thresholdForBboxDotProducts = 0.005;

export type Coordinates = L.LatLng | L.LatLng[] | L.LatLng[][];

export const isLatLngArrArr = (
  latlngs: L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | undefined,
): latlngs is L.LatLng[][] =>
  latlngs instanceof Array && latlngs[0] instanceof Array && latlngs[0][0] instanceof LatLng;

export const isLatLngArrArrCoordinates = (latlngs: Coordinates): latlngs is L.LatLng[][] =>
  latlngs instanceof Array && latlngs[0] instanceof Array;

export const isLatLngArrCoordinates = (latlngs: Coordinates): latlngs is L.LatLng[] =>
  latlngs instanceof Array && latlngs[0] instanceof L.LatLng;

export const isLatLngArr = (
  latlngs: L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | undefined,
): latlngs is L.LatLng[] => latlngs instanceof Array && latlngs[0] instanceof LatLng;

const pointsFormARightAngle = (a: LatLng, b: LatLng, c: LatLng) => {
  if (!a || !b || !c) {
    return false;
  }
  const dotProduct = Math.abs(
    (b.lat - a.lat) * (b.lat - c.lat) + (b.lng - a.lng) * (b.lng - c.lng),
  );
  return dotProduct < thresholdForBboxDotProducts;
};

export const areSegmentsOrthogonal = (a: LatLng, b: LatLng, c: LatLng, d: LatLng): boolean =>
  pointsFormARightAngle(a, b, c) &&
  pointsFormARightAngle(b, c, d) &&
  pointsFormARightAngle(c, d, a) &&
  pointsFormARightAngle(d, a, b);

export const isRectangle = (originalLatLngs: LatLng[][]): boolean => {
  const latLngs = _cloneDeep(originalLatLngs);
  if (latLngs.length !== 1) {
    return false;
  }
  const objectLatLngs = latLngs[0];
  if (objectLatLngs.length !== 4) {
    return false;
  }
  const rectangleLatLngs = objectLatLngs as [LatLng, LatLng, LatLng, LatLng];
  const lats = rectangleLatLngs.map(value => value.lat);
  const lngs = rectangleLatLngs.map(value => value.lng);
  lats.sort();
  lngs.sort();
  if (lats[0] === lats[1] && lats[2] === lats[3] && lngs[0] === lngs[1] && lngs[2] === lngs[3]) {
    return true;
  }
  try {
    const segmentsAreOrthogonal = areSegmentsOrthogonal(...rectangleLatLngs);
    return segmentsAreOrthogonal;
  } catch {
    return false;
  }
};

const findKeyFrames = (
  frameResponses: FrameResponses,
  selectedRangeObject: string | undefined,
): number[] =>
  Object.keys(frameResponses)
    .filter(key => responseContainsKeyFrame(frameResponses[key], selectedRangeObject))
    .map(frame => parseInt(frame, 10));

export const findNextKeyFrame = (
  frameResponses: FrameResponses,
  currentFrame: number,
  nFrames: number,
  selectedObjectIds: string[],
): number => {
  const keyFrames = findKeyFrames(frameResponses, selectedObjectIds[0]);
  const nextKeyFrames = keyFrames.filter(element => element > currentFrame);
  return nextKeyFrames.length === 0 ? nFrames - 1 : nextKeyFrames[0];
};

export const findPreviousKeyFrame = (
  frameResponses: FrameResponses,
  currentFrame: number,
  selectedObjectIds: string[],
): number => {
  const keyFrames = findKeyFrames(frameResponses, selectedObjectIds[0]);
  const previousKeyFrames = keyFrames.filter(element => element < currentFrame);
  return previousKeyFrames.length === 0 ? 0 : previousKeyFrames[previousKeyFrames.length - 1];
};

const findKeyFramesSplit = (mid: string | undefined): number[] => {
  const filteredObjectDetectionAnnotations = !mid
    ? findAnnotationsInCache(isVideoAnnotation)
    : findAnnotationsInCache(
        (annotation): annotation is VideoObjectDetectionAnnotation =>
          isVideoObjectDetectionAnnotation(annotation) && annotation.mid === mid,
      );

  return (
    filteredObjectDetectionAnnotations
      ?.flatMap(
        annotation => annotation.keyAnnotations?.map(keyAnnotation => keyAnnotation.frame) ?? [],
      )
      .sort((a, b) => a - b) ?? []
  );
};

export const findNextKeyFrameSplit = (
  currentFrame: number,
  nFrames: number,
  selectedRangeObjectsMids: string[],
): number => {
  const keyFrames = findKeyFramesSplit(selectedRangeObjectsMids[0]);
  const nextKeyFrames = keyFrames.filter(element => element > currentFrame);
  return nextKeyFrames.length === 0 ? nFrames - 1 : nextKeyFrames[0];
};

export const findPreviousKeyFrameSplit = (
  currentFrame: number,
  selectedRangeObjectsMids: string[],
): number => {
  const keyFrames = findKeyFramesSplit(selectedRangeObjectsMids[0]);
  const previousKeyFrames = keyFrames.filter(element => element < currentFrame);
  return previousKeyFrames.length === 0 ? 0 : previousKeyFrames[previousKeyFrames.length - 1];
};

const DELTA_PIXEL_SHIFT = 30;
const SHIFTS = {
  bottomRight: [-1, 1],
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  bottomLeft: [-1, -1],
  topLeft: [1, -1],
  topRight: [1, 1],
};

const shiftCoordinates =
  (deltaLat: number, deltaLng: number) =>
  (point: L.LatLng): L.LatLng =>
    new L.LatLng(point.lat + deltaLat, point.lng + deltaLng);

const getCoordinatesShifter = (index: number, factorValue: number) => {
  const [latDirection, lngDirection] = Object.values(SHIFTS)[index];
  return shiftCoordinates(latDirection * factorValue, lngDirection * factorValue);
};

const getShiftedAndWithinImageCoordinates = (
  shifter: (point: L.LatLng) => L.LatLng,
  imageFitter: (points: L.LatLng[]) => L.LatLng[],
  latLngs: Coordinates,
): [Coordinates, Coordinates] => {
  if (latLngs instanceof L.LatLng) {
    const shiftedLatLngs = shifter(latLngs);
    const latLngsWithinImageRaw = imageFitter([shiftedLatLngs]);
    return [shiftedLatLngs, latLngsWithinImageRaw[0]];
  }
  if (isLatLngArr(latLngs)) {
    const shiftedLatLngs = latLngs.map(shifter);
    const latLngsWithinImageRaw = imageFitter(shiftedLatLngs);
    return [shiftedLatLngs, latLngsWithinImageRaw];
  }
  const shiftedLatLngs = latLngs.map((points: L.LatLng[]) => points.map(shifter));
  const latLngsWithinImageRaw = shiftedLatLngs.map(imageFitter);
  return [shiftedLatLngs, latLngsWithinImageRaw];
};

const isLatLngArAr = (latLngs: Coordinates): latLngs is L.LatLng[][] => {
  return Array.isArray(latLngs) && Array.isArray(latLngs[0]);
};

const getShiftedCoordinatesWithinBounds = (
  bounds: L.LatLngBounds | undefined,
  latLngs: Coordinates,
): Coordinates => {
  if (!bounds) return latLngs;

  const shiftLatLng = (latlng: L.LatLng) => {
    const nw = bounds.getNorthWest();
    const sw = bounds.getSouthEast();
    const deltaLat = Math.abs(nw.lat - sw.lat);
    const deltaLng = Math.abs(nw.lng - sw.lng);
    const coef = 0.01;
    return new L.LatLng(latlng.lat - deltaLat * coef, latlng.lng + deltaLng * coef);
  };

  if (!Array.isArray(latLngs)) {
    return shiftLatLng(latLngs);
  }

  if (isLatLngArAr(latLngs)) {
    return latLngs.map((subLatLngs: L.LatLng[]) => subLatLngs.map(shiftLatLng));
  }

  return latLngs.map(shiftLatLng);
};

const getShiftedCoordinatesWithinImage = (
  width: number,
  height: number,
  latLngs: Coordinates,
): Coordinates => {
  const imageFitter = getLatLngsWithinImage(height, width);
  const deltaWidth = DELTA_PIXEL_SHIFT / width;
  let index = 0;
  let shifter = getCoordinatesShifter(index, deltaWidth);
  let [shiftedLatLngs, latLngsWithinImage] = getShiftedAndWithinImageCoordinates(
    shifter,
    imageFitter,
    latLngs,
  );
  while (!dequal(shiftedLatLngs, latLngsWithinImage)) {
    index += 1;
    if (index === 4) {
      latLngsWithinImage = latLngs;
      break;
    }
    shifter = getCoordinatesShifter(index, deltaWidth);
    [shiftedLatLngs, latLngsWithinImage] = getShiftedAndWithinImageCoordinates(
      shifter,
      imageFitter,
      latLngs,
    );
  }
  return latLngsWithinImage;
};

export const shiftCoordinatesFromLatLngs = (
  annotation: KiliAnnotation,
  latLngs: Coordinates,
  width: number,
  height: number,
  epsg?: ValidEPSG,
  bounds?: L.LatLngBounds,
): KiliAnnotation => {
  const projector = getProjectorFromLatLngToAnnotation(width, height, epsg, bounds);
  const latLngsWithinImage = epsg
    ? getShiftedCoordinatesWithinBounds(bounds, latLngs)
    : getShiftedCoordinatesWithinImage(width, height, latLngs);

  if (isLatLngArrArrCoordinates(latLngsWithinImage)) {
    const boundingPoly = latLngsWithinImage.map(sublatlngs => ({
      normalizedVertices: sublatlngs
        .map(projector)
        .filter((point): point is ImageVertices => !!point),
    }));
    return {
      ...annotation,
      boundingPoly,
    };
  }
  if (isLatLngArrCoordinates(latLngsWithinImage)) {
    const polyline = latLngsWithinImage
      .map(projector)
      .filter((point): point is ImageVertices => !!point);
    return {
      ...annotation,
      polyline,
    };
  }
  const point = projector(latLngsWithinImage);
  return {
    ...annotation,
    point,
  };
};

export const getShiftedAnnotationWithinImage = ({
  annotation,
  layer,
  width,
  height,
  epsg,
  bounds,
}: {
  annotation: KiliAnnotation;
  bounds?: [[number, number], [number, number]];
  epsg?: ValidEPSG;
  height: number;
  layer: L.Layer;
  width: number;
}): KiliAnnotation => {
  const isLayer2D = layer instanceof L.Rectangle || layer instanceof L.Polygon;
  const isLayer1D = layer instanceof L.Polyline;
  const isLayer0D = layer instanceof L.CircleMarker;
  let latLngs = null;
  if (isLayer2D) {
    latLngs = layer.getLatLngs() as L.LatLng[][];
  } else if (isLayer1D) {
    latLngs = layer.getLatLngs() as L.LatLng[];
  } else if (isLayer0D) {
    latLngs = layer.getLatLng() as L.LatLng;
  } else {
    latLngs = [];
  }
  return shiftCoordinatesFromLatLngs(
    annotation,
    latLngs,
    width,
    height,
    epsg,
    bounds ? getBounds(bounds) : undefined,
  );
};

export const computeImageDimensions = (
  base64: string,
  videoParams: VideoParams | undefined,
  callback: (width: number, height: number) => void,
): boolean => {
  if (!base64) {
    sendToDatadog(new Error('recomputeImageDimensions'), null, 'javascript');
  }

  const image = new Image();
  image.src = base64;
  image.onload = () => {
    if (image) {
      const width = videoParams?.width || image.naturalWidth;
      const height = videoParams?.height || image.naturalHeight;
      callback(width, height);
    }
  };
  return true;
};
