import _get from 'lodash/get';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { type ConnectedProps } from 'react-redux';

import { type connector } from './Notifier.wrap';

type WithReduxProps = ConnectedProps<typeof connector>;

const Notifier = (props: WithReduxProps) => {
  const { addNotification, notification, clearNotification } = props;
  const message = _get(notification, 'message');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(
    () => {
      if (clearNotification) {
        closeSnackbar();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearNotification],
  );

  useEffect(
    () => {
      if (message) {
        enqueueSnackbar(message, {
          action: _get(notification, 'action', null),
          autoHideDuration: _get(notification, 'autoHideDuration', 5000),
          onClick: _get(notification, 'onClick', null),
          persist: _get(notification, 'persist', false),
          variant: _get(notification, 'variant', 'info'),
        });
        setTimeout(() => {
          addNotification({ message: '', variant: 'default' });
        }, 0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message, notification],
  );

  return null;
};

export default Notifier;
