import { dequal } from 'dequal';

import { applicationCanUndo } from '../application/selectors';
import { jobsResponsesPast, jobsResponsesFuture, jobsResponses } from '../jobs/selectors';
import { JOBS_JUMP } from '../jobs/slice';
import { type Responses } from '../jobs/types';
import { type FrameResponses } from '../label-frames/types';
import { type AppThunk, type State } from '../types';

const countNumberOfUndosOrRedos = <T extends FrameResponses | Responses>({
  currentSelector,
  past,
  pastOrFutureSelector,
  state,
}: {
  currentSelector: (state: State) => T;
  past: boolean;
  pastOrFutureSelector: (state: State) => T[];
  state: State;
}): number => {
  const pastOrFutureDataArray = pastOrFutureSelector(state);
  let presentData = currentSelector(state);
  let numberOfUndosOrRedosBeforeLastChange = 1;
  const dataArray = past ? pastOrFutureDataArray.slice().reverse() : pastOrFutureDataArray.slice();
  dataArray.some(data => {
    if (!dequal(data, presentData)) {
      return true;
    }
    presentData = data;
    numberOfUndosOrRedosBeforeLastChange += 1;
    return false;
  });
  return numberOfUndosOrRedosBeforeLastChange;
};

export const undoOrRedo = <T extends Responses>({
  actionType,
  currentSelector,
  past,
  pastOrFutureSelector,
}: {
  actionType: string;
  currentSelector: (s: State) => T;
  past: boolean;
  pastOrFutureSelector: (s: State) => T[];
}): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const canUndo = applicationCanUndo(state);
    if (!canUndo) {
      return;
    }
    const numberOfChangesBetweenChange = countNumberOfUndosOrRedos({
      currentSelector,
      past,
      pastOrFutureSelector,
      state,
    });
    const newIndex = past ? -numberOfChangesBetweenChange : numberOfChangesBetweenChange;

    dispatch({ index: newIndex, type: actionType });
  };
};

export const undoOrRedoJobs = (undo: boolean): AppThunk<void> =>
  undoOrRedo({
    actionType: JOBS_JUMP,
    currentSelector: jobsResponses,
    past: undo,
    pastOrFutureSelector: undo ? jobsResponsesPast : jobsResponsesFuture,
  });
