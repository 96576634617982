import { css } from '@emotion/css';

export const classes = {
  line: css`
    display: flex;
    flex-direction: column;
  `,
  poseCategory: css`
    padding-left: var(--space-8);
    width: 100%;
  `,
  relation: css`
    width: 50%;
  `,
  relationCardFlex: css`
    align-items: flex-start;
  `,
  relationCardLine: css`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: var(--space-2);
    padding-left: var(--space-2);
    position: relative;
    width: 100%;
    & > div > div:first-of-type {
      min-height: var(--space-12);
    }
  `,
};
