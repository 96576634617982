import { gql } from '@apollo/client';

export const ORGANIZATION_LICENSE_FRAGMENTS = gql`
  fragment OrganizationLicense on Organization {
    license {
      api
      apiPriority
      enableSmartTools
      canUsePlugins
      contractedLabelingHours
      expiryDate
      inputType
      planType
      maxNumberOfAnnotations
      maxNumberOfAnnotationsPerMonth
      maxNumberOfLabeledAssets
      maxNumberOfPredictionsPerMonth
      organizationId
      seats
      startDate
      type
      uploadLocalData
      uploadCloudData
    }
  }
`;

export const ORGANIZATION_FRAGMENTS = gql`
  fragment OrganizationInfos on Organization {
    address
    city
    createdAt
    country
    id
    matchingDomain
    name
    license {
      api
      apiPriority
      enableSmartTools
      canUsePlugins
      expiryDate
      inputType
      planType
      maxNumberOfAnnotations
      maxNumberOfAnnotationsPerMonth
      maxNumberOfLabeledAssets
      maxNumberOfPredictionsPerMonth
      organizationId
      seats
      startDate
      type
      uploadLocalData
      uploadCloudData
    }
    numberOfLLMCallsRemaining
    users {
      email
    }
    zipCode
    isAllowedToSubmitNewLabels
  }
`;

export const ORGANIZATION_SETTINGS_FRAGMENTS = gql`
  fragment OrganizationSettings on Organization {
    settings {
      showUserPerformance
    }
  }
`;

export const ORGANIZATION_USAGE_METRICS_FRAGMENTS = gql`
  fragment OrganizationUsageMetrics on Organization {
    usageMetrics {
      currentMonthUsage {
        annotations
        labeledAssets
      }
      usageOnLicensePeriod {
        numberOfAnnotations
        numberOfLabeledAssets
        timeSpentOnLabeling
      }
      numberOfActiveMembers
    }
  }
`;

export const ORGANIZATION_METRICS_FRAGMENTS = gql`
  fragment OrganizationMetrics on OrganizationMetrics {
    maxNumberOfLabeledAssets
    numberOfAnnotations
    numberOfHours
    numberOfLabeledAssets
  }
`;
