import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isUsingAuth0, useAuth0 } from '../../auth0';
import { rootRoutes } from '../../pages/RootModule/RootPaths';
import { signOut } from '../../redux/authentication/actions';
import { initializeState } from '../../redux/user/actions';

export const useLogoutButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout } = useAuth0();

  const reduxLogout = () => {
    dispatch(signOut());
    dispatch(initializeState());
    history.push(rootRoutes.ROOT_SIGNIN_ROUTE.path);
  };

  const handleLogout = () =>
    isUsingAuth0 ? logout({ returnTo: `${window.location.origin}/label/` }) : reduxLogout();

  return {
    handleLogout,
  };
};
