import {
  type Annotation,
  type FrameJsonContent,
  type ImageVertices,
  type JobAnnotation,
  type ObjectAnnotation,
} from '@kili-technology/types';
import _get from 'lodash/get';
import _indexOf from 'lodash/indexOf';
import _isFinite from 'lodash/isFinite';
import _uniq from 'lodash/uniq';

import { type ResponsesOrAnnotationResponses } from '../../../redux/jobs/types';
import {
  type FrameResponses,
  type VideoJobs,
  type ChildVideoJob,
} from '../../../redux/label-frames/types';
import { useStore } from '../../../zustand';
import {
  ANNOTATIONS,
  CATEGORIES,
  CHILDREN,
  IS_KEY_FRAME,
  MID,
} from '../../InterfaceBuilder/FormInterfaceBuilder/constants';

const CACHE_HORIZON = 200;
export const PROPAGATION_HORIZON = 2000;

class RectangleProperties {
  angle: number;

  center: ImageVertices;

  length: number;

  width: number;

  constructor(angle: number, center: ImageVertices, length: number, width: number) {
    this.angle = angle;
    this.center = center;
    this.length = length;
    this.width = width;
  }
}

const bijectionCost = (permutedRectangle: ImageVertices[], rectangle: ImageVertices[]): number => {
  let cost = 0;
  for (let i = 0; i < 2; i += 1) {
    const ABx = rectangle[i + 1].x - rectangle[i].x;
    const ApermBpermx = permutedRectangle[i + 1].x - permutedRectangle[i].x;

    const ABy = rectangle[i + 1].y - rectangle[i].y;
    const ApermBpermy = permutedRectangle[i + 1].y - permutedRectangle[i].y;

    const ABnorm = Math.sqrt(ABx ** 2 + ABy ** 2);
    const ApermBnorm = Math.sqrt(ApermBpermx ** 2 + ApermBpermy ** 2);

    const cos = (ABx * ApermBpermx + ABy * ApermBpermy) / (ABnorm * ApermBnorm);

    cost -= cos;
  }
  return cost;
};

const distanceBetweenPoints = (point1: ImageVertices, point2: ImageVertices) =>
  Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2);

export const computeNewFramesInCache = (
  jsonContent: Record<string, string>,
  newUrlsAddedToCache: string[],
  urlsInCache: string[],
): number[] => {
  return Object.entries(jsonContent).reduce((acc, [frame, url]) => {
    return newUrlsAddedToCache.includes(url) || urlsInCache.includes(url)
      ? [...acc, +frame]
      : [...acc];
  }, [] as number[]);
};

export const computeLinksToAddToCache = (
  addedToCache: string[],
  currentFrame: number,
  jsonContent: FrameJsonContent,
  nFrames: number,
): string[] => {
  const startCacheFrame = currentFrame;
  const endCacheFrame = Math.min(startCacheFrame + CACHE_HORIZON, nFrames - 1);
  const shouldBeInCache = Array.from(
    { length: endCacheFrame - startCacheFrame + 1 },
    (_, k) => k + startCacheFrame,
  );
  const urlAddedToCache = addedToCache.map(frame => jsonContent[frame]);
  const linksToAddToCache = shouldBeInCache
    .map(frame => jsonContent[frame])
    .filter(url => !urlAddedToCache.includes(url));
  return linksToAddToCache;
};

const convertFromAbsoluteToNormalized = (v: ImageVertices, height: number, width: number) => {
  const x = v.x / width;
  const y = v.y / height;
  return { x, y };
};

const convertFromNormalizedToAbsolute = (v: ImageVertices, height: number, width: number) => {
  const x = v.x * width;
  const y = v.y * height;
  return { x, y };
};

export const filterLinksByImageCache = async (
  imageCache: Cache,
  jsonContent: FrameJsonContent,
): Promise<FrameJsonContent> => {
  const pastRequests = await imageCache.keys();
  const cacheUrls = pastRequests.map(request => request.url);
  const misingJsonContentFragment = Object.fromEntries(
    Object.entries(jsonContent).filter(([_key, url]) => !cacheUrls.includes(url)),
  );

  return misingJsonContentFragment;
};

const findRectangleAngle = (rectangle: ImageVertices[]): number => {
  const vectorAB = { x: rectangle[2].x - rectangle[1].x, y: rectangle[2].y - rectangle[1].y };
  return Math.atan2(vectorAB.y, vectorAB.x);
};

const findRectangleCenter = (rectangle: ImageVertices[]): ImageVertices => {
  const pointA = rectangle[0];
  const pointC = rectangle[2];

  return { x: (pointA.x + pointC.x) / 2, y: (pointA.y + pointC.y) / 2 };
};

const findRectangleProperties = (rectangle: ImageVertices[]): RectangleProperties => {
  if (rectangle.length !== 4) {
    throw new Error('Invalid rectangle format');
  }
  const angle = findRectangleAngle(rectangle);
  const center = findRectangleCenter(rectangle);
  const length = findRectangleLength(rectangle);
  const width = findRectangleWidth(rectangle);

  return { angle, center, length, width };
};

const findRectangleLength = (rectangle: ImageVertices[]): number => {
  return distanceBetweenPoints(rectangle[1], rectangle[2]);
};

export const findRectangleVerticesBijection = (
  rectangle1: ImageVertices[],
  rectangle2: ImageVertices[],
): ImageVertices[] => {
  const permutationArray = [
    [rectangle2[0], rectangle2[1], rectangle2[2], rectangle2[3]],
    [rectangle2[1], rectangle2[2], rectangle2[3], rectangle2[0]],
    [rectangle2[2], rectangle2[3], rectangle2[0], rectangle2[1]],
    [rectangle2[3], rectangle2[0], rectangle2[1], rectangle2[2]],
    [rectangle2[3], rectangle2[2], rectangle2[1], rectangle2[0]],
    [rectangle2[2], rectangle2[1], rectangle2[0], rectangle2[3]],
    [rectangle2[1], rectangle2[0], rectangle2[3], rectangle2[2]],
    [rectangle2[0], rectangle2[3], rectangle2[2], rectangle2[1]],
  ];
  const bijectionCostArray = permutationArray.map(permutedRectangle =>
    bijectionCost(permutedRectangle, rectangle1),
  );

  const optimalPermutationIndex = _indexOf(bijectionCostArray, Math.min(...bijectionCostArray));
  return permutationArray[optimalPermutationIndex];
};

const findRectangleWidth = (rectangle: ImageVertices[]): number => {
  return distanceBetweenPoints(rectangle[0], rectangle[1]);
};

export const frameResponseContainsAnnotations = (response: VideoJobs): boolean => {
  if (!response) return false;
  return Object.keys(response).some(
    job =>
      _get(response[job], ANNOTATIONS, []).length > 0 ||
      _get(response[job], CATEGORIES, []).length > 0 ||
      _get(response[job], 'text'),
  );
};

export const responseContainsKeyFrame = (
  response?: ResponsesOrAnnotationResponses | null,
  selectedRangeObject?: string | undefined,
): boolean => {
  if (!response) return false;
  return Object.entries(response).some(([key, jobResponse]) => {
    const annotationHasKeyFrame =
      (jobResponse?.[ANNOTATIONS] || []).filter((annotation: Annotation) => {
        const detectionKeyFrame = _get(annotation, IS_KEY_FRAME, false);
        const mid = _get(annotation, MID);
        const childResponse = _get(response, mid);
        const childKeyFrame = childResponse ? responseContainsKeyFrame(childResponse) : false;
        const annotationChild = _get(annotation, CHILDREN);
        const annotationChildKeyFrame = annotationChild
          ? responseContainsKeyFrame(annotationChild)
          : false;
        return (
          (detectionKeyFrame || childKeyFrame || annotationChildKeyFrame) &&
          (selectedRangeObject === undefined || mid === selectedRangeObject)
        );
      }).length > 0;
    const classificationHasKeyFrame =
      _get(jobResponse, IS_KEY_FRAME, false) &&
      (selectedRangeObject === undefined || key === selectedRangeObject);
    return annotationHasKeyFrame || classificationHasKeyFrame;
  });
};

export const frameResponseContainsKeyFrameForObjects = (
  response: VideoJobs,
  mids: string[],
): boolean => {
  if (!response) return false;
  return Object.keys(response).some(job => {
    const annotations = (response?.[job] as JobAnnotation)?.[ANNOTATIONS] ?? [];
    return annotations.filter(Boolean).some(annotation => {
      return (
        mids.includes(annotation[MID]) && Boolean((annotation as ObjectAnnotation)?.[IS_KEY_FRAME])
      );
    });
  });
};

export const isChildVideoJobKeyFrame = (
  response: VideoJobs,
  jobNames: string[],
  parentMid?: string,
): boolean => {
  if (!response) return false;

  if (!parentMid) {
    return jobNames.some(jobName => Boolean((response[jobName] as ChildVideoJob)?.[IS_KEY_FRAME]));
  }

  return jobNames.some(jobName =>
    Boolean(
      ((response[parentMid] as Record<string, ChildVideoJob>)?.[jobName] as ChildVideoJob)?.[
        IS_KEY_FRAME
      ],
    ),
  );
};

export const getFramesContainingTheSameObject = (
  arrayOfMids: Array<string>,
  frameJsonResponse: FrameResponses,
): number[] => {
  const framesContainingTheSameObject = Object.keys(frameJsonResponse)
    .map((key, _) => key)
    .filter(key => {
      const jsonResponse = frameJsonResponse[key];
      if (!jsonResponse) {
        return false;
      }
      return Object.values(jsonResponse)
        .map(response => _get(response, ANNOTATIONS, []))
        .some(annotations =>
          annotations.some((annotation: Annotation) => arrayOfMids.includes(_get(annotation, MID))),
        );
    })
    .map(key => parseInt(key, 10));
  return framesContainingTheSameObject;
};

export const getFrameArrayFromFrameOnwards = (
  arrayOfMids: Array<string>,
  frameToDeleteFrom: number,
  frameJsonResponse: FrameResponses,
): number[] => {
  const framesContainingTheSameObject = getFramesContainingTheSameObject(
    arrayOfMids,
    frameJsonResponse,
  );
  const { numberOfFrames: totalFrames } = useStore.getState().labelFrame.videoParams;

  const limit = totalFrames - frameToDeleteFrom;
  if (framesContainingTheSameObject.length === 0) {
    return Array.from({ length: Math.max(0, limit) }, (_, i) => frameToDeleteFrom + i);
  }
  return _uniq([frameToDeleteFrom, ...framesContainingTheSameObject]).filter(
    (index: number) => index >= frameToDeleteFrom,
  );
};

const verticesAreEqual = (
  nextVertices: ImageVertices[],
  previousVertices: ImageVertices[],
): boolean => {
  return nextVertices.every(
    (v, i) => v.x === previousVertices[i].x && v.y === previousVertices[i].y,
  );
};

export const interpolateRectangle = ({
  previousVertices,
  nextVertices,
  height,
  weight,
  width,
}: {
  height: number;
  nextVertices: ImageVertices[];
  previousVertices: ImageVertices[];
  weight: number;
  width: number;
}): ImageVertices[] => {
  // In case of equality between the previous and the next vertices,
  // we use to return the vertices in an inverted order because
  // of the findRectangleVerticesBijection function that check for bijection,
  // in the case of equality in the permutationArray many case can be true
  // and it was returning the vertices inverted. So we check if the vertices are equal
  // and return the previousVertices in this case.

  const areVerticesEqual = verticesAreEqual(nextVertices, previousVertices);
  if (areVerticesEqual) {
    return previousVertices;
  }
  const previousAbsoluteVertices = previousVertices.map((v: ImageVertices) =>
    convertFromNormalizedToAbsolute(v, height, width),
  );
  const nextAbsoluteVertices = nextVertices.map((v: ImageVertices) =>
    convertFromNormalizedToAbsolute(v, height, width),
  );
  const permutedNewVertices = findRectangleVerticesBijection(
    previousAbsoluteVertices,
    nextAbsoluteVertices,
  );
  const previousRectangleProperties = findRectangleProperties(previousAbsoluteVertices);
  const nextRectangleProperties = findRectangleProperties(permutedNewVertices);

  const interpolatedAngle = interpolateAngle(
    previousRectangleProperties.angle,
    nextRectangleProperties.angle,
    weight,
  );
  const interpolatedCenter = interpolatePoint(
    previousRectangleProperties.center,
    nextRectangleProperties.center,
    weight,
  );
  const interpolatedLength = interpolateNumber(
    previousRectangleProperties.length,
    nextRectangleProperties.length,
    weight,
  );
  const interpolatedWidth = interpolateNumber(
    previousRectangleProperties.width,
    nextRectangleProperties.width,
    weight,
  );

  const interpolatedRectangleProperties = {
    angle: interpolatedAngle,
    center: interpolatedCenter,
    length: interpolatedLength,
    width: interpolatedWidth,
  };

  const interpolatedRectangle = reconstructRectangleFromProperties(interpolatedRectangleProperties);
  const interpolatedVertices = interpolatedRectangle.map((v: ImageVertices) =>
    convertFromAbsoluteToNormalized(v, height, width),
  );
  return interpolatedVertices;
};

const interpolateNumber = (previousNumber: number, number: number, weight: number): number => {
  const interpolatedNumber = number * weight + (1 - weight) * previousNumber;
  return _isFinite(interpolatedNumber) ? interpolatedNumber : 0;
};

const interpolateAngle = (previousAngle: number, angle: number, weight: number): number => {
  const difference = Math.min(
    Math.abs(angle - previousAngle),
    2 * Math.PI - Math.abs(angle - previousAngle),
  );
  const sign =
    Math.abs(angle - previousAngle) < 2 * Math.PI - Math.abs(angle - previousAngle)
      ? Math.sign(angle - previousAngle)
      : Math.sign(2 * Math.PI - Math.abs(angle - previousAngle));

  const interpolatedAngle = previousAngle + sign * difference * weight;
  return _isFinite(interpolatedAngle) ? interpolatedAngle : 0;
};

export const interpolatePoint = (
  previousPoint: ImageVertices,
  nextPoint: ImageVertices,
  weight: number,
): ImageVertices => {
  const newX = interpolateNumber(previousPoint.x, nextPoint.x, weight);
  const newY = interpolateNumber(previousPoint.y, nextPoint.y, weight);

  return {
    x: newX,
    y: newY,
  };
};

const reconstructRectangleFromProperties = (properties: RectangleProperties): ImageVertices[] => {
  const { angle, center, length, width } = properties;
  const u = { x: 0, y: width };
  const v = { x: length, y: 0 };

  const rotatedU = rotateVector(u, angle);
  const rotatedV = rotateVector(v, angle);

  const pointA = {
    x: center.x + (rotatedU.x + rotatedV.x) / 2,
    y: center.y + (rotatedU.y + rotatedV.y) / 2,
  };
  const pointB = {
    x: center.x + (-rotatedU.x + rotatedV.x) / 2,
    y: center.y + (-rotatedU.y + rotatedV.y) / 2,
  };
  const pointC = {
    x: center.x - (rotatedU.x + rotatedV.x) / 2,
    y: center.y - (rotatedU.y + rotatedV.y) / 2,
  };
  const pointD = {
    x: center.x - (-rotatedU.x + rotatedV.x) / 2,
    y: center.y - (-rotatedU.y + rotatedV.y) / 2,
  };

  return [pointA, pointB, pointC, pointD];
};

const rotateVector = (vector: ImageVertices, angle: number): ImageVertices => {
  return {
    x: vector.x * Math.cos(angle) - vector.y * Math.sin(angle),
    y: vector.x * Math.sin(angle) + vector.y * Math.cos(angle),
  };
};
