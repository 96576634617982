import Button from '@kili-technology/button';
import { Community, Entreprise, GrowLicense } from '@kili-technology/icons';
import Modal from '@kili-technology/modal';

import LicenseModalColumn from './Components/LicenseModalColumn';
import classes from './LicenseModal.style';
import { FREE_FEATURES, GROW_FEATURES, ENTERPRISE_FEATURES } from './constants';

import { BOOK_URL } from '../../pages/auth/Register/components/BookDemo/constants';

interface PropsType {
  handleClose: () => void;
  open: boolean;
}

const LicenseModal = ({ handleClose, open }: PropsType): JSX.Element => {
  const handleClick = () => {
    window.open(`${BOOK_URL}`, '_blank');
  };

  return (
    <Modal className={classes.modal} onClose={handleClose} open={open}>
      <div className={classes.content}>
        <LicenseModalColumn
          contentFeatures={FREE_FEATURES}
          headerDescription="For individual contributors developing small scale projects"
          headerTitleElement={
            <div className={classes.headerTitleContainer}>
              <Community className={classes.licenseIcon} size={48} />
              <p className={classes.headerTitleText}>Free</p>
            </div>
          }
          pricingElement={
            <div className={classes.pricingContainer}>
              <h6 className={classes.pricingText}>
                <span>0</span>$/month
              </h6>
              <Button disabled>Current plan</Button>
            </div>
          }
        />

        <LicenseModalColumn
          contentFeatures={GROW_FEATURES}
          contentHeader="All Free features plus:"
          headerDescription="For companies wishing to invest in their labeling operations"
          headerTitleElement={
            <div className={classes.headerTitleContainer}>
              <GrowLicense className={classes.licenseIcon} size={48} />
              <p className={classes.headerTitleText}>Grow</p>
            </div>
          }
          pricingElement={
            <div className={classes.pricingContainer}>
              <h6 className={classes.pricingText}>
                Custom price
                <span />
              </h6>
              <Button onClick={() => handleClick()}>Book a demo</Button>
            </div>
          }
        />

        <LicenseModalColumn
          contentFeatures={ENTERPRISE_FEATURES}
          contentHeader="All Grow features plus:"
          headerDescription="For companies with enterprise needs and specific data security requirements"
          headerTitleElement={
            <div className={classes.headerTitleContainer}>
              <Entreprise className={classes.licenseIcon} size={48} />
              <p className={classes.headerTitleText}>Enterprise</p>
            </div>
          }
          pricingElement={
            <div className={classes.pricingContainer}>
              <h6 className={classes.pricingText}>
                Custom price
                <span />
              </h6>
              <Button onClick={() => handleClick()}>Talk to sales</Button>
            </div>
          }
        />
      </div>
    </Modal>
  );
};

export default LicenseModal;
