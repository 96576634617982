import { gql } from '@apollo/client';

import {
  PROJECT_AUTHOR_INFORMATION,
  PROJECT_FRAGMENTS_WITHOUT_PROPERTIES_AND_ROLES,
} from './fragments';

export const GQL_CHANGE_PROJECT_AUTHOR = gql`
  mutation ChangeProjectAuthor($where: ProjectWhere!) {
    changeProjectAuthor(where: $where) {
      ...ProjectAuthorInformation
    }
  }
  ${PROJECT_AUTHOR_INFORMATION}
`;

export const GQL_PROJECT_CREATE = gql`
  mutation CreateProject($data: CreateProjectData!) {
    createProject(data: $data) {
      ...ProjectInfos
    }
  }
  ${PROJECT_FRAGMENTS_WITHOUT_PROPERTIES_AND_ROLES}
`;

export const GQL_UPDATE_PROPERTIES_IN_PROJECT = gql`
  mutation UpdatePropertiesInProject($where: ProjectWhere!, $data: ProjectData!) {
    updatePropertiesInProject(where: $where, data: $data) {
      ...ProjectInfos
    }
  }
  ${PROJECT_FRAGMENTS_WITHOUT_PROPERTIES_AND_ROLES}
`;

export const GQL_PROJECT_DELETE_ASYNCHRONOUSLY = gql`
  mutation DeleteProjectAsynchronously($where: ProjectWhere!) {
    deleteProjectAsynchronously(where: $where)
  }
`;

export const GQL_PROJECT_RUN_PLUGIN = gql`
  mutation RunPlugin($data: RunPluginData!) {
    runPlugin(data: $data)
  }
`;

export const GQL_PROJECT_UPDATE_ANONYMIZATION = gql`
  mutation UpdateProjectAnonymization($input: UpdateProjectAnonymizationInput!) {
    updateProjectAnonymization(input: $input) {
      id
      isAnonymized
    }
  }
`;

export const GQL_PROJECT_EXPORT_PERFORMANCE_DATA = gql`
  mutation ExportPerformanceData($projectId: ID!) {
    data: exportPerformanceData(projectId: $projectId)
  }
`;
