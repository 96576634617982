import {
  type Annotation,
  type AnnotationCategory as Category,
  type ClassificationAtPageLevelAnnotation,
  type ImageBoundingPoly,
  isDetectionTask,
  type Job,
  type JobAnnotation,
  type JobResponse,
  type Jobs,
  type JsonResponse,
  LabelVersion,
  MachineLearningTask,
  type PoseEstimationAnnotation,
  Tool,
} from '@kili-technology/types';
import { generateHash } from '@kili-technology/utilities';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';

import { LabelType } from '@/__generated__/globalTypes';
import {
  ANNOTATIONS,
  CATEGORIES,
  CHILDREN,
  MID,
} from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { ROTATION_JOB } from '@/components/asset-ui/Image/constants';
import {
  convertOldPdfAnnotation,
  isOldPdfAnnotation,
} from '@/components/asset-ui/Pdf/PdfViewer/helpers/convertOldPdfAnnotation';
import { getJobFromName } from '@/pages/projects/label/LabelDialog/LabelInterface/helpers';
import { type PageLevelWrapper } from '@/redux/jobs/types';

import { isJobPoseEstimation, MID_SPLIT_POINT_STRING } from '../poseEstimation';

export type ResponseToSet = {
  jobName: string;
  jobResponse: JobResponse;
  jobs: Jobs;
  mlTask: MachineLearningTask;
  parentMid: string | undefined;
};

export type KiliAnnotationProvider<T extends Record<string, unknown>> = T & {
  boundingPoly?: ImageBoundingPoly[];
  jobName: string;
  labelVersion?: LabelVersion;
  mlTask?: MachineLearningTask;
  type?: Tool | undefined;
};

export type KiliAnnotation = KiliAnnotationProvider<Annotation>;

const reconstructJsonResponseAtPageLevel = (
  jobResponse: Record<string, ClassificationAtPageLevelAnnotation>,
): PageLevelWrapper<ClassificationAtPageLevelAnnotation> => {
  const allIds = Object.keys(jobResponse);
  return { allIds, byId: jobResponse };
};

const reconstructObjectDetectionResponses = (
  response: ResponseToSet,
  jobResponseWithMid: JobAnnotation,
  labelType: LabelType,
  job: Job,
) => {
  const tool = job?.tools?.[0];
  const defaultLabelVersion =
    labelType === LabelType.PREDICTION ? LabelVersion.PREDICTION : LabelVersion.DEFAULT;
  const jobResponseWithMidAndObjectDetectionInfo = {
    annotations: (jobResponseWithMid?.annotations || []).map(
      (annotation: Annotation & { labelVersion?: LabelVersion }) => ({
        ...annotation,
        labelVersion: annotation.labelVersion ?? defaultLabelVersion,
        type: tool,
        ...(tool === Tool.POSE && {
          allPoints: job?.content?.categories?.[annotation?.categories?.[0].name]?.points,
        }),
      }),
    ),
  };
  const responses = [
    {
      ...response,
      jobResponse: jobResponseWithMidAndObjectDetectionInfo,
    },
  ] as ResponseToSet[];
  const [lastCreatedItem] = jobResponseWithMidAndObjectDetectionInfo.annotations.slice(-1);
  if (lastCreatedItem && lastCreatedItem.categories) {
    responses.push({
      ...response,
      jobResponse: {
        categories: lastCreatedItem.categories.map(({ confidence, ...props }) => props),
      },
      mlTask: MachineLearningTask.CLASSIFICATION,
    });
  }
  return responses;
};

const getProcessedResponsesToSet =
  (labelType: LabelType) =>
  (response: ResponseToSet): ResponseToSet[] => {
    const { mlTask, jobName, jobResponse, jobs } = response;
    const job = jobs?.[jobName];
    const jobResponseWithMid: JobAnnotation = {
      annotations: ((jobResponse as JobAnnotation)?.[ANNOTATIONS] ?? []).map(annotation => {
        const newAnnotation = {
          ...annotation,
          jobName,
          mid: _get(annotation, MID) || generateHash(),
          mlTask,
        };
        return isOldPdfAnnotation(newAnnotation)
          ? convertOldPdfAnnotation(newAnnotation)
          : newAnnotation;
      }),
    };
    switch (mlTask) {
      case MachineLearningTask.ASSET_ANNOTATION:
      case MachineLearningTask.CLASSIFICATION:
      case MachineLearningTask.SPEECH_TO_TEXT:
      case MachineLearningTask.TRANSCRIPTION:
        return [response];
      case MachineLearningTask.PAGE_LEVEL_CLASSIFICATION:
      case MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION: {
        const reconstructedJobResponse = reconstructJsonResponseAtPageLevel(
          jobResponse as unknown as Record<string, ClassificationAtPageLevelAnnotation>,
        ) as unknown as JobResponse;
        return [{ ...response, jobResponse: reconstructedJobResponse }];
      }
      case MachineLearningTask.NAMED_ENTITIES_RECOGNITION:
      case MachineLearningTask.NAMED_ENTITIES_RELATION:
      case MachineLearningTask.OBJECT_RELATION:
      case MachineLearningTask.POSE_ESTIMATION:
        return [{ ...response, jobResponse: jobResponseWithMid }];
      case MachineLearningTask.OBJECT_DETECTION: {
        return reconstructObjectDetectionResponses(response, jobResponseWithMid, labelType, job);
      }
      default:
        return [];
    }
  };

const initializingChildrenJsonResponseForJobAtPageLevel = (jobs: Jobs, jobName: string) => {
  const jobsCategoriesFromJsonInterface = jobs?.[jobName]?.content?.categories ?? {};
  const childrenJobsName = Object.values(jobsCategoriesFromJsonInterface)
    .map(category => category.children ?? [])
    .flat();

  // Initializing the jsonResponse from children jobs
  const childrenJsonResponse = childrenJobsName.reduce<JsonResponse>((acc, childrenJobName) => {
    const nextAcc = { ...acc };
    nextAcc[childrenJobName] = {} as JobResponse;
    return nextAcc;
  }, {});
  return childrenJsonResponse;
};

const jobResponseIsJobAnnotation = (jobResponse: JobResponse): jobResponse is JobAnnotation => {
  if ((jobResponse as JobAnnotation)?.annotations?.length) {
    return true;
  }
  return false;
};

export const getResponsesToSet = (
  jobs: Jobs,
  labelType: LabelType,
  responses: JsonResponse,
  parentMid: string | undefined = undefined,
): ResponseToSet[] => {
  let responsesToSet: ResponseToSet[] = [];
  if (!_isObject(jobs) || _isEmpty(jobs) || !_isObject(responses) || _isEmpty(responses))
    return responsesToSet;
  Object.entries(responses).forEach(([jobName, jobResponse]) => {
    // When resetting, check if project is VIDEO,
    // When changing frame, set responses with jobName = currentFrame

    // When a frame changes, call setResponses(jobs, jsonResponse[currentFrame], parentMid)
    const job = getJobFromName(jobName, jobs, responses);
    const mlTask = jobName === ROTATION_JOB ? MachineLearningTask.ASSET_ANNOTATION : job?.mlTask;
    const getProcessedJobResponse = getProcessedResponsesToSet(labelType);
    responsesToSet = responsesToSet.concat(
      getProcessedJobResponse({
        jobName,
        jobResponse,
        jobs,
        mlTask,
        parentMid,
      }),
    );
    if (isDetectionTask(mlTask) && jobResponseIsJobAnnotation(jobResponse)) {
      const annotations = jobResponse?.annotations;
      annotations.forEach(annotation => {
        const mid = annotation?.mid;
        const childrenResponsesIfFrames = responses[mid];
        const annotationChildren = annotation?.children ?? {};
        const childrenResponses = childrenResponsesIfFrames || annotationChildren;
        if (annotation[MID] && !_isEmpty(childrenResponses)) {
          responsesToSet = responsesToSet.concat(
            getResponsesToSet(jobs, labelType, childrenResponses as unknown as JsonResponse, mid),
          );
        }
        if (isJobPoseEstimation(job)) {
          const points = (annotation as PoseEstimationAnnotation)?.points || [];
          points.forEach(point => {
            const childrenResponsesByPoint = point?.[CHILDREN] || {};
            const pointResponse = getResponsesToSet(
              jobs,
              labelType,
              childrenResponsesByPoint,
              `${mid}${MID_SPLIT_POINT_STRING}${point.code}`,
            );
            responsesToSet = responsesToSet.concat(pointResponse);
          });
        }
      });
    } else if (mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION) {
      const childrenJsonResponse = initializingChildrenJsonResponseForJobAtPageLevel(jobs, jobName);

      const computeChildrenJsonResponseForJobAtPageLevel = () => {
        Object.entries(
          jobResponse as unknown as Record<string, ClassificationAtPageLevelAnnotation>,
        ).forEach(([pageNumber, annotation]) => {
          annotation?.categories?.forEach(category => {
            const childrenResponses = category?.children;
            if (!childrenResponses) {
              return;
            }
            Object.entries(childrenResponses).forEach(([childrenJobName, childrenJobResponse]) => {
              if (_isEmpty(childrenJobResponse)) {
                return;
              }
              (
                childrenJsonResponse[childrenJobName] as unknown as Record<
                  string,
                  ClassificationAtPageLevelAnnotation
                >
              )[pageNumber] = childrenJobResponse as unknown as ClassificationAtPageLevelAnnotation;
            });
          });
        });
      };

      computeChildrenJsonResponseForJobAtPageLevel();

      responsesToSet = responsesToSet.concat(
        getResponsesToSet(jobs, labelType, childrenJsonResponse, parentMid),
      );
    } else {
      const childrenResponses = _get(jobResponse, CHILDREN);
      if (!_isEmpty(childrenResponses)) {
        responsesToSet = responsesToSet.concat(
          getResponsesToSet(jobs, labelType, childrenResponses, parentMid),
        );
      }
      _get(jobResponse, CATEGORIES, []).forEach((category: Category) => {
        const childrenResponsesInCategories = category.children ?? {};
        if (!_isEmpty(childrenResponsesInCategories)) {
          responsesToSet = responsesToSet.concat(
            getResponsesToSet(jobs, labelType, childrenResponsesInCategories, parentMid),
          );
        }
      });
    }
  });
  return responsesToSet;
};
