import { type VideoAnnotation } from '@/graphql/annotations/types';

import { isAnnotation } from './isAnnotation';

const VIDEO_ANNOTATION_TYPENAMES: readonly VideoAnnotation['__typename'][] = [
  'VideoClassificationAnnotation',
  'VideoObjectDetectionAnnotation',
  'VideoTranscriptionAnnotation',
];

export function isVideoAnnotation(input: unknown): input is VideoAnnotation {
  return (
    isAnnotation(input) &&
    VIDEO_ANNOTATION_TYPENAMES.includes((input as VideoAnnotation).__typename)
  );
}
