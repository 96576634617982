import { type LabelPdfSlice, type LabelPdfSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: LabelPdfSliceValues = {
  rotationByPage: {},
};

const log = createLogger('labelPdf');

export const createLabelPdfSlice: ZustandSlice<LabelPdfSlice> = setState => ({
  ...initialState,
  resetPagesRotation() {
    setState(
      state => {
        state.labelPdf.rotationByPage = {};
      },
      false,
      log('resetPagesRotation'),
    );
  },
  setPageRotation(page: number, rotation: number) {
    setState(
      state => {
        state.labelPdf.rotationByPage = {
          ...state.labelPdf.rotationByPage,
          [page]: rotation,
        };
      },
      false,
      log('setPageRotation', { page, rotation }),
    );
  },
});

export { type LabelPdfSlice, type LabelPdfSliceValues } from './types';
