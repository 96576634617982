import {
  type JsonCategories as Categories,
  type Job,
  type Jobs,
  MachineLearningTask,
} from '@kili-technology/types';
import { generateHash } from '@kili-technology/utilities';
import _get from 'lodash/get';
import Papa, { type ParseResult } from 'papaparse';

import { InputType, type Label } from '../../../../../__generated__/globalTypes';
import { getColorFromSeed } from '../../../../../components/helpers';
import { COLORS } from '../../../../../constants/jobsColors';
import { type VisibilityTree } from '../../../../../redux/application/types';
import { hasAnnotationsOrRelationsSelected } from '../../../../../redux/jobs/selectors';
import { projectID as selectProjectId } from '../../../../../redux/project/selectors';
import { projectInputType, userId as selectUserId } from '../../../../../redux/selectors';
import { downloadAssetContent } from '../../../../../services/assets/download';
import { type KiliAnnotation } from '../../../../../services/jobs/setResponse';
import { sortByOrderInRichText } from '../../../../../services/jobs/sortAnnotations';
import { store } from '../../../../../store';
import { useStore } from '../../../../../zustand';
import { TagMode } from '../../../../../zustand/label-interface';
import { selectLabelInterfaceTagSettings } from '../../../../../zustand/label-interface/selectors';

export const ASSET = 'asset';
export const JOBS = 'jobs';

export const getFirstColorNotInColors = (colors: string[]): string => {
  let returnColor;
  COLORS.forEach(color => {
    if (!colors.includes(color)) {
      returnColor = color;
    }
  });
  if (returnColor) {
    return returnColor;
  }
  return getColorFromSeed(generateHash());
};

export const getJobFromName = (jobName: string, jobs: Jobs, responses: unknown): Job => {
  const jobFromSettings = _get(jobs, jobName);
  if (jobFromSettings) return jobFromSettings;

  const originalJobName = _get(responses, [jobName, 'jobName']);
  return _get(jobs, originalJobName);
};

export const categoryIsHidden = (
  visibility: VisibilityTree | undefined,
  categoryCode: string,
  jobName: string,
  parentMid: string,
): boolean => {
  const categoryCodeWithParentMid = parentMid
    ? `${parentMid}.${categoryCode}`
    : `${jobName}.${categoryCode}`;
  if (!visibility) return false;
  return !_get(visibility, ['Classes', categoryCodeWithParentMid], true);
};

export const pointIsHidden = (
  visibility: VisibilityTree | undefined,
  pointCode: string,
): boolean => {
  if (!visibility) return false;
  return !_get(visibility, ['Points', pointCode], true);
};

export const isObjectHiddenBySearch = (
  visibility: VisibilityTree | undefined,
  objectMid: string,
): boolean => {
  if (!visibility) return false;
  return !(visibility?.Objects?.[objectMid] ?? true);
};

export const isJobHidden = (visibility: VisibilityTree | undefined, jobName: string): boolean => {
  if (!visibility) return false;
  return !(visibility?.Jobs?.[jobName] ?? true);
};

export const filterCategoriesByVisibility = (
  visibility: VisibilityTree | undefined,
  categories: Categories,
  jobName: string,
  parentMid: string,
): Categories => {
  if (!visibility) return categories;
  return Object.keys(categories)
    .filter(categoryCode => !categoryIsHidden(visibility, categoryCode, jobName, parentMid))
    .reduce((object: Categories, key: string) => {
      // eslint-disable-next-line no-param-reassign
      object[key] = categories[key];
      return object;
    }, {});
};

export const computeSecondsToLabel = (
  endTime: Date,
  previousLabelType: Label['labelType'] | null,
  previousTimeToLabel: Label['secondsToLabel'] | null,
  startTime: Date | undefined,
): number => {
  const previousLabelTime = previousTimeToLabel ?? 0;
  if (!startTime || !endTime) {
    return 0;
  }
  const newLabelTime = Math.round((endTime.valueOf() - startTime.valueOf()) / 1000);
  if (previousLabelType === 'AUTOSAVE') {
    return previousLabelTime + newLabelTime;
  }
  return newLabelTime;
};

export type CsvParseResult = Omit<ParseResult<string[]>, 'errors'>;
export async function downloadAndParseLlmCsvFile(
  token: string,
  dataIntegrationId: string | null,
  content: string,
): Promise<CsvParseResult> {
  const response = await downloadAssetContent(content, token, undefined, !!dataIntegrationId);
  if (!response?.ok) throw new Error('An error occured on asset content download');
  const text = await response.text();
  const result = await new Promise<ParseResult<string[]>>(complete => {
    Papa.parse(text, { complete });
  });

  const { errors, ...parsedCsvContent } = result;

  if (errors.length > 0) {
    throw new Error('An error occured with the CSV file');
  }

  return parsedCsvContent;
}

export const JOB_VIEWER_CUSTOM_SIZE = 'jobViewerSize';

export function saveNavigationSize(size: number) {
  localStorage.setItem(JOB_VIEWER_CUSTOM_SIZE, `${size}`);
}

export function getStoredNavigationSize(): number {
  const rawSize = localStorage.getItem(JOB_VIEWER_CUSTOM_SIZE) ?? '';
  const size = parseFloat(rawSize);
  if (Number.isNaN(size)) return 0;
  return size;
}

export const getOrderedMids = (inputType: InputType, sortedAnnotations: KiliAnnotation[]) => {
  const nextSortedAnnotations = sortedAnnotations.filter(
    ({ mlTask }) =>
      mlTask &&
      ![MachineLearningTask.OBJECT_RELATION, MachineLearningTask.NAMED_ENTITIES_RELATION].includes(
        mlTask,
      ),
  );
  if (inputType === InputType.TEXT) {
    const divAnnotations = Array.from(document.querySelectorAll('[data-mid]'));
    return sortByOrderInRichText(divAnnotations, nextSortedAnnotations);
  }
  return nextSortedAnnotations.map(({ mid }) => mid);
};

/**
 * To know if a a text area should be auto selected or not :
 * 1. Create an annotation
 *      1.a. isWizardOpen, auto select wizard field transcription
 *      1.b. setting mode !== hide, auto select leaflet map transcription
 *      1.c. setting mode === hide, auto select job viewer transcription
 * 2. Select an annotation
 *      2.a. in an image or in a video, do not auto select
 *      2.b. setting mode !== hide, auto select leaflet map transcription
 *      2.c. setting mode === hide, auto select job viewer transcription
 * 3. Don't select it
 */
export const shouldAutoSelect = (
  source?: 'annotation-wizard' | 'leaflet' | 'job-viewer',
  annotationMid?: string,
) => {
  if (!source) return false;
  if (source === 'annotation-wizard') return true;
  const reduxStore = store.getState();
  const zustandStore = useStore.getState();
  const isWizardOpen = zustandStore.labelInterface.temporaryAnnotations.length > 0;
  if (isWizardOpen) return false;
  const userId = selectUserId(reduxStore);
  const projectId = selectProjectId(reduxStore);
  const tagSettings = selectLabelInterfaceTagSettings(projectId, userId)(zustandStore);
  const inputType = projectInputType(reduxStore);

  if (inputType === InputType.IMAGE || inputType === InputType.VIDEO) return false;

  const { selectedObjectIds } = zustandStore.labelInterface;
  const selectedObjectInObjects = hasAnnotationsOrRelationsSelected(reduxStore, {
    selectedObjectIds,
  });

  const isRelatedAnnotationSelected =
    !!annotationMid && !!selectedObjectInObjects && selectedObjectIds.includes(annotationMid);

  if (source === 'job-viewer') {
    return tagSettings.displayMode === TagMode.NEVER && isRelatedAnnotationSelected;
  }

  return isRelatedAnnotationSelected;
};
