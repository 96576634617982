import {
  type Annotation,
  type Job,
  MachineLearningTask,
  type PoseEstimationAnnotation,
  type PoseEstimationMarker,
  Tool,
} from '@kili-technology/types';
import L from 'leaflet';
import _get from 'lodash/get';
import _last from 'lodash/last';

import { type WheelEvent } from '../../components/asset-ui/Image/LeafletMapv2/helpers';
import { type KiliAnnotationProvider } from '../jobs/setResponse';

export const MID_SPLIT_POINT_STRING = '---';
export const DURATION_FINISHED_POSE_DISPLAYED = 1000;

export enum PoseEstimationStatus {
  Finished = 'finished',
  Found = 'found',
  None = 'none',
}

export const isJobPoseEstimation = (job: Job): boolean =>
  job.mlTask === MachineLearningTask.POSE_ESTIMATION || job?.tools?.[0] === Tool.POSE;

export const annotationIsPoseEstimation = (
  annotation: KiliAnnotationProvider<Annotation>,
): annotation is KiliAnnotationProvider<PoseEstimationAnnotation> => 'points' in annotation;

export const getLastAnnotationPointFromPoseEstimation = (
  poseEstimationPoints: PoseEstimationMarker[],
): string => {
  const lastPoint = _last(poseEstimationPoints);
  return _get(lastPoint, 'code') || '';
};

export const poseAnnotationIsFinished = (
  pointCodes: string[],
  lastAnnotationPoint: string,
): boolean => {
  const lastAnnotationPointIsLastPossiblePoint =
    lastAnnotationPoint && lastAnnotationPoint === _last(pointCodes);
  return !!lastAnnotationPointIsLastPossiblePoint;
};

export const handleChangeCircleCursorOnWheel = (event: WheelEvent): void => {
  L.DomEvent.preventDefault(event);
  const element = document.getElementById('pose-marker');
  const size = parseInt((element?.style.height || '').replace('px', ''), 10);
  const deltaY = Math.max(-50, Math.min(event.deltaY / 5, 50));
  const newSize = size + deltaY;
  if (element) {
    element.style.height = `${newSize}px`;
    element.style.width = `${newSize}px`;
  }
};
