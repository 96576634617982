import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type AuthenticationPayload } from './types';

const sliceAuthentication = createSlice({
  initialState,
  name: 'authentication',
  reducers: {
    SIGN_IN(state, action: PayloadAction<AuthenticationPayload>) {
      return {
        ...state,
        email: action.payload.email,
        isAuthenticated: true,
        token: action.payload.token,
      };
    },
    SIGN_OUT(state) {
      return {
        ...state,
        email: null,
        isAuthenticated: false,
        token: null,
      };
    },
  },
});

export const { SIGN_OUT, SIGN_IN } = sliceAuthentication.actions;

export default sliceAuthentication.reducer;
