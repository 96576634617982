import { type Draft } from 'immer';

import { type HelpersSlice, type HelpersSliceValues } from './types';

import { type ZustandSlice, type ZustandStoreValues } from '..';
import createLogger from '../helpers';

const initialState: HelpersSliceValues = {};

const log = createLogger('helpers');

export const createHelpersSlice: ZustandSlice<HelpersSlice> = set => ({
  ...initialState,
  updateField: payload =>
    set(
      state => {
        const { key, value, sliceName } = payload;
        if (typeof value === 'function') {
          const nextValue = (
            value as (
              _: ZustandStoreValues[typeof sliceName][typeof key],
            ) => ZustandStoreValues[typeof sliceName][typeof key]
          )(state[sliceName][key]);
          (state[sliceName] as Draft<ZustandStoreValues>[typeof sliceName])[key] = nextValue;
        } else {
          (state[sliceName] as Draft<ZustandStoreValues>[typeof sliceName])[key] = value;
        }
      },
      false,
      log(`update-${payload.sliceName}-${String(payload.key)}`, payload),
    ),
});

export * from './types';
