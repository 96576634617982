import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { type JobState } from './types';

import { projectJobs } from '../selectors';

export const jobJob = createSelector([state => _get(state, 'job', {})], jobs => jobs as JobState);

export const jobCurrentJobName = createSelector([jobJob], job => job.currentJobName ?? '');
export const jobCurrentCategoryCode = createSelector([jobJob], job => job.currentCategoryCode);
export const jobCurrentJob = createSelector([jobCurrentJobName, projectJobs], (jobName, jobs) => {
  if (!jobName) return undefined;
  return jobs?.[jobName];
});
export const jobCurrentCategory = createSelector(
  [jobCurrentJob, jobCurrentCategoryCode],
  (job, categoryCode) => {
    if (!categoryCode) return undefined;
    return job?.content?.categories?.[categoryCode];
  },
);
export const jobCurrentCategories = createSelector(jobCurrentCategoryCode, categoryCode =>
  categoryCode ? [{ name: categoryCode }] : [],
);

export const jobCategoryPoints = createSelector(
  [jobCurrentJobName, jobCurrentCategoryCode, projectJobs],
  (jobName, categoryCode, jobs) => {
    if (!jobName || !categoryCode) {
      return [];
    }
    return jobs?.[jobName]?.content?.categories?.[categoryCode]?.points ?? [];
  },
);
