import { connect } from 'react-redux';

import StyledNotifier from './Notifier';

import { addNotification } from '../../redux/application/actions';
import {
  applicationClearNotification,
  applicationNotification,
} from '../../redux/application/selectors';
import { type State } from '../../redux/types';

const mapStateToProps = (state: State) => ({
  clearNotification: applicationClearNotification(state),
  notification: applicationNotification(state),
});

const mapDispatchToProps = {
  addNotification,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(StyledNotifier);
