import { type ApolloClient, type InMemoryCache, useApolloClient } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHomePath } from './useHomePath';

import { UserStatus } from '../../../__generated__/globalTypes';
import { Auth0Context, isUsingAuth0 } from '../../../auth0';
import { useUserEmail } from '../../../hooks/useUserEmail';
import { identifyProjectUserRole } from '../../../redux/application/actions';
import {
  applicationisGraphqlClientInitialized,
  applicationIsUserIdentified,
} from '../../../redux/application/selectors';
import { getOrganization, isOrganizationError } from '../../../redux/organization';
import { organizationId } from '../../../redux/organization/selectors';
import {
  userId as userIdSelector,
  userStatus as userStatusSelector,
} from '../../../redux/selectors';
import { getUser } from '../../../redux/user/actions';
import { userHasCompletedProfile } from '../../../redux/user/selectors';
import useFlag from '../../../services/flags/useFlag';
import { useStore } from '../../../zustand';
import { rootRoutes } from '../RootPaths';
import appHistory from '../history';

export function useRedirectUser() {
  const dispatch = useDispatch();
  const client = useApolloClient() as ApolloClient<InMemoryCache>;
  const isGraphqlClientInitialized = useSelector(applicationisGraphqlClientInitialized);
  const isUserIdentified = useSelector(applicationIsUserIdentified);
  const email = useUserEmail();
  const userId = useSelector(userIdSelector) || undefined;
  const status = useSelector(userStatusSelector) || undefined;
  const isProfileCompleted = useSelector(userHasCompletedProfile);
  const hasOrganizationSaved = !!useSelector(organizationId);
  const hasNoOrganization = useSelector(isOrganizationError);
  const isRegistering = useStore(state => state.register.isRegistering);
  const auth0Context = useContext(Auth0Context);
  const isEmailVerificationEnabled = useFlag('lops_auth_email_verification');

  const shouldGetUser = isGraphqlClientInitialized && !userId && !!email;
  const hasPendingStatus = status === UserStatus.PENDING;
  const shouldGetOrganization =
    !!email && isProfileCompleted && !hasOrganizationSaved && !hasNoOrganization;
  const shouldIdentifyProjectUserRole = !!userId && !!email && !isUserIdentified;

  const routePathRegister = rootRoutes.ROOT_REGISTER_ROUTE.path;
  const routePathProjects = useHomePath();

  useEffect(() => {
    if (shouldIdentifyProjectUserRole) {
      dispatch(identifyProjectUserRole());
    }
  }, [dispatch, shouldIdentifyProjectUserRole]);

  useEffect(() => {
    if (shouldGetUser) {
      dispatch(getUser({ client, email }));
    }
  }, [dispatch, client, email, shouldGetUser]);

  useEffect(() => {
    if (shouldGetOrganization) {
      dispatch(getOrganization({ client, email }));
    }
  }, [dispatch, client, email, shouldGetOrganization]);

  if (!userId) {
    return;
  }

  const { pathname } = appHistory.location;
  const isPageRegister = pathname === routePathRegister;

  if (
    ((!isProfileCompleted || hasNoOrganization) && !isPageRegister) ||
    hasPendingStatus ||
    (isEmailVerificationEnabled && isUsingAuth0 && !auth0Context.isEmailVerified)
  ) {
    appHistory.push(routePathRegister);
    return;
  }

  if (isProfileCompleted && hasOrganizationSaved && isPageRegister && !isRegistering) {
    appHistory.push(routePathProjects);
  }
}
