import {
  type ClassificationAnnotation,
  DetectionTasks,
  type JobResponse,
  MachineLearningTask,
  type TranscriptionAnnotation,
} from '@kili-technology/types';
import { get as _get, isEmpty as _isEmpty } from 'lodash';

export const isEmptyResponse = (
  jobResponse: JobResponse,
  mlTask?: MachineLearningTask,
): boolean => {
  if (_isEmpty(jobResponse)) return true;
  if (mlTask === MachineLearningTask.TRANSCRIPTION) {
    return !(jobResponse as TranscriptionAnnotation).text;
  }
  if (mlTask && DetectionTasks.includes(mlTask)) {
    const annotations = _get(jobResponse, 'annotations', []) || [];
    return annotations.length === 0;
  }
  if (mlTask === MachineLearningTask.CLASSIFICATION) {
    return (jobResponse as ClassificationAnnotation)?.categories?.length === 0;
  }

  return false;
};
