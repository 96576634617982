import { connect } from 'react-redux';

import AuthorizedApolloProvider from './AuthorizedApolloProvider';

import { authenticationToken } from '../../redux/authentication/selectors';
import { userId } from '../../redux/selectors';
import { type State } from '../../redux/types';

const mapStateToProps = (state: State) => ({
  token: authenticationToken(state),
  userId: userId(state),
});

const mapDispatchToProps = () => {
  return {};
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AuthorizedApolloProvider);
