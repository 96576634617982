import { gql } from '@apollo/client';

export const SIGNUP = gql`
  mutation SignUp($data: SignUpData!) {
    signUp(data: $data) {
      id
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($where: UserWhere!) {
    resetPassword(where: $where) {
      id
    }
  }
`;
