import { type ApolloClient, type InMemoryCache } from '@apollo/client';

import ApolloAssetGateway from './assetGateway/apolloAssetGateway';
import { type AssetGateway } from './assetGateway/assetGateway';
import ApolloProjectMemberGateway from './projectMemberGateway/apolloProjectMemberGateway';
import { type ProjectMemberGateway } from './projectMemberGateway/projectMemberGateway';

export interface EntityGateway {
  assetGateway: AssetGateway;
  projectMembersGateway: ProjectMemberGateway;
}

export const buildEntityGateway = ({
  client,
}: {
  client: ApolloClient<InMemoryCache>;
}): EntityGateway => {
  const projectMembersGateway = new ApolloProjectMemberGateway(client);
  const assetGateway = new ApolloAssetGateway(client);
  return { assetGateway, projectMembersGateway };
};
