import { css } from '@emotion/css';

const classes = {
  checkmark: css`
    min-height: 18px;
    min-width: 18px;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
    margin-top: var(--space-4);
  `,
  contentHeader: css`
    color: var(--color-beta-accent-6);
    margin-bottom: var(--space-2);
    margin-top: var(--space-0);
  `,
  featureLine: css`
    align-items: center;
    display: flex;
    gap: var(--space-1);

    p {
      margin: var(--space-0);
    }
  `,
  header: css`
    align-items: center;
    border-bottom: 1px solid var(--color-omega-accent-3);
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
    padding-bottom: var(--space-4);

    > p {
      text-align: center;
      margin: var(--space-0);
      height: 51px;
    }
  `,
  headerDescription: css`
    color: var(--color-omega-accent-7);
  `,
  pricing: css`
    border-bottom: 1px solid var(--color-omega-accent-3);
    padding: var(--space-4) var(--space-0);
  `,
  rootColumn: css`
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-8);
    padding: var(--space-4) var(--space-8);
    width: 345px;
  `,
};

export default classes;
