import _isEqual from 'lodash/isEqual';

import {
  type FrameInterval,
  type VideoObjectDetectionAnnotation,
} from '@/__generated__/globalTypes';
import {
  addKeyAnnotationsInCache,
  deleteAnnotationsInCache,
  deleteKeyAnnotationsInCache,
  getVideoAnnotationsFromCache,
  purgeAnnotationsInCache,
  updateFramesInCache,
} from '@/graphql/annotations/actions';
import { addAnnotationInCache } from '@/graphql/annotations/helpers/cache/addAnnotationInCache';
import {
  filterExistingRemaining,
  getAnnotationValueForFrame,
} from '@/graphql/annotations/helpers/common';
import {
  duplicateVideoKeyAnnotation,
  type VideoKeyAnnotationOverrideProperties,
} from '@/graphql/annotations/helpers/data/factories/duplicateVideoKeyAnnotation';
import { type VideoAnnotation, type VideoKeyAnnotation } from '@/graphql/annotations/types';
import { broadcastQueriesAfterUpdatingCache, getCacheIdFromGraphQLObject } from '@/graphql/helpers';
import { useStore } from '@/zustand';
import { useHistoryStore } from '@/zustand-history';

import { getAnnotationsRelatedToMidFromCache } from './cache/getAnnotationsRelatedToMidFromCache';
import { isVideoObjectDetectionAnnotation } from './data/validators/isVideoObjectDetectionAnnotation';

export const deleteVideoJobsSplit = () => {
  const annotations = getVideoAnnotationsFromCache();
  if (!annotations || annotations.length === 0) return;

  const action = {
    name: 'deleteAnnotationsSplit',
    redo: () => {
      purgeAnnotationsInCache();
      broadcastQueriesAfterUpdatingCache();
    },
    undo: () => {
      annotations.forEach(annotation => {
        addAnnotationInCache(annotation);
      });
      broadcastQueriesAfterUpdatingCache();
    },
  };

  action.redo();

  useHistoryStore.getState().history.addAction(action);
};

const clearFramesAndKeyAnnotationsFromFrame = ({
  annotation,
  frameToDeleteFrom,
  annotationIdsToDelete,
}: {
  annotation: VideoAnnotation;
  annotationIdsToDelete: string[];
  frameToDeleteFrom: number;
}) => {
  const newFrames = annotation.frames
    .map(frame =>
      frame.end > frameToDeleteFrom ? { ...frame, end: frameToDeleteFrom - 1 } : { ...frame },
    )
    .filter(frame => frame.start < frameToDeleteFrom);

  if (newFrames.length) {
    const { deletedElements: deletedKeyAnnotations } = filterExistingRemaining(
      annotation.keyAnnotations as VideoKeyAnnotation[],
      (keyAnnotation: VideoKeyAnnotation) => keyAnnotation.frame < frameToDeleteFrom,
    );
    updateFramesInCache(annotation, newFrames);
    deleteKeyAnnotationsInCache(getCacheIdFromGraphQLObject(annotation), deletedKeyAnnotations);
  } else {
    annotationIdsToDelete.push(annotation.id);
  }
};

export const deleteObjectFromFrameOnwardsSplit = ({
  mid,
  frameToDeleteFrom,
}: {
  frameToDeleteFrom: number;
  mid: string;
}) => {
  const annotationsRelatedToMid = getAnnotationsRelatedToMidFromCache(mid);
  const annotationIdsToDelete = [] as string[];

  annotationsRelatedToMid.forEach(annotation => {
    clearFramesAndKeyAnnotationsFromFrame({
      annotation,
      annotationIdsToDelete,
      frameToDeleteFrom,
    });
  });

  if (annotationIdsToDelete.length) {
    deleteAnnotationsInCache(annotationIdsToDelete);
  }

  broadcastQueriesAfterUpdatingCache();
};

export const deleteObjectFromFrameOnwardsSplitWithHistory = ({
  mid,
  frameToDeleteFrom,
}: {
  frameToDeleteFrom: number;
  mid: string;
}) => {
  const annotationsRelatedToMid = getAnnotationsRelatedToMidFromCache(mid);
  const annotation = annotationsRelatedToMid[0];
  const firstFrame = Math.min(...annotation.frames.map(frames => frames.start));

  const action = {
    name: 'deleteObjectFromFrameOnwardsSplit',
    redo: () => {
      deleteObjectFromFrameOnwardsSplit({
        frameToDeleteFrom,
        mid,
      });
    },
    undo: () => {
      if (frameToDeleteFrom > firstFrame) {
        deleteAnnotationsInCache(
          annotationsRelatedToMid.map(annotationRelatedToMid => annotationRelatedToMid.id),
        );
      }
      annotationsRelatedToMid.forEach(previousAnnotation => {
        addAnnotationInCache(previousAnnotation);
      });
      broadcastQueriesAfterUpdatingCache();
    },
  };

  action.redo();

  useHistoryStore.getState().history.addAction(action);
};

const deleteSelectedFramesInVideoAnnotation = ({
  selectedFrames,
  annotation,
}: {
  annotation: VideoAnnotation;
  selectedFrames: {
    max: number;
    min: number;
  };
}) => {
  if (!annotation.keyAnnotations?.length) throw new Error('No key annotations found');

  const newFrames = annotation.frames.reduce((acc, curr) => {
    if (curr.start < selectedFrames.min && curr.end > selectedFrames.max) {
      return acc.concat([
        { ...curr, end: selectedFrames.min - 1 },
        { ...curr, start: selectedFrames.max + 1 },
      ]);
    }
    if (curr.start > selectedFrames.max || curr.end < selectedFrames.min) {
      return acc.concat(curr);
    }
    if (curr.start >= selectedFrames.min && curr.end > selectedFrames.max) {
      return acc.concat({ ...curr, start: selectedFrames.max + 1 });
    }
    if (curr.end <= selectedFrames.max && curr.start < selectedFrames.min) {
      return acc.concat({ ...curr, end: selectedFrames.min - 1 });
    }
    return acc;
  }, [] as FrameInterval[]);

  if (_isEqual(annotation.frames, newFrames)) return;

  if (newFrames.length) {
    updateFramesInCache(annotation, newFrames);

    const { deletedElements: deletedKeyAnnotations } = filterExistingRemaining(
      (annotation.keyAnnotations as VideoKeyAnnotation[]) ?? [],
      (keyAnnotation: VideoKeyAnnotation) =>
        !(keyAnnotation.frame >= selectedFrames.min && keyAnnotation.frame <= selectedFrames.max),
    );

    if (deletedKeyAnnotations.length) {
      // In this case we need to keep a key frame at the right and the left (only for object detection as it's not
      // needed for classification or transcription) of the selection to not loose the already set values

      const nextFrameIntervalAfterSelection = newFrames.reduce(
        (acc: FrameInterval | undefined, curr) => {
          if (curr.start > selectedFrames.max && (!acc || curr.start < acc.start)) {
            return curr;
          }
          return acc;
        },
        undefined,
      );

      if (
        nextFrameIntervalAfterSelection &&
        !annotation.keyAnnotations.some(
          keyAnnotation => keyAnnotation.frame === nextFrameIntervalAfterSelection.start,
        )
      ) {
        const newKeyAnnotationAfter = duplicateVideoKeyAnnotation(deletedKeyAnnotations[0], {
          annotationValue: getAnnotationValueForFrame({
            frame: nextFrameIntervalAfterSelection.start,
            jobName: annotation.job,
            keyAnnotations: annotation.keyAnnotations,
          }),
          frame: nextFrameIntervalAfterSelection.start,
        } as VideoKeyAnnotationOverrideProperties);
        addKeyAnnotationsInCache(getCacheIdFromGraphQLObject(annotation), [newKeyAnnotationAfter]);
      }

      if (isVideoObjectDetectionAnnotation(annotation)) {
        const previousFrameBeforeSelection = newFrames.reduce(
          (acc: FrameInterval | undefined, curr) => {
            if (curr.end < selectedFrames.min && (!acc || curr.end > acc.end)) {
              return curr;
            }
            return acc;
          },
          undefined,
        );

        if (
          previousFrameBeforeSelection &&
          !annotation.keyAnnotations.some(
            keyAnnotation => keyAnnotation.frame === previousFrameBeforeSelection.end,
          )
        ) {
          const newKeyAnnotationBefore = duplicateVideoKeyAnnotation(deletedKeyAnnotations[0], {
            annotationValue: getAnnotationValueForFrame({
              frame: previousFrameBeforeSelection.end,
              jobName: annotation.job,
              keyAnnotations: annotation.keyAnnotations,
            }),
            frame: previousFrameBeforeSelection.end,
          } as VideoKeyAnnotationOverrideProperties);
          addKeyAnnotationsInCache(getCacheIdFromGraphQLObject(annotation), [
            newKeyAnnotationBefore,
          ]);
        }
      }

      deleteKeyAnnotationsInCache(getCacheIdFromGraphQLObject(annotation), deletedKeyAnnotations);
    }
  } else {
    deleteAnnotationsInCache([annotation.id]);
  }
  broadcastQueriesAfterUpdatingCache();
};

export const deleteObjectInSelectedFramesSplit = ({
  mid,
  selectedFrames,
}: {
  mid: string;
  selectedFrames: {
    max: number;
    min: number;
  };
}) => {
  const annotationsRelatedToMid = getAnnotationsRelatedToMidFromCache(mid);
  annotationsRelatedToMid.forEach(annotation =>
    deleteSelectedFramesInVideoAnnotation({
      annotation,
      selectedFrames,
    }),
  );
  broadcastQueriesAfterUpdatingCache();
};

export const deleteObjectInSelectedFramesSplitWithHistory = ({
  mid,
  selectedFrames,
}: {
  mid: string;
  selectedFrames: {
    max: number;
    min: number;
  };
}) => {
  const annotationsRelatedToMid = getAnnotationsRelatedToMidFromCache(mid);

  const action = {
    name: 'deleteObjectSplit',
    redo: () => {
      deleteObjectInSelectedFramesSplit({
        mid,
        selectedFrames,
      });
    },
    undo: () => {
      deleteObjectSplit(mid);
      annotationsRelatedToMid.forEach(annotation => {
        addAnnotationInCache(annotation as VideoObjectDetectionAnnotation);
      });
      broadcastQueriesAfterUpdatingCache();
    },
  };

  action.redo();

  useHistoryStore.getState().history.addAction(action);
};

export const deleteObjectSplit = (mid: string) => {
  const annotationsToDelete = getAnnotationsRelatedToMidFromCache(mid);
  const annotationIdsToDelete = annotationsToDelete.map(annotation => annotation.id);

  deleteAnnotationsInCache(annotationIdsToDelete);
  broadcastQueriesAfterUpdatingCache();
};

export const deleteObjectSplitWithHistory = (mid?: string) => {
  let midToDelete: string;
  if (mid) midToDelete = mid;
  else {
    const { selectedObjectIds } = useStore.getState().labelInterface;
    if (selectedObjectIds.length !== 1)
      throw new Error(
        'Can only delete delete one annotation at a time with deleteObjectSplitWithHistory.',
      );
    [midToDelete] = selectedObjectIds;
  }

  const annotationsToDelete = getAnnotationsRelatedToMidFromCache(midToDelete);

  const action = {
    name: 'deleteObjectSplit',
    redo: () => {
      deleteObjectSplit(midToDelete);
    },
    undo: () => {
      annotationsToDelete.forEach(annotation => {
        addAnnotationInCache(annotation as VideoObjectDetectionAnnotation);
      });
      broadcastQueriesAfterUpdatingCache();
    },
  };

  action.redo();

  useHistoryStore.getState().history.addAction(action);
};
