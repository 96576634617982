import { gql } from '@apollo/client';

import { USER_FRAGMENTS, API_KEY_FRAGMENTS } from './fragments';

export const GQL_CREATE_USER = gql`
  mutation createUser($data: CreateUserData!) {
    createUser(data: $data) {
      ...UserInfos
    }
  }
  ${USER_FRAGMENTS}
`;

export const GQL_UPDATE_PROPERTIES_IN_USER = gql`
  mutation UpdatePropertiesInUser($where: UserWhere!, $data: UserData!) {
    updatePropertiesInUser(where: $where, data: $data) {
      ...UserInfos
    }
  }
  ${USER_FRAGMENTS}
`;

export const GQL_PASSWORD_RESET = gql`
  mutation ResetAndReturnPassword($where: UserWhere!) {
    resetAndReturnPassword(where: $where)
  }
`;

export const GQL_PASSWORD_UPDATE = gql`
  mutation UpdatePassword($data: UpdatePasswordData!, $where: UserWhere!) {
    updatePassword(data: $data, where: $where) {
      id
    }
  }
`;

export const GQL_SEND_EMAIL_TO_SUPPORT = gql`
  mutation SendEmailToSupport($data: SendEmailToSupportData!) {
    sendEmailToSupport(data: $data)
  }
`;

export const GQL_APPEND_TO_API_KEYS = gql`
  mutation AppendToApiKeys($data: AppendToApiKeysData!, $where: UserWhere!) {
    appendToApiKeys(data: $data, where: $where) {
      ...ApiKeyInfos
    }
  }
  ${API_KEY_FRAGMENTS}
`;

export const GQL_UPDATE_PROPERTIES_IN_API_KEY = gql`
  mutation UpdatePropertiesInApiKey($data: UpdateApiKeyPropertiesData!, $where: ApiKeyWhere!) {
    updatePropertiesInApiKey(data: $data, where: $where) {
      ...ApiKeyInfos
    }
  }
  ${API_KEY_FRAGMENTS}
`;

export const GQL_DECLINE_ADMISSION_REQUEST = gql`
  mutation DeclineAdmissionRequest($where: DeclineAdmissionRequest!) {
    declineAdmissionRequest(where: $where)
  }
`;

export const GQL_ACCEPT_ADMISSION_REQUEST = gql`
  mutation AcceptAdmissionRequest($where: AcceptAdmissionRequest!) {
    acceptAdmissionRequest(where: $where)
  }
`;

export const GQL_SEND_VERIFICATION_EMAIL = gql`
  mutation SendVerificationEmail {
    sendVerificationEmail
  }
`;
