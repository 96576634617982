import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { isUsingAuth0, useAuth0 } from './auth0';
import appHistory from './pages/RootModule/history';
import { authenticationIsAuthenticated } from './redux/authentication/selectors';

const ORIGINAL_PATH_KEY = 'originalPath';
const ORIGINAL_SEARCH_KEY = 'originalSearch';

export const useCacheOriginalUrl = () => {
  useEffect(() => {
    if (window.location.search.includes('code=')) {
      return;
    }
    localStorage.setItem(ORIGINAL_PATH_KEY, window.location.pathname);
    localStorage.setItem(ORIGINAL_SEARCH_KEY, window.location.search);
  }, []);
};

export const useOriginalUrlRedirector = () => {
  const { isAuthenticated: auth0IsAuthenticated } = useAuth0();
  const kiliIsAuthenticated = useSelector(authenticationIsAuthenticated);
  const isAuthenticated = isUsingAuth0 ? auth0IsAuthenticated : kiliIsAuthenticated;
  const originalPath = localStorage.getItem(ORIGINAL_PATH_KEY);
  const originalSearch = localStorage.getItem(ORIGINAL_SEARCH_KEY);
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname !== originalPath && originalPath) {
      const pathWithoutLabel = originalPath?.slice(7);
      const path = `${pathWithoutLabel}${originalSearch}`;
      if (path && isAuthenticated) {
        localStorage.removeItem(ORIGINAL_PATH_KEY);
        localStorage.removeItem(ORIGINAL_SEARCH_KEY);
        appHistory.push(path);
      }
    }
  }, [isAuthenticated, originalPath, originalSearch]);
};
