import { css } from '@emotion/css';

const useStyles = () => {
  return {
    button: css`
      margin-top: var(--space-2);
      margin-bottom: var(--space-2);
      margin-left: var(--space-1);
      width: var(--space-8);
      min-width: var(--space-8);
      padding: 0 0 !important;
    `,
  };
};

export default useStyles;
