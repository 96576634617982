import { type Draft } from 'immer';

import { type IssuesSlice, type IssuesSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: IssuesSliceValues = {
  focusIssueTextArea: false,
  newIssueMid: null,
};

const log = createLogger('issues');

export const createIssuesSlice: ZustandSlice<IssuesSlice> = set => ({
  ...initialState,
  initialize: () =>
    set(
      state => {
        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;

          (state.issues[keyInitialState] as unknown) = value;
        });
      },
      false,
      log('initialize'),
    ),
  setNewIssueMid: objectMid =>
    set(
      state => {
        state.issues.newIssueMid = objectMid;
        state.issues.focusIssueTextArea = !!objectMid;
      },
      false,
      log('setNewIssueMid', objectMid),
    ),
  updateField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.issues as Draft<IssuesSliceValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
});
