import {
  type ProjectQueueSelectCounters,
  ProjectQueueCountersType,
  type ProjectQueueCounts,
} from './types';

import { type Status } from '../../__generated__/globalTypes';

const computeCountToUpdate = (
  counters: ProjectQueueSelectCounters,
  increment: -1 | 1 | 0,
  status?: Status,
  isHoneypot?: boolean | null,
) => {
  if (!status || !ProjectQueueCountersType[status]) {
    return {};
  }
  const counterToIncrement = {} as Partial<ProjectQueueCounts>;
  const key = ProjectQueueCountersType[status] as keyof ProjectQueueCounts;
  const counterType = counters[key as keyof ProjectQueueSelectCounters];
  counterToIncrement[key] = (counterType ?? 0) + increment;
  if (isHoneypot) {
    counterToIncrement['counterHoneypot' as keyof ProjectQueueCounts] =
      (counterType ?? 0) + increment;
  }

  return counterToIncrement;
};

export default computeCountToUpdate;
