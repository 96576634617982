/* eslint-disable */
// @ts-nocheck file supposed to be deprecated in May 2022
import buffer from '@turf/buffer';
import difference from '@turf/difference';
import * as helpers from '@turf/helpers';
import union from '@turf/union';
import { io } from 'jsts';
import L, { DivIcon, LeafletMouseEvent, Point } from 'leaflet';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _last from 'lodash/last';
import React from 'react';

import { ctrlOrMetaKey } from '../../../../../../../constants/shortcuts';

export const ADD = 'add';
export const DELETE = 'delete';
export const SUBTRACT = 'substract';
export const VIEW = 'view';

export const LAYER_ADD = 'layeradd';
export const LAYER_REMOVE = 'layerremove';
export const LAYER_SUBTRACT = 'layersubtract';

export const FREEDRAW_POLYLINE_CLASSNAME = 'leaflet-do-not-remove';
export const FREE_DRAW_POLYGON_CLASSNAME = 'leaflet-free-hand-shapes';

export const INITIAL_POSITION = { lat: 0, lng: 0 } as L.LatLng;

export const EPSILON = 0.01;

export const intersects = function (poly1: unknown, poly2: unknown) {
  const reader = new io.GeoJSONReader();

  const geometry1 = _get(poly1, 'type') === 'Feature' ? _get(poly1, 'geometry') : poly1;
  const geometry2 = _get(poly2, 'type') === 'Feature' ? _get(poly2, 'geometry') : poly2;

  if (!geometry1 || !geometry2) {
    return false;
  }

  const geoJson1 = reader.read(JSON.stringify(geometry1));
  const geoJson2 = reader.read(JSON.stringify(geometry2));

  return !!geoJson1.intersects(geoJson2);
};

export const pointNearFirstPoint =
  (epsilon: number, mapLat: number, mapLng: number, firstPoint: L.LatLng) =>
  (point: L.LatLng): boolean =>
    Math.sqrt(
      ((point.lat - firstPoint.lat) / mapLat) ** 2 + ((point.lng - firstPoint.lng) / mapLng) ** 2,
    ) < epsilon;

type PointIsNearFirstPoint = (point: L.LatLng) => boolean;

export const removePointsCloseToFirstPoint = (
  latlngs: L.LatLng[],
  pointIsNearFirstPoint: PointIsNearFirstPoint,
): L.LatLng[] => {
  const numberOfPoints = latlngs.length;
  let indexOfLastPoint = numberOfPoints - 1;
  while (pointIsNearFirstPoint(latlngs[indexOfLastPoint])) {
    if (indexOfLastPoint === 0) break;
    indexOfLastPoint -= 1;
  }
  return latlngs.slice(0, indexOfLastPoint + 1);
};
