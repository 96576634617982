/* eslint-disable no-underscore-dangle */
import { SelectorClass } from '@kili-technology/cursors';
import {
  type ImageBoundingPoly,
  type ImageVertices,
  MachineLearningTask,
  type ObjectRelation,
  Tool,
} from '@kili-technology/types';
import L from 'leaflet';
import _maxBy from 'lodash/maxBy';
import _minBy from 'lodash/minBy';
import { batch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { InputType } from '@/__generated__/globalTypes';
import { canBlur, memoizedGetColorForCategories } from '@/components/helpers';
import { deleteObjectSplitWithHistory } from '@/graphql/annotations/helpers/delete';
import { updateAnnotationSplitWithHistory } from '@/graphql/annotations/helpers/updateAnnotation';
import { updateResponseForAnnotations } from '@/redux/jobs/actions';
import { deleteObject } from '@/redux/jobs/actions/deleteObject';
import { isKiliAnnotationObjectRelation } from '@/redux/jobs/relations';
import { jobsImageRelationAnnotations } from '@/redux/jobs/selectors';
import { updateAnnotation } from '@/redux/label-frame/actions';
import { isValidAnnotation } from '@/redux/label-image/helper';
import { undoOrRedoJobs } from '@/redux/redux-undo/actions';
import { projectInputType } from '@/redux/selectors';
import { type State } from '@/redux/types';
import { isFlagActivated } from '@/services/flags/useFlag';
import { type KiliAnnotation } from '@/services/jobs/setResponse';
import { getRelationBadgesData, type RelationBadgeData } from '@/services/text/relations';
import { store } from '@/store';
import { useStore } from '@/zustand';
import { selectIsADrawToolSelected } from '@/zustand/label-interface/selectors';
import { useHistoryStore } from '@/zustand-history';

import KiliFeatureGroup from './KiliFeatureGroup';
import RelationBadge from './RelationBadge/RelationBadge';
import { RELATION_MARKER_SHIFTX } from './RelationBadge/constants';
import { getEditingIconForCategory, getPositionsMaxLeftRight, getZoomFactor } from './helpers';
import {
  type BadgeLayer,
  type Geometry,
  type HiddableLayer,
  isBadgeLayer,
  type KiliAnnotationWithGeometry,
  type KiliLayer,
  type KiliOptions,
} from './types';

import layersByMid from '../ImageAnnotations/LayerMap';
import badgeLayers, { addBadgeLayers } from '../ImageAnnotations/badgeLayers';
import { clickOnLayerWithEventHandler } from '../ImageAnnotations/layerEventHandlers';
import { layerToHover } from '../hooks/KiliCursor';

const updatePoly = (layer: KiliLayer, latlngs: L.LatLng[]) => {
  if (!layer.editing || !layer.editing.enabled()) {
    return;
  }

  layer.editing.disable();
  // @ts-expect-error _poly is not exposed on L.Edit.Poly
  // eslint-disable-next-line no-param-reassign
  layer.editing._poly = layer;
  // @ts-expect-error latlngs is not exposed on L.Edit.Poly
  // eslint-disable-next-line no-param-reassign
  layer.editing.latlngs = latlngs;
  layer.editing.enable();
};

const updateRectangle = (layer: KiliLayer, latlngs: L.LatLng[]) => {
  // @ts-expect-error dragging is not exposed on KiliLayer
  if (!layer.dragging) return;
  // @ts-expect-error transform is not exposed on KiliLayer
  // Move the rect to the right coordinates
  layer.transform?._rect?._setLatLngs?.(latlngs);
  // @ts-expect-error dragging is not exposed on KiliLayer
  // eslint-disable-next-line no-param-reassign
  layer.dragging._matrix = new L.Matrix(1, 0, 0, 1, 0, 0);
  // @ts-expect-error transform is not exposed on KiliLayer
  layer.transform?._updateHandlers?.();
  // @ts-expect-error transform is not exposed on KiliLayer

  // SImulate a drag and drop
  layer.transform?._onDragEnd?.({});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyConstructor<A> = new (...input: any[]) => A;

// Let Typescript infer the type of the wrapped class

export const KiliWrapper = <TBase extends AnyConstructor<L.Path>>(Base: TBase) =>
  class KiliShape extends Base {
    kiliOptions?: KiliOptions;

    editing?: L.Edit.SimpleShape;

    group!: KiliFeatureGroup;

    layerId!: string;

    map!: L.Map;

    _defsPath?: SVGPathElement;

    _selectedUsePath?: SVGUseElement;

    _mask?: SVGMaskElement;

    initializeKili(options: KiliOptions) {
      const {
        categories,
        dashArrayProperty,
        group,
        inputType,
        jobName,
        labelVersion,
        map,
        mid,
        mlTask,
        type,
        ...baseOptions
      } = options;
      if (type !== Tool.MARKER) {
        this.setStyle({
          color: memoizedGetColorForCategories(categories, jobName),
          // @ts-expect-error bad types in leaflet package
          cursor: '',
          dashArray: dashArrayProperty,
          opacity: 1,
          weight: 3,
        });
      }
      this.kiliOptions = {
        ...baseOptions,
        categories,
        dashArrayProperty,
        inputType,
        isSelected: false,
        jobName,
        labelVersion,
        map,
        mid,
        mlTask,
        type,
      };
      this.map = map;
      if (!group) {
        this.group = new KiliFeatureGroup(mid);
      } else {
        this.group = group;
      }
      this.group.shapeGroup.addLayer(this);
      this.layerId = uuidv4();
      this.on('mouseover', e => {
        // This check is to prevent from triggering the hover when the user is editing a shape
        if (e.originalEvent && e.originalEvent.buttons) return;
        if (!selectIsADrawToolSelected(useStore.getState())) {
          this.group.onHover(true);
          this.getElement()?.classList.add(SelectorClass);
        } else {
          layerToHover.layer = this;
        }
      });
      this.on('mouseout', e => {
        if (e.originalEvent && e.originalEvent.buttons) return;
        if (layerToHover.layer) {
          layerToHover.layer = undefined;
        } else {
          this.group.onHover(false);
          this.getElement()?.classList.remove(SelectorClass);
        }
      });

      this.on('click', () => {
        // AAU, when I click on an element, it deselects any textarea with the focus
        if (canBlur(document?.activeElement)) document.activeElement.blur();
      });
    }

    updateKiliOptions(newOptions: Partial<KiliOptions>) {
      if (!this.kiliOptions) return;
      this.kiliOptions = { ...this.kiliOptions, ...newOptions };
    }

    _setLatLngs(latlngs: L.LatLng[], save = true): void {
      // @ts-expect-error _setLatLngs is not exposed
      super._setLatLngs(latlngs);
      if (save) this.save();
    }

    _setLatLng(latlng: L.LatLng, save = true): void {
      // @ts-expect-error setLatLng is not exposed
      super.setLatLng(latlng);
      if (save) this.save();
    }

    resetEdit() {
      const type = this.kiliOptions?.type;
      // @ts-expect-error getLatLngs does not exist systematically in KiliLayers
      const latlngs = this.getLatLngs?.();
      if (type === Tool.RECTANGLE) {
        // @ts-expect-error sending KiliShape instance as KiliLayer
        updateRectangle(this, latlngs);
      }
      if (type && [Tool.POLYGON, Tool.POLYLINE, Tool.VECTOR].includes(type)) {
        // @ts-expect-error sending KiliShape instance as KiliLayer
        updatePoly(this, type === Tool.POLYLINE || type === Tool.VECTOR ? [latlngs] : latlngs);
      }

      if (type === Tool.MARKER) {
        if (this.editing?.enabled()) {
          this.editing?.disable();
          this.editing?.enable();
        }
      }
    }

    updateEditMarkerIcon() {
      if (
        this.kiliOptions?.categories &&
        this.kiliOptions?.jobName &&
        this.kiliOptions?.type &&
        [Tool.POLYGON, Tool.POLYLINE, Tool.VECTOR, Tool.POSE].includes(this.kiliOptions?.type)
      ) {
        const editMarkerIcon = getEditingIconForCategory(
          this.kiliOptions.categories,
          this.kiliOptions.jobName,
        );

        // @ts-expect-error poly is not in PathOptions from leaflet types
        this.options.poly = {
          allowIntersection: true,
          drawError: {
            color: '#b00b00',
            timeout: 1000,
          },
          icon: editMarkerIcon,
          touchIcon: editMarkerIcon,
        };
      }
    }

    getAnnotation(): KiliAnnotationWithGeometry {
      const geometry = this.getGeometry();
      const type = this.kiliOptions?.type;
      return {
        categories: this.kiliOptions?.categories || [],
        jobName: this.kiliOptions?.jobName || '',
        mid: this.kiliOptions?.mid || '',
        type,
        ...geometry,
      };
    }

    getAnnotationsForSemantic(): KiliAnnotation[] {
      if (!this.kiliOptions?.mid) return [];
      const otherLayers = layersByMid.get(this.kiliOptions.mid);
      return [this.getAnnotation(), ...(otherLayers ?? []).map(layer => layer.getAnnotation())];
    }

    fitToBounds() {}

    getArea(): number {
      if (
        this.kiliOptions?.type === Tool.MARKER ||
        this.kiliOptions?.type === Tool.POLYLINE ||
        this.kiliOptions?.type === Tool.POSE ||
        this.kiliOptions?.type === Tool.VECTOR
      ) {
        return 0;
      }
      // @ts-expect-error getLatLngs() is not exposed in KiliLayer
      const [latlngs] = this.getLatLngs() as L.LatLng[][];
      return L.GeometryUtil.geodesicArea(latlngs);
    }

    getBoundingPoly(): ImageBoundingPoly[] | undefined {
      if (this instanceof L.Polygon) {
        const latlngs = this.getLatLngs() as L.LatLng[][];
        const mapToUse = this.map || this._map;
        return latlngs.map(sublatlngs => ({
          normalizedVertices: sublatlngs
            .map(point => mapToUse.unprojectPoint(point))
            .filter((point): point is ImageVertices => !!point),
        }));
      }
      return undefined;
    }

    getPolyline(): ImageVertices[] | undefined {
      if (this instanceof L.Polyline && !(this instanceof L.Polygon)) {
        const latlngs = this.getLatLngs() as L.LatLng[];
        return latlngs
          .map((point: L.LatLng) => this.map.unprojectPoint(point))
          .filter((point): point is ImageVertices => !!point);
      }
      return undefined;
    }

    getMarker(): ImageVertices | undefined {
      if (this instanceof L.CircleMarker && this.map?.unprojectPoint) {
        const latlngs = this.getLatLng() as L.LatLng;
        return this.map.unprojectPoint(latlngs) as ImageVertices;
      }
      return undefined;
    }

    getGeometry(): Geometry {
      return {
        boundingPoly: this.getBoundingPoly(),
        point: this.getMarker(),
        polyline: this.getPolyline(),
      };
    }

    getUpperRightCorner(): L.LatLng | undefined {
      let flattenedLatlngs: L.LatLng[] = [];
      if (this instanceof L.Rectangle || this instanceof L.Polygon || this instanceof L.Polyline)
        flattenedLatlngs = this.getLatLngs().flat(2);
      else if (this instanceof L.CircleMarker) flattenedLatlngs = [this.getLatLng()];
      return _maxBy(flattenedLatlngs, o => o.lat + o.lng);
    }

    getLowerLeftCorner(): L.LatLng | undefined {
      let flattenedLatlngs: L.LatLng[] = [];
      if (this instanceof L.Rectangle || this instanceof L.Polygon || this instanceof L.Polyline) {
        flattenedLatlngs = this.getLatLngs().flat(2);
      } else if (this instanceof L.CircleMarker) {
        flattenedLatlngs = [this.getLatLng()];
      }

      return _minBy(flattenedLatlngs, o => o.lat + o.lng);
    }

    canKeepDrawing(): boolean {
      return false;
    }

    addHooks() {}

    removeHooks() {}

    readyToBeSaved() {
      return (
        !!this.kiliOptions?.mid && !!this.kiliOptions?.jobName && !!this.kiliOptions?.categories
      );
    }

    save(): void {
      if (!this.readyToBeSaved()) {
        return;
      }
      const annotation = this.getAnnotation();
      const { jobName, mid } = annotation;

      const isSemantic = this.kiliOptions?.type === Tool.SEMANTIC;

      if (isSemantic) {
        throw new Error('Save method should not be called for semantic annotations');
      }

      if (!isValidAnnotation(annotation)) {
        if (
          isFlagActivated('lab_splitted_json_response') &&
          projectInputType(store.getState()) === InputType.VIDEO
        ) {
          deleteObjectSplitWithHistory(mid);
        } else {
          store.dispatch(
            deleteObject({
              jobName,
              mid,
              mlTask: MachineLearningTask.OBJECT_DETECTION,
            }),
          );

          if (this.kiliOptions?.inputType === InputType.VIDEO) {
            const { undo } = useHistoryStore.getState().history;
            undo();
          }

          batch(() => {
            store.dispatch(undoOrRedoJobs(true));
          });
        }

        return;
      }

      if (this.kiliOptions?.inputType !== InputType.VIDEO) {
        store.dispatch(
          updateResponseForAnnotations({
            annotations: [annotation],
            mlTask: MachineLearningTask.OBJECT_DETECTION,
          }),
        );
      } else if (isFlagActivated('lab_splitted_json_response')) {
        updateAnnotationSplitWithHistory([annotation]);
      } else {
        store.dispatch(updateAnnotation(annotation));
      }
    }

    addBadge(badge: BadgeLayer, relationMid: string, relationGroup?: KiliFeatureGroup) {
      relationGroup?.shapeGroup.addLayer(badge);
      addBadgeLayers(relationMid, badge);
    }

    computeBadgePosition = (index: number, totalNumberBadges: number, map: L.Map) => {
      let latlng = this.getUpperRightCorner();
      if (!latlng) return undefined;

      let badgePosition: L.LatLngExpression;

      if (this instanceof L.Rectangle) {
        const listLeftRightLatLng = getPositionsMaxLeftRight(this.getLatLngs().flat(2));
        badgePosition = [
          listLeftRightLatLng[0].lat +
            ((index + 1) / (totalNumberBadges + 1)) *
              (listLeftRightLatLng[1].lat - listLeftRightLatLng[0].lat),
          listLeftRightLatLng[0].lng +
            ((index + 1) / (totalNumberBadges + 1)) *
              (listLeftRightLatLng[1].lng - listLeftRightLatLng[0].lng),
        ];
        [, latlng] = listLeftRightLatLng;
      } else {
        const zoomFactor = getZoomFactor(map);
        const shiftX = index * RELATION_MARKER_SHIFTX;

        const projectedLatLng = map.project(latlng, zoomFactor);

        const badgePositionLatLng = map.unproject(
          new L.Point(projectedLatLng.x + shiftX, projectedLatLng.y),
          zoomFactor,
        );

        badgePosition = [badgePositionLatLng.lat, badgePositionLatLng.lng];
      }
      return badgePosition;
    };

    createBadge(
      color: string,
      index: number,
      badgesData: RelationBadgeData[],
      relationMid: string,
      map: L.Map,
      relationGroup?: KiliFeatureGroup,
    ): L.LatLngTuple | undefined {
      const isLayerMarker =
        this.kiliOptions?.type === Tool.MARKER || this.kiliOptions?.type === Tool.POSE;

      const badgePosition = this.computeBadgePosition(index, badgesData.length, map);
      if (!badgePosition) return undefined;

      const iconMarker = RelationBadge(
        color,
        isLayerMarker,
        `${this.kiliOptions?.mid}_${relationMid}`,
      );

      const badge = L.marker(badgePosition, {
        bubblingMouseEvents: true,
        draggable: false,
        icon: iconMarker,
        interactive: true,
      }) as BadgeLayer;

      badge.kiliOptions = { isBadgeOnPoint: isLayerMarker, mid: relationMid };
      badge.on('click', event => {
        batch(() => {
          clickOnLayerWithEventHandler(event, badge as HiddableLayer);
        });
      });
      badge.on('mouseover', () => {
        if (!selectIsADrawToolSelected(useStore.getState())) {
          relationGroup?.onHover(true);
          badge.getElement()?.classList.add(SelectorClass);
        } else {
          layerToHover.layer = badge;
        }
      });
      badge.on('mouseout', () => {
        if (layerToHover.layer) {
          layerToHover.layer = undefined;
        } else {
          relationGroup?.onHover(false);
          badge.getElement()?.classList.remove(SelectorClass);
        }
      });
      badge.relationOptions = {
        objectMid: this.kiliOptions?.mid ?? '',
        objectUpperRightCorner: new L.LatLng(badgePosition[0], badgePosition[1]),
        relationMid,
      };
      this.addBadge(badge, relationMid, relationGroup);
      // @ts-expect-error - Leaflet draw typings are not up to date
      delete badge.editing;
      return badgePosition;
    }

    renderBadges(
      badgesData: RelationBadgeData[],
      hiddenMids: string[],
      relationMidsToKeep: string[],
      relationGroup: KiliFeatureGroup,
      map: L.Map,
    ) {
      const objectMid = this.kiliOptions?.mid;
      const relations = relationGroup.annotationMid?.split('_');
      if (!objectMid) return [];

      const isObjectHidden = hiddenMids.includes(objectMid);
      if (isObjectHidden) return [];
      return badgesData.map(({ color, mid: relationMid }, index) => {
        if (
          relationMidsToKeep.includes(relationMid) ||
          hiddenMids.includes(relationMid) ||
          relationMid !== relations?.[0]
        )
          return [];

        return this.createBadge(color, index, badgesData, relationMid, map, relationGroup);
      });
    }

    removeBadge(badge: BadgeLayer, relationMid: string, relationGroupLayer: KiliFeatureGroup) {
      relationGroupLayer.shapeGroup.removeLayer(badge);
      const relationLayersToKeep = (badgeLayers.get(relationMid) ?? []).filter(
        relationLayer =>
          relationLayer.relationOptions?.objectMid !== badge.relationOptions?.objectMid,
      );
      badgeLayers.set(relationMid, relationLayersToKeep);
    }

    getRelationMidsToKeep(
      badgesData: RelationBadgeData[],
      hiddenMids: string[],
      relationGroup: KiliFeatureGroup,
    ): string[] {
      const badgeMids = badgesData.map(badge => badge.mid);
      const layerMid = this.kiliOptions?.mid;
      if (!layerMid) return [];
      const isObjectHidden = hiddenMids.includes(layerMid);
      const relationMidsToKeep = [] as string[];
      const relationGroupLayer = relationGroup;
      if (!relationGroupLayer) return [];

      relationGroupLayer.shapeGroup.eachLayer(layer => {
        if (!isBadgeLayer(layer) || !layer.relationOptions) return;
        const { relationMid, objectMid, objectUpperRightCorner } = layer.relationOptions;
        if (layerMid !== objectMid) return;
        const isRelationBadgePresent = !badgeMids.includes(relationMid);
        const hasPositionChanged = objectUpperRightCorner !== this.getUpperRightCorner();
        if (
          badgesData.length <= 0 ||
          !isRelationBadgePresent ||
          isObjectHidden ||
          hasPositionChanged
        ) {
          this.removeBadge(layer, relationMid, relationGroupLayer);
          return;
        }
        relationMidsToKeep.push(relationMid);
      });
      return relationMidsToKeep;
    }

    updateBadgeRelation(relationGroup: KiliFeatureGroup, map: L.Map) {
      const mid = this.kiliOptions?.mid;
      if (!mid) return [];
      const state: State = store.getState();
      const imageRelationAnnotations = jobsImageRelationAnnotations(state);
      const temporaryRelationAnnotations = useStore
        .getState()
        .labelInterface.temporaryAnnotations.filter(ann =>
          isKiliAnnotationObjectRelation(ann),
        ) as ObjectRelation[];
      const filteredTemporaryAnnotationsFromCreatedRelation = temporaryRelationAnnotations.filter(
        ann => !imageRelationAnnotations.some(existingAnn => existingAnn.mid === ann.mid),
      );
      const { hiddenObjectIds } = useStore.getState().labelInterface;
      const badgesData = getRelationBadgesData(
        mid,
        [...imageRelationAnnotations, ...filteredTemporaryAnnotationsFromCreatedRelation],
        true,
      );

      const relationMidsToKeep = this.getRelationMidsToKeep(
        badgesData,
        hiddenObjectIds,
        relationGroup,
      );

      if (badgesData.length > 0) {
        return this.renderBadges(
          badgesData,
          hiddenObjectIds,
          relationMidsToKeep,
          relationGroup,
          map,
        ).filter(e => e);
      }
      return [];
    }

    getBadge(relationMid: string): BadgeLayer | undefined {
      const objectMid = this.kiliOptions?.mid;
      if (!objectMid) return undefined;
      const badgeLayer = badgeLayers
        .get(relationMid)
        ?.find(element => element.relationOptions?.objectMid === objectMid);
      return badgeLayer || undefined;
    }

    addSelectionContour(isHovered = false, isSelected = false) {
      // @ts-expect-error _renderer is not exposed
      if (this._renderer && !this._selectedUsePath) {
        const isLineTool =
          this.kiliOptions?.type === Tool.POLYLINE || this.kiliOptions?.type === Tool.VECTOR;

        const isPoseEstimationTool = this.kiliOptions?.type === Tool.POSE;

        const isMarkerTool = this.kiliOptions?.type === Tool.MARKER;

        this._selectedUsePath = L.SVG.create('use');
        this._selectedUsePath.setAttribute('stroke', '#2DC0FF');
        this._selectedUsePath.setAttribute('href', `#leaflet-path-${this._leaflet_id.toString()}`);
        this._selectedUsePath.style.setProperty('pointer-events', 'none');
        this._selectedUsePath.setAttribute('fill', 'none');

        if (isLineTool) {
          this._selectedUsePath.setAttribute('stroke-width', '1.5');
        } else if (isPoseEstimationTool) {
          this._selectedUsePath.setAttribute('stroke-width', '1.5');

          if (this.kiliOptions?.group?._layers) {
            Object.values(this.kiliOptions.group._layers).find(layerGroup => {
              const layers = Object.values(layerGroup._layers ?? {}) as KiliLayer[];
              const hasPoseEstimationLayerInLayers = layers.some(
                layer => layer instanceof L.Polyline,
              );
              if (hasPoseEstimationLayerInLayers) {
                layers
                  .filter(layer => layer instanceof L.CircleMarker)
                  .forEach(layer => {
                    layer.addSelectionContour(isHovered, isSelected);
                  });
              }
              return hasPoseEstimationLayerInLayers;
            });
          }
        } else if (isMarkerTool) {
          if (isSelected) {
            this._selectedUsePath.setAttribute('stroke', '#FFFFFF');
            this._selectedUsePath.setAttribute('fill', '#2DC0FF');
          } else if (isHovered) {
            this._selectedUsePath.setAttribute('stroke', '#2DC0FF');
            this._selectedUsePath.setAttribute('fill', '#FFFFFF');
          }
          this._selectedUsePath.setAttribute('stroke-width', '2');
        } else {
          // @ts-expect-error _renderer is not exposed
          this._renderer.createMaskForLayer(this);
          this._selectedUsePath.setAttribute('stroke-width', '7');
          this._selectedUsePath.setAttribute('mask', `url(#mask-${this._leaflet_id.toString()})`);
        }

        // @ts-expect-error _renderer is not exposed
        this._renderer.addUsePath(this._selectedUsePath);
      }
    }

    removeSelectionContour() {
      // @ts-expect-error _renderer is not exposed
      if (this._renderer && this._selectedUsePath) {
        // @ts-expect-error _renderer is not exposed
        this._renderer.removeMaskForLayer(this);
        // @ts-expect-error _renderer is not exposed
        this._renderer.removeUsePath(this._selectedUsePath);
        delete this._mask;
        delete this._selectedUsePath;

        const isPoseEstimationTool = this.kiliOptions?.type === Tool.POSE;

        if (isPoseEstimationTool) {
          if (this.kiliOptions?.group?._layers) {
            Object.values(this.kiliOptions.group._layers).find(layerGroup => {
              const layers = Object.values(layerGroup._layers ?? {}) as KiliLayer[];
              const hasPoseEstimationLayerInLayers = layers.some(
                layer => layer instanceof L.Polyline,
              );
              if (hasPoseEstimationLayerInLayers) {
                layers
                  .filter(layer => layer instanceof L.CircleMarker)
                  .forEach(layer => {
                    layer.removeSelectionContour();
                  });
              }
              return hasPoseEstimationLayerInLayers;
            });
          }
        }
      }
    }
  };
