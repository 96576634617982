import { type Job, type Jobs } from '@kili-technology/types';
import _get from 'lodash/get';

export type CategoryInfo = {
  code: string;
  jobName: string;
  name: string;
};

export type Category = {
  code: string;
  id: string;
  jobCode: string;
  jobName: string;
  name: string;
};

export type TreeStructuredCategory = {
  categoryCode?: string;
  categoryId: string;
  children: string[];
  jobCode: string;
  name: string;
  parent?: string;
};

export const concatCodes = (jobCode: string, categoryCode: string): string =>
  `${jobCode}.${categoryCode}`;

export const getParentFromCategory = (jobs: Job[] | Jobs, jobCodeSearched: string): string => {
  let parent = '';
  Object.entries(jobs).forEach(([jobCode, job]) => {
    const categories = _get(job, ['content', 'categories'], {});
    Object.entries(categories).forEach(([categoryCode, category]) => {
      const children = _get(category, 'children', [] as string[]);
      if (children.includes(jobCodeSearched)) {
        parent = concatCodes(jobCode, categoryCode);
      }
    });
  });
  return parent;
};

export const createCategoryJobIdsFromJobs = (
  jobs: Job[] | Jobs,
  childrenJobsOfCategory: string[],
): string[] => {
  const categoryIdList = [] as string[];
  childrenJobsOfCategory.forEach(jobCodeOfCategory => {
    Object.entries(jobs).forEach(([jobCode, job]) => {
      const categories = _get(job, ['content', 'categories'], {});
      Object.entries(categories).forEach(([categoryCode]) => {
        if (jobCode === jobCodeOfCategory)
          categoryIdList.push(concatCodes(jobCodeOfCategory, categoryCode));
      });
    });
  });
  return categoryIdList;
};

export const categoriesFromJobCodesList = (jobsList: string[], jobs: Job[] | Jobs): Category[] => {
  const allCategories: Category[] = [];
  Object.entries(jobs).forEach(([jobCode, job]) => {
    if (jobsList.includes(jobCode)) {
      const categories = _get(job, ['content', 'categories'], {});
      Object.entries(categories).forEach(([categoryCode, category]) => {
        const name = _get(category, 'name');
        allCategories.push({
          code: categoryCode,
          id: concatCodes(jobCode, categoryCode),
          jobCode,
          jobName: job.instruction,
          name,
        });
      });
    }
  });
  return allCategories;
};
