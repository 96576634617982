/**
 * This file modifies base class of Leaflet and Leaflet.draw
 * to add our customized behaviour
 */
/* eslint-disable no-underscore-dangle */

import L, { type LeafletMouseEvent } from 'leaflet';

import { UNDO_POINT_EVENT } from './KiliPoseEstimation/events';

import { APPLICATION_UPDATE_FIELD } from '../../../../../redux/application/slice';
import { store } from '../../../../../store';

const BasePolygon = L.Draw.Polygon;

L.Draw.Polyline = L.Draw.Polyline.extend({
  _onMouseDown(e: LeafletMouseEvent) {
    if (!this._clickHandled && !this._touchHandled && !this._disableMarkers) {
      this._onMouseMove(e);
      this._clickHandled = true;
      this._disableNewMarkers();
      const { originalEvent } = e;
      const { clientX } = originalEvent;
      const { clientY } = originalEvent;
      this._startPoint.call(this, clientX, clientY);
      originalEvent.stopPropagation();
    }
  },
});

L.Draw.Polygon = L.Draw.Polygon.extend({
  addHooks() {
    BasePolygon.prototype.addHooks?.call(this);
    if (this._map) {
      this._map.on('click', this.resetSavedLastPoint, this);
      this._map.doubleClickZoom.disable();
    }
    this._map.on(UNDO_POINT_EVENT, this.undoOrRedoPoint, this);
    this._map.on('mousedown touchstart', this.mouseDown, this);
  },

  initialize(map: L.DrawMap, options: L.DrawOptions.PolygonOptions) {
    if (!options) return;
    this.savedLastPoint = null;
    BasePolygon.prototype.initialize.call(this, map, options);

    this.setCanUndo = (canUndo: boolean) => {
      store.dispatch(APPLICATION_UPDATE_FIELD({ path: 'canUndo', value: canUndo }));
    };
  },

  mouseDown() {
    this.setCanUndo(false);
  },

  removeHooks() {
    this.setCanUndo(true);
    BasePolygon.prototype.removeHooks?.call(this);
    if (this._map) {
      this._map.off('click', this.resetSavedLastPoint, this);
      this._map.doubleClickZoom.enable();
    }
    this._map.off(UNDO_POINT_EVENT, this.undoOrRedoPoint, this);
    this._map.off('mousedown touchstart', this.mouseDown, this);
  },

  resetSavedLastPoint() {
    this.savedLastPoint = null;
  },

  undoOrRedoPoint(event: { shift: boolean }) {
    const { shift } = event;
    if (shift) {
      if (!this.savedLastPoint) return;
      this._poly.addLatLng(this.savedLastPoint._latlng);
      this._map.addLayer(this.savedLastPoint);
      this._markers.push(this.savedLastPoint);
      this.savedLastPoint = null;
      return;
    }

    const lastMarker = this._markers.pop();
    if (lastMarker) {
      this.savedLastPoint = lastMarker;
      this._map.removeLayer(lastMarker);
    }
    const latlngs = this._poly.getLatLngs();
    latlngs.pop();
    this._poly.setLatLngs(latlngs);
  },
});

// Overriding L.DomEvent to add fakeStop and skipped that has been removed in leaflet 1.8+,
// but are needed in leaflat-draw-drag
const skipEvents: Record<string, boolean> = {};

function fakeStop(e: L.LeafletEvent) {
  // fakes stopPropagation by setting a special event flag, checked/reset with skipped(e)
  skipEvents[e.type] = true;
}

function skipped(e: L.LeafletEvent) {
  const events = skipEvents[e.type];
  // reset when checking, as it's only used in map container and propagates outside of the map
  skipEvents[e.type] = false;
  return events;
}

// @ts-expect-error redefining fakeStop
L.DomEvent.fakeStop = fakeStop;
// @ts-expect-error redefining skipped
L.DomEvent.skipped = skipped;
