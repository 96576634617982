import _get from 'lodash/get';
import { type Dispatch } from 'redux';

import { SIGN_IN, SIGN_OUT } from './slice';
import { type SaveTokenPayload, type SignInPayload } from './types';

import { getApiEndpoint } from '../../config';
import { addNotification } from '../application/actions';
import { type AppThunk } from '../types';

export const signOut = (): AppThunk => {
  return async (dispatch: Dispatch) => {
    return dispatch({
      type: SIGN_OUT,
    });
  };
};

export const signIn = (payload: SignInPayload): AppThunk => {
  return async (dispatch: Dispatch, getState) => {
    const { email, password } = payload;
    const request = new Request(`${getApiEndpoint()}/authentication`, {
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      method: 'POST',
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(signInResult => {
        saveToken(signInResult)(dispatch, getState, null);
      })
      .catch(() => {
        dispatch(
          addNotification({
            message: 'Invalid email / password combination',
            variant: 'warning',
          }),
        );
      });
  };
};

export const saveToken = (payload: SaveTokenPayload): AppThunk => {
  return async (dispatch: Dispatch) => {
    const token = _get(payload, 'token');
    const email = _get(payload, 'user.email');
    if (token && email) {
      const variables = {
        email,
        token,
      };
      return dispatch({
        payload: variables,
        type: SIGN_IN,
      });
    }
    dispatch(
      addNotification({
        message: 'Invalid email / password combination',
        variant: 'warning',
      }),
    );
  };
};
