import { useQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { OrganizationRole, UserStatus } from '../../../__generated__/globalTypes';
import {
  type CountPendingUsersQuery,
  type CountPendingUsersQueryVariables,
} from '../../../graphql/user/__generated__/queries.graphql';
import {
  type PendingRequestCreatedOrUpdatedSubscription,
  type PendingRequestCreatedOrUpdatedSubscriptionVariables,
} from '../../../graphql/user/__generated__/subscriptions.graphql';
import { GQL_PENDING_USERS_COUNT } from '../../../graphql/user/queries';
import { ON_PENDING_REQUEST_CREATED_OR_UPDATED } from '../../../graphql/user/subscriptions';
import { rootRoutes } from '../../../pages/RootModule/RootPaths';
import { addNotification } from '../../../redux/application/actions';
import { user as userSelector } from '../../../redux/selectors';
import useIsInProject from '../hooks/useIsInProject';

export const usePendingRequestIcon = () => {
  const { push } = useHistory();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const isAdmin = user?.organizationRole === OrganizationRole.ADMIN;
  const { isInProject, isInProjectList } = useIsInProject();
  const [lastUpdatedData, setLastUpdatedData] = useState<number | undefined>(undefined);
  const navigateToOrganizationUsers = () => push(rootRoutes.ORGANIZATION_MEMBERS_ROUTE.path);

  const handleIconKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => e.preventDefault();

  const { data } = useQuery<CountPendingUsersQuery, CountPendingUsersQueryVariables>(
    GQL_PENDING_USERS_COUNT,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        where: {
          status: UserStatus.PENDING,
        },
      },
    },
  );
  useEffect(() => {
    if (isAdmin && isInProjectList && data && data.countUsers > 0)
      dispatch(
        addNotification({
          message:
            'Pending requests to join your organization. Review them by clicking on this notification',
          onClick: () => navigateToOrganizationUsers(),
          variant: 'info',
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isInProject, data]);

  const organizationId = user?.organizations?.[0].id ?? '';
  const { data: updatedData } = useSubscription<
    PendingRequestCreatedOrUpdatedSubscription,
    PendingRequestCreatedOrUpdatedSubscriptionVariables
  >(ON_PENDING_REQUEST_CREATED_OR_UPDATED, {
    variables: { input: { organization: organizationId, userID: user?.id ?? '' } },
  });

  useEffect(() => {
    if (typeof updatedData !== 'undefined') {
      setLastUpdatedData(updatedData.count);
    }
  }, [updatedData]);

  const numberOfPendingRequests =
    typeof lastUpdatedData !== 'undefined' ? lastUpdatedData : data?.countUsers;

  const showButton = !(!isAdmin || isInProject || numberOfPendingRequests === 0);
  return {
    handleIconClick: navigateToOrganizationUsers,
    handleIconKeyDown,
    numberOfPendingRequests,
    showButton,
    updatedData,
  };
};
