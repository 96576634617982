import Button from '@kili-technology/button';
import { CircledQuestionMark as CircledQuestionMarkIcon } from '@kili-technology/icons';
import Popper from '@kili-technology/popper';
import { useRef, useState } from 'react';

import useStyles from './HelpPopper.style';

import { TOPBAR_POPPER_PROFILE_ZINDEX } from '../../../constants/zIndexes';
import HelpMenu from '../HelpMenu';

interface PropsType {
  handleApiDocs: () => void;
  handleContactSupport: () => void;
  handleDemoProject: () => void;
  handleDocs: () => void;
  handleGettingStarted: () => void;
  isPaidLicense: boolean;
}

const HelpPopper = ({
  handleApiDocs,
  handleContactSupport,
  handleDemoProject,
  handleDocs,
  handleGettingStarted,
  isPaidLicense,
}: PropsType): JSX.Element => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  return (
    <>
      <div ref={buttonRef}>
        <Button
          className={classes.helpButton}
          data-cy="help-view-button"
          isSquare
          onClick={() => setIsHelpOpen(!isHelpOpen)}
          onKeyDown={e => e.preventDefault()}
          variant="secondary-ghost"
        >
          <CircledQuestionMarkIcon fill="var(--color-omega-accent-6)" />
        </Button>
      </div>
      <Popper
        anchorEl={buttonRef.current}
        data-cy="help-menu-list"
        onClose={() => {
          setIsHelpOpen(false);
        }}
        open={isHelpOpen}
        placement="bottom-end"
        style={{ zIndex: TOPBAR_POPPER_PROFILE_ZINDEX }}
      >
        <HelpMenu
          handleApiDocs={handleApiDocs}
          handleContactSupport={handleContactSupport}
          handleDemoProject={handleDemoProject}
          handleDocs={handleDocs}
          handleGettingStarted={handleGettingStarted}
          isPaidLicense={isPaidLicense}
        />
      </Popper>
    </>
  );
};

export default HelpPopper;
