import {
  type ObjectDetectionAnnotationValue,
  type VideoObjectDetectionAnnotation,
} from '@/__generated__/globalTypes';
import { broadcastQueriesAfterUpdatingCache, getCacheIdFromGraphQLObject } from '@/graphql/helpers';
import { type KiliAnnotation } from '@/services/jobs/setResponse';
import { useStore } from '@/zustand';
import { useHistoryStore } from '@/zustand-history';

import { findAnnotationInCache } from './cache/findAnnotationInCache';
import { computeVerticesFromKiliAnnotations } from './common';
import {
  createObjectDetectionAnnotationValue,
  createVideoObjectDetectionKeyAnnotation,
} from './data/factories/createVideoObjectDetectionKeyAnnotation';
import { isVideoObjectDetectionAnnotation } from './data/validators/isVideoObjectDetectionAnnotation';
import { toggleKeyFrameSplit } from './toggleKeyFrame';

import {
  addKeyAnnotationsInCache,
  isVideoKeyAnnotationExistingInCache,
  updateKeyAnnotationValueInCache,
} from '../actions';

export const updateAnnotationSplit = (
  currentAnnotation: VideoObjectDetectionAnnotation,
  newAnnotationValue: ObjectDetectionAnnotationValue,
  currentFrame: number,
  keyAnnotationId: string,
) => {
  const cacheKeyAnnotationId = getCacheIdFromGraphQLObject({
    __typename: 'VideoObjectDetectionKeyAnnotation',
    id: keyAnnotationId,
  });

  const existingKeyAnnotation = isVideoKeyAnnotationExistingInCache(cacheKeyAnnotationId);

  if (existingKeyAnnotation) {
    updateKeyAnnotationValueInCache(
      getCacheIdFromGraphQLObject(currentAnnotation),
      cacheKeyAnnotationId,
      newAnnotationValue,
    );
  } else {
    const newKeyAnnotation = createVideoObjectDetectionKeyAnnotation(
      {
        annotationValue: newAnnotationValue,
        frame: currentFrame,
      },
      currentAnnotation.id,
    );
    addKeyAnnotationsInCache(getCacheIdFromGraphQLObject(currentAnnotation), [newKeyAnnotation]);
  }
  broadcastQueriesAfterUpdatingCache();
};

export const updateAnnotationSplitWithHistory = (newKiliAnnotations: KiliAnnotation[]) => {
  if (newKiliAnnotations.length === 0) throw new Error('New kili annotations are empty');

  const { frame: currentFrame, selectedFrames } = useStore.getState().labelFrame;

  const { mid } = newKiliAnnotations[0];
  const currentAnnotation = findAnnotationInCache(
    (annotation): annotation is VideoObjectDetectionAnnotation =>
      isVideoObjectDetectionAnnotation(annotation) && annotation.mid === mid,
  );

  if (!currentAnnotation) throw new Error(`No annotation of mid ${mid} found`);

  const currentKeyAnnotation = currentAnnotation.keyAnnotations?.find(
    keyAnnotation => keyAnnotation.frame === currentFrame,
  );

  const currentAnnotationValue = currentKeyAnnotation?.annotationValue as
    | ObjectDetectionAnnotationValue
    | undefined;

  const keyAnnotationId = `${currentAnnotation.id}-${currentFrame}`;

  const newAnnotationValue = createObjectDetectionAnnotationValue({
    id: keyAnnotationId,
    isPrediction: false,
    vertices: computeVerticesFromKiliAnnotations(newKiliAnnotations),
  });

  const action = {
    name: 'updateAnnotationSplit',
    redo: () => {
      updateAnnotationSplit(currentAnnotation, newAnnotationValue, currentFrame, keyAnnotationId);
    },
    undo: () => {
      if (currentAnnotationValue) {
        updateAnnotationSplit(
          currentAnnotation,
          currentAnnotationValue,
          currentFrame,
          keyAnnotationId,
        );
      } else {
        toggleKeyFrameSplit(mid, selectedFrames);
      }
    },
  };

  action.redo();

  useHistoryStore.getState().history.addAction(action);
};
