import { type ApolloClient, type InMemoryCache } from '@apollo/client';

import { type ProjectMemberGateway } from './projectMemberGateway';

import {
  type Exact,
  type ProjectUserData,
  type ProjectUserWhere,
} from '../../__generated__/globalTypes';
import { type UpdatePropertiesInProjectUserMutation } from '../../graphql/projectUser/__generated__/mutations.graphql';
import {
  type ProjectUsersMembersQuery,
  type ProjectUsersMembersQueryVariables,
} from '../../graphql/projectUser/__generated__/queries.graphql';
import { GQL_UPDATE_PROPERTIES_IN_PROJECT_USER } from '../../graphql/projectUser/mutations';
import { GQL_PROJECT_USERS_MEMBERS } from '../../graphql/projectUser/queries';

class ApolloProjectMemberGateway implements ProjectMemberGateway {
  constructor(private client: ApolloClient<InMemoryCache>) {
    this.client = client;
  }

  async read(
    queryCriteria: ProjectUsersMembersQueryVariables,
  ): Promise<{ data: ProjectUsersMembersQuery; loading: boolean }> {
    return this.client.query<ProjectUsersMembersQuery>({
      context: { clientName: 'V2' },
      fetchPolicy: 'network-only',
      query: GQL_PROJECT_USERS_MEMBERS,
      variables: queryCriteria,
    });
  }

  async update(query: Exact<{ data: ProjectUserData; where: ProjectUserWhere }>) {
    return this.client.mutate<UpdatePropertiesInProjectUserMutation>({
      context: { clientName: 'V2' },
      mutation: GQL_UPDATE_PROPERTIES_IN_PROJECT_USER,
      variables: query,
    });
  }
}

export default ApolloProjectMemberGateway;
