import { Tool } from '@kili-technology/types';
import L from 'leaflet';

import {
  areGeometriesEqual,
  getAllowedAnnotationPointsWithinImage,
  type LatLngGeometry,
} from './geometry';

const updateLayerGeometry = (
  layer: L.Layer,
  latlngs: LatLngGeometry,
  latlngsWithinImage: LatLngGeometry,
) => {
  if (layer instanceof L.CircleMarker) {
    const noChangeNecessary = areGeometriesEqual(latlngsWithinImage, latlngs);
    if (noChangeNecessary) return;
    layer.setLatLng((latlngsWithinImage as L.LatLng[])[0]);
    return;
  }
  if (layer instanceof L.Polyline) {
    const noChangeNecessary = areGeometriesEqual(latlngsWithinImage, latlngs);
    if (noChangeNecessary) return;
    layer.setLatLngs(latlngsWithinImage as L.LatLng[] | L.LatLng[][]);
  }
};

const fitRectangleToBounds = (layer: L.Rectangle, map: L.Map) => {
  const latlngs = layer.getLatLngs()[0] as L.LatLng[];
  const latlngsWithinImage = getAllowedAnnotationPointsWithinImage({
    latlngs,
    map,
    type: Tool.RECTANGLE,
  });

  updateLayerGeometry(layer, latlngs, latlngsWithinImage);
};

const fitPolygonToBounds = (layer: L.Polygon, map: L.Map) => {
  const latlngs = layer.getLatLngs() as L.LatLng[][];
  const latlngsWithinImage = latlngs.map(latlngPolygon =>
    getAllowedAnnotationPointsWithinImage({
      latlngs: latlngPolygon,
      map,
      type: Tool.POLYGON,
    }),
  );
  updateLayerGeometry(layer, latlngs, latlngsWithinImage);
};

const fitPolylineToBounds = (layer: L.Polyline, map: L.Map) => {
  const latlngs = layer.getLatLngs() as L.LatLng[];
  const latlngsWithinImage = getAllowedAnnotationPointsWithinImage({
    latlngs,
    map,
    type: Tool.POLYLINE,
  });
  updateLayerGeometry(layer, latlngs, latlngsWithinImage);
};

const fitMarkerToBounds = (layer: L.CircleMarker, map: L.Map) => {
  const latlngs = [layer.getLatLng()] as L.LatLng[];
  const latlngsWithinImage = getAllowedAnnotationPointsWithinImage({
    latlngs,
    map,
    type: Tool.POLYLINE,
  });
  updateLayerGeometry(layer, latlngs, latlngsWithinImage);
};

export default function fitLayerToBounds(layer: L.Layer, map: L.Map) {
  if (layer instanceof L.Rectangle) {
    fitRectangleToBounds(layer, map);
    return;
  }
  if (layer instanceof L.Polygon) {
    fitPolygonToBounds(layer, map);
    return;
  }
  if (layer instanceof L.Polyline) {
    fitPolylineToBounds(layer, map);
    return;
  }
  if (layer instanceof L.CircleMarker) {
    fitMarkerToBounds(layer, map);
  }
}
