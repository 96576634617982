import { type Reference } from '@apollo/client';

import { ApolloClientProvider } from '../components/AuthorizedApolloProvider/AuthorizedApolloProvider';

export const getApolloClient = () => {
  const client = ApolloClientProvider.getClient();

  if (!client) throw new Error('Apollo client not found');

  return client;
};

export const clearCache = () => {
  const client = getApolloClient();
  client.cache.gc();
};

export type GraphQLObject = {
  __typename: string;
  id: string;
};

export const getCacheIdFromGraphQLObject = (object: GraphQLObject | Reference) => {
  const client = getApolloClient();
  const cacheId = client.cache.identify(object);
  if (!cacheId) throw new Error('Cannot find a cache id for this annotation');
  return cacheId;
};

export const getObjectIdFromCache = (cacheId: string) => {
  const splitId = cacheId.split(':');
  if (splitId.length !== 2) throw new Error('Cannot find an id from this cacheId');
  return splitId[1];
};

export const broadcastQueriesAfterUpdatingCache = () => {
  const client = getApolloClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (client as any).queryManager.broadcastQueries();
};
