import { connect, type ConnectedProps } from 'react-redux';

import StyledNotificationPopper from './NotificationPopper.gql';

import { userId } from '../../../redux/selectors';
import { type State } from '../../../redux/types';

const mapStateToProps = (state: State) => ({
  userID: userId(state),
});

const connector = connect(mapStateToProps);

export type ReduxPropsType = ConnectedProps<typeof connector>;

export default connector(StyledNotificationPopper);
