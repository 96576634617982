import { css } from '@emotion/css';

const useStyles = () => {
  return {
    container: css`
      display: flex;
    `,
    organizationAvatar: css`
      height: 240px;
      overflow-y: scroll;
      overflow-x: scroll;

      .organization-item {
        display: flex;
        height: var(--space-6);
        align-items: center;
        padding: var(--space-2);
        transition: all 0.2s ease-out;

        & > span {
          margin-right: var(--space-2);
          margin-left: var(--space-2);
        }

        &:hover {
          background: var(--color-omega-accent-2);
          cursor: pointer;
        }
      }
    `,
    popperContainer: css`
      position: fixed;
      background-color: var(--color-omega-accent-0);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
      border-radius: var(--br-6);
    `,
    userCompany: css`
      font-family: 'Archia';
      color: var(--color-omega-accent-6);
    `,
    userLicense: css`
      display: flex;
      gap: var(--space-2);
      align-items: center;
      justify-content: center;
      padding: var(--space-2) var(--space-4);
    `,
    userLicenseText: css`
      color: var(--color-omega-accent-5);
    `,
    userLicenseTypeText: css`
      flex: 2;
      color: var(--color-beta-accent-6);
    `,
    userMenuItem: css`
      height: var(--space-8);
      justify-content: start;
      padding: var(--space-2) var(--space-4);
    `,
    userMenuListContainer: css`
      color: var(--color-omega-accent-9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin-left: var(--space-2);
    `,
    userMenuListGrow: css`
      box-sizing: content-box;
      display: flex;
      justify-content: space-between;
      text-transform: none;
    `,
    userMenuListItem: css`
      margin-right: var(--space-3);
    `,
    userMenuListOpen: css`
      display: flex;
      flex-direction: column;
      padding: var(--space-2) var(--space-4);
      justify-content: center;
    `,
    userMenuTitle: css`
      height: 1px;
      background-color: var(--color-omega-accent-2);
      border: none;
    `,
    userName: css`
      margin-left: var(--space-2);
      margin-right: theme.spacing(2);
      text-transform: capitalize;
    `,
    userOpenCompany: css`
      color: var(--color-omega-accent-5);
    `,
    userOpenContainer: css`
      display: flex;
      flex-direction: column;
      height: 100px;
      width: 230px;
      justify-content: center;
      align-items: center;
      background-color: var(--color-omega-accent-1);
      & > span {
        margin-top: var(--space-2);
      }
    `,
  };
};

export default useStyles;
