import { IS_KEY_FRAME } from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { type ObjectPositionInfo } from '@/services/assets/video';
import {
  createChildVideoJobObjectInFrameResponse,
  createObjectInFrameResponse,
} from '@/services/jobs/create';
import { removeObjectsInFramesFromFrameResponses } from '@/services/jobs/delete';
import { type KiliAnnotation } from '@/services/jobs/setResponse';
import {
  updateChildVideoJobObjectInFrameResponse,
  updateObjectInFrameResponse,
} from '@/services/jobs/update';

import { updateSubJob } from './updateSubJob';

import { type ChildVideoJob, type FrameResponses } from '../../label-frames/types';
import { type FlatTree } from '../../project/ontologyTree';
import { type KeyAnnotation } from '../helpers';

export const setNewVideoJobInFrameResponses = (
  keyAnnotations: KeyAnnotation[],
  objectInfo: ObjectPositionInfo,
  responses: FrameResponses,
  flatTree: FlatTree,
  mid?: string,
) => {
  let newResponses = { ...responses };

  const firstKeyAnnotation = keyAnnotations.find(
    keyAnnotation => keyAnnotation.keyFrame === objectInfo.keyFrames[0],
  );

  if (firstKeyAnnotation && firstKeyAnnotation.job) {
    const newAnnotation = { ...firstKeyAnnotation.job };
    if (IS_KEY_FRAME in newAnnotation) delete newAnnotation[IS_KEY_FRAME];

    const startFrame = objectInfo.frames[0];
    const endFrame = objectInfo.frames.slice(-1)[0];

    if (mid !== undefined) {
      newResponses = createObjectInFrameResponse(
        startFrame,
        endFrame - startFrame,
        newAnnotation as KiliAnnotation,
        newResponses,
      );
    } else {
      newResponses = createChildVideoJobObjectInFrameResponse(
        startFrame,
        endFrame - startFrame,
        objectInfo.jobName,
        newAnnotation as ChildVideoJob,
        newResponses,
      );
    }

    if (firstKeyAnnotation.subJobs) {
      firstKeyAnnotation.subJobs.forEach(subJob => {
        newResponses = updateSubJob(
          firstKeyAnnotation.keyFrame,
          subJob.subJobName,
          subJob.subJobValue,
          endFrame,
          newResponses,
          flatTree,
          null,
          mid,
        );
      });
    }

    const totalFramesArray = Array.from(
      { length: Math.max(...objectInfo.frames) - Math.min(...objectInfo.frames) },
      (_, index) => Math.min(...objectInfo.frames) + index,
    );
    const removedFramesArray = totalFramesArray.filter(frame => !objectInfo.frames.includes(frame));
    if (mid !== undefined) {
      newResponses = removeObjectsInFramesFromFrameResponses(
        removedFramesArray,
        mid,
        objectInfo.jobName,
        newResponses,
      );
    }

    keyAnnotations.slice(1).forEach(keyAnnotation => {
      if (keyAnnotation.job) {
        if (mid !== undefined) {
          newResponses = updateObjectInFrameResponse(
            keyAnnotation.keyFrame,
            keyAnnotation.job as KiliAnnotation,
            newResponses,
          );
        } else {
          newResponses = updateChildVideoJobObjectInFrameResponse(
            keyAnnotation.keyFrame,
            objectInfo.jobName,
            keyAnnotation.job as ChildVideoJob,
            newResponses,
          );
        }
      }
      if (keyAnnotation.subJobs) {
        keyAnnotation.subJobs.forEach(subJob => {
          newResponses = updateSubJob(
            keyAnnotation.keyFrame,
            subJob.subJobName,
            subJob.subJobValue,
            endFrame,
            newResponses,
            flatTree,
            null,
            mid,
          );
        });
      }
    });
  }
  return newResponses;
};
