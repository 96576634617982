/* eslint-disable max-classes-per-file */

/* eslint-disable no-underscore-dangle */
import { Tool, type PoseEstimationPoint } from '@kili-technology/types';
import L from 'leaflet';

import './KiliPoseEstimation.css';

import { type LayerPoseOptions } from './BasePose';
import type Pose from './BasePose';

import { clickOnLayerWithEventHandler } from '../../ImageAnnotations/layerEventHandlers';
import { getAllowedAnnotationPointsWithinImage } from '../../helpers/geometry';
import KiliMarker from '../KiliMarkerLayer/KiliMarkerLayer';
import { KiliWrapper } from '../KiliWrapper';
import { areObjectsLocked } from '../helpers/areObjectsLocked';
import {
  type HiddableLayer,
  type KiliAnnotationWithGeometry,
  type KiliOptions,
  type PoseAnnotation,
} from '../types';

type PoseOptions = {
  allPoints: PoseEstimationPoint[];
  pointIndicesDrawn: number[];
};

export class KiliPoseEstimationMarker extends L.Marker {
  name: string;

  kiliOptions: KiliOptions;

  constructor(latlng: L.LatLng, options: L.MarkerOptions, name: string, kiliOptions: KiliOptions) {
    super(latlng, options);
    this.name = name;
    this.kiliOptions = kiliOptions;
  }
}

export class PoseEstimation extends KiliWrapper(L.Polyline) {
  poseOptions: PoseOptions;

  kiliOptions: KiliOptions;

  markerGroup?: L.LayerGroup<KiliPoseEstimationMarker>;

  editing?: L.Edit.PoseEstimation;

  isFinished?: boolean;

  constructor(layer: Pose, options: KiliOptions) {
    const latLngBounds = layer.getLatLngs() as L.LatLng[];
    const { allPoints, pointIndicesDrawn, isFinished } = layer.options as LayerPoseOptions;
    super(latLngBounds, options);
    super.initializeKili(options);
    this.kiliOptions = options;
    this.updateEditMarkerIcon();
    this.poseOptions = {
      allPoints,
      pointIndicesDrawn,
    };
    this.isFinished = isFinished;

    if (areObjectsLocked()) {
      this.editing = undefined;
      return;
    }

    this.editing = new L.Edit.PoseEstimation(this);
  }

  _setLatLngs(latlngs: L.LatLng[], save: boolean) {
    super._setLatLngs(latlngs, save);
    this.resetMarkers();
    if (this.editing) {
      this.editing._initHandlers();
    }
  }

  setPointIndicesDrawn(newPointIndicesDrawn: number[]) {
    this.poseOptions = { ...this.poseOptions, pointIndicesDrawn: newPointIndicesDrawn };
  }

  setPoseFromAnnotation(latlngs: L.LatLng[], annotation: PoseAnnotation) {
    const { allPoints, points } = annotation;
    if (!allPoints) return;
    const annotationPointCodes = (points ?? []).map(point => point.code);
    const pointIndicesDrawn = [...Array(allPoints.length).keys()].filter(index =>
      annotationPointCodes.includes(allPoints.at(index)?.code ?? ''),
    );
    this.setPointIndicesDrawn(pointIndicesDrawn);
    const isFinished = pointIndicesDrawn.at(-1) === allPoints.length - 1;

    this.isFinished = isFinished;
    this._setLatLngs(latlngs, false);
  }

  fitToBounds() {
    const latlngs = this.getLatLngs() as L.LatLng[];
    const latlngsWithinImage = getAllowedAnnotationPointsWithinImage({
      latlngs,
      map: this.map,
      type: this.kiliOptions?.type,
    });
    const noChangeNecessary = latlngs
      .map((latlng, index) => latlng.equals(latlngsWithinImage[index], 1e-3))
      .reduce((prev, curr) => prev && curr, true);
    if (!noChangeNecessary) {
      this.setLatLngs(latlngsWithinImage);
    }
    return noChangeNecessary;
  }

  initMarkers() {
    const latlngs = this.getLatLngs() as L.LatLng[];
    latlngs.forEach(latlng => {
      const marker = new KiliMarker(latlng, {
        ...this.kiliOptions,
        className: 'kili-pose-estimation-marker',
        isPoseEstimationMarker: true,
        type: Tool.MARKER,
      });
      marker.on('click', event => {
        clickOnLayerWithEventHandler(event, this as HiddableLayer);
      });
      if (this.markerGroup) {
        this.markerGroup.addLayer(marker);
      }
    });
  }

  addHooks() {
    if (this.map) {
      this.markerGroup = new L.LayerGroup();
      this.initMarkers();
      if (this.markerGroup) {
        this.map.addLayer(this.markerGroup);
      }
    }
    if (this._path) {
      L.DomUtil.addClass(this._path, 'leaflet-pose-estimation-line');
    }
  }

  resetMarkers() {
    if (this.markerGroup) {
      this.map.removeLayer(this.markerGroup);
    }
    delete this.markerGroup;
    if (this.map) {
      this.markerGroup = new L.LayerGroup();
      this.initMarkers();
      if (this.markerGroup) {
        this.map.addLayer(this.markerGroup);
      }
    }
    if (this.poseOptions) {
      this.editing = new L.Edit.PoseEstimation(this);
    }
  }

  removeHooks() {
    if (this.markerGroup) {
      this.map.removeLayer(this.markerGroup);
    }
    delete this.markerGroup;
  }

  canKeepDrawing(): boolean {
    const { allPoints, pointIndicesDrawn } = this.poseOptions;
    if (!pointIndicesDrawn) return false;
    const lastIndexDrawn = pointIndicesDrawn.at(-1) ?? 0;
    return lastIndexDrawn < allPoints.length - 1;
  }

  getPointsDrawn() {
    const { allPoints, pointIndicesDrawn } = this.poseOptions;
    return pointIndicesDrawn.map(indexDrawn => allPoints.at(indexDrawn));
  }

  getAnnotation(): KiliAnnotationWithGeometry {
    const annotation = super.getAnnotation();
    const { polyline, ...cleanAnnotation } = annotation;
    const { allPoints, pointIndicesDrawn } = this.poseOptions;
    const polylineFromLatLngs = this.getPolyline() ?? [];
    const pointsDrawn = pointIndicesDrawn.map((indexDrawn, index) => ({
      code: allPoints.at(indexDrawn)?.code,
      point: polylineFromLatLngs.at(index),
    }));
    return {
      ...cleanAnnotation,
      allPoints,
      points: pointsDrawn,
    } as KiliAnnotationWithGeometry;
  }
}
