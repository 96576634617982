import { createSelector } from 'reselect';

import { type GenericState, LoadingFlag } from './types';

import { type State } from '../../types';

export function createGenericSelectorsFlag<T>(name: keyof State) {
  const flagState = createSelector(
    [(state: State) => state[name] as GenericState<T>],
    subState => subState,
  );

  return {
    /* eslint-disable no-bitwise */
    isError: createSelector([flagState], state => Boolean(state.flags & LoadingFlag.IsError)),
    isLoading: createSelector([flagState], state => Boolean(state.flags & LoadingFlag.IsLoading)),
    /* eslint-enable no-bitwise */
  };
}
