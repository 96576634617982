import 'leaflet-arrowheads';

import { type CurvePathDataElement } from '@elfalem/leaflet-curve';
import { css, cx } from '@emotion/css';
import { SelectorClass } from '@kili-technology/cursors';
import {
  LabelVersion,
  MachineLearningTask,
  type ObjectAnnotation,
  type ObjectAnnotation1D,
  type ObjectAnnotation2D,
  type ObjectAnnotationMarker,
  type ObjectRelation,
  Tool,
  type AnnotationCategory,
} from '@kili-technology/types';
import L from 'leaflet';

import { memoizedGetColorForCategories } from '@/components/helpers';

import type KiliFeatureGroup from './KiliFeatureGroup';
import {
  isObjectAnnotationMarker,
  isObjectAnnotationPolygon,
  isObjectAnnotationPolyline,
  isObjectAnnotationPose,
  isObjectAnnotationRectangle,
  isObjectAnnotationSemantic,
  isObjectAnnotationVector,
  type KiliLayer,
  type LayerAnnotation,
  type LayerAnnotation0D,
  type LayerAnnotation1D,
  type LayerAnnotation2D,
  layerIsPolygon,
  layerIsPolyline,
  layerIsRectangle,
  type LayerOptions,
  type PoseAnnotation,
  type PolygonAnnotation,
  type SemanticAnnotation,
} from './types';

import { type InputType } from '../../../../../__generated__/globalTypes';
import { type KiliAnnotationProvider } from '../../../../../services/jobs/setResponse';
import { getPositionBadgeByMidBbox } from '../ImageAnnotations/helpers';

import kiliLayers from './index';

const ARROW_ZOOM_DEFAULT = 10; // This one is never used as the map always has bounds when reaching the functions but is necessary to avoid errors
const ARROW_HEAD_LENGTH = 8; // It represents the length of the arrow head with the initial zoom
const OFFSET_DISTANCE = 10; // It represents the distance from badge to end of arrow with the initial zoom
const LIMIT_LATITUDE_DIFFERENCE = 40; // It represents the default difference in pixels (with the initial zoom) used to define which kind of shape the arrow will have
const CURVE_ARROW_HORIZONTAL_ALIGN = 40; // It represents the number of vertical pixels used for the control points of the curve when shapes are vertically close

type LatLngId = {
  latLngs: L.LatLng | L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | undefined;
  mpid?: string;
};

type Positions = {
  isPosePoint?: boolean;
  lat: number;
  lng: number;
};

export const getLatLngsFromLayerWithLayerId = (
  annotation: LayerAnnotation,
  map: L.Map,
): LatLngId | undefined => {
  const { type } = annotation;
  switch (type) {
    case Tool.POLYGON:
    case Tool.RECTANGLE:
    case Tool.SEMANTIC: {
      const { boundingPoly } = annotation as LayerAnnotation2D;
      const layer = kiliLayers.getLayerFromBoundingPoly(boundingPoly, map);
      return {
        latLngs: layer.getLatLngs(),
      };
    }
    case Tool.POLYLINE:
    case Tool.VECTOR: {
      const { polyline } = annotation as LayerAnnotation1D;
      const layer = kiliLayers.getLayerFromPolyline(polyline, map);
      return {
        latLngs: layer.getLatLngs(),
      };
    }
    case Tool.MARKER: {
      const { point } = annotation as LayerAnnotation0D;
      if (point) {
        const layer = kiliLayers.getLayerFromMarker(point, map);
        return {
          latLngs: layer.getLatLng(),
        };
      }
      break;
    }
    case Tool.POSE: {
      const { points } = annotation as PoseAnnotation;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const polyline = points.map(point => point.point!);
      const layer = kiliLayers.getLayerFromPolyline(polyline, map);
      return {
        latLngs: layer.getLatLngs(),
      };
    }
    default:
      return undefined;
  }
  return undefined;
};

export const getLatLngsFromLayer = (
  annotation: LayerAnnotation,
  map: L.Map,
): L.LatLng | L.LatLng[] | L.LatLng[][] | L.LatLng[][][] | undefined => {
  return getLatLngsFromLayerWithLayerId(annotation, map)?.latLngs;
};

const getGeometryFromAnnotation = (annotation: LayerAnnotation) => {
  const boundingPoly =
    layerIsPolygon(annotation) || layerIsRectangle(annotation)
      ? annotation.boundingPoly
      : undefined;
  const polyline = layerIsPolyline(annotation) ? annotation.polyline : undefined;
  return { boundingPoly, polyline };
};

export const getLayerOptionsFromAnnotation = (annotation: LayerAnnotation): LayerOptions => {
  const {
    categories,
    inputType,
    isInteractiveSegmentationMarker,
    isNegativeInteractiveSegmentationMarker,
    jobName,
    labelVersion,
    mid,
    type,
  } = annotation;
  const dashArrayProperty = labelVersion === LabelVersion.PREDICTION ? '10,10' : '';
  const geometry = getGeometryFromAnnotation(annotation);

  return {
    ...geometry,
    categories,
    dashArrayProperty,
    inputType,
    isInteractiveSegmentationMarker,
    isNegativeInteractiveSegmentationMarker,
    jobName,
    mid,
    mlTask: MachineLearningTask.OBJECT_DETECTION,
    type,
  };
};

export const getLayerFromAnnotation = (
  annotation: KiliAnnotationProvider<ObjectAnnotation>,
  leafletOptions: { featureGroup?: KiliFeatureGroup; map: L.Map },
  inputType: InputType,
): KiliLayer | undefined => {
  if (isObjectAnnotationRectangle(annotation)) {
    return kiliLayers.annotationToKiliRectangle(
      {
        ...annotation,
        inputType,
        type: Tool.RECTANGLE,
      },
      leafletOptions,
    );
  }
  if (isObjectAnnotationPolygon(annotation)) {
    return kiliLayers.annotationToKiliPolygon(
      {
        ...(annotation as PolygonAnnotation | SemanticAnnotation),
        inputType,
        type: Tool.POLYGON,
      },
      leafletOptions,
    );
  }
  if (isObjectAnnotationPolyline(annotation)) {
    return kiliLayers.annotationToKiliPolyline(
      {
        ...(annotation as PolygonAnnotation | SemanticAnnotation),
        inputType,
        type: Tool.POLYLINE,
      },
      leafletOptions,
    );
  }
  if (isObjectAnnotationSemantic(annotation)) {
    return kiliLayers.annotationToKiliPolygon(
      {
        ...(annotation as PolygonAnnotation | SemanticAnnotation),
        inputType,
        type: Tool.SEMANTIC,
      },
      leafletOptions,
    );
  }
  if (isObjectAnnotationVector(annotation)) {
    return kiliLayers.annotationToKiliVector(
      {
        ...(annotation as PolygonAnnotation | SemanticAnnotation),
        inputType,
        type: Tool.VECTOR,
      },
      leafletOptions,
    );
  }
  if (isObjectAnnotationMarker(annotation)) {
    return kiliLayers.annotationToKiliMarker(
      {
        ...(annotation as PolygonAnnotation | SemanticAnnotation),
        inputType,
        type: Tool.MARKER,
      },
      leafletOptions,
      true,
    );
  }
  if (isObjectAnnotationPose(annotation)) {
    return kiliLayers.annotationToKiliPose(
      {
        ...(annotation as PolygonAnnotation | SemanticAnnotation),
        inputType,
        type: Tool.POSE,
      } as PoseAnnotation,
      leafletOptions,
    );
  }
  return undefined;
};

export const layerWithHandlers = <T extends L.Evented & { save: () => void }>(
  layer: KiliLayer,
): T => {
  layer.on('edit', () => {
    if (!layer.kiliOptions) {
      return;
    }
    const noChangeNecessary = layer.fitToBounds();
    if (!noChangeNecessary) {
      layer.redraw();
      layer.resetEdit();
    }
    layer.save();
  });

  layer.on('add', () => {
    layer.addHooks();
  });

  layer.on('remove', () => {
    layer.removeHooks();
  });

  // @ts-expect-error return type must be T
  return layer;
};

const computeRectangleTagPosition = (annotations: ObjectAnnotation2D[], map: L.Map) => {
  const bottomCoords = annotations
    .map(annotation => kiliLayers.getLayerFromBoundingPoly(annotation.boundingPoly, map))
    .filter(d => !!d)
    .flat(2)
    .map(d => d.getLatLngs())
    .flat(3)
    .map(({ lat, lng }) => ({ lat, lng }))
    .reduce((prev, current) => {
      return {
        lat: prev.lat < current.lat ? prev.lat : current.lat,
        lng: prev.lng < current.lng ? prev.lng : current.lng,
      };
    });

  return [bottomCoords];
};

type GetCurveMiddlePointParams = {
  controlPoint1: L.LatLngTuple;
  controlPoint2: L.LatLngTuple;
  endLatLng: L.LatLng;
  startLatLng: L.LatLng;
};

export const getCurveMiddlePoint = ({
  startLatLng,
  endLatLng,
  controlPoint1,
  controlPoint2,
}: GetCurveMiddlePointParams) => {
  const controlPointAvgLat = (controlPoint1[0] + controlPoint2[0]) / 2;
  const controlPointAvgLng = (controlPoint1[1] + controlPoint2[1]) / 2;

  const t = 0.5;
  const midLat =
    (1 - t) ** 2 * startLatLng.lat + 2 * (1 - t) * t * controlPointAvgLat + t ** 2 * endLatLng.lat;
  const midLng =
    (1 - t) ** 2 * startLatLng.lng + 2 * (1 - t) * t * controlPointAvgLng + t ** 2 * endLatLng.lng;

  return L.latLng(midLat, midLng);
};

const computeMarkerTagPosition = (annotations: ObjectAnnotationMarker[], map: L.Map) => {
  // eslint-disable-next-line
  const coords = kiliLayers.getLayerFromMarker(annotations[0].point!, map);
  return [coords.getLatLng()];
};

const computeRelationTagInMiddlePosition = (annotation: ObjectRelation, map: L.Map) => {
  const startLatLng = getPositionBadgeByMidBbox(
    annotation.mid,
    annotation.startObjects[0].mid,
  )?.getLatLng();
  const endLatLng = getPositionBadgeByMidBbox(
    annotation.mid,
    annotation.endObjects[0].mid,
  )?.getLatLng();
  if (startLatLng && endLatLng) {
    const { adjustedStartLatLng, adjustedEndLatLng } = getAdjustedStartEndLatLng(
      startLatLng,
      endLatLng,
      map,
    );

    const listControlPoint: L.LatLngExpression[] = computeCurveRelationArrow(
      startLatLng,
      endLatLng,
      map,
    );

    const controlPoint1: L.LatLngTuple = listControlPoint[0] as L.LatLngTuple;
    const controlPoint2: L.LatLngTuple = listControlPoint[1] as L.LatLngTuple;

    const curveMiddlePoint = getCurveMiddlePoint({
      controlPoint1,
      controlPoint2,
      endLatLng: adjustedEndLatLng,
      startLatLng: adjustedStartLatLng,
    });

    return [curveMiddlePoint];
  }

  return [];
};

const computeRelationTagInStartPosition = (annotation: ObjectRelation) => {
  const positionBadgeStart = getPositionBadgeByMidBbox(
    annotation.mid,
    annotation.startObjects[0].mid,
  );
  const coords = positionBadgeStart?.getLatLng() as L.LatLng;

  if (!coords) return [];
  return [coords];
};

const computeRelationTagPosition = (
  annotations: ObjectAnnotation[] | ObjectRelation[],
  map: L.Map,
  isMultipleRelation?: boolean,
) => {
  const annotation = annotations[0] as ObjectRelation;
  return isMultipleRelation
    ? computeRelationTagInStartPosition(annotation)
    : computeRelationTagInMiddlePosition(annotation, map);
};

const computePoseEstimationTagsPositions = (
  annotations: PoseAnnotation[],
  map: L.Map,
): Positions[] => {
  const annotation = annotations[0];
  const coords = annotation?.points
    // eslint-disable-next-line
    .map(point => kiliLayers.getLayerFromPolyline([point.point!], map))
    .filter(d => !!d)
    .map(d => d.getLatLngs())
    .flat(3);

  const basePoint = coords[0]
    ? [
        {
          lat: coords[0].lat,
          lng: coords[0].lng,
        },
      ]
    : [];
  return [
    ...basePoint,
    ...coords.map(d => {
      return {
        isPosePoint: true,
        lat: d.lat,
        lng: d.lng,
      };
    }),
  ];
};

const computePolylineTagPosition = (annotations: ObjectAnnotation1D[], map: L.Map) => {
  const coords = annotations
    .map(annotation => kiliLayers.getLayerFromPolyline(annotation.polyline, map))
    .filter(d => !!d)[0]
    .getLatLngs()
    .flat(2);
  return [
    {
      lat: coords && coords[0].lat ? coords[0].lat : 0,
      lng: coords && coords[0].lng ? coords[0].lng : 0,
    },
  ];
};

const computePolygonTagPosition = (annotations: ObjectAnnotation2D[], map: L.Map) => {
  const coords = annotations
    .map(annotation => kiliLayers.getLayerFromBoundingPoly(annotation.boundingPoly, map))
    .filter(d => !!d)
    .map(layer => layer.getLatLngs && layer.getLatLngs()?.flat(2))
    .flat(2)
    .filter(d => !!d)
    .reduce(
      (prev, current: L.LatLng) => {
        return {
          maxLat: prev.maxLat && prev.maxLat > current.lat ? prev.maxLat : current.lat,
          maxLng: prev.maxLng && prev.maxLng > current.lng ? prev.maxLng : current.lng,
          minLat: prev.minLat && prev.minLat < current.lat ? prev.minLat : current.lat,
          minLng: prev.minLng && prev.minLng < current.lng ? prev.minLng : current.lng,
        };
      },
      { maxLat: 0, maxLng: 0, minLat: 0, minLng: 0 },
    );
  return [
    {
      lat: coords ? (coords.maxLat - coords.minLat) / 2 + coords.minLat : 0,
      lng: coords ? (coords.maxLng - coords.minLng) / 2 + coords.minLng : 0,
    },
  ];
};

export const computeTagPosition = (
  annotations: LayerAnnotation[],
  map: L.Map,
  isMultipleRelation?: boolean,
): Positions[] => {
  // eslint-disable-next-line
  const [mainAnnotation, _] = annotations;
  switch (mainAnnotation.type) {
    case Tool.POLYGON:
    case Tool.SEMANTIC:
      return computePolygonTagPosition(annotations as ObjectAnnotation2D[], map);
    case Tool.POLYLINE:
    case Tool.VECTOR:
      return computePolylineTagPosition(annotations as ObjectAnnotation1D[], map);
    case Tool.RANGE:
    case Tool.MARKER:
      return computeMarkerTagPosition(annotations as ObjectAnnotationMarker[], map);
    case Tool.POSE:
      return computePoseEstimationTagsPositions(annotations as PoseAnnotation[], map);
    case Tool.RECTANGLE:
      return computeRectangleTagPosition(annotations as ObjectAnnotation2D[], map);
    default:
      if ((annotations[0] as LayerOptions)?.mlTask !== 'OBJECT_RELATION') {
        throw new Error(`The annotation is neither a relation nor a valid object annotation`);
      }
      return computeRelationTagPosition(annotations as ObjectAnnotation[], map, isMultipleRelation);
  }
};

export const getPositionsMaxLeftRight = (flattenedLatlngs: L.LatLng[]): L.LatLng[] => {
  flattenedLatlngs.sort((a, b) => b.lat - a.lat);

  const leftRightPosition = flattenedLatlngs.slice(0, 2);

  return leftRightPosition.sort((a, b) => a.lng - b.lng);
};

export const computeCurveRelationArrow = (
  startLatLng: L.LatLng,
  endLatLng: L.LatLng,
  map: L.Map,
): L.LatLngExpression[] => {
  const dy: number = getDeltaEndYStartY(startLatLng, endLatLng, map);
  let controlPoint1: L.LatLngExpression;
  let controlPoint2: L.LatLngExpression;

  const zoomFactor = getZoomFactor(map);

  const scaleFactor = 0.12;

  const StartEndDistLng = endLatLng.lng - startLatLng.lng;
  const StartEndDistLat = endLatLng.lat - startLatLng.lat;

  if (Math.abs(dy) < LIMIT_LATITUDE_DIFFERENCE) {
    const upperPointProjected =
      startLatLng.lat > endLatLng.lat
        ? map.project(startLatLng, zoomFactor)
        : map.project(endLatLng, zoomFactor);

    const controlPointLat = map.unproject(
      new L.Point(upperPointProjected.x, upperPointProjected.y - CURVE_ARROW_HORIZONTAL_ALIGN),
      zoomFactor,
    ).lat;

    controlPoint1 = [controlPointLat, startLatLng.lng];
    controlPoint2 = [controlPointLat, endLatLng.lng - StartEndDistLng * scaleFactor];
  } else {
    controlPoint1 = [startLatLng.lat + StartEndDistLat / 2, startLatLng.lng];
    controlPoint2 = [
      startLatLng.lat + StartEndDistLat / 2,
      endLatLng.lng - StartEndDistLng * scaleFactor,
    ];
  }

  return [controlPoint1, controlPoint2];
};

export const getZoomFactor = (map: L.Map): number => {
  const { bounds } = map;
  if (!bounds) return ARROW_ZOOM_DEFAULT;
  return map.getBoundsZoom(bounds);
};

export const getDeltaEndYStartY = (
  startLatLng: L.LatLng,
  endLatLng: L.LatLng,
  map: L.Map,
): number => {
  const zoomFactor = getZoomFactor(map);
  return map.project(endLatLng, zoomFactor).y - map.project(startLatLng, zoomFactor).y;
};

const computeEndLatLngWithOffset = (
  startLatLng: L.LatLng,
  endLatLng: L.LatLng,
  map: L.Map,
): L.LatLng => {
  const dy: number = getDeltaEndYStartY(startLatLng, endLatLng, map);
  const zoomFactor = getZoomFactor(map);
  const endPoint = map.project(endLatLng, zoomFactor);
  const directedOffset =
    Math.abs(dy) < LIMIT_LATITUDE_DIFFERENCE ? OFFSET_DISTANCE : Math.sign(dy) * OFFSET_DISTANCE;
  const offsetPoint = new L.Point(endPoint.x, endPoint.y - directedOffset);
  const offsetLatLng = map.unproject(offsetPoint, zoomFactor);

  return offsetLatLng;
};

export const getAdjustedStartEndLatLng = (
  startLatLng: L.LatLng,
  endLatLng: L.LatLng,
  map: L.Map,
): { adjustedEndLatLng: L.LatLng; adjustedStartLatLng: L.LatLng } => {
  const adjustedStartLatLng = startLatLng;
  const adjustedEndLatLng = computeEndLatLngWithOffset(startLatLng, endLatLng, map);

  return { adjustedEndLatLng, adjustedStartLatLng };
};

/**
 * @param endLatLng
 * @param angle
 * @param map
 * @returns
 */
export const computeStickOfArrowHead = (
  endLatLng: L.LatLng,
  angle: number,
  map: L.Map,
): L.LatLng[] => {
  const zoomFactor = getZoomFactor(map);
  const endPoint = map.project(endLatLng, zoomFactor);
  const leftPoint = new L.Point(
    endPoint.x - ARROW_HEAD_LENGTH * Math.cos(angle - Math.PI / 4),
    endPoint.y - ARROW_HEAD_LENGTH * Math.sin(angle - Math.PI / 4),
  );
  const rightPoint = new L.Point(
    endPoint.x - ARROW_HEAD_LENGTH * Math.cos(angle + Math.PI / 4),
    endPoint.y - ARROW_HEAD_LENGTH * Math.sin(angle + Math.PI / 4),
  );
  return [map.unproject(leftPoint, zoomFactor), map.unproject(rightPoint, zoomFactor)];
};

export const computeLineCurvePath = (
  startLatLng: L.LatLng,
  endLatLng: L.LatLng,
  controlPoint1: L.LatLngTuple,
  controlPoint2: L.LatLngTuple,
): CurvePathDataElement[] => {
  return [
    'M',
    [startLatLng.lat, startLatLng.lng],
    'C',
    controlPoint1,
    controlPoint2,
    [endLatLng.lat, endLatLng.lng],
  ];
};

export const computeArrowHead = (
  arrowHeadLeft: L.LatLng,
  arrowHeadRight: L.LatLng,
  endLatLng: L.LatLng,
): L.LatLng[] => {
  return [endLatLng, arrowHeadLeft, endLatLng, arrowHeadRight];
};

export const getEditingIconForCategory = (
  categories: AnnotationCategory[],
  jobName?: string,
  hoverable = true,
) => {
  const editMarkerIconStyle = css`
    background: var(--color-omega-accent-0);
    border-radius: 35px;
    border: 2px solid
      ${jobName
        ? memoizedGetColorForCategories(categories, jobName)
        : 'var(--color-omega-accent-5)'};
    ${hoverable && '&:hover {border: 2px solid #2dc0ff;}'}
  `;

  const editMarkerIcon = new L.DivIcon({
    className: cx(editMarkerIconStyle, 'leaflet-editing-icon', SelectorClass),
    iconSize: [12, 12],
  });
  return editMarkerIcon;
};
