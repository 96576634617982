import { type VideoTranscriptionKeyAnnotation } from '@/__generated__/globalTypes';

import { createTranscriptionAnnotationValue } from './createTranscriptionAnnotation';
import { type AnnotationFactoryProperties } from './types';

import { getVideoKeyAnnotationId } from '../helpers/getVideoKeyAnnotationId';

export function createVideoTranscriptionKeyAnnotation(
  properties: AnnotationFactoryProperties<VideoTranscriptionKeyAnnotation>,
  annotationId: string,
): VideoTranscriptionKeyAnnotation {
  const { annotationValue, frame } = properties;
  const { isPrediction, text } = annotationValue;
  const id = getVideoKeyAnnotationId(annotationId, properties.frame);
  return {
    __typename: 'VideoTranscriptionKeyAnnotation',
    annotationValue: createTranscriptionAnnotationValue({ id, isPrediction, text }),
    frame,
    id,
  };
}
