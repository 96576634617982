import { type HistorySliceValues, type HistorySlice } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: HistorySliceValues = {
  actions: [],
  head: null,
};

const log = createLogger('history');

const MAX_ACTIONS_IN_HISTORY = 30;

export const createHistorySlice: ZustandSlice<HistorySlice> = set => ({
  ...initialState,
  addAction: action =>
    set(
      state => {
        const { actions, head } = state.history;

        if (head && head > 0) Array.from({ length: head }, () => actions.shift());
        if (actions.length === MAX_ACTIONS_IN_HISTORY) actions.pop();

        actions.unshift(action);

        return { ...state, history: { ...state.history, actions, head: 0 } };
      },
      false,
      log('addAction', action),
    ),
  initialize: () =>
    set(
      state => {
        return { ...state, history: { ...state.history, actions: [], head: null } };
      },
      true,
      log('initialize'),
    ),
  redo: () =>
    set(
      state => {
        const { actions, head } = state.history;

        if (head !== null) {
          if (head !== 0) {
            actions[head - 1].redo();
            return { ...state, history: { ...state.history, head: Math.max(head - 1, 0) } };
          }
        }
        return state;
      },
      false,
      log('redoAction'),
    ),
  undo: () =>
    set(
      state => {
        const { actions, head } = state.history;

        if (head !== null) {
          if (head !== actions.length) {
            actions[head].undo();

            return {
              ...state,
              history: { ...state.history, head: Math.min(head + 1, actions.length) },
            };
          }
        }
        return state;
      },
      false,
      log('undoAction'),
    ),
});

export * from './types';
