import { makeStyles } from '@material-ui/core/styles';

import { TOPBAR_LEFT_ZINDEX } from '../../../constants/zIndexes';

const useStyles = makeStyles(theme => {
  return {
    subContainer: {
      borderRadius: 0,
      boxSizing: 'content-box',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1.5),
      textTransform: 'none',
      zIndex: TOPBAR_LEFT_ZINDEX,
    },
  };
});

export default useStyles;
