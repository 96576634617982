import { css } from '@emotion/css';
import React from 'react';

import { type DocUrl, DOC_BASE_URL } from '../../constants/docUrls';

export type TooltipTitleLinkProps = {
  docUrl: DocUrl;
  plainText?: string;
  title: string;
};

const TooltipTitleLink = (props: TooltipTitleLinkProps): React.ReactElement => {
  const { docUrl, plainText, title } = props;

  return (
    <div
      className={css`
        display: inline-block;
        padding: 4px 8px;
      `}
    >
      {plainText && <span>{plainText}</span>}
      {plainText && <br />}

      <a
        className={css`
          color: white;
        `}
        href={`${DOC_BASE_URL}${docUrl}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {title}
      </a>
    </div>
  );
};

export default TooltipTitleLink;
