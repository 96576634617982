import { createStyles, type StyleRules, withStyles } from '@material-ui/core/styles';

import Loader from './Loader';

import { LOADER_ZINDEX } from '../../constants/zIndexes';

export const styles = (): StyleRules<'progress'> =>
  createStyles({
    progress: {
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: LOADER_ZINDEX,
    },
  });

export default withStyles(styles)(Loader);
