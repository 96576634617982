/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { type ImageBoundingPoly, Tool } from '@kili-technology/types';
import L, { Polygon } from 'leaflet';

import type KiliFeatureGroup from './KiliFeatureGroup';
import { KiliWrapper } from './KiliWrapper';
import { getLatLngsFromLayer, getLayerOptionsFromAnnotation, layerWithHandlers } from './helpers';
import { areObjectsLocked } from './helpers/areObjectsLocked';
import { type KiliOptions, type PolygonAnnotation, type SemanticAnnotation } from './types';

import { GeometryTools } from '../../../../../constants/tools';
import { getAllowedAnnotationPointsWithinImage } from '../helpers/geometry';

class KiliPolygon extends KiliWrapper(Polygon) {
  constructor(latLngBounds: L.LatLng[][], options: KiliOptions) {
    const { type } = options;
    super(latLngBounds, options);
    super.initializeKili(options);
    this.updateEditMarkerIcon();

    if (areObjectsLocked()) {
      this.editing = undefined;
      return;
    }

    // @ts-expect-error KiliPolygon not assignable to Poly
    this.editing = type === Tool.SEMANTIC ? null : new L.Edit.Poly(this);
  }

  fitToBounds() {
    const latlngs = this.getLatLngs() as L.LatLng[][];
    const latlngsWithinImage = latlngs.map(latlngPolygon =>
      getAllowedAnnotationPointsWithinImage({
        latlngs: latlngPolygon,
        map: this.map,
        type: this.kiliOptions?.type,
      }),
    );
    const noChangeNecessary = latlngs[0]
      .map((latlng, index) => latlng.equals(latlngsWithinImage[0][index], 1e-3))
      .reduce((prev, curr) => prev && curr, true);
    if (!noChangeNecessary) {
      this.setLatLngs(latlngsWithinImage);
    }
    return noChangeNecessary;
  }

  getCenter() {
    this._map = this.map;
    return super.getCenter();
  }

  _projectLatlngs(latlngs: L.LatLng, result: unknown, projectedBounds: unknown) {
    this._map = this.map;
    // @ts-ignore
    return super._projectLatlngs(latlngs, result, projectedBounds);
  }

  removeInteractiveTarget(targetEl: unknown) {
    this._map = this.map;
    // @ts-ignore
    return super.removeInteractiveTarget(targetEl);
  }
}

export const getKiliPolygon = (latLngBounds: L.LatLng[][], options: KiliOptions): KiliPolygon => {
  const polygon = new KiliPolygon(latLngBounds, options);
  return layerWithHandlers(polygon);
};

export const annotationToKiliPolygon = (
  annotation: PolygonAnnotation | SemanticAnnotation,
  leafletOptions: { featureGroup?: KiliFeatureGroup; map: L.Map },
): KiliPolygon => {
  const { map, featureGroup } = leafletOptions;
  const layerOptions = featureGroup
    ? { ...getLayerOptionsFromAnnotation(annotation), group: featureGroup }
    : getLayerOptionsFromAnnotation(annotation);
  const bounds = getLatLngsFromLayer(annotation, map) as L.LatLng[][];
  const kiliLayer = getKiliPolygon(bounds, { ...layerOptions, map });
  return kiliLayer;
};

const getLatLngs = (boundingPoly: ImageBoundingPoly[], map: L.Map): [number, number][][] => {
  return boundingPoly.map(polygon =>
    polygon.normalizedVertices.map(point => map.projectPoint(point) || [0, 0]),
  );
};

export const getLayerFromBoundingPoly = (
  boundingPoly: ImageBoundingPoly[] | undefined,
  map: L.Map,
): L.Polygon => {
  const coordinates = getLatLngs(boundingPoly || [], map);
  return L.GeoJSON.geometryToLayer({
    geometry: {
      coordinates,
      type: GeometryTools.GEO_JSON_POLYGON,
    },
    properties: {},
    type: 'Feature',
  }) as L.Polygon;
};

export default KiliPolygon;
