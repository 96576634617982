import {
  type Annotation,
  type AssetAnnotation,
  type AnnotationCategory as Category,
  type ClassificationAnnotation,
  DetectionTasks,
  type EntityRelation,
  type FrameJsonResponse,
  Input,
  type Job,
  type Jobs,
  type JsonCategory as JobCategory,
  type JobResponse,
  type JsonResponse,
  MachineLearningTask,
  type ObjectRelation,
  type PoseEstimationAnnotation,
  type PoseEstimationPoint,
  type TranscriptionAnnotation,
} from '@kili-technology/types';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _map from 'lodash/map';
import fromEntries from 'object.fromentries';

import { isValidNumber } from '@/pages/projects/label/LabelDialog/LabelInterface/JobsColumn/components/JobTranscriptionInput/helpers';
import { isEmptyResponse } from '@/redux/jobs/helpers/isEmptyResponse';

import { InputType } from '../../__generated__/globalTypes';
import { parseDate } from '../../components/DatePicker/DatePicker';
import {
  ANNOTATIONS,
  CATEGORIES,
  CHILDREN,
  IS_CHILD,
  NAME,
} from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { isJobPoseEstimation } from '../poseEstimation';

type EveryJobOfJobsIsCompletedPayload = {
  allJobs: Jobs;
  jobs: Jobs;
  jsonResponse: FrameJsonResponse | JsonResponse | undefined;
};
type EveryJobIsCompletedPayload = {
  allJobs: Jobs;
  jobs: Jobs;
  jsonResponse: FrameJsonResponse | JsonResponse | undefined;
  root: boolean;
};
type GetLabelStatusFromJobPayload = {
  allJobs: Jobs;
  job: Job;
  response: JobResponse;
};

export const labelStatus = {
  INCOMPLETE: 'INCOMPLETE',
  SUCCESS: 'SUCCESS',
} as const;
type LabelStatus = typeof labelStatus[keyof typeof labelStatus];

type JobAndLabelStatus = { jobName?: string; status: LabelStatus };
type GlobalLabelStatus = {
  frameIndex?: number;
  jobAndLabelStatusList: JobAndLabelStatus[];
  status: LabelStatus;
};

const everyJobOfJobsIsCompleted = ({
  allJobs,
  jobs,
  jsonResponse,
}: EveryJobOfJobsIsCompletedPayload): GlobalLabelStatus => {
  const jobAndLabelStatusList = Object.entries(jobs)
    .map(([jobName, job]) => {
      const response = (jsonResponse?.[jobName] ?? {}) as JobResponse;
      return { jobName, status: getLabelStatusFromJob({ allJobs, job, response }) };
    })
    .filter(({ status }) => status !== labelStatus.SUCCESS);

  return jobAndLabelStatusList.length > 0
    ? { jobAndLabelStatusList, status: jobAndLabelStatusList[0].status }
    : { jobAndLabelStatusList: [], status: labelStatus.SUCCESS };
};

const everyJobIsCompleted = ({
  allJobs,
  jobs,
  jsonResponse,
  root,
}: EveryJobIsCompletedPayload): GlobalLabelStatus => {
  if (root) {
    const rootJobs = fromEntries(
      Object.entries(jobs).filter(([jobName, _]) => !_get(jobs, [jobName, IS_CHILD])),
    );
    return everyJobOfJobsIsCompleted({
      allJobs,
      jobs: rootJobs,
      jsonResponse,
    });
  }
  return everyJobOfJobsIsCompleted({ allJobs, jobs, jsonResponse });
};

export const posePointsCompleted = (
  allJobs: Jobs,
  category: JobCategory,
  annotation: PoseEstimationAnnotation,
): boolean => {
  const poseEstimationCategoryPoints = _get(category, 'points', []) as PoseEstimationPoint[];
  const poseEstimationCategoryPointsCodes = poseEstimationCategoryPoints.map(
    point => point?.code ?? '',
  );
  const mandatoryPointCodes = poseEstimationCategoryPoints
    .filter(point => !!point?.mandatory)
    .map(point => point?.code ?? '');
  const pointAnnotations = annotation?.points || ([] as PoseEstimationPoint[]);
  const annotationPointCodes = pointAnnotations.map(point => point?.code ?? '');
  const allMandatoryPointsAreLabeled = mandatoryPointCodes.every(mandatoryPointCode =>
    annotationPointCodes.includes(mandatoryPointCode),
  );
  const allChildJobsOfPointsAreCompleted = pointAnnotations.every(point => {
    const indexPointOfPointAnnotation = poseEstimationCategoryPointsCodes.indexOf(point.code);
    if (indexPointOfPointAnnotation < 0) {
      return true;
    }
    const childrenJobNamesOfPoint = _get(
      poseEstimationCategoryPoints,
      [indexPointOfPointAnnotation, CHILDREN],
      [] as string[],
    );
    const childrenJobsOfPoint = fromEntries(
      Object.entries(allJobs).filter(([jobName, _]) => childrenJobNamesOfPoint.includes(jobName)),
    );
    const everyJobStatus = everyJobIsCompleted({
      allJobs,
      jobs: childrenJobsOfPoint,
      jsonResponse: point?.children ?? {},
      root: false,
    });

    return everyJobStatus.status === labelStatus.SUCCESS;
  });
  return allMandatoryPointsAreLabeled && allChildJobsOfPointsAreCompleted;
};

export const getLabelStatusFromJob = ({
  allJobs,
  job,
  response,
}: GetLabelStatusFromJobPayload): LabelStatus => {
  if (_isEmpty(job)) {
    return labelStatus.SUCCESS;
  }
  const mlTask = job?.mlTask;
  const jobIsVisible = job?.isVisible ?? true;
  if (!jobIsVisible) {
    return labelStatus.SUCCESS;
  }
  const jobIsRequired = !!(job?.required ?? false);
  const responseIsEmpty = isEmptyResponse(response, mlTask);
  if (responseIsEmpty) {
    return jobIsRequired ? labelStatus.INCOMPLETE : labelStatus.SUCCESS;
  }
  if (mlTask === MachineLearningTask.TRANSCRIPTION) {
    if (!(response as TranscriptionAnnotation)?.text) return labelStatus.INCOMPLETE;
    if (
      job?.content?.input === Input.DATE &&
      !parseDate((response as TranscriptionAnnotation)?.text, /^(\d{4})-(\d{2})-(\d{2})$/)
    ) {
      return labelStatus.INCOMPLETE;
    }
    if (
      job?.content?.input === Input.NUMBER &&
      !isValidNumber((response as TranscriptionAnnotation)?.text)
    ) {
      return labelStatus.INCOMPLETE;
    }

    return labelStatus.SUCCESS;
  }
  if (mlTask === MachineLearningTask.ASSET_ANNOTATION) {
    const rotation = (response as AssetAnnotation)?.rotation;
    return typeof rotation === 'number' && !Number.isNaN(rotation)
      ? labelStatus.SUCCESS
      : labelStatus.INCOMPLETE;
  }

  const annotations = (
    DetectionTasks.includes(mlTask) || mlTask === MachineLearningTask.SPEECH_TO_TEXT
      ? _get(response, ANNOTATIONS, []) || []
      : [response]
  ) as (ClassificationAnnotation | Annotation)[];
  if (
    mlTask === MachineLearningTask.SPEECH_TO_TEXT ||
    mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION ||
    mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION
  ) {
    return annotations?.length ? labelStatus.SUCCESS : labelStatus.INCOMPLETE;
  }
  if (mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION) {
    return (annotations as EntityRelation[]).every(
      annotation => annotation.startEntities.length && annotation.endEntities.length,
    )
      ? labelStatus.SUCCESS
      : labelStatus.INCOMPLETE;
  }
  if (mlTask === MachineLearningTask.OBJECT_RELATION) {
    return (annotations as ObjectRelation[]).every(
      annotation => annotation.startObjects.length && annotation.endObjects.length,
    )
      ? labelStatus.SUCCESS
      : labelStatus.INCOMPLETE;
  }
  const responseCategories = _flatten(
    annotations.map(annotation => (annotation?.categories ?? []).map(category => category.name)),
  );
  const jobCategories = job?.content?.categories ?? {};

  // Here all categories of the response that are not in the json interface are removed from the categories
  // for which we want to check the children jobs as it's not possible to check if the children jobs are completed
  // if the category is not in the json interface. This would be handled by the json validator to refuse categories
  // that are not in the json interface.
  const selectedJobCategories = Object.entries(jobCategories).filter(([categoryName, _]) =>
    responseCategories.includes(categoryName),
  );
  const childrenJobsCompleted = selectedJobCategories.map(([categoryName, category]) => {
    const childrenJobNames = category?.children ?? [];
    const childrenJobs = fromEntries(
      Object.entries(allJobs).filter(([jobName, _]) => childrenJobNames.includes(jobName)),
    );
    const jobHasNoChild = _isEmpty(childrenJobs);
    if (jobHasNoChild && !isJobPoseEstimation(job)) {
      return true;
    }
    const annotationsOfCategory = annotations.filter(annotation => {
      const categoryNames = _map(_get(annotation, CATEGORIES, []), NAME);
      return categoryNames.includes(categoryName);
    });
    return annotationsOfCategory.every(annotation => {
      const categoriesOfAnnotation = _get(annotation, CATEGORIES, []) as Category[];
      const categoryOfAnnotation = categoriesOfAnnotation.filter(
        annotationCategory => _get(annotationCategory, NAME) === categoryName,
      );
      const legacyChildrenJsonResponse = _get(annotation, CHILDREN);
      const childrenJsonResponse =
        legacyChildrenJsonResponse || _get(categoryOfAnnotation, [0, CHILDREN], {});
      const childrenOfAnnotationCompleted =
        everyJobIsCompleted({
          allJobs,
          jobs: childrenJobs,
          jsonResponse: childrenJsonResponse,
          root: false,
        }).status === labelStatus.SUCCESS;
      const childrenOfPosePointsCompleted = isJobPoseEstimation(job)
        ? posePointsCompleted(allJobs, category, annotation as PoseEstimationAnnotation)
        : true;
      return childrenOfAnnotationCompleted && childrenOfPosePointsCompleted;
    });
  });
  const areChildrenJobsCompleted = childrenJobsCompleted.every(e => e);
  return areChildrenJobsCompleted ? labelStatus.SUCCESS : labelStatus.INCOMPLETE;
};

export const shouldAllowNext = (
  jobs: Jobs,
  jsonResponse: FrameJsonResponse | JsonResponse | undefined,
  inputType?: InputType,
): GlobalLabelStatus => {
  const shouldNotUseFrameResponse = !_isObject(jsonResponse) || _isEmpty(jsonResponse);
  const inFrameAndCanCheck = inputType === InputType.VIDEO && !shouldNotUseFrameResponse;
  if (inFrameAndCanCheck) {
    return Object.entries(jsonResponse as FrameJsonResponse)
      .map(([frameIndex, jsonResponseFrame]) => {
        const everyJobIsCompletedFrame = everyJobIsCompleted({
          allJobs: jobs,
          jobs,
          jsonResponse: jsonResponseFrame,
          root: true,
        });
        everyJobIsCompletedFrame.frameIndex = parseInt(frameIndex, 10) + 1;
        return everyJobIsCompletedFrame;
      })
      .reduce((acc, everyJobIsCompletedFrame) => {
        return acc?.status !== labelStatus.SUCCESS ? acc : everyJobIsCompletedFrame;
      });
  }

  return everyJobIsCompleted({
    allJobs: jobs,
    jobs,
    jsonResponse,
    root: true,
  });
};
