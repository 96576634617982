import L, { Rectangle } from 'leaflet';

import type KiliFeatureGroup from './KiliFeatureGroup';
import { KiliWrapper } from './KiliWrapper';
import { getLatLngsFromLayer, getLayerOptionsFromAnnotation, layerWithHandlers } from './helpers';
import { areObjectsLocked } from './helpers/areObjectsLocked';
import { type RectangleAnnotation, type KiliOptions } from './types';

import { getAllowedAnnotationPointsWithinImage } from '../helpers/geometry';

class KiliRectangle extends KiliWrapper(Rectangle) {
  constructor(latlngs: L.LatLng[], options: KiliOptions) {
    const lats = latlngs.map(latlng => latlng.lat);
    const lngs = latlngs.map(latlng => latlng.lng);
    const southWest = new L.LatLng(Math.min(...lats), Math.min(...lngs));
    const northEast = new L.LatLng(Math.max(...lats), Math.max(...lngs));
    const bounds = new L.LatLngBounds(southWest, northEast);
    super(bounds, options);
    this.setLatLngs(latlngs);
    super.initializeKili(options);

    if (areObjectsLocked()) {
      this.editing = undefined;
      return;
    }

    // @ts-expect-error KiliRectangle not assignable to Rectangle
    this.editing = new L.Edit.Rectangle(this);
  }

  fitToBounds() {
    const latlngs = this.getLatLngs()[0] as L.LatLng[];
    const latlngsWithinImage = getAllowedAnnotationPointsWithinImage({
      latlngs,
      map: this.map,
      type: this.kiliOptions?.type,
    });
    const noChangeNecessary = latlngs
      .map((latlng, index) => latlng.equals(latlngsWithinImage[index], 1e-3))
      .reduce((prev, curr) => prev && curr, true);
    if (!noChangeNecessary) {
      this.setLatLngs(latlngsWithinImage);
    }
    return noChangeNecessary;
  }
}

export const getKiliRectangle = (latLngs: L.LatLng[], options: KiliOptions): KiliRectangle => {
  const rectangle = new KiliRectangle(latLngs, options);
  return layerWithHandlers(rectangle);
};

export const annotationToKiliRectangle = (
  annotation: RectangleAnnotation,
  leafletOptions: { featureGroup?: KiliFeatureGroup; map: L.Map },
): KiliRectangle | undefined => {
  const { map, featureGroup } = leafletOptions;
  const layerOptions = featureGroup
    ? { ...getLayerOptionsFromAnnotation(annotation), group: featureGroup }
    : getLayerOptionsFromAnnotation(annotation);
  const [latLngs] = (getLatLngsFromLayer(annotation, map) ?? []) as L.LatLng[][];
  const kiliLayer = getKiliRectangle(latLngs, { ...layerOptions, map });
  return kiliLayer;
};

export default KiliRectangle;
