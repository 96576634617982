import { type ObjectPositionInfo } from '@/services/assets/video';
import { removeObjectsInFramesFromFrameResponses } from '@/services/jobs/delete';
import { store } from '@/store';

import { setNewVideoJobInFrameResponses } from './setNewVideoJobInFrameResponses';

import { updateFrameResponses } from '../../label-frame/actions/updateFrameResponses';
import { projectTreeFlat } from '../../project/selectors';
import { type KeyAnnotation } from '../helpers';
import { frameResponsesObjectsInfo } from '../selectors';

export const reCreateAnnotationInFrameResponses = (
  keyAnnotations: KeyAnnotation[],
  objectInfo: ObjectPositionInfo,
  mid: string,
) => {
  const responses = store.getState().labelFrames.frameResponses;
  const currentObjectInfo = frameResponsesObjectsInfo(store.getState())[mid];
  const flatTree = projectTreeFlat(store.getState());

  let newResponses = { ...responses };

  if (currentObjectInfo) {
    newResponses = removeObjectsInFramesFromFrameResponses(
      currentObjectInfo.frames,
      mid,
      currentObjectInfo.jobName,
      responses,
    );
  }
  newResponses = setNewVideoJobInFrameResponses(
    keyAnnotations,
    objectInfo,
    newResponses,
    flatTree,
    mid,
  );
  return updateFrameResponses({ frameResponses: newResponses });
};
