const PoseCursor = ({ color, name }: { color: string; name: string }): JSX.Element => {
  return (
    <span
      style={{
        color,
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: '0.86rem',
        fontWeight: 400,
        lineHeight: 1.75,
        marginLeft: '20px',
        whiteSpace: 'nowrap',
      }}
    >
      {name}
    </span>
  );
};

export default PoseCursor;
