import { connect } from 'react-redux';

import StyledProjectsPanel from './ProjectsPanel.style';

import { projectTitle } from '../../../../redux/project/selectors';
import { type State } from '../../../../redux/types';

const mapStateToProps = (state: State) => ({
  projectTitle: projectTitle(state),
});

export const connector = connect(mapStateToProps);

export default connector(StyledProjectsPanel);
