import {
  type ImageVertices,
  type ObjectAnnotation2D,
  type PoseEstimationAnnotation,
} from '@kili-technology/types';
import L from 'leaflet';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';

import { reCreateAnnotationInFrameResponses } from '@/redux/jobs/helpers/reCreateAnnotationInFrameResponses';
import { updateFrameResponses } from '@/redux/label-frame/actions/updateFrameResponses';

import useVideoParams from './VideoParamsStore';
import { type TrackingResponse } from './type';

import { getApiEndpoint, onCypress } from '../../../../config';
import { sendToDatadog } from '../../../../datadog';
import { SegmentEvents } from '../../../../pages/RootModule/helpers';
import { sendToSegment } from '../../../../redux/application/actions';
import { applicationAuthenticationToken } from '../../../../redux/application/selectors';
import { type AddNotificationPayload } from '../../../../redux/application/types';
import { selectSelectedAssetId } from '../../../../redux/asset-label/selectors';
import {
  getKeyAnnotations,
  getNextFrameWithKeyFrameForObjectDetection,
} from '../../../../redux/jobs/helpers';
import { frameResponsesObjectsInfo } from '../../../../redux/jobs/selectors';
import { type ObjectDetectionAnnotations } from '../../../../redux/jobs/types';
import { labelFramesFrameResponses } from '../../../../redux/label-frames/selectors';
import { type FrameResponses } from '../../../../redux/label-frames/types';
import { projectID, projectTreeFlat } from '../../../../redux/project/selectors';
import {
  downloadImageAsset,
  type ImageCallBackFunction,
} from '../../../../services/assets/download';
import { fetchResponseForModel, TrackerType } from '../../../../services/models';
import { handleChangeCircleCursorOnWheel } from '../../../../services/poseEstimation';
import { store } from '../../../../store';
import { updateField, useStore } from '../../../../zustand';
import { useHistoryStore } from '../../../../zustand-history';
import { DEFAULT_FRAMES_PLAYED_PER_SECOND } from '../../../DragAndDrop/VideoParametersModal/constants';

const IMAGE_LOAD_TIMEOUT = 20000;
export const MAXIMUM_CLICK_DURATION = onCypress ? 1000 : 300;

export type PoseEstimationAnnotationWithPoint = PoseEstimationAnnotation & {
  poseEstimationPoint: { code: string; color: string; name: string } | null | undefined;
};

export const getTrackingServiceResponse = (
  bbox: ImageVertices[],
  startFrame: number,
  endFrame: number,
): {
  abortController: AbortController | null;
  trackingResponsePromise: Promise<TrackingResponse> | Promise<void>;
} => {
  const projectId = projectID(store.getState());
  const assetId = selectSelectedAssetId(store.getState());
  const videoParams = useVideoParams.getState().videoParams[assetId ?? ''];
  const authenticationToken = applicationAuthenticationToken(store.getState());

  if (!assetId || !projectId || !videoParams || !authenticationToken)
    return { abortController: null, trackingResponsePromise: Promise.resolve() };

  const modelEndpoint = `${getApiEndpoint()}/smart-tools/tracking/predict/`;
  const framesDisplayedPerSecond =
    videoParams.framesPlayedPerSecond ?? DEFAULT_FRAMES_PLAYED_PER_SECOND;

  const predictionPayload = {
    bbox,
    projectId,
    tracker: TrackerType.Ocean,
    videoParams: {
      endFrame,
      fps: framesDisplayedPerSecond,
      startFrame,
      videoUrl: videoParams.assetUrl,
    },
  };

  sendToSegment({ event: SegmentEvents.VIDEO_OBJECT_TRACKING_CALLED });

  const { fetchPromise, abortController } = fetchResponseForModel<TrackingResponse>(
    authenticationToken,
    modelEndpoint,
    predictionPayload,
  );

  return { abortController, trackingResponsePromise: fetchPromise };
};

const mergeTrackingResponseIntoFrameResponse = (
  trackingResponse: TrackingResponse,
  frameJsonResponse: FrameResponses,
  annotation: ObjectAnnotation2D,
  startFrame: number,
) => {
  const { jobName, mid } = annotation;

  if (!jobName) return frameJsonResponse;

  const baseAnnotation = {
    ...annotation,
    isKeyFrame: true,
  };

  const newFrameJsonResponse = _cloneDeep(frameJsonResponse);
  for (let i = startFrame; i < startFrame + trackingResponse.length; i += 1) {
    if (!newFrameJsonResponse[i]) newFrameJsonResponse[i] = {};
    if (!(newFrameJsonResponse[i][jobName] as ObjectDetectionAnnotations)?.annotations) {
      newFrameJsonResponse[i][jobName] = {
        annotations: [],
      };
    }
    (newFrameJsonResponse[i][jobName] as ObjectDetectionAnnotations).annotations = [
      ...(newFrameJsonResponse[i][jobName] as ObjectDetectionAnnotations).annotations.filter(
        d => d.mid !== mid,
      ),
      {
        ...baseAnnotation,
        boundingPoly: [{ normalizedVertices: trackingResponse[i - startFrame] }],
      },
    ];
  }
  return newFrameJsonResponse;
};

type TrackOptions = {
  startFrame?: number;
};

const TRACKING_DEFAULT_RANGE_FRAME = 200;
export const smartTrack = (
  annotation: ObjectAnnotation2D,
  options: TrackOptions = {},
): AbortController | null => {
  const frameJsonResponse = labelFramesFrameResponses(store.getState());
  const { startFrame } = options;
  const { jobName, mid } = annotation;
  const bbox = annotation?.boundingPoly?.[0]?.normalizedVertices;
  const maxFrame = useStore.getState().labelFrame.videoParams.numberOfFrames;

  const { frame: currentFrame } = useStore.getState().labelFrame;
  const isFetchingAnnotation = store.getState().labelImageSemantic?.isFetchingAnnotation ?? false;

  if (!jobName || !mid || bbox?.length !== 4 || isFetchingAnnotation) return null;

  updateField({
    key: 'isFetchingAnnotation',
    sliceName: 'labelImageSemantic',
    value: true,
  });
  const newStartFrame = startFrame ?? currentFrame;
  const nextKeyFrame =
    Number(
      getNextFrameWithKeyFrameForObjectDetection({
        currentFrame: newStartFrame,
        jobName,
        mid,
        newFrameResponses: frameJsonResponse,
      })?.[0] ?? maxFrame,
    ) - 1;
  const endFrame = Math.min(nextKeyFrame, newStartFrame + TRACKING_DEFAULT_RANGE_FRAME - 1);
  updateField({
    key: 'pendingTrackingRange',
    sliceName: 'labelFrame',
    value: {
      annotationMid: mid,
      end: endFrame,
      start: newStartFrame,
    },
  });

  const { abortController, trackingResponsePromise } = getTrackingServiceResponse(
    bbox,
    newStartFrame,
    endFrame,
  );

  trackingResponsePromise
    .then(trackingResponse => {
      if (trackingResponse) {
        const newFrameJsonResponse = mergeTrackingResponseIntoFrameResponse(
          trackingResponse,
          frameJsonResponse,
          annotation,
          newStartFrame,
        );

        const objectInfo = frameResponsesObjectsInfo(store.getState())[mid];
        const jobCategoryTree = projectTreeFlat(store.getState());
        const keyAnnotations = getKeyAnnotations(jobName, frameJsonResponse, jobCategoryTree, mid);

        const action = {
          name: 'smartTrack',
          redo: () => {
            store.dispatch(updateFrameResponses({ frameResponses: newFrameJsonResponse }));
          },
          undo: () => {
            store.dispatch(reCreateAnnotationInFrameResponses(keyAnnotations, objectInfo, mid));
          },
        };

        store.dispatch(updateFrameResponses({ frameResponses: newFrameJsonResponse }));
        useHistoryStore.getState().history.addAction(action);
      }
    })
    .catch(error => {
      if (error instanceof Error && (!abortController || !abortController.signal.aborted)) {
        sendToDatadog(error, 'Error on Tracking model', 'network');
      }
    })
    .finally(() => {
      updateField({
        key: 'isFetchingAnnotation',
        sliceName: 'labelImageSemantic',
        value: false,
      });
      updateField({
        key: 'pendingTrackingRange',
        sliceName: 'labelFrame',
        value: undefined,
      });
    });
  return abortController;
};

const sigmoid = (v: number) => {
  const scale = 8;
  const divider = 2;
  const d3 = (scale * Math.log(2 / (1 + Math.exp(-Math.abs(v / divider))))) / Math.LN2;
  return v > 0 ? d3 : -d3;
};

export type WheelEvent = Event & { deltaX: number; deltaY: number; shiftKey: boolean };

// @ts-expect-error ScrollWheelZoom is not exposed
export const scrollWheelPan = L.Map.ScrollWheelZoom.extend({
  addHooks() {
    L.DomEvent.on(this.map.container, 'wheel', this.onWheelScroll, this);

    this.deltaX = 0;
    this.deltaY = 0;
    this.delta = 0;
  },

  onWheelScroll(e: WheelEvent & { altKey: boolean }) {
    if (!this.map) {
      return;
    }
    const delta = L.DomEvent.getWheelDelta(e);

    if (e.altKey) {
      handleChangeCircleCursorOnWheel(e);
      return;
    }

    const x = e.deltaX;
    const y = e.deltaY;

    const wheelPxFactor = window.devicePixelRatio;

    const debounce = 10;

    this.deltaX += x / wheelPxFactor;
    this.deltaY -= y / wheelPxFactor;
    this.delta += delta;

    this.lastMousePos = this.map.mouseEventToContainerPoint(e);

    if (!this.startTime) {
      this.startTime = +new Date();
    }

    const left = Math.max(debounce - (+new Date() - this.startTime), 0);

    clearTimeout(this.timer);
    this.timer = setTimeout(L.Util.bind(this.performZoom, this), left);

    if (e.shiftKey) {
      L.DomEvent.stop(e);
    }
  },

  performZoom() {
    const { map } = this;
    let { deltaX } = this;
    let deltaY = this.detaY;
    if (!_get(map, ['scrolWheelPan', '_enabled'])) {
      return;
    }

    map.stop(); // stop panning and fly animations if any

    const speed = 20;
    deltaX = sigmoid(deltaX);
    deltaY = sigmoid(deltaY);
    if (Math.abs(deltaX) < Math.abs(deltaY)) {
      deltaX = 0;
    } else {
      deltaY = 0;
    }

    this.deltaX = 0;
    this.deltaY = 0;
    this.delta = 0;
    this.startTime = null;

    if (!deltaX && !deltaY) {
      return;
    }

    map.panBy([deltaX * speed, -deltaY * speed]);
  },
});

export const downloadImageAssetWithAbort = (
  addNotification: (notif: AddNotificationPayload) => void,
  authenticationToken: string,
  currentAbortController: AbortController | null,
  currentTimer: NodeJS.Timeout | null,
  getShouldShowTimeoutWarning: () => boolean,
  imageUrl: string,
  shouldAbort: boolean,
  callback: ImageCallBackFunction = base64Image => base64Image,
  cacheName = 'assets',
  shouldAppendToCache = true,
): { abortController: AbortController; imageTimeoutTimer: NodeJS.Timeout } => {
  const newAbortController = new AbortController();
  const { signal } = newAbortController;
  if (currentAbortController && shouldAbort) {
    currentAbortController.abort();
  }
  if (currentTimer) {
    clearTimeout(currentTimer);
  }
  const imageTimeoutTimer = setTimeout(() => {
    if (getShouldShowTimeoutWarning()) {
      addNotification({ message: 'Image load time out', variant: 'warning' });
    }
  }, IMAGE_LOAD_TIMEOUT);
  downloadImageAsset(
    imageUrl,
    authenticationToken,
    signal,
    callback,
    shouldAppendToCache,
    cacheName,
  ).catch(err => {
    if (err.name !== 'AbortError') {
      sendToDatadog(err, 'Error fetching image', 'network');
      throw err;
    }
    console.warn(err.message);
  });
  return { abortController: newAbortController, imageTimeoutTimer };
};
