/* eslint-disable @typescript-eslint/no-explicit-any */
import _get from 'lodash/get';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import { rootRoutes } from './RootPaths';

import { useAuth0, isUsingAuth0 } from '../../auth0';
import { addErrorNotification } from '../../redux/application/actions';
import { authenticationIsAuthenticated } from '../../redux/authentication/selectors';

type PrivateRouteProps = {
  component: (props: any) => JSX.Element;
  exact: boolean;
  path: string;
};

const PrivateRoute = ({ component: Component, path, ...rest }: PrivateRouteProps) => {
  const history = useHistory();
  const isOnPremAuthenticated = useSelector(authenticationIsAuthenticated);
  const dispatch = useDispatch();
  const { isAuthenticated: auth0IsAuthenticated, loginWithRedirect, user } = useAuth0();
  const isAuthenticated = isUsingAuth0 ? auth0IsAuthenticated : isOnPremAuthenticated;
  const auth0Email = _get(user, 'email');
  const isEmailInvalid = auth0Email === '' || auth0Email === undefined || auth0Email === null;
  const isAuth0EmailInvalid = isAuthenticated && isUsingAuth0 && isEmailInvalid;
  useEffect(() => {
    if (isAuth0EmailInvalid) {
      dispatch(
        addErrorNotification(
          'There has been an error in the authentication process. Please try clearing your cookies and/or using another browser. Contact Kili support if the problem persists',
          true,
        ),
      );
    }
  });

  useEffect(() => {
    if (!isUsingAuth0 || auth0IsAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    fn();
  }, [auth0IsAuthenticated, loginWithRedirect, path]);

  if (!isAuthenticated || isAuth0EmailInvalid) {
    history.push(rootRoutes.ROOT_SIGNIN_ROUTE.path);
    return null;
  }

  const render = (props: any) => <Component {...props} />;
  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
