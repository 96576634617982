import { getAnnotationFromCache } from './getAnnotationFromCache';
import { getLightAnnotationsFromCache, type LightAnnotation } from './getLightAnnotationsFromCache';

import { type Annotation } from '../../types';

export function findAnnotationInCache<TargetAnnotation extends Annotation>(
  predicate: (annotation: LightAnnotation) => annotation is TargetAnnotation,
): TargetAnnotation | undefined;

export function findAnnotationInCache(
  predicate: (annotation: LightAnnotation) => boolean,
): Annotation | undefined;

export function findAnnotationInCache(
  predicate: (annotation: LightAnnotation) => boolean,
): Annotation | undefined {
  const lightAnnotation = getLightAnnotationsFromCache().find(predicate);

  if (lightAnnotation === undefined) {
    return undefined;
  }
  return getAnnotationFromCache(lightAnnotation);
}
