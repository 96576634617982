import { createStyles, type StyleRules, type Theme, withStyles } from '@material-ui/core/styles';

import NotificationRow from './NotificationRow';

import { failureColor, successColor } from '../../../../theme';

export const styles = (
  theme: Theme,
): StyleRules<'container' | 'failureIcon' | 'message' | 'pendingIcon' | 'successIcon'> =>
  createStyles({
    container: {
      display: 'flex',
    },
    failureIcon: {
      color: failureColor,
    },
    message: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'break-spaces',
    },
    pendingIcon: {
      display: 'flex',
      margin: theme.spacing(2),
    },
    successIcon: {
      color: successColor,
    },
  });

export default withStyles(styles)(NotificationRow);
