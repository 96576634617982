import { type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type OrganizationState } from './types';

import { createGenericSliceFlag } from '../helpers/flag';

const sliceOrganization = createGenericSliceFlag({
  initialState,
  name: 'organization',
  reducers: {
    setOrganization(state, action: PayloadAction<OrganizationState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  setOrganization,
  setFail: setOrganizationFail,
  setLoading: setOrganizationLoading,
  setSuccess: setOrganizationSuccess,
} = sliceOrganization.actions;

export default sliceOrganization.reducer;
