/* eslint-disable no-underscore-dangle */
import _get from 'lodash/get';
import { type compose } from 'redux';

import { Environment } from './constants/environment';

declare global {
  interface Window {
    Cypress: {
      isNotUsingAuth0: boolean;
      isRemovingAutosaveBeforeUnload: boolean;
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __APOLLO_CLIENT__: unknown;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    // @ts-expect-error @types/segment-analytics cannot be imported (cannot be resolved)
    analytics: SegmentAnalytics.AnalyticsJS;
  }

  export const $screeb: (
    functionName: string,
    userId?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: Record<string, any>,
  ) => Promise<unknown>;
}

const DEFAULT_RENEW_LOCK_INTERVAL_IN_MILLISECONDS = 30_000;

export const SEGMENT_ANALYTICS = 'analytics';

export const onCypress = window.Cypress;
export const isTestingOldSignInOnCypress = onCypress ? onCypress.isNotUsingAuth0 : false;
export const isRemovingAutosaveBeforeUnload = onCypress
  ? onCypress.isRemovingAutosaveBeforeUnload
  : false;

export const audience = 'http://localhost:4000/';

export const azureId = _get(window, '_env_.BUCKET_AZURE__CLIENT_ID');

export const clientId = _get(window, '_env_.AUTHENTICATION__AUTH0_CLIENT_ID');
export const domain =
  _get(window, '_env_.AUTHENTICATION__ISSUER') || 'kili-technology.eu.auth0.com';
export const googleTagManagerId = _get(window, '_env_.ANALYTICS__GOOGLE_TAG_MANAGER_ID');
export const googleTagManagerAuth = _get(window, '_env_.ANALYTICS__GOOGLE_TAG_MANAGER_AUTH');
export const googleTagManagerPreview = _get(window, '_env_.ANALYTICS__GOOGLE_TAG_MANAGER_PREVIEW');

export const environment = _get(window, '_env_.ENVIRONMENT')
  ? _get(window, '_env_.ENVIRONMENT')
  : Environment.DEVELOPMENT;

export const shouldAddDevTools =
  [Environment.DEVELOPMENT, Environment.STAGING].includes(environment) &&
  !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export const isUsingBookDemo = (): boolean => window._env_?.KILI__IS_USING_BOOK_DEMO === 'True';

export const isUsingMailing = (): boolean =>
  !(_get(window, '_env_.KILI__IS_USING_NO_MAILING') === 'True');

export const isUsingSignUp = (): boolean => isNotOnPremise() && isUsingMailing();

export const isNotOnPremise = (): boolean =>
  !(_get(window, '_env_.DEPLOYMENT__IS_ON_PREMISE') === 'True');

export const isOnPremise = (): boolean =>
  _get(window, '_env_.DEPLOYMENT__IS_ON_PREMISE') === 'True';

export const isDevelopmentEnvironment = (): boolean => environment === Environment.DEVELOPMENT;

export const isProductionEnvironment = () => environment === Environment.PRODUCTION;

export const isStagingEnvironment = () => environment === Environment.STAGING;

export const isPreproductionEnvironment = () => environment === Environment.PREPRODUCTION;

export const isUsingGTM = (): boolean => !!googleTagManagerId;

export const isUsingSentry = (): boolean => isNotOnPremise() && !isDevelopmentEnvironment();

export const isUsingSegment = (): boolean =>
  isNotOnPremise() &&
  !isDevelopmentEnvironment() &&
  _get(window, '_env_.ANALYTICS__SEGMENT_WRITE_KEY');

export const isUsingDatadog = (): boolean =>
  isNotOnPremise() &&
  !isDevelopmentEnvironment() &&
  !!window._env_.ANALYTICS__DATADOG_APPLICATION_ID;

export const isUsingFlagsmith = (): boolean => !!window._env_.FLAGSMITH__ENVIRONMENT_ID;

export const isUsingHubspot = (): boolean => isNotOnPremise();

export const version = _get(window, '_env_.KILI__VERSION');
export const timeBeforeInactivity = _get(window, '_env_.KILI__TIME_BEFORE_INACTIVITY');

const kiliRenewLockInterval = parseInt(
  _get(window, '_env_.KILI__RENEW_LOCK_INTERVAL_IN_MILLISECONDS'),
  10,
);
export const renewLockInterval = Number.isNaN(kiliRenewLockInterval)
  ? DEFAULT_RENEW_LOCK_INTERVAL_IN_MILLISECONDS
  : kiliRenewLockInterval;

export const getRouterUrl = (): string =>
  `${_get(window, '_env_.ENDPOINT__ROUTER')}${_get(window, '_env_.ENDPOINT__API_V2')}`;

export const getBackendUrl = (): string => `${_get(window, '_env_.ENDPOINT__NGINX_BASE_URL')}`;

export const getApiEndpoint = (): string =>
  `${_get(window, '_env_.ENDPOINT__NGINX_BASE_URL')}${_get(window, '_env_.ENDPOINT__API_V2')}`;

export const getGraphqlEndpoint = (): string =>
  `${getApiEndpoint()}${_get(window, '_env_.ENDPOINT__GRAPHQL')}`;

export const getProxyEndpoint = (): string =>
  `${getRouterUrl()}${_get(window, '_env_.ENDPOINT__PROXY')}`;

export const getVersionEndpoint = (): string =>
  `${getRouterUrl()}${_get(window, '_env_.ENDPOINT__VERSION')}`;

export const getWebSocketEndpoint = (): string =>
  `${_get(window, '_env_.ENDPOINT__WEBSOCKET')}${_get(window, '_env_.ENDPOINT__API_V2')}${_get(
    window,
    '_env_.ENDPOINT__GRAPHQL',
  )}`;

export const isCopilotEnabled = () => window._env_?.COPILOT__ENABLED !== 'False';

export const isDataProcessingEnabled = window._env_?.DATAPROCESSING__ENABLED === 'True';
