import { xorWith as _xorWith } from 'lodash';

import { reCreateAnnotationInFrameResponses } from '@/redux/jobs/helpers/reCreateAnnotationInFrameResponses';
import { type KiliAnnotation } from '@/services/jobs/setResponse';
import { type AppThunk, store } from '@/store';
import { useHistoryStore } from '@/zustand-history';

import { getKeyAnnotations } from '../../jobs/helpers';
import { frameResponsesObjectsInfo } from '../../jobs/selectors';
import { labelFramesFrameResponses } from '../../label-frames/selectors';
import { projectTreeFlat } from '../../project/selectors';
import { changeAnnotationClassInFrameResponses } from '../actions';

export const changeAnnotationClassVideo = (newAnnotation: KiliAnnotation): AppThunk => {
  return async (dispatch, _) => {
    const objectsInfo = frameResponsesObjectsInfo(store.getState());
    const previousAnnotation = {
      ...newAnnotation,
      categories: objectsInfo[newAnnotation.mid].categoryCodes.map(el => ({ name: el })),
    } as KiliAnnotation;

    if (
      _xorWith(previousAnnotation.categories, newAnnotation.categories, (a, b) => a.name === b.name)
        .length === 0 &&
      previousAnnotation.jobName === newAnnotation.jobName &&
      previousAnnotation.mid === newAnnotation.mid
    )
      return;

    const frameJsonResponse = labelFramesFrameResponses(store.getState());
    if (!frameJsonResponse) return;

    const objectInfo = objectsInfo[newAnnotation.mid];
    const jobCategoryTree = projectTreeFlat(store.getState());
    const keyAnnotations = getKeyAnnotations(
      objectInfo.jobName,
      frameJsonResponse,
      jobCategoryTree,
      newAnnotation.mid,
    );

    const action = {
      name: 'changeAnnotationClass',
      redo: () => {
        dispatch(changeAnnotationClassInFrameResponses(newAnnotation));
      },
      undo: () => {
        dispatch(reCreateAnnotationInFrameResponses(keyAnnotations, objectInfo, newAnnotation.mid));
      },
    };

    dispatch(changeAnnotationClassInFrameResponses(newAnnotation));
    useHistoryStore.getState().history.addAction(action);
  };
};
