import { css } from '@emotion/css';

export const classes = {
  logoutIcon: css`
    margin-right: 11px;
  `,
  menuItem: css`
    height: var(--space-8);
    justify-content: start;
    padding: var(--space-2) var(--space-4);
  `,
};
