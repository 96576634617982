import {
  type Annotation,
  type BaseAnnotation,
  type AnnotationCategory as Category,
  type ImageBoundingPoly,
  type ImageVertices,
  type JsonInterface,
  LabelVersion,
  MachineLearningTask,
  type ObjectAnnotation,
  type ObjectAnnotation1D,
  type ObjectAnnotation2D,
  type OcrMetadata,
  Tool,
} from '@kili-technology/types';
import { booleanPointInPolygon, centroid, type Feature, type Geometry, polygon } from '@turf/turf';
import { dequal } from 'dequal';
import L, { type LatLngTuple } from 'leaflet';
import _flattenDeep from 'lodash/flattenDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniqWith from 'lodash/uniqWith';

import { CoordinateReferenceSystems, type ValidEPSG } from './Geospatial/constants';
import { FREEDRAW_POLYLINE_CLASSNAME } from './LeafletMapv2/Components/Freedraw/leaflet-freedraw';
import { type PoseEstimationAnnotationWithPoint } from './LeafletMapv2/helpers';

import { GeometryTools } from '../../../constants/tools';
import { type Responses } from '../../../redux/jobs/types';
import {
  ANNOTATIONS,
  JOBS,
  JOB_NAME,
  LABEL_VERSION,
  MID,
  POINTS,
  TOOLS,
} from '../../InterfaceBuilder/FormInterfaceBuilder/constants';
import { memoizedGetColorForCategories } from '../../helpers';

export const divide = (x: number, y: number): number => (y === 0 ? 1 : x / y);

const boundCoordinates = (n: number) => Math.max(0, Math.min(1, n));

export const convertDescriptionToSelectedCategories = (
  categories: Category[],
): { name: string }[] => categories.map((category: Category) => ({ name: category.name }));

export const latLngToXY =
  (width: number, height: number) =>
  (point: L.LatLng): ImageVertices | undefined => {
    if (
      !point ||
      point.lng === undefined ||
      point.lng === null ||
      point.lat === undefined ||
      point.lat === null
    )
      return undefined;
    return width > height
      ? { x: point.lng, y: boundCoordinates(1 - divide(width, height) * point.lat) }
      : { x: boundCoordinates(divide(height, width) * point.lng), y: 1 - point.lat };
  };

export const xyToLatLng =
  (height: number, width: number) =>
  (point: ImageVertices): [number, number] | undefined => {
    if (
      !point ||
      point.x === undefined ||
      point.x === null ||
      point.y === undefined ||
      point.y === null
    )
      return undefined;
    return width > height
      ? [point.x, (1 - point.y) * divide(height, width)]
      : [divide(width, height) * point.x, 1 - point.y];
  };

export const xyToLatLngPoint = (point: ImageVertices, height: number, width: number): L.LatLng => {
  const coords = xyToLatLng(height, width)(new L.Point(point.x, point.y));
  if (!coords) {
    return new L.LatLng(0, 0);
  }
  return new L.LatLng(coords[0], coords[1]);
};

const annotationIsPoseEstimation = (
  annotation: ObjectAnnotation,
): annotation is PoseEstimationAnnotationWithPoint => {
  return (_get(annotation, [POINTS]) || []).length > 0;
};

type Properties = {
  categories: Category[];
  code?: string;
  color: string;
  isNegativeInteractiveSegmentationMarker: boolean;
  jobName?: string;
  labelVersion?: LabelVersion;
  mid: string;
  name?: string;
};

export const annotationIs2DFromGenericAnnotation = (
  annotation: Annotation,
): annotation is ObjectAnnotation2D => 'boundingPoly' in annotation;

const annotationIs2D = (annotation: ObjectAnnotation): annotation is ObjectAnnotation2D =>
  'boundingPoly' in annotation;

const annotationIs1D = (annotation: ObjectAnnotation): annotation is ObjectAnnotation1D =>
  'polyline' in annotation;

export const convertAnnotationToLeafletLayer = (
  projector: (point: ImageVertices) => [number, number] | undefined,
  annotation: ObjectAnnotation,
): Feature<Geometry, Properties | undefined>[] => {
  const coordinates = annotationIs2D(annotation)
    ? (annotation.boundingPoly || []).map(currentPolygon =>
        currentPolygon.normalizedVertices.map(point => projector(point) || [0, 0]),
      )
    : [];

  const labelVersion: LabelVersion = annotation?.[LABEL_VERSION] || LabelVersion.DEFAULT;

  const mid = annotation?.[MID];
  const jobName = (annotation as { [JOB_NAME]?: string })?.[JOB_NAME];
  const isPoseEstimation = annotationIsPoseEstimation(annotation);
  const mlTask = isPoseEstimation
    ? MachineLearningTask.POSE_ESTIMATION
    : MachineLearningTask.OBJECT_DETECTION;

  const line = annotationIs1D(annotation)
    ? (annotation?.polyline || []).map(point => projector(point) || [0, 0])
    : [];

  const coordinate = projector(_get(annotation, 'point', { x: 0, y: 0 })) || [0, 0];
  const categories = _get(annotation, 'categories');
  const selectedCategories = categories ? convertDescriptionToSelectedCategories(categories) : null;
  const commonProperties = {
    categories,
    code: '',
    jobName,
    labelVersion,
    mid,
    mlTask,
    name: '',
  };
  let isNegativeInteractiveSegmentationMarker = false;

  if (isPoseEstimation) {
    const poseEstimationPoints = annotation?.[POINTS] || [];
    return poseEstimationPoints.map(point => {
      const geometryPointCoordinates = projector(point?.point || { x: 0, y: 0 }) || [0, 0];
      return {
        geometry: {
          coordinates: geometryPointCoordinates,
          type: GeometryTools.GEO_JSON_MARKER,
        },
        properties: {
          ...commonProperties,
          code: point.code,
          color: point.color,
          isNegativeInteractiveSegmentationMarker,
          name: point.name,
        },
        type: 'Feature',
      } as Feature<Geometry, Properties>;
    });
  }

  const type = _get(annotation, 'type', '') || '';
  let geometryType = GeometryTools.GEO_JSON_POLYGON;
  let geometryCoordinates: number[] | number[][] | number[][][] = [];

  // In case of predictions, types are entered by humans via API. We want to be case-insensitive to minimize errors.
  switch (type.toLowerCase()) {
    case Tool.MARKER:
      geometryType = GeometryTools.GEO_JSON_MARKER;
      geometryCoordinates = coordinate;
      isNegativeInteractiveSegmentationMarker = _get(
        annotation,
        'isNegativeInteractiveSegmentationMarker',
        false,
      );
      break;
    // For retro-compatibility, annotations without type are considered polygons
    case Tool.POLYGON:
    case Tool.RECTANGLE:
    case '':
      geometryType = GeometryTools.GEO_JSON_POLYGON;
      geometryCoordinates = coordinates;
      break;
    case Tool.POLYLINE:
      geometryType = GeometryTools.GEO_JSON_POLYLINE;
      geometryCoordinates = line;
      break;
    default:
      break;
  }

  return [
    {
      geometry: {
        coordinates: geometryCoordinates,
        type: geometryType,
      },
      properties: {
        color: selectedCategories ? memoizedGetColorForCategories(selectedCategories) : 'white',
        isNegativeInteractiveSegmentationMarker,
        ...commonProperties,
      },
      type: 'Feature',
    },
  ];
};

export const getScaleAndSouthWestFromBounds = (
  bounds: L.LatLngBounds,
  projector: L.Projection,
): { scaleX: number; scaleY: number; southWest: L.Point } => {
  const southWest = bounds.getSouthWest();
  const northEast = bounds.getNorthEast();
  const xySouthWest = projector.project(southWest);
  const xyNorthEast = projector.project(northEast);
  const scaleX = xyNorthEast.x - xySouthWest.x;
  const scaleY = xyNorthEast.y - xySouthWest.y;
  return { scaleX, scaleY, southWest: xySouthWest };
};

export const getProjectorFromLatLngToAnnotation = (
  width: number,
  height: number,
  epsg?: ValidEPSG,
  bounds?: L.LatLngBounds,
): ((point: L.LatLng) => ImageVertices | undefined) => {
  if (!epsg) {
    return latLngToXY(width, height);
  }
  const projector = L.Projection.LonLat;
  if (epsg === CoordinateReferenceSystems.TiledImage && bounds) {
    const { scaleX, scaleY, southWest } = getScaleAndSouthWestFromBounds(bounds, projector);
    return (point: L.LatLng) => {
      const xyPoint = projector.project(point);
      return {
        x: (xyPoint.x - southWest.x) / scaleX,
        y: 1 - (xyPoint.y - southWest.y) / scaleY,
      };
    };
  }
  return (point: L.LatLng) => {
    const latlng = projector.project(point);
    return { x: latlng.x, y: latlng.y };
  };
};

export const getLatLngsWithinImage =
  (height: number, width: number) =>
  (points: L.LatLng[]): L.LatLng[] =>
    points.map((item: L.LatLng) =>
      width < height
        ? new L.LatLng(
            item.lat < 0 ? 0 : Math.min(item.lat, 1),
            item.lng < 0 ? 0 : Math.min(item.lng, divide(width, height)),
          )
        : new L.LatLng(
            item.lat < 0 ? 0 : Math.min(item.lat, divide(height, width)),
            item.lng < 0 ? 0 : Math.min(item.lng, 1),
          ),
    );

export const computeCenter = (height: number, width: number): [number, number] => {
  if (height === 0 || width === 0) {
    return [0, 0];
  }
  if (width > height) {
    return [height / width / 2, 1 / 2];
  }

  return [1 / 2, width / height / 2];
};

export const computeBounds = (
  height: number,
  width: number,
  mapSize?: { x: number; y: number },
  forAnnotations = false,
  readOnly = false,
): L.LatLngBoundsLiteral => {
  if (!height || !width) {
    return [
      [0, 0],
      [1, 1],
    ];
  }
  if (width > height) {
    return [
      [0, 0],
      [height / width, 1],
    ];
  }
  if (height > 4 * width && !!mapSize && !forAnnotations && !readOnly) {
    const mapSizeRatio = mapSize.y / mapSize.x;
    return [
      [1 - (width / height) * mapSizeRatio, 0],
      [1, width / height],
    ];
  }

  return [
    [0, 0],
    [1, width / height],
  ];
};

export const computeMaxBounds = (
  height: number,
  width: number,
  bounds: L.LatLngBoundsLiteral,
  mapSize?: { x: number; y: number },
): L.LatLngBoundsLiteral => {
  if (height > 4 * width && !!mapSize) {
    const corner1: LatLngTuple = [bounds[0][0] + 1.5, bounds[0][1] + 1.5];
    const corner2: LatLngTuple = [bounds[0][0] - 1.5, bounds[0][1] - 1.5];
    return [corner1, corner2];
  }
  const corner1: LatLngTuple = [bounds[0][0] - 0.5, bounds[0][1] - 0.5];
  const corner2: LatLngTuple = [bounds[1][0] + 0.5, bounds[1][1] + 0.5];
  return [corner1, corner2];
};

export const isLayerDrawn = (layer: L.Layer): boolean =>
  (layer instanceof L.CircleMarker ||
    layer instanceof L.Polyline ||
    layer instanceof L.Rectangle ||
    layer instanceof L.Polygon) &&
  _get(layer, 'options.className') !== FREEDRAW_POLYLINE_CLASSNAME &&
  !_get(layer, 'options.v3', false);

const getJobNameFromAnnotationMid = (mid: string, responses?: Responses) => {
  const jobsArray = Object.values(responses ?? {}).filter(Boolean);
  for (let i = 0; i < jobsArray.length; i += 1) {
    const jobs = jobsArray[i];
    // eslint-disable-next-line no-continue
    if (!jobs) continue;
    const jobName = Object.keys(jobs).find(name => {
      // @ts-expect-error Union type don't work well with that syntax
      const job = jobs[name] as never;
      if (!job) return false;
      const annotations = ((job?.[ANNOTATIONS] ?? []) as BaseAnnotation[])
        .map(a => a?.[MID])
        .filter(Boolean);

      return annotations.includes(mid);
    });

    if (jobName) return jobName;
  }
  return '';
};

export const getToolsFromMid = (
  mid: string,
  responses?: Responses,
  jsonInterface?: JsonInterface,
): Tool[] => {
  const jobName = getJobNameFromAnnotationMid(mid, responses) ?? '';
  return jsonInterface?.[JOBS]?.[jobName]?.[TOOLS] ?? [];
};

export const isJobToolsSemantic = (jobTools: Tool[]): boolean =>
  jobTools && jobTools.includes(Tool.SEMANTIC);

export const listAllLayers = (leafletElement: L.Map): L.Layer[] => {
  const layers: L.Layer[] = [];
  leafletElement.eachLayer((layer: L.Layer) => {
    if (!!_get(layer, 'options.selectedCategories') && !_get(layer, 'options.isArrowHead')) {
      layers.push(layer);
    }
  });

  return _uniqWith(layers, isEqualLayer);
};

export const isCentroidInBoundingPoly = (
  boundingPoly1: ImageBoundingPoly,
  boundingPoly2: ImageBoundingPoly,
): boolean => {
  const envelop1 = _get(boundingPoly1, 'normalizedVertices', []).map(({ x, y }: ImageVertices) => [
    x,
    y,
  ]);
  const envelop2 = _get(boundingPoly2, 'normalizedVertices', []).map(({ x, y }: ImageVertices) => [
    x,
    y,
  ]);
  const polygon1 = polygon([envelop1.concat([_get(envelop1, '[0]', [])])]);
  const polygon2 = polygon([envelop2.concat([_get(envelop2, '[0]', [])])]);
  const centroid1 = centroid(polygon1);
  return booleanPointInPolygon(centroid1, polygon2);
};

export const pdfToImageJsonMetadata = (jsonMetadata: Record<string, unknown>): OcrMetadata => {
  if ('fullTextAnnotation' in jsonMetadata) {
    return jsonMetadata as OcrMetadata;
  }
  // For the moment we only handle the case with one page
  const fullTextAnnotation = _get(
    jsonMetadata,
    'responses[0].responses[0].fullTextAnnotation',
    {},
  ) as string;
  const textAnnotations = _flattenDeep(
    _get(fullTextAnnotation, 'pages', []).map((page: unknown) =>
      _get(page, 'blocks', []).map((block: unknown) =>
        _get(block, 'paragraphs', []).map((paragraph: unknown) =>
          _get(paragraph, 'words', []).map((word: unknown) => ({
            boundingPoly: _get(word, 'boundingBox', []) as ImageBoundingPoly[],
            description: _get(word, 'symbols', [])
              .map((symbol: unknown) => _get(symbol, 'text', ''))
              .join(''),
          })),
        ),
      ),
    ),
  );
  return {
    fullTextAnnotation,
    textAnnotations,
  } as OcrMetadata;
};

export const getOcrContentSuggestion = (
  jsonMetadata: Record<string, unknown>,
  boundingPoly: [ImageBoundingPoly],
): string | undefined => {
  if (!jsonMetadata || _isEmpty(jsonMetadata) || !boundingPoly) {
    return undefined;
  }
  const imageJsonMetadata = pdfToImageJsonMetadata(jsonMetadata);
  const { height, width } = _get(imageJsonMetadata, 'fullTextAnnotation.pages[0]', {});
  const normalizedTextAnnotations = _get(imageJsonMetadata, 'textAnnotations', []).map(
    metadataAnnotation => {
      const currentBoundingPoly = _get(metadataAnnotation, 'boundingPoly', {});
      const vertices = _get(currentBoundingPoly, 'vertices', []);
      return {
        ...metadataAnnotation,
        boundingPoly: {
          ...boundingPoly,
          normalizedVertices: vertices.map((vertice: ImageVertices) => {
            if (height === 0 || width === 0) {
              return { x: 0, y: 0 };
            }
            return {
              x: _get(vertice, 'x') / width,
              y: _get(vertice, 'y') / height,
            };
          }),
        },
      };
    },
  );
  const contentSuggestions = normalizedTextAnnotations
    .filter(ocrAnnotation =>
      isCentroidInBoundingPoly(
        _get(ocrAnnotation, 'boundingPoly', { normalizedVertices: [] }),
        _get(boundingPoly, '[0]', {}),
      ),
    )
    .map(textAnnotation => _get(textAnnotation, 'description'));
  return contentSuggestions.join(' ');
};

export const isEqualLayer = (layer1: L.Layer, layer2: L.Layer): boolean => {
  return (
    _get(layer1, 'options.mid') === _get(layer2, 'options.mid') &&
    dequal(_get(layer1, '_latlng'), _get(layer2, '_latlng')) &&
    dequal(_get(layer1, '_latlngs'), _get(layer2, '_latlngs'))
  );
};

export const midInObjects = (mid: string | null, objects: Annotation[]): boolean => {
  if (mid === null) return false;
  const mids = objects.map(object => object?.mid);
  return mids.includes(mid);
};
