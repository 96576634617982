import { type Draft } from 'immer';

import { type AssetLockSlice, type AssetLockSliceValues } from './types';

import { type ZustandSlice } from '..';
import {
  type DeleteLocksMutation,
  type DeleteLocksMutationVariables,
} from '../../graphql/lock/__generated__/mutations.graphql';
import { GQL_DELETE_LOCKS } from '../../graphql/lock/mutations';
import createLogger from '../helpers';

const initialState: AssetLockSliceValues = {
  lockedAssetIds: [],
};

const log = createLogger('assetLock');

export const createAssetLockSlice: ZustandSlice<AssetLockSlice> = (set, get) => ({
  ...initialState,
  addLockedAsset: newAssetId =>
    set(
      state => {
        if (state.assetLock.lockedAssetIds.includes(newAssetId)) return;

        state.assetLock.lockedAssetIds.push(newAssetId);
      },
      false,
      log('addLockedAsset', newAssetId),
    ),
  cleanLocks: async ({ client, ignoredAsset, lockType, projectId }) => {
    const assetsToUnlock = get().assetLock.lockedAssetIds.filter(
      assetId => assetId !== ignoredAsset,
    );

    await Promise.all(
      assetsToUnlock.map(assetId => {
        return client.mutate<DeleteLocksMutation, DeleteLocksMutationVariables>({
          context: { clientName: 'V2' },
          mutation: GQL_DELETE_LOCKS,
          variables: { input: { assetId, lockType, projectId } },
        });
      }),
    );

    const newLockedAssets = ignoredAsset ? [ignoredAsset] : [];
    set(
      state => {
        state.assetLock.lockedAssetIds = newLockedAssets;
      },
      false,
      log('lockedAssetIds', newLockedAssets),
    );
  },
  removeFromLocks: assetIdToRemove =>
    set(
      state => {
        state.assetLock.lockedAssetIds = state.assetLock.lockedAssetIds.filter(
          id => id !== assetIdToRemove,
        );
      },
      false,
      log('removeFromLocks', assetIdToRemove),
    ),
  updateField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.assetLock as Draft<AssetLockSliceValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
});

export * from './types';
