import {
  type ObjectAnnotation,
  type ObjectAnnotation2D,
  type ObjectAnnotationMarker,
  Tool,
} from '@kili-technology/types';
import { set } from 'lodash/fp';
import _get from 'lodash/get';

import { ANNOTATIONS, MID } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import {
  interpolateRectangle,
  interpolatePoint,
  frameResponseContainsKeyFrameForObjects,
} from '../../components/asset-ui/Frame/helpers';
import { labelFramesFrameResponses } from '../../redux/label-frames/selectors';
import { type FrameResponses } from '../../redux/label-frames/types';
import { store } from '../../store';
import { useStore } from '../../zustand';

export const interpolateKeyFrames = (
  mid: string,
  jobName: string,
  frameJsonResponses: FrameResponses,
  newResponses: FrameResponses,
  updatedFrame?: number,
) => {
  const { initialKeyFrames, keyFramesDifferences, resultKeyFrames } = getKeyFramesDifferences(
    frameJsonResponses,
    newResponses,
    mid,
    updatedFrame,
  );
  const keyFramesPairs = findPairsToUpdate(initialKeyFrames, keyFramesDifferences, resultKeyFrames);
  return interpolateKeyFramesPairs(keyFramesPairs, mid, jobName, newResponses);
};

export const getKeyFramesDifferences = (
  initialResponses: FrameResponses,
  resultResponses: FrameResponses,
  mid: string,
  updatedFrame?: number,
) => {
  const initialKeyFrames = Object.entries(initialResponses)
    .filter(([_, response]) => frameResponseContainsKeyFrameForObjects(response, [mid]))
    .map(([frame, _]) => parseInt(frame, 10));
  const resultKeyFrames = Object.entries(resultResponses)
    .filter(([_, response]) => frameResponseContainsKeyFrameForObjects(response, [mid]))
    .map(([frame, _]) => parseInt(frame, 10));
  const keyFramesDifferences = resultKeyFrames
    .filter(frame => !initialKeyFrames.includes(frame))
    .sort((a, b) => a - b);

  return {
    initialKeyFrames,
    keyFramesDifferences:
      keyFramesDifferences.length === 0 && updatedFrame !== undefined
        ? [updatedFrame]
        : keyFramesDifferences,
    resultKeyFrames,
  };
};

export const findPairsToUpdate = (
  initialArray: number[],
  differenceArray: number[],
  resultArray: number[],
): number[][] => {
  const pairsArray: number[][] = [];

  let filteredResultArray = [...resultArray].filter((frame, index, array) => {
    /* KeyFrames Addition */
    return (
      differenceArray.includes(frame) ||
      (index + 1 !== array.length && differenceArray.includes(array[index + 1])) ||
      (index > 0 && differenceArray.includes(array[index - 1]))
    );
  });

  if (filteredResultArray.length === 0) {
    /* KeyFrames Substraction */
    filteredResultArray = [...resultArray].filter((frame, index, array) => {
      return (
        initialArray.includes(frame) ||
        (index + 1 !== array.length && initialArray.includes(array[index + 1])) ||
        (index > 0 && initialArray.includes(array[index - 1]))
      );
    });
  }

  const compareElements = (baseIndex: number, indexToCompare: number, arrayToCompare: number[]) => {
    if (baseIndex + 1 < resultArray.length && indexToCompare + 1 < arrayToCompare.length) {
      if (resultArray[baseIndex + 1] === arrayToCompare[indexToCompare + 1]) return;
      pairsArray.push([resultArray[baseIndex], resultArray[baseIndex + 1]]);
    }
    if (baseIndex + 1 === resultArray.length && indexToCompare + 1 === arrayToCompare.length) {
      if (resultArray[baseIndex] !== initialArray.slice(-1)[0])
        pairsArray.push([resultArray[baseIndex]]); /* Un-interpolate frame until the end */
      return;
    }

    if (indexToCompare + 1 === arrayToCompare.length) {
      pairsArray.push([resultArray[baseIndex], resultArray[baseIndex + 1]]);
    } else if (baseIndex + 1 === resultArray.length)
      pairsArray.push([resultArray[baseIndex]]); /* Un-interpolate frame until the end */
  };

  filteredResultArray.forEach(item => {
    const resultIndex = resultArray.indexOf(item);
    const i = initialArray.indexOf(item);
    const j = differenceArray.indexOf(item);

    if (i !== -1 && j !== -1) {
      /* item was updated */
      if (resultIndex > 0) pairsArray.push([resultArray[resultIndex - 1], item]);
      if (resultIndex + 1 !== resultArray.length)
        pairsArray.push([item, resultArray[resultIndex + 1]]);
      else return pairsArray.push([resultArray[resultIndex]]);
    }
    if (i !== -1) return compareElements(resultIndex, i, initialArray); /* item was removed */
    if (j !== -1) return compareElements(resultIndex, j, differenceArray); /* item was added */
    return true;
  });
  return pairsArray;
};

export const interpolateKeyFramesPairs = (
  keyFrames: number[][],
  mid: string,
  jobName: string,
  frameJsonResponses: FrameResponses,
): FrameResponses => {
  let newResponses = frameJsonResponses ?? labelFramesFrameResponses(store.getState());

  keyFrames.forEach(([startFrame, endFrame]) => {
    newResponses =
      endFrame === undefined
        ? removeInterpolationAfterFrame(startFrame, mid, jobName, newResponses)
        : interpolateAnnotationBetweenTwoKeyFrames(
            startFrame,
            endFrame,
            mid,
            jobName,
            newResponses,
          );
  });
  return newResponses;
};

const removeInterpolationAfterFrame = (
  keyFrame: number,
  mid: string,
  jobName: string,
  frameJsonResponses?: FrameResponses,
): FrameResponses => {
  const responses = frameJsonResponses ?? labelFramesFrameResponses(store.getState());
  const { numberOfFrames: totalFrames } = useStore.getState().labelFrame.videoParams;

  const framesToIterate = Array.from(
    { length: totalFrames - keyFrame - 1 },
    (_, i) => keyFrame + i + 1,
  );

  let newResponses = { ...responses };

  const keyFrameAnnotation = (
    _get(responses[keyFrame], [jobName, ANNOTATIONS], []) as ObjectAnnotation[]
  ).find(annotation => annotation[MID] === mid);

  framesToIterate.forEach(frame => {
    if (!keyFrameAnnotation) return;

    const frameAnnotation = (
      _get(responses[frame], [jobName, ANNOTATIONS], []) as ObjectAnnotation[]
    ).find(annotation => annotation[MID] === mid);

    if (!frameAnnotation) return;

    const responseToSetForFrameAndMid = {
      ...keyFrameAnnotation,
      isKeyFrame: false,
    };

    const previousAnnotations = _get(
      responses[frame],
      [jobName, ANNOTATIONS],
      [],
    ) as ObjectAnnotation[];
    const previousAnnotationsWithOtherMid = previousAnnotations.filter(
      annotation => _get(annotation, MID) !== mid,
    );

    newResponses = set(
      [frame, jobName, ANNOTATIONS],
      [...previousAnnotationsWithOtherMid, responseToSetForFrameAndMid],
      newResponses,
    );
  });
  return newResponses;
};

const interpolateAnnotationBetweenTwoKeyFrames = (
  firstKeyFrame: number,
  nextKeyFrame: number,
  mid: string,
  jobName: string,
  frameJsonResponses?: FrameResponses,
): FrameResponses => {
  const responses = frameJsonResponses ?? labelFramesFrameResponses(store.getState());

  const framesToIterate = Array.from(
    { length: nextKeyFrame - firstKeyFrame - 1 },
    (_, i) => firstKeyFrame + i + 1,
  );

  let newResponses = { ...responses };

  const firstKeyFrameAnnotation = (
    _get(responses[firstKeyFrame], [jobName, ANNOTATIONS], []) as ObjectAnnotation[]
  ).find(annotation => annotation[MID] === mid);
  const nextKeyFrameAnnotation = (
    _get(responses[nextKeyFrame], [jobName, ANNOTATIONS], []) as ObjectAnnotation[]
  ).find(annotation => annotation[MID] === mid);

  framesToIterate.forEach(frame => {
    if (!firstKeyFrameAnnotation || !nextKeyFrameAnnotation) return;

    const frameAnnotation = (
      _get(responses[frame], [jobName, ANNOTATIONS], []) as ObjectAnnotation[]
    ).find(annotation => annotation[MID] === mid);

    if (!frameAnnotation) return;

    let responseToSetForFrameAndMid;

    const weight = (frame - firstKeyFrame) / (nextKeyFrame - firstKeyFrame);
    if (firstKeyFrameAnnotation.type === Tool.RECTANGLE) {
      const { imageHeight, imageWidth } = useStore.getState().labelInterface;
      const previousVertices = (firstKeyFrameAnnotation as ObjectAnnotation2D).boundingPoly?.[0]
        ?.normalizedVertices;
      const nextVertices = (nextKeyFrameAnnotation as ObjectAnnotation2D).boundingPoly?.[0]
        ?.normalizedVertices;

      if (!previousVertices || !nextVertices) return;

      const interpolatedVertices = interpolateRectangle({
        height: imageHeight,
        nextVertices,
        previousVertices,
        weight,
        width: imageWidth,
      });
      responseToSetForFrameAndMid = {
        ...firstKeyFrameAnnotation,
        boundingPoly: [{ normalizedVertices: interpolatedVertices }],
        isKeyFrame: false,
      };
    } else if (firstKeyFrameAnnotation.type === Tool.MARKER) {
      const previousPoint = (firstKeyFrameAnnotation as ObjectAnnotationMarker).point;
      const nextPoint = (nextKeyFrameAnnotation as ObjectAnnotationMarker).point;

      if (!previousPoint || !nextPoint) return;

      const interpolatedPoint = interpolatePoint(previousPoint, nextPoint, weight);
      responseToSetForFrameAndMid = {
        ...firstKeyFrameAnnotation,
        isKeyFrame: false,
        point: interpolatedPoint,
      };
    } else {
      responseToSetForFrameAndMid = {
        ...firstKeyFrameAnnotation,
        isKeyFrame: false,
      };
    }

    const previousAnnotations = _get(
      responses[frame],
      [jobName, ANNOTATIONS],
      [],
    ) as ObjectAnnotation[];
    const previousAnnotationsWithOtherMid = previousAnnotations.filter(
      annotation => _get(annotation, MID) !== mid,
    );

    newResponses = set(
      [frame, jobName, ANNOTATIONS],
      [...previousAnnotationsWithOtherMid, responseToSetForFrameAndMid],
      newResponses,
    );
  });
  return newResponses;
};
