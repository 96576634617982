import _get from 'lodash/get';
import { type AnyAction, type Dispatch } from 'redux';

import { userEmail } from './selectors';
import { USER_INITIALIZE_STATE, USER_UPDATE, USER_UPDATE_FIELD } from './slice';
import {
  type CreateUserPayload,
  type GetUserPayload,
  type ResetPasswordOfUserPayload,
  type UpdatePasswordPayload,
  type UpdatePropertiesInUserPayload,
  type UserState,
} from './types';

import { type User } from '../../__generated__/globalTypes';
import { type UpdatePropertiesInUserMutation } from '../../graphql/user/__generated__/mutations.graphql';
import {
  type ViewerQuery,
  type ViewerQueryVariables,
} from '../../graphql/user/__generated__/queries.graphql';
import {
  GQL_CREATE_USER,
  GQL_PASSWORD_RESET,
  GQL_PASSWORD_UPDATE,
  GQL_UPDATE_PROPERTIES_IN_USER,
} from '../../graphql/user/mutations';
import { GQL_GET_USERS_FROM_MY_ORGANIZATION, GQL_GET_VIEWER } from '../../graphql/user/queries';
import { updateFieldGenerics } from '../../types';
import { addNotification, addErrorNotification } from '../application/actions';
import {
  applicationNewPassword1,
  applicationNewPassword2,
  applicationOldPassword,
} from '../application/selectors';
import { SHOW_PASSWORD_CHANGE_ERROR } from '../application/slice';
import { initializeState as assetInit } from '../asset-label/actions';
import { LABELED_ASSETS_RESET } from '../asset-label/slice';
import { type AppThunk } from '../types';

export const updateField = updateFieldGenerics<UserState>()(USER_UPDATE_FIELD);

export const signUpInit = (): AppThunk => {
  return async dispatch => {
    dispatch(assetInit());
    dispatch(LABELED_ASSETS_RESET());
  };
};

export const updatePropertiesInUser = (
  payload: UpdatePropertiesInUserPayload,
): AppThunk<Promise<AnyAction>> => {
  const actionId = `updatePropertiesInUser`;

  return (dispatch: Dispatch) => {
    const { client, data, where } = payload;

    return client
      .mutate<UpdatePropertiesInUserMutation>({
        context: {
          clientName: 'V2',
          headers: {
            actionId,
          },
        },
        mutation: GQL_UPDATE_PROPERTIES_IN_USER,
        variables: {
          data,
          where,
        },
      })
      .then(response => {
        const updatedUser = response?.data?.updatePropertiesInUser ?? {};

        return Promise.all(
          Object.entries({ ...updatedUser, loaded: true }).map(([path, value]) =>
            dispatch(updateField({ path: path as keyof User, value })),
          ),
        );
      })
      .then(() => dispatch(addNotification({ message: 'Informations saved', variant: 'success' })))
      .catch(error => {
        return dispatch(
          addNotification({
            message: error.message.replace('GraphQL error: ', ''),
            variant: 'info',
          }),
        );
      });
  };
};

export const updatePassword = (payload: UpdatePasswordPayload): AppThunk => {
  return async (dispatch, getState) => {
    const { client } = payload;
    const state = getState();
    const email = userEmail(state);

    const oldPassword = applicationOldPassword(state);
    const newPassword1 = applicationNewPassword1(state);
    const newPassword2 = applicationNewPassword2(state);

    const variables = { data: { newPassword1, newPassword2, oldPassword }, where: { email } };
    client
      .mutate({
        context: { clientName: 'V2' },
        mutation: GQL_PASSWORD_UPDATE,
        variables,
      })
      .then(({ data }) => {
        if (!data.updatePassword) {
          dispatch(addErrorNotification('Error while updating password'));
          return;
        }
        dispatch(addNotification({ message: 'Password changed successfully', variant: 'success' }));
      })
      .catch(error => {
        dispatch(SHOW_PASSWORD_CHANGE_ERROR({ message: error.toString() }));
      });
  };
};

export const resetPasswordOfUser = (payload: ResetPasswordOfUserPayload): AppThunk => {
  const actionId = `resetPasswordOfUser`;
  return async (_dispatch, _getState) => {
    const { client, email, callBack } = payload;
    const variables = { where: { email } };
    client
      .mutate({
        context: { clientName: 'V2', headers: { actionId } },
        mutation: GQL_PASSWORD_RESET,
        variables,
      })
      .then(response => {
        const newPassword = _get(response, ['data', 'resetAndReturnPassword']);
        return newPassword;
      })
      .then(callBack);
  };
};

export const getUser = (payload: GetUserPayload): AppThunk => {
  return async dispatch => {
    const { client, email } = payload;
    if (!email) return;
    const response = await client.query<ViewerQuery, ViewerQueryVariables>({
      fetchPolicy: 'network-only',
      query: GQL_GET_VIEWER,
    });
    const { data } = response;
    if (!data) return;
    const user = data?.viewer;
    if (!user) return;
    dispatch(USER_UPDATE(user as User));
  };
};

export const createUser = (payload: CreateUserPayload): AppThunk => {
  const actionId = `createUser`;
  return async dispatch => {
    const { client, email, organizationRole } = payload;
    const variables = {
      data: {
        email,
        organizationRole,
      },
    };
    try {
      const response = await client.mutate({
        context: {
          clientName: 'V2',
          headers: {
            actionId,
          },
        },
        mutation: GQL_CREATE_USER,
        refetchQueries: [
          {
            query: GQL_GET_USERS_FROM_MY_ORGANIZATION,
          },
        ],
        variables,
      });
      const error = _get(response, 'errors');
      if (error) {
        dispatch(
          addNotification({
            message: `Could not create user with email "${email}"`,
            variant: 'warning',
          }),
        );
        return;
      }
      dispatch(
        addNotification({
          message: `New member "${email}" added sucessfully in the organization`,
          variant: 'info',
        }),
      );
    } catch (error) {
      dispatch(
        addNotification({
          message: (error as Error).message.replace('GraphQL error: ', ''),
          variant: 'info',
        }),
      );
    }
  };
};

export const initializeState = (): { type: string } => USER_INITIALIZE_STATE();
