import { css } from '@emotion/css';

const useStyles = () => {
  return {
    helpButton: css`
      margin-top: var(--space-2);
      margin-bottom: var(--space-2);
      width: var(--space-8);
      min-width: var(--space-8);
      padding: 0 0 !important;
    `,
  };
};
export default useStyles;
