import {
  type AnnotationCategory as Category,
  type JsonInterface,
  MachineLearningTask,
  type PoseEstimationPoint,
} from '@kili-technology/types';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import md5 from 'md5';
import randomColor from 'randomcolor';
import { type KeyboardEventHandler } from 'react';
import { defaultMemoize } from 'reselect';

import {
  CATEGORIES,
  COLOR,
  CONTENT,
  JOBS,
  NAME,
  POINTS,
} from './InterfaceBuilder/FormInterfaceBuilder/constants';
import { getVariable } from './asset-ui/Common/useCssVar';

import { applicationColors } from '../redux/application/selectors';
import { type Color } from '../redux/application/types';
import { projectJsonInterface } from '../redux/selectors';
import { store } from '../store';

const getColorForCategories = (selectedCategories: Category[], jobName = ''): string => {
  const selectedCategoriesCopy = [...selectedCategories];
  selectedCategoriesCopy.sort();
  if (_isEmpty(selectedCategoriesCopy)) {
    return getVariable('--color-epsilon-accent-2');
  }
  const jsonInterface = projectJsonInterface(store.getState());
  const color = getColorFromJsonInterface(selectedCategoriesCopy, jobName, jsonInterface);
  if (color) {
    return color;
  }
  const colors = applicationColors(store.getState());
  return getColorFromDefault(selectedCategoriesCopy, colors);
};

export const memoizedGetColorForCategories = defaultMemoize(getColorForCategories);

const getNameForCategory = (jobName: string, categories: Category[]): string[] => {
  const jsonInterface = projectJsonInterface(store.getState());
  return categories.map(category => {
    return jsonInterface.jobs?.[jobName]?.content?.categories?.[category.name]?.name || '';
  });
};

export const memoizedGetNameForCategory = defaultMemoize(getNameForCategory);

const getInstructionsForJob = (jobName: string): string => {
  const jsonInterface = projectJsonInterface(store.getState());
  return jsonInterface.jobs?.[jobName]?.instruction || '';
};

export const memoizedGetInstructionsForJob = defaultMemoize(getInstructionsForJob);

const getFirstTranscriptionChildrenJob = (
  jobName: string,
  categoryName: string,
): string | undefined => {
  const jsonInterface = projectJsonInterface(store.getState());
  const children = jsonInterface.jobs?.[jobName]?.content?.categories?.[categoryName]?.children;
  if (!children || !children.length) return undefined;
  return children.filter(
    d => jsonInterface.jobs[d]?.mlTask === MachineLearningTask.TRANSCRIPTION,
  )[0];
};

export const memoizedGetChildrenJob = defaultMemoize(getFirstTranscriptionChildrenJob);

export const getColorFromJsonInterface = (
  selectedCategories: Category[],
  jobName: string,
  jsonInterface: JsonInterface,
): string | null => {
  const name = _get(selectedCategories, [0, NAME]);
  const path = [JOBS, jobName, CONTENT, CATEGORIES, name, COLOR];
  const color = _get(jsonInterface, path);
  if (selectedCategories.length === 1 && color) {
    return color;
  }
  return null;
};

export const getColorFromDefault = (categories: Category[], colors: Color[]): string => {
  const concatenatedCategories = categories.reduce(
    (acc: string, category: Category) => `${acc}${category.name}`,
    '',
  );
  const categoryColor = _filter(
    colors,
    color => _get(color, 'category') === concatenatedCategories,
  );
  if (categoryColor.length === 1) {
    return _get(categoryColor, [0, COLOR]);
  }
  const seed = md5(concatenatedCategories);
  return getColorFromSeed(seed);
};

export const getColorFromSeed = (seed: number | string): string => {
  return randomColor({ seed });
};

export const getColorsFromJson = (jsonInterface: JsonInterface | null | undefined): string[] => {
  const colors: string[] = [];
  const jobs = _get(jsonInterface, JOBS, {});
  // eslint-disable-next-line no-unused-expressions
  jobs &&
    Object.values(jobs).forEach(job => {
      const categories = _get(job, [CONTENT, CATEGORIES], {});
      Object.values(categories).forEach(category => {
        const color = category.color || '';
        colors.push(color);
        if (POINTS in category) {
          const categoryPoseEstimationPoints = _get(category, POINTS, []);
          categoryPoseEstimationPoints.forEach((point: PoseEstimationPoint) => {
            colors.push(point.color || '');
          });
        }
      });
    });
  return colors;
};

export const canBlur = (element: unknown): element is { blur: () => void } => {
  const casted = element as { blur: () => void };
  return casted.blur && typeof casted.blur === 'function';
};

export const defaultTextAreaKeyHandler: KeyboardEventHandler<HTMLTextAreaElement> = e => {
  e.stopPropagation();
  if (e.key === 'Escape' && canBlur(e.target)) e.target.blur();
};

export const defaultDivKeyHandler: KeyboardEventHandler<HTMLDivElement> = e => {
  e.stopPropagation();
  if (e.key === 'Escape' && canBlur(e.target)) e.target.blur();
};
