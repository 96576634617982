import { connect } from 'react-redux';

import StyledProjectsPanelPage from './ProjectsPanelPage.style';

import { sendToSegment } from '../../../../../redux/application/actions';
import { projectID } from '../../../../../redux/project/selectors';
import { projectsTable } from '../../../../../redux/projects/selectors';
import { type State } from '../../../../../redux/types';

const mapStateToProps = (state: State) => ({
  projectID: projectID(state),
  projectsTable: projectsTable(state),
});

const mapDispatchToProps = { sendToSegment };

export const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(StyledProjectsPanelPage);
