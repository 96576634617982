import { JOB_INITIALIZE, JOB_UPDATE_CURRENT_CATEGORY, JOB_UPDATE_CURRENT_JOB_NAME } from './slice';

export const initializeState = (): { type: string } => JOB_INITIALIZE();

export const setCurrentJobName = (
  payload?: string,
): {
  payload?: string;
  type: string;
} => JOB_UPDATE_CURRENT_JOB_NAME(payload);

export const setSelectedCategory = (jobName: string, categoryCode: string) =>
  JOB_UPDATE_CURRENT_CATEGORY({ category: categoryCode, jobName });
