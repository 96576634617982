import { css } from '@emotion/css';

const classes = {
  content: css`
    display: flex;

    > div:not(:last-child) {
      border-right: 1px solid var(--color-omega-accent-3);
    }
  `,
  headerTitleContainer: css`
    align-items: center;
    display: flex;
    gap: var(--space-2);
    justify-content: center;
    padding: var(--space-2) var(--space-3);
    width: 100%;
  `,
  headerTitleText: css`
    color: var(--color-beta-accent-6);
    font-family: 'Open Sans';
    font-size: 34px;
    font-weight: 700;
    line-height: 46px;
    margin: var(--space-0);
  `,
  licenseIcon: css`
    padding: var(--space-1);
  `,
  modal: css`
    float: left;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    & > div.modal-container {
      border-bottom: var(--space-0);

      & > button.modal-close-button {
        height: var(--space-8);
        width: var(--space-8);
      }
    }
  `,
  pricingContainer: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
  `,
  pricingText: css`
    color: var(--color-omega-accent-7);
    height: 58px;
    line-height: 58px;
    margin: var(--space-0);

    span {
      font-size: 48px;
      font-weight: 600;
    }
  `,
};

export default classes;
