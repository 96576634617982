import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type RolesState } from './types';

const sliceRoles = createSlice({
  initialState,
  name: 'roles',
  reducers: {
    ROLES_INITIALIZE() {
      return initialState;
    },
    ROLES_UPDATE(state, action: PayloadAction<RolesState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { ROLES_INITIALIZE, ROLES_UPDATE } = sliceRoles.actions;

export default sliceRoles.reducer;
