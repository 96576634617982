import { type JsonInterface, MachineLearningTask } from '@kili-technology/types';

import { type VideoObjectDetectionAnnotation } from '@/__generated__/globalTypes';
import { broadcastQueriesAfterUpdatingCache, getCacheIdFromGraphQLObject } from '@/graphql/helpers';
import { canChangeClass, type CategoryResponse } from '@/services/jobs/changeClass';
import { useHistoryStore } from '@/zustand-history';

import { addAnnotationInCache } from './cache/addAnnotationInCache';
import { findAnnotationInCache } from './cache/findAnnotationInCache';
import { findAnnotationsInCache } from './cache/findAnnotationsInCache';
import { getAnnotationsRelatedToMidFromCache } from './cache/getAnnotationsRelatedToMidFromCache';
import { isVideoObjectDetectionAnnotation } from './data/validators/isVideoObjectDetectionAnnotation';

import {
  deleteAnnotationsInCache,
  updateCategoryNameAndJobForVideoObjectDetectionAnnotationInCache,
} from '../actions';

type AvailableClassesToChangeSplitPayload = {
  annotation: VideoObjectDetectionAnnotation;
  jsonInterface: JsonInterface | undefined;
  query: string | undefined;
};

export const availableClassesToChangeSplit = (payload: AvailableClassesToChangeSplitPayload) => {
  const { annotation, jsonInterface, query } = payload;
  const { category: categoryCodeForAnnotation, job: jobNameForAnnotation } = annotation;
  const mlTask = MachineLearningTask.OBJECT_DETECTION;
  const jobs = jsonInterface?.jobs;

  if (!jobs) {
    throw new Error('No job interface available to change class');
  }

  const jobOfAnnotation = jobs?.[jobNameForAnnotation];
  const changedLayerTool = jobOfAnnotation?.tools?.[0];

  if (!changedLayerTool) {
    throw new Error('Tool not provided. Impossible to select available classes for change class');
  }

  const allowedJobAndCategoryCodes = Object.entries(jobs)
    .map(([jobName, job]) => {
      const isValidMlTask = mlTask === job.mlTask;
      if (isValidMlTask && canChangeClass(jobName, jsonInterface, changedLayerTool)) {
        const allowedCategories = jobs?.[jobName]?.content?.categories ?? {};
        const currentAllowedJobAndCategoryCodes = Object.keys(allowedCategories).map(
          categoryCode => {
            if (categoryCode === categoryCodeForAnnotation && jobName === jobNameForAnnotation) {
              return null;
            }
            if (!query) {
              return {
                categoryCode,
                categoryName: allowedCategories[categoryCode].name,
                jobName,
              };
            }
            if (allowedCategories[categoryCode].name.toLowerCase().includes(query.toLowerCase())) {
              return {
                categoryCode,
                categoryName: allowedCategories[categoryCode].name,
                jobName,
              };
            }
            return null;
          },
        );
        return currentAllowedJobAndCategoryCodes;
      }
      return [];
    })
    .flat()
    .filter((obj: CategoryResponse | null): obj is CategoryResponse => {
      return !!obj;
    });
  return allowedJobAndCategoryCodes;
};

export const changeAnnotationClassVideoSplit = ({
  categoryCode,
  jobName,
  mid,
  name,
}: {
  categoryCode: string;
  jobName: string;
  mid: string;
  name: string;
}) => {
  const annotationOfMid = findAnnotationInCache(
    (annotation): annotation is VideoObjectDetectionAnnotation =>
      isVideoObjectDetectionAnnotation(annotation) && annotation.mid === mid,
  );

  if (!annotationOfMid) return;

  // First delete all subjobs corresponding to old annotation
  const annotationsToDelete = findAnnotationsInCache(annotation =>
    annotation.path.some(p => p[0] === annotationOfMid.id && p[1] === annotationOfMid.category),
  );
  deleteAnnotationsInCache(annotationsToDelete.map(d => d.id));

  // Then update the category
  updateCategoryNameAndJobForVideoObjectDetectionAnnotationInCache(
    getCacheIdFromGraphQLObject(annotationOfMid),
    categoryCode,
    name,
    jobName,
  );
  broadcastQueriesAfterUpdatingCache();
};

export const changeAnnotationClassVideoSplitWithHistory = ({
  categoryCode,
  jobName,
  mid,
  name,
}: {
  categoryCode: string;
  jobName: string;
  mid: string;
  name: string;
}) => {
  const [annotationOfMid, ...annotationsChildrenOfMid] = getAnnotationsRelatedToMidFromCache(mid);

  const action = {
    name: 'changeAnnotationClassVideoSplit',
    redo: () => {
      changeAnnotationClassVideoSplit({ categoryCode, jobName, mid, name });
    },
    undo: () => {
      changeAnnotationClassVideoSplit({
        categoryCode: annotationOfMid.category,
        jobName: annotationOfMid.job,
        mid,
        name: annotationOfMid.name as string,
      });

      annotationsChildrenOfMid.forEach(subAnnotation => {
        addAnnotationInCache(subAnnotation);
      });
    },
  };

  action.redo();

  useHistoryStore.getState().history.addAction(action);
};
