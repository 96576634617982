import { createStyles, type StyleRules, type Theme, withStyles } from '@material-ui/core/styles';

import PageNotFound from './PageNotFound';

export const styles = (
  theme: Theme,
): StyleRules<'container' | 'appBarSpacer' | 'content' | 'card' | 'bullet' | 'title' | 'pos'> =>
  createStyles({
    appBarSpacer: theme.mixins.toolbar,
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    card: {
      minWidth: 275,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      padding: theme.spacing(3),
    },
    pos: {
      marginBottom: 12,
    },
    title: {
      fontSize: 14,
    },
  });

export default withStyles(styles)(PageNotFound);
