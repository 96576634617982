import { HandClickingClass } from '@kili-technology/cursors';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { type WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { MdNotifications } from 'react-icons/md';

import { type styles } from './NotificationIcon.style';

import Tooltip from '../../../../components/Tooltip';

type PropsType = {
  isBadgeInvisible: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
} & WithStyles<typeof styles>;

const NotificationIcon = ({ classes, onClick, isBadgeInvisible }: PropsType): JSX.Element => (
  <Tooltip title="Notifications">
    <IconButton
      className={`${HandClickingClass} ${classes.notificationIcon}`}
      color="inherit"
      data-cy="notifications-button"
      data-testid="notifications-button"
      onClick={onClick}
      onKeyDown={e => e.preventDefault()}
    >
      <Badge color="error" invisible={isBadgeInvisible} overlap="rectangular" variant="dot">
        <MdNotifications />
      </Badge>
    </IconButton>
  </Tooltip>
);

export default NotificationIcon;
