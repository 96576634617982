import { css } from '@emotion/css';

const useStyles = () => {
  return {
    container: css`
      '& > h6': {
        margin-bottom: var(--space-1);
      },
      align-items: center;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      padding: var(--space-4);
      width: 598px;
      height: 296px;
      gap: var(--space-5);
    `,
    modal: css`
      left: 50%;
      top: 30%;
      transform: translateX(-50%);

      & > div.modal-container {
        border-bottom: 0;
      }
    `,
    sendMessage: css`
      margin-left: auto;
    `,
  };
};
export default useStyles;
