import {
  Tool,
  type JsonInterface,
  type Jobs,
  MachineLearningTask,
  isRelationTask,
  isObjectTask,
} from '@kili-technology/types';
import { dequal } from 'dequal';
import _get from 'lodash/get';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import { type State } from './types';
import { type UserState } from './user/types';

import { InputType } from '../__generated__/globalTypes';
import {
  type Category,
  type TreeStructuredCategory,
  categoriesFromJobCodesList,
  concatCodes,
  createCategoryJobIdsFromJobs,
  getParentFromCategory,
} from '../services/table/category';

const DEFAULT_OBJECT = {};

export const createDeepSelectorFunction = (): typeof createSelector => {
  return createSelectorCreator(defaultMemoize, dequal);
};

export const user = (state: State): UserState | null => state?.user ?? null;

export const userId = createSelector([user], userInStore => _get(userInStore, 'id', ''));

export const userStatus = createSelector([user], userInStore => userInStore?.status);

export const userMatchingDomains = createSelector(
  [user],
  userInStore => userInStore?.matchingDomainOrganizations || [],
);

export const projectJsonInterface = createSelector(
  [state => _get(state, ['project', 'jsonInterface'], '{}')],
  jsonInterface => {
    const jsonInterfaceObject: JsonInterface = JSON.parse(jsonInterface);
    return jsonInterfaceObject;
  },
);

// do not delete this selector, it prevents circular imports
export const projectInputType = createSelector(
  [(state: State) => state?.project ?? {}],
  project => {
    const inputType = project?.inputType;
    return inputType as InputType;
  },
);

export const projectIsVideo = createSelector([projectInputType], inputType => {
  return inputType === InputType.VIDEO;
});

export const projectJobs = createSelector([projectJsonInterface], jsonInterface => {
  const jobsObject: Jobs = _get(jsonInterface, 'jobs', DEFAULT_OBJECT);
  return jobsObject;
});

export const selectProjectTools = createSelector(
  [projectJsonInterface, projectJobs],
  (jsonInterface, jobs) => {
    return Object.values(jobs)
      .map(job => {
        if (job.isModel) return undefined;
        if (isObjectTask(job.mlTask) && jsonInterface.lockObjects === true) return undefined;
        if (isRelationTask(job.mlTask)) return 'relation';
        if (job.mlTask === MachineLearningTask.NAMED_ENTITIES_RECOGNITION) return 'text';
        return job.tools?.[0];
      })
      .filter((v): v is Tool => !!v);
  },
);

export const jobsIncludePoseEstimation = createSelector([projectJobs], jobs =>
  Object.values(jobs).some(
    job => job.mlTask === MachineLearningTask.POSE_ESTIMATION || job.tools?.[0] === Tool.POSE,
  ),
);

export const projectCategories = createSelector([projectJobs], jobs => {
  const keys = Object.keys(jobs);
  return categoriesFromJobCodesList(keys, jobs);
});

export const projectRootCategories = createSelector([projectJobs], jobs => {
  const jobsList = Object.entries(jobs)
    .filter(([_, job]) => !job.isChild && _get(job, 'isVisible', true))
    .map(([jobName, _]) => jobName);
  return categoriesFromJobCodesList(jobsList, jobs);
});

export const projectFirstNestedCategories = createSelector([projectJobs], jobs => {
  const allCategories: { [key: string]: Category[] } = {};
  Object.entries(jobs).forEach(([jobCode, job]) => {
    if (!job.isChild) {
      const categories = _get(job, ['content', 'categories'], {});
      Object.entries(categories).forEach(([categoryCode, category]) => {
        const children = _get(category, 'children', []) as string[];
        allCategories[concatCodes(jobCode, categoryCode)] = categoriesFromJobCodesList(
          children,
          jobs,
        );
      });
    }
  });
  return allCategories;
});

export const treeStructuredCategories = createSelector([projectJobs], jobs => {
  const allCategories = [] as TreeStructuredCategory[];
  Object.entries(jobs).forEach(([jobCode, job]) => {
    const categories = _get(job, ['content', 'categories'], {});
    Object.entries(categories).forEach(([categoryCode, category]) => {
      const childrenJobCodes = _get(category, 'children', []) as string[];
      const childrenFiltered = createCategoryJobIdsFromJobs(jobs, childrenJobCodes);
      allCategories.push({
        categoryId: concatCodes(jobCode, categoryCode),
        children: childrenFiltered,
        jobCode,
        name: category.name,
        parent: getParentFromCategory(jobs, jobCode),
      });
    });
  });
  return allCategories;
});
