import { useFlagsmith } from 'flagsmith/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { flagsStateMap, FLAG_KEY } from './useFlag';

import { isUsingFlagsmith } from '../../config';
import { useUserEmail } from '../../hooks/useUserEmail';
import { organizationId } from '../../redux/organization/selectors';
import { userId } from '../../redux/selectors';

export const useFlagsmithIdentifier = (): void => {
  const flagsmith = useFlagsmith();
  const email = useUserEmail();
  const userIdValue = useSelector(userId);
  const organizationIdValue = useSelector(organizationId);
  useEffect(() => {
    flagsStateMap.set(FLAG_KEY, flagsmith.getState());
    if (email && userIdValue && organizationIdValue && isUsingFlagsmith()) {
      flagsmith.identify(userIdValue, { email, organizationId: organizationIdValue }).then(() => {
        flagsStateMap.set(FLAG_KEY, flagsmith.getState());
      });
    }
  }, [email, flagsmith, organizationIdValue, userIdValue]);
};
