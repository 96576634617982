import { type ObjectAnnotation } from '@kili-technology/types';
import { set } from 'lodash/fp';
import _get from 'lodash/get';

import { type KiliAnnotation } from './setResponse';

import { ANNOTATIONS, MID } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { type FrameResponses, type ChildVideoJob } from '../../redux/label-frames/types';

export const createObjectInFrameResponse = (
  startFrame: number,
  propagationValue: number,
  newAnnotation: KiliAnnotation,
  frameJsonResponses: FrameResponses,
) => {
  const { jobName, mid } = newAnnotation;
  let newResponses = { ...frameJsonResponses };

  Array.from({ length: 1 + propagationValue }, (_, index) => index + startFrame).forEach(
    (frame, index) => {
      const previousAnnotations = _get(
        frameJsonResponses[frame],
        [jobName, ANNOTATIONS],
        [],
      ) as ObjectAnnotation[];
      const filteredPreviousAnnotations = previousAnnotations.filter(
        annotation => _get(annotation, MID) !== mid,
      );

      newResponses = set(
        [frame, jobName, ANNOTATIONS],
        [...filteredPreviousAnnotations, { isKeyFrame: index === 0, ...newAnnotation }],
        newResponses,
      );
    },
  );

  return newResponses;
};

export const createChildVideoJobObjectInFrameResponse = (
  startFrame: number,
  propagationValue: number,
  jobName: string,
  newAnnotation: ChildVideoJob,
  responses: FrameResponses,
) => {
  let newResponses = { ...responses };

  Array.from({ length: 1 + propagationValue }, (_, index) => index + startFrame).forEach(
    (frame, index) => {
      newResponses = set(
        [frame, jobName],
        { ...newAnnotation, isKeyFrame: index === 0 },
        newResponses,
      );
    },
  );

  return newResponses;
};
