import { css } from '@emotion/css';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';

import { RELATION_MARKER_HEIGHT, VERTICAL_SHIFT_FOR_BADGE_ON_POINT } from './constants';

import { transparent } from '../../../../../../theme';
import MarkerBadge from '../KiliMarkerLayer/MarkerBase';

const RelationBadge = (color: string, isLayerMarker: boolean, badgeId: string): L.DivIcon => {
  const border = 1.5;

  const outline = `${border}px solid var(--color-omega-accent-0)`;

  const html = ReactDOMServer.renderToString(
    <div
      className={css`
        margin-top: ${isLayerMarker
          ? -(RELATION_MARKER_HEIGHT + VERTICAL_SHIFT_FOR_BADGE_ON_POINT)
          : -RELATION_MARKER_HEIGHT}px;
        pointer-events: none;
      `}
    >
      <MarkerBadge
        badgeId={badgeId}
        border={border}
        borderRadius={{ type: 'px', value: 2 }}
        color={color}
        height={RELATION_MARKER_HEIGHT}
        marginLeft={-(RELATION_MARKER_HEIGHT / 2)}
        marginTop={RELATION_MARKER_HEIGHT / 2}
        opacity={1}
        outline={outline}
      />
    </div>,
  );
  return L.divIcon({
    className: css`
      background-color: ${transparent};
      border-radius: 50%;
    `,
    html,
    iconSize: [0, 0],
  });
};

export default RelationBadge;
