export const FREE_FEATURES = [
  'Image, video, text, PDFs, large images & rich text labeling interfaces',
  '100 annotations max',
  '2 users max',
  'Quality workflows',
  'Quality and productivity metrics',
  'API and Python SDK (rate limited)',
  'Documentation & Slack Community for support',
];

export const GROW_FEATURES = [
  'Advanced labeling features',
  'API and Python SDk (no rate limit)',
  'Plugins',
  'Data cloud storage',
  'Support level adapted to your needs',
  'Accessible professional services',
];

export const ENTERPRISE_FEATURES = [
  'SSO and on-premise hosting',
  'Custom contract & terms',
  'Professional services',
  'Dedicated customer success representative',
];
