/*
 * Allow to abort a promise, ie reject the promise with an 'AbortError' instead.
 *
 * This is especially useful for promises called inside React effects not to set component states
 * after they have been unmounted.
 */

class AbortError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AbortError';
  }
}

export function abortable<PromiseResult>(
  basePromise: Promise<PromiseResult>,
): [Promise<PromiseResult>, () => void] {
  const abortController = new AbortController();
  const { signal } = abortController;

  const promise = new Promise<PromiseResult>((resolve, reject) => {
    const throwAbortError = () => reject(new AbortError('Promise has been aborted.'));

    signal.addEventListener('abort', throwAbortError);

    basePromise
      .then(result => {
        if (!signal.aborted) {
          resolve(result);
        }
      })
      .catch(error => {
        if (!signal.aborted) {
          reject(error);
        }
      })
      .finally(() => {
        signal.removeEventListener('abort', throwAbortError);
      });
  });

  return [promise, () => abortController.abort()];
}

export function isAbortError(input: unknown): input is AbortError {
  return input instanceof AbortError;
}
