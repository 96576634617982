import { createStyles, type StyleRules, withStyles } from '@material-ui/core/styles';

import Tooltip from './Tooltip';

export const styles = (): StyleRules<'tooltip'> =>
  createStyles({
    tooltip: {
      display: 'flex',
      padding: 0,
    },
  });

export default withStyles(styles)(Tooltip);
