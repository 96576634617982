/* eslint-disable no-underscore-dangle */
import { Check as FinishedPose } from '@kili-technology/icons';
import { type PoseEstimationPoint } from '@kili-technology/types';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';

import Pose from './BasePose';
import { type PoseEstimation } from './KiliPoseEstimation';
import { POSE_POINT_CHANGE_EVENT, UNDO_POINT_EVENT } from './events';

import { emit } from '../../../../../../hooks/useCustomEventListener';
import { DURATION_FINISHED_POSE_DISPLAYED } from '../../../../../../services/poseEstimation';
import { generateNewCreatingOrEditingObjectId, useStore } from '../../../../../../zustand';
import MarkerCircle from '../../Components/MarkerIcons/MarkerCircle';
import PoseCursor from '../../Components/MarkerIcons/PoseCursor';
import layersByMid from '../../ImageAnnotations/LayerMap';
import MarkerBase from '../KiliMarkerLayer/MarkerBase';

L.Edit.PolyVerticesEditPose = L.Edit.PolyVerticesEdit.extend({
  _createMarker(latlng: L.LatLng, index: number) {
    // @ts-expect-error Extending L.Marker in TouchEvents.js to include touch.
    const marker = new L.Marker.Touch(latlng, {
      draggable: true,
      icon: this.options.icon,
    });

    marker._origLatLng = latlng;
    marker._index = index;

    marker
      .on('dragstart', this._onMarkerDragStart, this)
      .on('drag', this._onMarkerDrag, this)
      .on('dragend', this._fireEdit, this)
      .on('touchmove', this._onTouchMove, this)
      .on('touchend', this._fireEdit, this)
      .on('MSPointerMove', this._onTouchMove, this)
      .on('MSPointerUp', this._fireEdit, this);

    this._markerGroup.addLayer(marker);

    return marker;
  },

  _createMiddleMarker() {
    // Do not create middle edit markers for pose estimation
  },

  _onMarkerClick() {},

  initialize(
    poly: L.Polyline,
    latlngs: L.LatLngExpression[],
    options: L.EditOptions.EditPolyVerticesEditOptions,
    points: PoseEstimationPoint[],
  ) {
    // if touch, switch to touch icon
    if (L.Browser.touch) {
      this.options.icon = this.options.touchIcon;
    }
    this._poly = poly;

    this._latlngs = latlngs;
    this.points = points;

    L.setOptions(this, options);
  },
});

L.Edit.PoseEstimation = L.Edit.Poly.extend({
  _initHandlers() {
    const poly = this._poly;
    const { poseOptions } = poly;
    const { allPoints } = poseOptions;
    if (!allPoints) {
      return;
    }
    this._verticesHandlers = [];
    for (let i = 0; i < this.latlngs.length; i += 1) {
      this._verticesHandlers.push(
        new L.Edit.PolyVerticesEditPose(
          this._poly,
          this.latlngs[i],
          this._poly.options.poly,
          allPoints,
        ),
      );
    }
  },

  addHooks() {
    this._poly.removeHooks();
    this._initHandlers();
    this._eachVertexHandler((handler: L.Handler) => {
      handler.addHooks?.();
    });
  },

  getNewDrawer(): L.Draw.PoseEstimation {
    const poly = this._poly;
    const { poseOptions, options, kiliOptions } = poly;
    const { allPoints, pointIndicesDrawn } = poseOptions;
    return new L.Draw.PoseEstimation(poly._map, {
      // autoEnable: false,
      categories: kiliOptions?.categories,
      isEditing: true,
      jobName: kiliOptions?.jobName,
      pointIndicesDrawn,
      posePoints: (allPoints as PoseEstimationPoint[]).map(point => ({
        ...point,
        color: options.color,
      })),
      repeatMode: true,
      shapeOptions: {
        color: options.color,
      },
    });
  },

  initialize(poly: PoseEstimation) {
    this.canKeepDrawing = poly.canKeepDrawing();
    this.isFinished = poly.isFinished;
    // @ts-expect-error _latlngs is not declared (private property)
    this.latlngs = [poly._latlngs];

    this._poly = poly;

    this._poly.on('revert-edited', this._updateLatLngs, this);
    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        if (this.drawer) this.drawer.removeHooks();
      }
    });
  },

  removeHooks() {
    this._poly.fire('mouseout');
    this._eachVertexHandler((handler: L.Handler) => {
      handler.removeHooks?.();
    });
    this._poly.addHooks();
  },
});

L.Draw.PoseEstimation = L.Draw.Polyline.extend({
  Pose,

  _clearGuides() {
    if (this.guide) {
      this._map.removeLayer(this.guide);
      delete this.guide;
    }
  },

  _drawGuide(pointA: L.Point, pointB: L.Point) {
    const latlngA = this._map.layerPointToLatLng(pointA);
    const latlngB = this._map.layerPointToLatLng(pointB);
    const newLatLngs = [latlngA, latlngB];
    if (this.guide) {
      this.guide._setLatLngs(newLatLngs);
      this.guide.redraw();
    } else {
      this.guide = new L.Polyline(newLatLngs, {
        color: '#3388ff',
        dashArray: '5, 5',
        dashOffset: '0',
      });
      this._map.addLayer(this.guide);
      if (this.guide._path) {
        L.DomUtil.addClass(this.guide._path, 'leaflet-pose-estimation-line');
      }
    }
  },

  _endPoint(clientX: number, clientY: number, e: L.LeafletMouseEvent) {
    if (this._mouseDownOrigin) {
      const dragCheckDistance = L.point(clientX, clientY).distanceTo(this._mouseDownOrigin);
      if (Math.abs(dragCheckDistance) > 9) {
        this._enableNewMarkers(); // after a short pause, enable new markers
        this._mouseDownOrigin = null;
        return;
      }
      const pointOnOverlay = this._map.projectPointOnOverlay(e.latlng);
      this.addVertex(pointOnOverlay);
      this._enableNewMarkers(); // after a short pause, enable new markers
      const shouldConsiderEvent = e?.target?._container?.id !== 'map';
      if (shouldConsiderEvent) {
        this.pointIndicesDrawn.push(this.pointIndex);
        this._fireCreatedEvent();
        const isPoseCompleted = this.jumpOnePoint();
        if (isPoseCompleted) {
          this.completePose();
        }
        this._clearGuides();
      }
    }
    this._mouseDownOrigin = null;
  },

  _fireCreatedEvent() {
    const latlngs = this._poly.getLatLngs();
    const basePoseLayer = new Pose(latlngs, {
      ...this.options.shapeOptions,
      ...this.options,
      allPoints: this.options.posePoints,
      isFinished: this.isPoseCompleted(),
      isPose: true,
      pointIndicesDrawn: this.pointIndicesDrawn,
    });
    L.Draw.Feature.prototype._fireCreatedEvent.call(this, basePoseLayer);
  },

  _updateGuide(newPos: L.Point) {
    const markerCount = this._markers ? this._markers.length : 0;

    if (markerCount > 0) {
      const effectiveNewPosition = newPos || this._map.latLngToLayerPoint(this._currentLatLng);

      // draw the guide line
      this._drawGuide(
        this._map.latLngToLayerPoint(this._markers[markerCount - 1].getLatLng()),
        effectiveNewPosition,
      );
    }
  },

  addHooks() {
    if (L.Draw.Polyline.prototype.addHooks) {
      L.Draw.Polyline.prototype.addHooks.call(this);
      this._poly.on('add', () => {
        L.DomUtil.addClass(this._poly._path, 'leaflet-pose-estimation-line');
      });
    }
    this.getCurrentMouseMarker();
    this._map.on(UNDO_POINT_EVENT, this.undoOrRedoPoint, this);

    const skipOnePointHandler = this.skipOnePoint.bind(this);
    this.skipOnePointHandler = skipOnePointHandler;
    document.body.addEventListener('skipPosePoint', skipOnePointHandler);
    if (this._path) {
      L.DomUtil.addClass(this._path, 'leaflet-pose-estimation-line');
    }
  },

  clearFinishedCursor(finishedCursor: L.Marker | undefined) {
    if (finishedCursor) {
      this._map.removeLayer(finishedCursor);
    }
  },

  completePose() {
    const success = this.makeFinishedCursor();
    const { finishedCursor } = this;
    if (success) {
      setTimeout(() => {
        this.clearFinishedCursor(finishedCursor);
      }, DURATION_FINISHED_POSE_DISPLAYED);
    }
    this.disable();
    this.resetPoseData();
    this.enable();
  },

  disable() {
    if (!this._enabled) {
      return;
    }
    L.Handler.prototype.disable.call(this);
    this._map.fire(L.Draw.Event.DRAWSTOP, { layerType: this.type });
  },

  enable() {
    if (this._enabled) {
      return;
    }

    L.Handler.prototype.enable.call(this);
    this._map.fire(L.Draw.Event.DRAWSTART, { layerType: this.type });
  },

  getBaseMarkerIcon() {
    const currentPoint = this.getCurrentPoint();
    const { color } = currentPoint;
    const markerHeight = 14;
    const margin = 1;
    const border = 1;
    return (
      <MarkerBase
        border={border}
        color={color}
        height={markerHeight - 2 * (margin + border)}
        marginLeft={margin}
        marginTop={margin}
        opacity={1}
      />
    );
  },

  getCurrentMouseMarker() {
    const currentPoint = this.getCurrentPoint();
    emit(POSE_POINT_CHANGE_EVENT, currentPoint);
    const { name, color } = currentPoint;

    if (!currentPoint || !name) {
      return;
    }

    const marker = (
      <div>
        {this.useCircleCursor ? <MarkerCircle /> : this.getBaseMarkerIcon()}
        <PoseCursor color={color} name={name} />
      </div>
    );
    if (!this._mouseMarker) {
      return;
    }
    const newMarkerPosition = this._mouseMarker.getLatLng();
    this.removeMouseMarker();
    this._mouseMarker = L.marker(newMarkerPosition, {
      icon: L.divIcon({
        className: 'leaflet-mouse-marker-pose',
        html: ReactDOMServer.renderToString(marker),
        iconSize: [14, 14],
      }),
      opacity: 1,
      zIndexOffset: this.options.zIndexOffset,
    });
    this._mouseMarker
      .on('mouseout', L.Draw.Polyline.prototype._onMouseOut, this)
      .on('mousemove', L.Draw.Polyline.prototype._onMouseMove, this) // Necessary to prevent 0.8 stutter
      .on('mousedown', L.Draw.Polyline.prototype._onMouseDown, this)
      .on('mouseup', L.Draw.Polyline.prototype._onMouseUp, this) // Necessary for 0.8 compatibility
      .addTo(this._map);
  },

  getCurrentPoint(): PoseEstimationPoint | undefined {
    return this.options?.posePoints?.[this.pointIndex] ?? {};
  },

  initialize(
    map: L.DrawMap,
    options:
      | false
      | {
          pointIndicesDrawn?: number[];
          posePoints: PoseEstimationPoint[];
          repeatMode: boolean;
          shapeOptions: {
            color: string;
          };
        },
  ) {
    if (!options) {
      return;
    }
    L.Draw.Polyline.prototype.initialize.call(this, map, options);
    this.type = 'pose';
    this.maxPoints = this.options?.posePoints?.length;
    const pointIndicesDrawn = options?.pointIndicesDrawn ?? [];
    this.pointIndex = pointIndicesDrawn.length > 0 ? (pointIndicesDrawn.at(-1) ?? -1) + 1 : 0;
    this.pointIndicesDrawn = pointIndicesDrawn;
    this.useCircleCursor = true;
  },

  isPoseCompleted() {
    return this.pointIndex + 1 === this.maxPoints;
  },

  jumpOnePoint() {
    const isPoseCompleted = this.isPoseCompleted();
    if (this.pointIndex >= this.maxPoints - 1) {
      return isPoseCompleted;
    }
    this.pointIndex += 1;
    this.getCurrentMouseMarker();
    return isPoseCompleted;
  },

  lastIndexDrawn(): number {
    return this.pointIndicesDrawn[this.pointIndicesDrawn.length - 1] ?? 0;
  },

  makeFinishedCursor() {
    if (!this._currentLatLng) {
      return false;
    }
    this.finishedCursor = L.marker(this._currentLatLng, {
      icon: L.divIcon({
        className: 'leaflet-mouse-marker-pose',
        html: ReactDOMServer.renderToString(
          <FinishedPose
            data-cy="pose-finished-icon"
            size={10}
            stroke="var(--color-omega-accent-0)"
            style={{
              background: 'black',
              borderRadius: '50%',
              marginLeft: 'var(--space-4)',
              marginTop: 'var(--space-4)',
            }}
          />,
        ),
        iconSize: [14, 14],
      }),
      opacity: 1,
      zIndexOffset: this.options.zIndexOffset,
    });
    this._map.addLayer(this.finishedCursor);
    return true;
  },

  options: {
    icon: new L.DivIcon({
      className: 'pose-marker',
      iconSize: new L.Point(10, 10),
    }),
  },

  removeHooks() {
    if (this._tooltip) {
      if (L.Draw.Polyline.prototype.removeHooks) {
        L.Draw.Polyline.prototype.removeHooks.call(this);
      }
    }
    if (this._mouseMarker) {
      this.removeMouseMarker();
    }
    this._map.off('changedCircleCursor');
    this._map.off(UNDO_POINT_EVENT, this.undoOrRedoPoint, this);
    emit(POSE_POINT_CHANGE_EVENT, null);
    const skipOnePointHandlerToRemove = this.skipOnePointHandler || this.skipOnePoint.bind(this);
    document.body.removeEventListener('skipPosePoint', skipOnePointHandlerToRemove);
    this.resetPoseData();
  },

  removeMouseMarker() {
    this._mouseMarker
      .off('mouseout', L.Draw.Polyline.prototype._onMouseOut, this)
      .off('mousemove', L.Draw.Polyline.prototype._onMouseMove, this)
      .off('mousedown', L.Draw.Polyline.prototype._onMouseDown, this)
      .off('mouseup', L.Draw.Polyline.prototype._onMouseUp, this);
    this._map.removeLayer(this._mouseMarker);
    this._mouseMarker.remove();
    delete this._mouseMarker;
  },

  resetPoseData() {
    this.pointIndex = 0;
    this.pointIndicesDrawn = [];
  },

  skipOnePoint() {
    if (this.getCurrentPoint()?.mandatory) {
      return;
    }
    const isPoseCompleted = this.jumpOnePoint();
    if (isPoseCompleted) {
      this.completePose();
      generateNewCreatingOrEditingObjectId();
    }
  },

  statics: {
    TYPE: 'pose',
  },

  type: 'pose',

  undoOrRedoPoint(event: { shift: boolean }) {
    const { shift } = event;
    if (shift) {
      const { creatingOrEditingObjectId } = useStore.getState().labelInterface;
      if (!creatingOrEditingObjectId) return;
      const poseLayer = layersByMid.get(creatingOrEditingObjectId)?.[0] as PoseEstimation;
      if (!poseLayer) return;
      const newLatLngs = poseLayer.getLatLngs();
      const latestPoint = newLatLngs.at(-1);
      if (!latestPoint) return;
      const pointOnOverlay = this._map.projectPointOnOverlay(latestPoint);
      this.addVertex(pointOnOverlay);
      this._enableNewMarkers(); // after a short pause, enable new markers
      this.pointIndicesDrawn.push(this.pointIndex);
      this.jumpOnePoint();
      this.updateGuide();
      return;
    }
    this.pointIndicesDrawn.pop();
    const lastMarker = this._markers.pop();
    if (lastMarker) this._map.removeLayer(lastMarker);
    if (this.pointIndex > 0) {
      this.pointIndex -= 1;
    } else {
      this.pointIndex = 0;
    }
    this._poly._latlngs.pop();
    this.updateGuide();
    this.getCurrentMouseMarker();
  },

  updateGuide() {
    this._clearGuides();
    this._poly.redraw();
    this._updateGuide();
  },
});
