import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { type LicenseType } from '../../__generated__/globalTypes';
import { environment, isDevelopmentEnvironment, isOnPremise } from '../../config';
import { dataForScreeb } from '../../redux/organization/selectors';
import { user as userSelector } from '../../redux/selectors';
import { type UserPartial } from '../../redux/user/types';

type OrganizationDataForScreeb = {
  organizationId: string;
  organizationName: string;
  plan: LicenseType;
};

async function trackUserUsingScreeb(
  user: UserPartial,
  organizationData: OrganizationDataForScreeb | null,
) {
  const { id: userId, email, firstname, lastname, organizationRole } = user;
  const emailDomain = email && email.substring(email.lastIndexOf('@') + 1);
  await $screeb('init', 'a08295ae-300b-44bd-819a-1ea695927d2c', {
    identity: {
      id: userId,
      properties: {
        email,
        emailDomain,
        environment,
        firstname,
        lastname,
        loggingDate: new Date(),
        organizationRole,
        ...organizationData,
      },
    },
  });
}

export default function useScreebTracking() {
  const user = useSelector(userSelector);
  const organizationData = useSelector(dataForScreeb, shallowEqual);

  useEffect(() => {
    if (!user || !organizationData || isOnPremise() || isDevelopmentEnvironment()) return;
    trackUserUsingScreeb(user, organizationData);
  }, [user, organizationData]);
}
