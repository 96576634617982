import { gql, disableFragmentWarnings } from '@apollo/client';
import { type DocumentNode } from 'graphql';

import { SIMPLE_LABEL_FRAGMENTS } from './fragments';

disableFragmentWarnings();

export const GQL_ASSETS_INSPECT = (fragment: DocumentNode): DocumentNode => gql`
  query Assets($where: AssetWhere!, $skip: Int!, $first: PageSize!) {
    assets(where: $where, skip: $skip, first: $first) {
      ...AssetFragment
    }
  }
  ${fragment}
`;

export const GQL_ASSETS_COUNT_INSPECT = gql`
  query CountAssets($where: AssetWhere!) {
    count: countAssets(where: $where)
  }
`;

export const GQL_ASSETS_COUNTS_BY_HONEYPOT = gql`
  query AssetsCountByHoneypot($whereHoneypot: AssetWhere!) {
    countHoneypot: countAssets(where: $whereHoneypot)
  }
`;

export const GQL_ASSETS_COUNTS_BY_STATUSES = gql`
  query AssetsCountByStatuses(
    $whereLabeled: AssetWhere!
    $whereOngoing: AssetWhere!
    $whereReviewed: AssetWhere!
    $whereTodo: AssetWhere!
    $whereToReview: AssetWhere!
    $skipLabeled: Boolean!
    $skipOngoing: Boolean!
    $skipReviewed: Boolean!
    $skipTodo: Boolean!
    $skipToReview: Boolean!
  ) {
    countLabeled: countAssets(where: $whereLabeled) @skip(if: $skipLabeled)
    countOngoing: countAssets(where: $whereOngoing) @skip(if: $skipOngoing)
    countReviewed: countAssets(where: $whereReviewed) @skip(if: $skipReviewed)
    countTodo: countAssets(where: $whereTodo) @skip(if: $skipTodo)
    countToReview: countAssets(where: $whereToReview) @skip(if: $skipToReview)
  }
`;

export const GQL_DATASET_GET_ASSET_IS_LOCKED = gql`
  query GetAssetCanBeLockedBy($assetID: ID!, $userId: ID!) {
    asset(id: $assetID) {
      id
      canBeLockedBy(userId: $userId)
    }
  }
`;

export const GQL_DATASET_GET_ASSET = gql`
  query GetAsset($assetID: ID!) {
    asset(id: $assetID) {
      id
      content
      dataIntegrationId
      isHoneypot
      isProcessingAuthorized
      jsonContent
      jsonMetadata
      externalId
      ocrMetadata
      status
      skipped
      projectId
      toBeLabeledBy {
        id
        user {
          id
        }
      }
    }
  }
`;

export const GQL_GET_ASSET_WITH_URLS = gql`
  query GetAssetWithUrls($assetID: ID!) {
    asset(id: $assetID) {
      id
      content
      jsonContent
      ocrMetadata
      thumbnail
    }
  }
`;

export const GQL_GET_ASSET_METADATA_VALUES = gql`
  query GetAssetsMetadataValues($projectID: ID!, $metadataKey: String!) {
    getAssetsMetadataValues(projectID: $projectID, metadataKey: $metadataKey)
  }
`;

export const GQL_GET_NEXT_ASSET_FROM_PROJECT = gql`
  query GetNextAssetFromProject($projectID: ID!) {
    getNextAssetFromProject(projectID: $projectID) {
      id
    }
  }
`;

export const GQL_GET_NEXT_ASSET_FOR_REVIEW_FROM_PROJECT = gql`
  query GetNextAssetForReview($projectID: ID!) {
    getNextAssetForReviewFromProject(projectID: $projectID) {
      id
    }
  }
`;

export const GQL_GET_NEXT_ASSET_FROM_LABEL = gql`
  query GetNextAssetFromLabel(
    $labelAssetIDs: [ID!]
    $nonSubmittedViewedAssetIds: [ID!]!
    $projectId: ID!
  ) {
    getNextAssetFromLabel(
      labelAssetIDs: $labelAssetIDs
      nonSubmittedViewedAssetIds: $nonSubmittedViewedAssetIds
      projectId: $projectId
    ) {
      id
    }
  }
`;

export const GQL_GET_NEXT_ASSET_FOR_REVIEW_FROM_LABEL = gql`
  query GetNextAssetForReviewFromLabel($labelAssetIDs: [ID!], $projectId: ID!) {
    getNextAssetForReviewFromLabel(labelAssetIDs: $labelAssetIDs, projectId: $projectId) {
      id
    }
  }
`;

export const GQL_GET_LABEL = gql`
  query GetLabel($where: LabelWhere!, $skip: Int!, $first: PageSize!) {
    labels(where: $where, skip: $skip, first: $first) {
      id
      author {
        email
        id
        firstname
        lastname
      }
      jsonResponse
      labelType
      secondsToLabel
      clientVersion
    }
  }
`;

export const GQL_LABELS_GET_AND_COUNT = gql`
  query Labels($where: LabelWhere!, $skip: Int!, $first: PageSize!) {
    data: labels(where: $where, skip: $skip, first: $first) {
      ...LabelWithAssetInfos
    }
    count: countLabels(where: $where)
  }
  ${SIMPLE_LABEL_FRAGMENTS}
`;

export const GQL_GET_LABELS_FOR_USER = gql`
  query GetLabelsOrPredictionsForUser($assetID: ID!, $userID: ID!, $labelID: ID) {
    getLabelsOrPredictionsForUser(assetID: $assetID, userID: $userID, labelID: $labelID) {
      id
      author {
        email
        id
        firstname
        lastname
      }
      jsonResponse
      labelType
      secondsToLabel
      clientVersion
    }
  }
`;

export const GQL_CREATE_UPLOAD_BUCKET_SIGNED_URLS = gql`
  query CreateUploadBucketSignedUrls($filePaths: [String!]!) {
    urls: createUploadBucketSignedUrls(filePaths: $filePaths)
  }
`;
