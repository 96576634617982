import _get from 'lodash/get';

import { type User } from '../../__generated__/globalTypes';
import { type State } from '../types';

export const authenticationEmail = (state: State): User['email'] | null =>
  _get(state, 'authentication.email', '');

export const authenticationIsAuthenticated = (state: State): boolean =>
  _get(state, 'authentication.isAuthenticated', false);

export const authenticationToken = (state: State): string | null =>
  _get(state, 'authentication.token', '');
