import { type Jobs, MachineLearningTask } from '@kili-technology/types';

import { ANY_RELATION_VALUE } from '../../components/InterfaceBuilder/FormInterfaceBuilder/JobCategory/JobCategoryRelation';

export interface RelationCategory {
  categoryCode: string;
  jobName: string;
  mlTask: MachineLearningTask;
}

export type RelationCategoryWithRelationMid = RelationCategory & {
  relationMid: string;
};

export interface RelationCategories {
  end: RelationCategory[];
  start: RelationCategory[];
}

export type RelationCategoriesWithRelationMid = {
  end: RelationCategoryWithRelationMid[];
  start: RelationCategoryWithRelationMid[];
};

export type JobCategoryToRelationCategories = Record<string, RelationCategories>;

const canCategoryBeInRelationStep = (categoryCode: string, stepRelationCategories: string[]) => {
  return (
    stepRelationCategories.includes(categoryCode) ||
    stepRelationCategories.includes(ANY_RELATION_VALUE)
  );
};

const getRelationCategoriesInWhichCategoryCanBePresent = (
  categoryCode: string,
  relationJobs: Jobs,
): RelationCategories => {
  const relationsOfCategory: RelationCategories = { end: [], start: [] };
  Object.entries(relationJobs).forEach(([jobName, job]) => {
    Object.entries(job?.content?.categories ?? {}).forEach(
      ([relationJobCategoryCode, relationCategory]) => {
        const isObjectRelation = !!relationCategory?.endObjects;
        const relationTask = isObjectRelation
          ? MachineLearningTask.OBJECT_RELATION
          : MachineLearningTask.NAMED_ENTITIES_RELATION;
        const endCategories = (relationCategory?.endEntities ?? []).concat(
          relationCategory?.endObjects ?? [],
        );
        if (canCategoryBeInRelationStep(categoryCode, endCategories)) {
          relationsOfCategory.end.push({
            categoryCode: relationJobCategoryCode,
            jobName,
            mlTask: relationTask,
          });
        }
        const startCategories = (relationCategory?.startEntities ?? []).concat(
          relationCategory?.startObjects ?? [],
        );
        if (canCategoryBeInRelationStep(categoryCode, startCategories)) {
          relationsOfCategory.start.push({
            categoryCode: relationJobCategoryCode,
            jobName,
            mlTask: relationTask,
          });
        }
      },
    );
  });
  return relationsOfCategory;
};

export default function buildObjectCategoryToPossibleRelationCategories(
  detectionCategories: string[],
  relationJobs: Jobs,
): JobCategoryToRelationCategories {
  return Object.fromEntries(
    detectionCategories.map(categoryCode => [
      categoryCode,
      getRelationCategoriesInWhichCategoryCanBePresent(categoryCode, relationJobs),
    ]),
  );
}
