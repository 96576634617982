/* eslint-disable no-underscore-dangle */
import { LabelVersion } from '@kili-technology/types';
import L, { type Layer } from 'leaflet';

import { SegmentEvents } from '../../../../../pages/RootModule/helpers';
import { sendToSegment } from '../../../../../redux/application/actions';
import { handleRelation } from '../../../../../redux/jobs/actions';
import { store } from '../../../../../store';
import { useStore, replaceAllWithSelectedObjectId } from '../../../../../zustand';
import { selectIsADrawToolSelected } from '../../../../../zustand/label-interface/selectors';
import { type PopupState } from '../KiliLeafletLayers/ImagePopup/types';
import {
  type HiddableLayer,
  isGroupLayer,
  isKiliLayer,
  type KiliLayer,
} from '../KiliLeafletLayers/types';

type GetEventHandlersPayload = {
  setPopup: (state: PopupState) => void;
};

export const clickOnLayerWithEventHandler = (event: L.LeafletMouseEvent, layer: HiddableLayer) => {
  const layerHasBeenSelected = clickOnLayer(layer);
  if (layerHasBeenSelected) {
    event.originalEvent.stopPropagation();
    L.DomEvent.stop(event);
  }
};

const clickOnLayer = (layer: HiddableLayer): boolean => {
  const { mid: layerMid, map: kiliOptionsMap } = layer?.kiliOptions ?? {};
  const isADrawToolSelected = selectIsADrawToolSelected(useStore.getState());
  if (isADrawToolSelected) {
    return false;
  }

  const { labelInterface } = useStore.getState();

  const isEditingRelation =
    labelInterface.grantedTools.Relation?.isSelected && !!labelInterface.creatingOrEditingObjectId;

  if (isEditingRelation) {
    store.dispatch(handleRelation(layerMid ?? ''));
    return true;
  }

  const loopedLayers: Record<string, boolean> = {};

  const updatePrediction = (sublayer: Layer) => {
    if (loopedLayers[sublayer._leaflet_id]) return;
    loopedLayers[sublayer._leaflet_id] = true;
    if (isGroupLayer(sublayer)) {
      loopThroughEachLayers(sublayer);
    }
    if (sublayer._layers) {
      Object.values(sublayer._layers).forEach(l => l && updatePrediction(l));
    }

    if (!isKiliLayer(sublayer)) {
      return;
    }
    const localLayerMid = sublayer?.kiliOptions?.mid;
    if (
      localLayerMid === layerMid &&
      sublayer.kiliOptions &&
      sublayer.kiliOptions.labelVersion !== LabelVersion.DEFAULT
    ) {
      // eslint-disable-next-line no-param-reassign
      sublayer.kiliOptions.labelVersion = LabelVersion.DEFAULT;
      // eslint-disable-next-line no-param-reassign
      sublayer.kiliOptions.dashArrayProperty = '';
      sublayer?.setStyle?.({
        dashArray: '',
      });
    }
  };

  const loopThroughEachLayers = (layerContainer: L.LayerGroup | L.Map) => {
    layerContainer.eachLayer(subLayer => {
      updatePrediction(subLayer as KiliLayer);
    });
  };

  // @ts-expect-error: We shouldn't access protected _map property
  loopThroughEachLayers(kiliOptionsMap ?? layer._map);

  if (layer.editing) {
    layer.editing.enable();
    layer.resetEdit?.();
  }
  if (layerMid) {
    replaceAllWithSelectedObjectId({ mid: layerMid });
  }

  return true;
};

const getEventHandlers = ({ setPopup }: GetEventHandlersPayload): L.LeafletEventHandlerFnMap => ({
  click: (event: L.LeafletMouseEvent) => {
    event.originalEvent.stopPropagation();
    const { layer } = event;
    const layerHasBeenSelected = clickOnLayer(layer as HiddableLayer);
    if (layerHasBeenSelected) {
      L.DomEvent.stop(event);
    }
  },
  contextmenu: (event: L.LeafletMouseEvent) => {
    store.dispatch(sendToSegment({ event: SegmentEvents.OBJECT_RIGHT_CLICK }));
    L.DomEvent.stop(event);
    const { layer, latlng } = event;
    const layerMid = layer?.kiliOptions?.mid;
    if (!isKiliLayer(layer)) {
      return;
    }
    layer?.editing?.enable();
    if (layerMid) {
      replaceAllWithSelectedObjectId({ mid: layerMid });
    }
    setPopup({ clickedLayer: layer, position: latlng });
  },
});

export default getEventHandlers;
