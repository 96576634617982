import {
  type Job,
  type JsonInterface,
  MachineLearningTask,
  Tool,
  type EntityRelation,
  type ObjectRelation,
} from '@kili-technology/types';

import { type AvailableClassesToChangePayload } from './type';

import { ANY_RELATION_VALUE } from '../../components/InterfaceBuilder/FormInterfaceBuilder/JobCategory/JobCategoryRelation';
import { type KiliLayer } from '../../components/asset-ui/Image/LeafletMapv2/KiliLeafletLayers/types';
import { getAnnotationFromMid } from '../../redux/jobs/helpers';
import { jobsResponses } from '../../redux/jobs/selectors';
import { store } from '../../store';

export type CategoryResponse = {
  categoryCode: string;
  categoryName: string;
  jobName: string;
};

const canJobChangeClass = (job: Job, changedLayerTool: Tool | undefined): boolean => {
  if (job.isVisible !== undefined && !job.isVisible) {
    return false;
  }
  if (job.mlTask === MachineLearningTask.POSE_ESTIMATION) {
    return false;
  }
  if (job.mlTask === MachineLearningTask.OBJECT_DETECTION && changedLayerTool) {
    return (
      !!job.tools &&
      (!changedLayerTool ||
        job.tools.includes(changedLayerTool) ||
        (changedLayerTool === Tool.POLYGON && job.tools.includes(Tool.SEMANTIC)))
    );
  }
  return true;
};

export const canChangeClass = (
  jobName: string | undefined,
  jsonInterface: JsonInterface,
  changedLayerTool: Tool | undefined,
): boolean => {
  if (!jobName) {
    return true;
  }
  const job = jsonInterface?.jobs?.[jobName];

  if (!job) {
    return false;
  }

  return canJobChangeClass(job, changedLayerTool);
};

export const availableClassesToChange = (
  payload: AvailableClassesToChangePayload,
): CategoryResponse[] => {
  const { annotation, jsonInterface, query, noForbiddenJobName } = payload;
  const changedLayerTool = annotation?.type;
  const forbiddenCategories = annotation?.categories;
  const forbiddenJobName = noForbiddenJobName ? undefined : annotation?.jobName;
  const jobs = jsonInterface?.jobs;
  const mlTask = annotation?.mlTask;
  let startRelationCategory: string | undefined;

  if (mlTask === MachineLearningTask.OBJECT_RELATION) {
    startRelationCategory = getAnnotationFromMid(
      (annotation as ObjectRelation)?.startObjects?.[0].mid,
      jobsResponses(store.getState()),
    )?.categories?.[0]?.name;
  } else if (mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION) {
    startRelationCategory = getAnnotationFromMid(
      (annotation as EntityRelation)?.startEntities?.[0].mid,
      jobsResponses(store.getState()),
    )?.categories?.[0]?.name;
  }

  if (!jobs) {
    return [];
  }
  const allowedJobAndCategoryCodes = Object.entries(jobs)
    .map(([jobName, job]) => {
      const isValidMlTask = !mlTask || mlTask === job.mlTask;
      if (isValidMlTask && canChangeClass(jobName, jsonInterface, changedLayerTool)) {
        const allowedCategories = jobs?.[jobName]?.content?.categories ?? {};
        const currentAllowedJobAndCategoryCodes = Object.entries(allowedCategories).map(
          ([categoryCode, category]) => {
            if (
              !!forbiddenCategories &&
              jobName === forbiddenJobName &&
              forbiddenCategories[0].name === categoryCode
            ) {
              return null;
            }
            if (
              mlTask === MachineLearningTask.OBJECT_RELATION &&
              startRelationCategory &&
              !category.startObjects?.includes(ANY_RELATION_VALUE) &&
              !category.startObjects?.includes(startRelationCategory)
            ) {
              return null;
            }
            if (
              mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION &&
              startRelationCategory &&
              !category.startEntities?.includes(ANY_RELATION_VALUE) &&
              !category.startEntities?.includes(startRelationCategory)
            ) {
              return null;
            }
            if (!query) {
              return {
                categoryCode,
                categoryName: category.name,
                jobName,
              };
            }
            if (category.name.toLowerCase().includes(query.toLowerCase())) {
              return {
                categoryCode,
                categoryName: category.name,
                jobName,
              };
            }
            return null;
          },
        );
        return currentAllowedJobAndCategoryCodes;
      }
      return [];
    })
    .flat()
    .filter((obj: CategoryResponse | null): obj is CategoryResponse => {
      return !!obj;
    });
  return allowedJobAndCategoryCodes;
};

export const canChangeLayerClass = (
  layer: KiliLayer & {
    options?: { mlTask?: MachineLearningTask };
  },
): boolean => {
  if (layer?.options?.mlTask !== undefined) {
    return layer?.options?.mlTask !== MachineLearningTask.POSE_ESTIMATION;
  }
  if (layer?.kiliOptions?.type) {
    return true;
  }
  return false;
};
