export enum CoordinateReferenceSystems {
  'EPSG3857' = 'EPSG3857',
  'EPSG4326' = 'EPSG4326',
  'Simple' = 'Simple',
  'TiledImage' = 'TiledImage',
}

export type ValidEPSG = 'EPSG3857' | 'EPSG4326' | 'Simple' | 'TiledImage';
export const VALID_EPSG_ARRAY = ['EPSG3857', 'EPSG4326', 'Simple', 'TiledImage'];

export const ZOOM_AFTER_LAYER_MAX = 4;
