/* eslint-disable no-underscore-dangle */

import { cx } from '@emotion/css';
import { SelectorClass } from '@kili-technology/cursors';
import { type ImageVertices } from '@kili-technology/types';
import L from 'leaflet';

import { GeometryTools } from '../../../../../../constants/tools';
import { memoizedGetColorForCategories } from '../../../../../helpers';
import { getAllowedAnnotationPointsWithinImage } from '../../helpers/geometry';
import type KiliFeatureGroup from '../KiliFeatureGroup';
import { KiliWrapper } from '../KiliWrapper';
import { getLatLngsFromLayer, getLayerOptionsFromAnnotation, layerWithHandlers } from '../helpers';
import { areObjectsLocked } from '../helpers/areObjectsLocked';
import './Draw.Marker';
import { type MarkerAnnotation, type KiliOptions } from '../types';

L.Edit.CircleMarker = L.Edit.SimpleShape.extend({
  _createMoveMarker() {
    const center = this._shape.getLatLng();
    this._moveMarker = this._createMarker(center, this.options.moveIcon);
  },

  _createResizeMarker() {
    // To avoid an undefined check in L.Edit.SimpleShape.removeHooks
    this._resizeMarkers = [];
  },

  _move(latlng: L.LatLng) {
    if (this._resizeMarkers.length) {
      const resizemarkerPoint = this._getResizeMarkerPoint(latlng);
      // Move the resize marker
      this._resizeMarkers[0].setLatLng(resizemarkerPoint);
    }

    // Move the circle
    this._shape.setLatLng(latlng);
  },

  _onMarkerDragEnd() {
    this._fireEdit();
  },

  addHooks() {
    const shape = this._shape;
    if (this._shape._map) {
      this._map = this._shape._map;
      this._map.doubleClickZoom.disable();
      shape.setStyle(shape.options.editing);

      if (shape._map) {
        this._map = shape._map;
        if (!this._markerGroup) {
          this._initMarkers();
        }
        this._map.addLayer(this._markerGroup);
      }
    }
  },

  removeHooks() {
    const shape = this._shape;

    shape.fire('mouseout');

    if (shape._map && this._map) {
      this._map.doubleClickZoom.enable();
      this._unbindMarker(this._moveMarker);

      for (let i = 0, l = this._resizeMarkers.length; i < l; i += 1) {
        this._unbindMarker(this._resizeMarkers[i]);
      }
      this._resizeMarkers = null;

      this._map.removeLayer(this._markerGroup);
      delete this._markerGroup;
    }

    this._map = null;
  },
});

class KiliMarker extends KiliWrapper(L.CircleMarker) {
  constructor(latlng: L.LatLng, options: KiliOptions) {
    const {
      isNegativeInteractiveSegmentationMarker,
      isInteractiveSegmentationMarker,
      isPoseEstimationMarker,
    } = options;
    const fillColor = isNegativeInteractiveSegmentationMarker
      ? 'red'
      : memoizedGetColorForCategories(options.categories, options.jobName);
    const optionsWithStyle = {
      ...options,
      className: cx('kili-marker', options.className),
      color: '#FFFFFF',
      fillColor,
      fillOpacity: 1,
      original: {
        color: '#FFFFFF',
        fillColor,
      },
      radius: 5,
      weight: isInteractiveSegmentationMarker ? 0 : 2,
    };
    super(latlng, optionsWithStyle);
    super.initializeKili(optionsWithStyle);
    if (isInteractiveSegmentationMarker || isPoseEstimationMarker) {
      this.save = () => {};
    }

    if (areObjectsLocked()) {
      this.editing = undefined;
      return;
    }

    this.editing =
      isInteractiveSegmentationMarker || isPoseEstimationMarker
        ? undefined
        : // @ts-expect-error KiliMarker not assignable to Marker
          new L.Edit.CircleMarker(this, {
            moveIcon: new L.DivIcon({
              className: cx('leaflet-editing-icon leaflet-editing-icon-transparent', SelectorClass),
              iconSize: new L.Point(20, 20),
            }),
          });
  }

  updateKiliOptions(newOptions: Partial<KiliOptions>) {
    if (!this.kiliOptions) return;
    if (newOptions.categories) {
      const fillColor = memoizedGetColorForCategories(newOptions.categories, newOptions.jobName);
      const optionsToChange = {
        fillColor,
        original: {
          color: '#FFFFFF',
          fillColor,
        },
      };
      L.Util.setOptions(this, { ...newOptions, ...optionsToChange });
      this.kiliOptions = { ...this.kiliOptions, ...{ ...newOptions, ...optionsToChange } };
    } else {
      this.kiliOptions = { ...this.kiliOptions, ...newOptions };
    }
  }

  fitToBounds() {
    const latlngs = [this.getLatLng()];
    const latlngsWithinImage = getAllowedAnnotationPointsWithinImage({
      latlngs,
      map: this.map,
      type: this.kiliOptions?.type,
    });
    const noChangeNecessary = latlngs
      .map((latlng, index) => latlng.equals(latlngsWithinImage[index], 1e-3))
      .reduce((prev, curr) => prev && curr, true);
    if (!noChangeNecessary) {
      this.setLatLng(latlngsWithinImage[0]);
    }
    return noChangeNecessary;
  }

  getLatLngs() {
    return [this.getLatLng()];
  }
}

export const getLayerFromMarker = (marker: ImageVertices, map: L.Map): L.CircleMarker => {
  return L.GeoJSON.geometryToLayer({
    geometry: {
      coordinates: map.projectPoint(marker) || [0, 0],
      type: GeometryTools.GEO_JSON_MARKER,
    },
    properties: {},
    type: 'Feature',
  }) as L.CircleMarker;
};

export const getKiliMarker = (
  latlng: L.LatLng,
  options: KiliOptions,
  addHandlers = true,
): KiliMarker => {
  const marker = new KiliMarker(latlng, options);
  if (addHandlers) {
    return layerWithHandlers(marker);
  }
  return marker;
};

export const annotationToKiliMarker = (
  annotation: MarkerAnnotation,
  leafletOptions: { className?: string; featureGroup?: KiliFeatureGroup; map: L.Map },
  addHandlers = true,
): KiliMarker | undefined => {
  const { map, featureGroup, className } = leafletOptions;
  const layerOptions = featureGroup
    ? { ...getLayerOptionsFromAnnotation(annotation), group: featureGroup }
    : getLayerOptionsFromAnnotation(annotation);
  const latlng = getLatLngsFromLayer(annotation, map) as L.LatLng;
  if (latlng) {
    const kiliLayer = getKiliMarker(latlng, { ...layerOptions, className, map }, addHandlers);
    return kiliLayer;
  }
  return undefined;
};

export default KiliMarker;
