import { cx } from '@emotion/css';
import MultipleChoiceAutocomplete from '@kili-technology/multiple-choice-autocomplete';
import { MachineLearningTask } from '@kili-technology/types';
import _get from 'lodash/get';
import { useMemo } from 'react';
import { type ValueType } from 'react-select';

import { classes } from './JobCategory.style';
import { getDropdownOptions, getDropdownValue, getPayloadFromValue } from './helpers';

import { InputType } from '../../../../__generated__/globalTypes';
import { retrieveAllObjectsForTask } from '../../../../services/jobs/objectTasks';
import { type ReactifiedJobs, type ReactifiedCategory } from '../../helpers';
import { END_ENTITIES, END_OBJECTS, START_ENTITIES, START_OBJECTS, IS_CHILD } from '../constants';
import { type EntityTitle, type RelationOption } from '../types';

type JobCategoryRelationProps = {
  category: ReactifiedCategory;
  handleEditObjectsForRelations: (
    entityKey: string,
  ) => (payload: ValueType<RelationOption, boolean>) => void;
  inputType: InputType;
  jobs: ReactifiedJobs;
  mlTask: MachineLearningTask;
  projectId: string;
};

export const ANY_RELATION_VALUE = 'ANY';
const ANY_RELATION: RelationOption = {
  label: 'Any',
  value: ANY_RELATION_VALUE,
};

const JobCategoryRelation = ({
  category,
  handleEditObjectsForRelations,
  inputType,
  jobs,
  mlTask,
  projectId,
}: JobCategoryRelationProps) => {
  const isNamedEntitiesRelationsTask = mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION;
  const stepsForRelations: EntityTitle[] = isNamedEntitiesRelationsTask
    ? [
        { entityKey: START_ENTITIES, label: 'Start' },
        { entityKey: END_ENTITIES, label: 'End' },
      ]
    : [
        { entityKey: START_OBJECTS, label: 'Start' },
        { entityKey: END_OBJECTS, label: 'End' },
      ];
  const jobsList = useMemo(() => Object.entries(jobs).filter(([_, job]) => !job[IS_CHILD]), [jobs]);
  const namedEntities = useMemo(
    () => retrieveAllObjectsForTask(jobsList, MachineLearningTask.NAMED_ENTITIES_RECOGNITION),
    [jobsList],
  );
  const objects = useMemo(
    () => retrieveAllObjectsForTask(jobsList, MachineLearningTask.OBJECT_DETECTION),
    [jobsList],
  );

  const choicesForRelationSteps = useMemo(() => {
    if (mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION) {
      if (inputType === InputType.PDF) {
        return { ...namedEntities, ...objects };
      }
      return namedEntities;
    }
    if (mlTask === MachineLearningTask.OBJECT_RELATION) {
      return objects;
    }
  }, [inputType, mlTask, namedEntities, objects]);

  return (
    <div className={cx(classes.line, classes.relation)}>
      <div className={classes.relationCardLine} data-cy="category-relation">
        {stepsForRelations.map(({ entityKey }) => {
          const options = [ANY_RELATION].concat(
            choicesForRelationSteps ? getDropdownOptions(choicesForRelationSteps) : [],
          );
          const selectedCategory = _get(category, [entityKey], []);
          const values = getDropdownValue(options, selectedCategory);

          return (
            <MultipleChoiceAutocomplete
              key={`relation_${projectId}_${entityKey}_${values}`}
              menuItems={options}
              onItemSelect={items =>
                handleEditObjectsForRelations(entityKey)(
                  getPayloadFromValue(values, items, options),
                )
              }
              values={values}
            />
          );
        })}
      </div>
    </div>
  );
};

export default JobCategoryRelation;
