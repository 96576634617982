import { getAnnotationFromCache } from './getAnnotationFromCache';
import { getLightAnnotationsFromCache } from './getLightAnnotationsFromCache';

import { type GetAnnotationsLightQuery } from '../../__generated__/queries.graphql';
import { type Annotation } from '../../types';

type LightAnnotation = NonNullable<GetAnnotationsLightQuery['annotations']>[number];

export function findAnnotationsInCache<TargetAnnotation extends Annotation>(
  predicate: (annotation: LightAnnotation) => annotation is TargetAnnotation,
): TargetAnnotation[];

export function findAnnotationsInCache(
  predicate: (annotation: LightAnnotation) => boolean,
): Annotation[];

export function findAnnotationsInCache(
  predicate: (annotation: LightAnnotation) => boolean,
): Annotation[] {
  return getLightAnnotationsFromCache().filter(predicate).map(getAnnotationFromCache);
}
