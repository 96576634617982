import { HandClass, HandGrabbingClass } from '@kili-technology/cursors';
import type L from 'leaflet';
import { useEffect, useState } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

import { useStore } from '../../../../../zustand';
import { selectIsADrawToolSelected } from '../../../../../zustand/label-interface/selectors';

export const layerToHover = {
  layer: undefined,
} as { layer: L.Layer | undefined };

export const useChangeCursor = () => {
  const [showCrossHairsCursor, setShowCrossHairsCursor] = useState(false);
  const map = useMap();

  const toolSelectedMode = useStore(selectIsADrawToolSelected);

  useMapEvents({
    dragend: () => {
      const mapContainer = map.getContainer();
      mapContainer.classList.remove(HandGrabbingClass);
    },
    dragstart: () => {
      const mapContainer = map.getContainer();
      mapContainer.classList.remove('leaflet-grab');
      mapContainer.classList.add(HandGrabbingClass);
    },
  });

  useEffect(() => {
    if (toolSelectedMode) {
      const stopPropagate = (e: Event) => {
        e.stopPropagation();
      };
      // Here we deactivate propagation for hover and right clicks on the map when a tool is selected
      // We cannot do that for classic click as when editing sometimes we need to click on a child of the map
      // (example polygon, IS, etc). It has to be handled in the click handler directly.
      const mapContainer = map.getContainer();
      mapContainer.addEventListener('contextmenu', stopPropagate, { capture: true });
      return () => {
        mapContainer.removeEventListener('contextmenu', stopPropagate, { capture: true });
        if (layerToHover.layer) {
          layerToHover.layer.fire('mouseover');
          layerToHover.layer = undefined;
        }
      };
    }
    return () => {};
  }, [map, toolSelectedMode]);

  useEffect(() => {
    const containerLayer = map.getContainer();

    if (toolSelectedMode) {
      const cursor = showCrossHairsCursor ? 'none' : 'crosshair';
      containerLayer?.classList?.remove(HandClass);
      containerLayer.style.cursor = cursor;
      return;
    }
    containerLayer.style.removeProperty('cursor');
    containerLayer?.classList?.add(HandClass);
  }, [toolSelectedMode, showCrossHairsCursor, map]);

  return {
    setShowCrossHairsCursor,
    showCrossHairsCursor,
  };
};
