import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type ProjectVersionState } from './types';

const sliceProjectVersion = createSlice({
  initialState,
  name: 'projectVersion',
  reducers: {
    PROJECT_VERSION_UPDATE(state, action: PayloadAction<ProjectVersionState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { PROJECT_VERSION_UPDATE } = sliceProjectVersion.actions;

export default sliceProjectVersion.reducer;
