export const COLORS = [
  '#733AFB',
  '#3CD876',
  '#ECB82A',
  '#FA484A',
  '#199CFC',
  '#3BCADB',
  '#FB753C',
  '#D33BCE',
  '#5CE7B7',
  '#472CED',
];
