import { createStyles, makeStyles, type Theme } from '@material-ui/core/styles';

import { DIALOG_VERSION_CHANGE_ZINDEX } from '../../constants/zIndexes';
import { BORDER_RADIUS } from '../../theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& button': {
        alignSelf: 'flex-start',
      },
      background: theme.palette.omega.accent0.main,
      borderRadius: BORDER_RADIUS,
      bottom: 0,
      boxShadow:
        '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
      color: theme.palette.omega.accent9.main,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      margin: theme.spacing(4),
      maxHeight: 'calc(100% - 64px)',
      maxWidth: '444px',
      overflowY: 'auto',
      padding: theme.spacing(2),
      position: 'absolute',
      right: 0,
      zIndex: DIALOG_VERSION_CHANGE_ZINDEX,
    },
  }),
);
