import { deleteObjectEntirelyInFrameResponses } from '@/services/jobs/delete';
import { store } from '@/store';

import { updateFrameResponses } from '../../label-frame/actions/updateFrameResponses';

export const deleteAnnotationInFrameResponses = (
  annotationMid: string,
  annotationJobName: string,
) => {
  const responses = store.getState().labelFrames.frameResponses;
  const newResponses = deleteObjectEntirelyInFrameResponses(
    annotationMid,
    annotationJobName,
    responses,
  );

  return updateFrameResponses({ frameResponses: newResponses });
};
