import { type ObjectAnnotation, LabelVersion } from '@kili-technology/types';
import { get as _get, isArray as _isArray, uniq as _uniq, find as _find } from 'lodash';
import { set, unset } from 'lodash/fp';

import {
  ANNOTATIONS,
  MID,
  CATEGORIES,
  LABEL_VERSION,
} from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { isChildVideoJobKeyFrame } from '@/components/asset-ui/Frame/helpers';
import { type ObjectDetectionAnnotations } from '@/redux/jobs/types';
import { type FrameResponses, type VideoJob, type ChildVideoJob } from '@/redux/label-frames/types';
import { type FlatTree, type FlatNode } from '@/redux/project/ontologyTree';

import { interpolateKeyFrames, findPairsToUpdate } from './interpolate';
import { type KiliAnnotation } from './setResponse';

import { type ObjectPositionInfo } from '../assets/video';

export const updateChildVideoJobObjectInFrameResponse = (
  frameToUpdate: number,
  jobName: string,
  newAnnotation: ChildVideoJob,
  responses: FrameResponses,
) => {
  let newResponses = { ...responses };

  newResponses = set([frameToUpdate, jobName], { ...newAnnotation }, newResponses);

  return newResponses;
};

export const updateObjectInFrameResponse = (
  frameToUpdate: number,
  newAnnotation: ObjectAnnotation,
  frameJsonResponses: FrameResponses,
) => {
  const { jobName, mid } = newAnnotation;

  if (jobName === undefined) {
    throw new Error(`jobName is not defined in annotation ${mid}`);
  }

  const previousAnnotations = (
    frameJsonResponses[frameToUpdate][jobName] as ObjectDetectionAnnotations
  ).annotations;

  const filteredPreviousAnnotations = previousAnnotations.filter(
    annotation => annotation.mid !== mid,
  );

  const newResponses = {
    ...frameJsonResponses,
    [frameToUpdate]: {
      ...frameJsonResponses[frameToUpdate],
      [jobName]: {
        annotations: filteredPreviousAnnotations.concat({ isKeyFrame: true, ...newAnnotation }),
      },
    },
  };

  const interpolatedResponses = interpolateKeyFrames(
    mid,
    jobName,
    frameJsonResponses,
    newResponses,
    frameToUpdate,
  );

  return interpolatedResponses;
};

export const updateObjectClassInFrameResponse = (
  newAnnotation: KiliAnnotation,
  frameJsonResponses: FrameResponses,
  objectsInfo: Record<string, ObjectPositionInfo>,
) => {
  const { jobName, mid } = newAnnotation;
  const category = _get(newAnnotation, CATEGORIES, null);
  const frames = _get(objectsInfo, [mid, 'frames'], []);
  const oldJobName = objectsInfo[mid].jobName;

  let newResponses = { ...frameJsonResponses };

  frames.forEach((frame: number) => {
    const oldAnnotations = _get(newResponses, [frame, oldJobName, ANNOTATIONS], []);
    if (mid in newResponses[frame]) {
      newResponses[frame] = unset([mid], newResponses[frame]);
      delete newResponses[frame][mid];
    }

    if (oldJobName === jobName) {
      oldAnnotations.forEach((annotation: ObjectAnnotation, index: number) => {
        if (annotation?.mid === mid) {
          newResponses = set(
            [frame, jobName, ANNOTATIONS, index],
            {
              ...annotation,
              [CATEGORIES]: category,
              [LABEL_VERSION]: LabelVersion.DEFAULT,
              [MID]: mid,
            },
            newResponses,
          );
        }
      });
    } else {
      const filteredOldAnnotations = oldAnnotations.filter(
        (annotation: ObjectAnnotation) => annotation.mid !== mid,
      );
      newResponses = set([frame, oldJobName, ANNOTATIONS], filteredOldAnnotations, newResponses);

      oldAnnotations
        .filter((annotation: ObjectAnnotation) => annotation.mid === mid)
        .forEach((annotation: ObjectAnnotation) => {
          const newJobNameAnnotations = _get(newResponses, [frame, jobName, ANNOTATIONS], []);

          newResponses = set(
            [frame, jobName, ANNOTATIONS],
            [
              ...newJobNameAnnotations,
              {
                ...annotation,
                [CATEGORIES]: category,
                [LABEL_VERSION]: LabelVersion.DEFAULT,
                [MID]: mid,
              },
            ],
            newResponses,
          );
        });
    }
  });
  return newResponses;
};

export const updateFrameSubJobInFrameResponse = (
  frame: number,
  jobName: string,
  frameJsonResponses: FrameResponses,
  subJob: VideoJob | null,
  parentMid?: string,
) => {
  let newResponses = { ...frameJsonResponses };

  if (parentMid) {
    if (subJob === null) {
      if ((newResponses[frame]?.[parentMid] as Record<string, ChildVideoJob>)?.[jobName]) {
        newResponses = set([frame, parentMid, jobName], undefined, newResponses);
        delete (newResponses[frame][parentMid] as Record<string, ChildVideoJob>)[jobName];
      }
    } else newResponses = set([frame, parentMid, jobName], subJob, newResponses);
  } else if (subJob === null) {
    if (newResponses[frame]?.[jobName] as Record<string, ChildVideoJob>) {
      newResponses = set([frame, jobName], undefined, newResponses);
      delete newResponses[frame][jobName];
    }
  } else newResponses = set([frame, jobName], subJob, newResponses);

  return newResponses;
};

export const computeChangesToMakeForDeletion = (
  removedFrames: number[],
  jobName: string,
  parentJobsNames: string[],
  frameJsonResponses: FrameResponses,
  maxFrame: number,
  parentMid?: string,
) => {
  const firstRemovedFrame = removedFrames[0];
  const lastRemovedFrame = removedFrames.slice(-1)[0];
  const subJobsKeyFrames = Object.entries(frameJsonResponses)
    .filter(([_, response]) =>
      isChildVideoJobKeyFrame(response, [jobName, ...parentJobsNames], parentMid),
    )
    .map(([frame, _]) => parseInt(frame, 10));

  const previousKeyFrame = subJobsKeyFrames.reduce(
    (previous, current) =>
      previous < firstRemovedFrame && firstRemovedFrame <= current ? previous : current,
    -1,
  );

  const noSubJobsFound = previousKeyFrame === -1;

  const nextKeyFrame = subJobsKeyFrames.find(frame => frame >= lastRemovedFrame + 1) ?? maxFrame;

  let subJob: VideoJob | null;
  if (noSubJobsFound) subJob = null;
  else if (parentMid)
    subJob = _get(frameJsonResponses, [previousKeyFrame, parentMid, jobName], {}) as VideoJob;
  else subJob = _get(frameJsonResponses, [previousKeyFrame, jobName], {}) as VideoJob;

  return {
    firstFrame: lastRemovedFrame + 1,
    lastFrame: nextKeyFrame,
    subJob: subJob ? ({ ...subJob, isKeyFrame: false } as VideoJob) : null,
  };
};

export const computeChangesToMakeForUpdate = (
  updatedFrame: number,
  jobName: string,
  parentJobsNames: string[],
  frameJsonResponses: FrameResponses,
  maxFrame: number,
  parentMid?: string,
) => {
  const subJobsKeyFrames = Object.entries(frameJsonResponses)
    .filter(([_, response]) =>
      isChildVideoJobKeyFrame(response, [jobName, ...parentJobsNames], parentMid),
    )
    .map(([frame, _]) => parseInt(frame, 10));

  const previousKeyFrame = updatedFrame;

  const nextKeyFrame = subJobsKeyFrames.find(frame => frame >= updatedFrame + 1) ?? maxFrame;

  let subJob: VideoJob | null;
  if (parentMid)
    subJob = _get(frameJsonResponses, [previousKeyFrame, parentMid, jobName], {}) as VideoJob;
  else subJob = _get(frameJsonResponses, [previousKeyFrame, jobName], {}) as VideoJob;

  return {
    firstFrame: updatedFrame + 1,
    lastFrame: nextKeyFrame,
    subJob: subJob ? ({ ...subJob, isKeyFrame: false } as VideoJob) : null,
  };
};

export const computeChangesToMakeForGroup = (
  groupedFrames: number[],
  jobName: string,
  parentJobsNames: string[],
  frameJsonResponses: FrameResponses,
  maxFrame: number,
  parentMid?: string,
) => {
  const subJobsKeyFrames = Object.entries(frameJsonResponses)
    .filter(([_, response]) =>
      isChildVideoJobKeyFrame(response, [jobName, ...parentJobsNames], parentMid),
    )
    .map(([frame, _]) => parseInt(frame, 10));

  const pairsToUpdate = findPairsToUpdate(subJobsKeyFrames, groupedFrames, subJobsKeyFrames);
  const pairsArray = pairsToUpdate.length
    ? pairsToUpdate
    : subJobsKeyFrames.map((el, index, array) => [el, array[index + 1] ?? maxFrame]);

  return pairsArray.map(pair => {
    const previousKeyFrame = pair[0];
    let subJob: VideoJob | null;
    if (parentMid)
      subJob = _get(frameJsonResponses, [previousKeyFrame, parentMid, jobName], {}) as VideoJob;
    else subJob = _get(frameJsonResponses, [previousKeyFrame, jobName], {}) as VideoJob;

    return {
      endFrame: pair[1] ?? maxFrame + 1,
      startFrame: pair[0] + 1,
      subJobToApply: subJob ? ({ ...subJob, isKeyFrame: false } as VideoJob) : null,
    };
  });
};

export const getSubJobsWithKeyFramesInSelectedFrames = (
  selectedFrames: number[],
  mid: string,
  frameJsonResponses: FrameResponses,
): string[] => {
  const subJobs: string[] = [];

  selectedFrames.forEach((frame: number) => {
    const frameMidSubJobs = frameJsonResponses[frame]?.[mid];
    if (!frameMidSubJobs) return;

    Object.entries(frameMidSubJobs).forEach(([key, value]) => {
      if (value?.isKeyFrame === true && !subJobs.includes(key)) subJobs.push(key);
    });
  });

  return subJobs;
};

/**
 * In a range of frames, return all subjobs keyframes of the parent job as an array of subjobs with their frame, jobName and subjob value
 * @param frames
 * @param frameJsonResponses
 * @param parentMid
 */
export const getJobSubJobsKeyFramesInFramesArray = (
  frames: number[],
  frameJsonResponses: FrameResponses,
  parentMid?: string,
) => {
  const subJobs: { frame: number; jobName: string; subJob: ChildVideoJob }[] = [];

  frames.forEach((frame: number) => {
    if (parentMid) {
      const frameMidSubJobs = frameJsonResponses[frame]?.[parentMid];
      if (!frameMidSubJobs) return;

      Object.entries(frameMidSubJobs).forEach(([key, value]) => {
        if (value?.isKeyFrame === true && !subJobs.includes({ frame, jobName: key, subJob: value }))
          subJobs.push({ frame, jobName: key, subJob: value });
      });
    } else {
      const frameSubJobs = frameJsonResponses[frame] as Record<string, ChildVideoJob>;
      if (!frameSubJobs) return;

      Object.entries(frameSubJobs).forEach(([key, value]) => {
        if (value?.isKeyFrame === true && !subJobs.includes({ frame, jobName: key, subJob: value }))
          subJobs.push({ frame, jobName: key, subJob: value });
      });
    }
  });

  return subJobs;
};

export const getSubJobChildren = (jobCategoryTree: FlatTree | undefined, jobName: string) => {
  const subJobChildren: string[] = [];

  Object.entries(jobCategoryTree ?? {})
    .filter(([key, _]) => key === jobName)
    .forEach(([_, flatTreeJob]) => {
      if (_isArray(flatTreeJob)) {
        flatTreeJob.forEach(el => {
          if (el.jobName !== jobName && !subJobChildren.find(el.jobName))
            subJobChildren.push(el.jobName);
        });
      } else {
        Object.values(flatTreeJob).forEach(choices => {
          if (_isArray(choices)) {
            choices.forEach(choice => {
              if (choice.jobName !== jobName && !subJobChildren.find(el => el === choice.jobName))
                subJobChildren.push(choice.jobName);
            });
          } else {
            Object.values(choices).forEach(children => {
              if (_isArray(children)) {
                children.forEach(child => {
                  if (child.jobName !== jobName && !subJobChildren.find(el => el === child.jobName))
                    subJobChildren.push(child.jobName);
                });
              }
            });
          }
        });
      }
    });

  return _uniq(subJobChildren);
};

const getSubJobParents = (jobCategoryTree: FlatTree | undefined, jobName: string) => {
  const subJobParents: string[] = [];
  Object.entries(jobCategoryTree ?? {})
    .filter(([key, _]) => key !== jobName)
    .forEach(([key, flatTreeJob]) => {
      Object.entries(flatTreeJob).forEach(([_, choices]) => {
        if (_isArray(choices)) {
          const foundItem = _find(choices, { jobName });

          if (foundItem !== undefined) subJobParents.push(key);
        }
      });
    });

  return _uniq(subJobParents);
};

type FramesChanges =
  | {
      groupedFrames?: undefined;
      removedFrames: number[];
      updatedFrame?: undefined;
    }
  | {
      groupedFrames?: undefined;
      removedFrames?: undefined;
      updatedFrame: number;
    }
  | {
      groupedFrames: number[];
      removedFrames?: undefined;
      updatedFrame?: undefined;
    };

export const updateSubJobsInFrameResponse = (
  changedFrames: FramesChanges,
  jobNames: string[],
  responses: FrameResponses,
  maxFrame: number,
  jobCategoryTree: FlatTree | undefined,
  classificationChildrenJobsToClean: FlatNode[] | null,
  parentMid?: string,
): FrameResponses => {
  let newResponses = { ...responses };

  jobNames.forEach(jobName => {
    const subJobsToUpdate: {
      endFrame: number;
      startFrame: number;
      subJobToApply: VideoJob | null;
    }[] = [];

    const subJobParents = getSubJobParents(jobCategoryTree, jobName);

    if (changedFrames.removedFrames !== undefined) {
      const { firstFrame, lastFrame, subJob } = computeChangesToMakeForDeletion(
        changedFrames.removedFrames,
        jobName,
        subJobParents,
        newResponses,
        maxFrame,
        parentMid,
      );
      subJobsToUpdate.push({
        endFrame: lastFrame,
        startFrame: firstFrame,
        subJobToApply: subJob,
      });
    } else if (changedFrames.updatedFrame !== undefined) {
      const { firstFrame, lastFrame, subJob } = computeChangesToMakeForUpdate(
        changedFrames.updatedFrame,
        jobName,
        subJobParents,
        newResponses,
        maxFrame,
        parentMid,
      );
      subJobsToUpdate.push({
        endFrame: lastFrame,
        startFrame: firstFrame,
        subJobToApply: subJob,
      });
    } else if (changedFrames.groupedFrames !== undefined) {
      const subJobsArray = computeChangesToMakeForGroup(
        changedFrames.groupedFrames,
        jobName,
        subJobParents,
        newResponses,
        maxFrame,
        parentMid,
      );

      subJobsArray.forEach(subJob => {
        subJobsToUpdate.push({
          endFrame: subJob.endFrame,
          startFrame: subJob.startFrame,
          subJobToApply: subJob.subJobToApply,
        });
      });
    }

    subJobsToUpdate.forEach(subJobToUpdate => {
      Array.from(
        { length: subJobToUpdate.endFrame + 1 - subJobToUpdate.startFrame },
        (_, index) => index + subJobToUpdate.startFrame - 1,
      ).forEach((frame: number) => {
        if (frame !== subJobToUpdate.startFrame - 1) {
          newResponses = updateFrameSubJobInFrameResponse(
            frame,
            jobName,
            newResponses,
            subJobToUpdate.subJobToApply,
            parentMid,
          );
        }

        if (classificationChildrenJobsToClean) {
          classificationChildrenJobsToClean.forEach(childJobToClean => {
            newResponses = updateFrameSubJobInFrameResponse(
              frame,
              childJobToClean.jobName,
              newResponses,
              null,
              parentMid,
            );
          });
        } else {
          const subJobChildrenToRemove = getSubJobChildren(jobCategoryTree, jobName);
          subJobChildrenToRemove.forEach(subJobChild => {
            newResponses = updateFrameSubJobInFrameResponse(
              frame,
              subJobChild,
              newResponses,
              null,
              parentMid,
            );
          });
        }
      });
    });
  });

  return newResponses;
};
