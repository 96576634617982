import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import initialState from './initialState';

const sliceJob = createSlice({
  initialState,
  name: 'job',
  reducers: {
    JOB_INITIALIZE() {
      return initialState;
    },
    JOB_UPDATE_CURRENT_CATEGORY(
      state,
      action: PayloadAction<{ category: string; jobName: string }>,
    ) {
      return {
        ...state,
        currentCategoryCode: action.payload.category,
        currentJobName: action.payload.jobName,
      };
    },
    JOB_UPDATE_CURRENT_JOB_NAME(state, action: PayloadAction<string | undefined>) {
      return {
        ...state,
        currentJobName: action.payload,
      };
    },
  },
});

export const { JOB_INITIALIZE, JOB_UPDATE_CURRENT_JOB_NAME, JOB_UPDATE_CURRENT_CATEGORY } =
  sliceJob.actions;

export default sliceJob.reducer;
