import { useEffect, useRef } from 'react';

export function emit<T>(eventName: string, detail?: T, dispatcher: EventTarget = window) {
  const event = new CustomEvent(eventName, {
    bubbles: true,
    cancelable: true,
    detail,
  });

  dispatcher.dispatchEvent(event);

  return {
    cancel: () => {
      if (event.defaultPrevented) return;
      event.stopImmediatePropagation();
      event.preventDefault();
    },
  };
}

function listen<T>(
  eventName: string,
  handler: (event: Event & { detail?: T }) => void,
  listener: EventTarget = window,
) {
  listener.addEventListener(eventName, handler);
  return () => listener.removeEventListener(eventName, handler);
}

export function useCustomEventListener<T>(
  eventName: string,
  handler: (event: Event & { detail?: T }) => void,
) {
  const handlerRef = useRef(handler);
  if (handler !== handlerRef.current) {
    handlerRef.current = handler;
  }

  useEffect(() => listen<T>(eventName, e => handlerRef.current(e)), [eventName]);
}
