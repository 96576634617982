import { HandClickingClass } from '@kili-technology/cursors';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { type WithStyles } from '@material-ui/core/styles';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { useState } from 'react';
import { type ConnectedProps } from 'react-redux';

import { type styles } from './ProjectsPanel.style';
import { type connector } from './ProjectsPanel.wrap';
import ProjectsPanelPage from './ProjectsPanelPage';

type PropsType = Record<string, unknown>;

type WithReduxProps = PropsType & ConnectedProps<typeof connector>;

const ProjectsPanel: React.FC<WithReduxProps & WithStyles<typeof styles>> = props => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { classes, projectTitle } = props;

  const handleClosePanel = () => {
    setIsPanelOpen(false);
  };
  const handleTogglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <div className={classes.container}>
      <Button
        classes={{ label: classes.label }}
        className={`${classes.subContainer} ${HandClickingClass}`}
        color="inherit"
        data-cy="projects-panel-button"
        data-testid="projects-panel-button"
        disableRipple
        onClick={handleTogglePanel}
        ref={node => {
          setMenuAnchorEl(node);
        }}
      >
        {projectTitle}
        {isPanelOpen ? <ArrowDown /> : <ArrowRight />}
      </Button>

      {menuAnchorEl && (
        <Popper
          anchorEl={menuAnchorEl}
          className={classes.popper}
          modifiers={{
            flip: {
              enabled: false,
            },
          }}
          open={isPanelOpen}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={handleClosePanel}>
            <Paper className={classes.paper}>
              <div>
                <ProjectsPanelPage handleClosePanel={handleClosePanel} />
              </div>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
};

export default ProjectsPanel;
