import CircularProgress from '@material-ui/core/CircularProgress';
import { type WithStyles } from '@material-ui/core/styles';
import { PureComponent } from 'react';

import { type styles } from './Loader.style';

type PropsType = {
  classes: Record<string, string>;
};

type StateType = Record<string, never>;
class Loader extends PureComponent<PropsType & WithStyles<typeof styles>, StateType> {
  render(): JSX.Element {
    const { classes } = this.props;

    return (
      <div className={classes.progress}>
        <CircularProgress data-cy="progress-bar" />
      </div>
    );
  }
}

export default Loader;
