import { type MarkerBaseStyleProps, useStyles } from './MarkerBase.style';

export type MarkerBase = MarkerBaseStyleProps & {
  badgeId?: string;
};

const MarkerBase = (props: MarkerBase): JSX.Element => {
  const { badgeId } = props;
  const classes = useStyles(props);
  return <div className={classes.root} data-badge-id={badgeId} />;
};

export default MarkerBase;
