import {
  type Annotation,
  type AnnotationCategory as Category,
  type ImageBoundingPoly,
  type ImageVertices,
  type Tool,
} from '@kili-technology/types';
import L from 'leaflet';

import Pose from '../KiliLeafletLayers/KiliPoseEstimation/BasePose';
import { type PoseAnnotation } from '../KiliLeafletLayers/types';

type DrawAnnotationOptions = {
  categories: Category[];
  jobName: string;
  mid: string;
  selectedAnnotation?: Annotation;
  type: Tool;
};

const getBoundingPoly = (latlngs: L.LatLng[][], map: L.Map): ImageBoundingPoly[] => {
  return latlngs.map(sublatlngs => ({
    normalizedVertices: sublatlngs
      .map(point => map.unprojectPoint(point))
      .filter((point): point is ImageVertices => !!point),
  }));
};

const getPolyline = (latlngs: L.LatLng[], map: L.Map): ImageVertices[] => {
  return latlngs
    .map((point: L.LatLng) => map.unprojectPoint(point))
    .filter((point): point is ImageVertices => !!point);
};

const getPoint = (latlng: L.LatLng, map: L.Map): ImageVertices => {
  return map.unprojectPoint(latlng) as ImageVertices;
};

export const getGeometryFromLayer = (
  layer: L.Layer,
  map: L.Map,
  selectedAnnotation?: Annotation,
) => {
  if (layer instanceof L.Rectangle || layer instanceof L.Polygon) {
    const latlngs = layer.getLatLngs() as L.LatLng[][];
    return { boundingPoly: getBoundingPoly(latlngs, map) };
  }
  if (layer instanceof Pose) {
    const previousPoints = (selectedAnnotation as PoseAnnotation)?.points ?? [];
    const latlngs = layer.getLatLngs() as L.LatLng[];
    const pointCoordinates = getPolyline(latlngs, map);
    const { allPoints, pointIndicesDrawn } = layer.options;
    const mergedPoints: Record<number, { x: number; y: number }> = [
      ...previousPoints,
      ...pointCoordinates.map((p, i) => ({ code: pointIndicesDrawn[i], point: p })),
    ].reduce((cumul, p) => ({ ...cumul, [p.code]: p.point }), {});
    const points = allPoints
      .filter((d, i) => !!mergedPoints[i])
      .map(d => {
        const { code, children } = d;
        return { children, code, point: mergedPoints[allPoints.indexOf(d)] };
      });

    return { allPoints, points };
  }
  if (layer instanceof L.Polyline) {
    const latlngs = layer.getLatLngs() as L.LatLng[];
    return { polyline: getPolyline(latlngs, map) };
  }
  if (layer instanceof L.CircleMarker) {
    const latlng = layer.getLatLng() as L.LatLng;
    return { point: getPoint(latlng, map) };
  }
  return {};
};

const getAnnotationFromLayer = (map: L.Map, options: DrawAnnotationOptions) => (layer: L.Layer) => {
  const geometry = getGeometryFromLayer(layer, map, options.selectedAnnotation);
  const { selectedAnnotation, ...baseOptions } = options;
  return { ...geometry, ...baseOptions };
};

export default getAnnotationFromLayer;
