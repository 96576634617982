import { type EntityRelation, type ObjectRelation } from '@kili-technology/types';
import _get from 'lodash/get';

import { type RelationObject } from './types';

import {
  CATEGORIES,
  END_ENTITIES,
  END_OBJECTS,
  JOB_NAME,
  MID,
  NAME,
  START_ENTITIES,
  START_OBJECTS,
} from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { memoizedGetColorForCategories } from '../../components/helpers';

export interface RelationBadgeData {
  categoryCode: string;
  color: string;
  mid: string;
}

type AnnotationRelation = EntityRelation | ObjectRelation;

const getRelationsMids = (relation: AnnotationRelation, entities: string): string[] =>
  _get(relation, entities, []).map((element: RelationObject) => _get(element, MID));

export const getRelationBadgesData = (
  mid: string,
  annotationRelations: AnnotationRelation[],
  objects = false,
): RelationBadgeData[] => {
  const pathToStart = objects ? START_OBJECTS : START_ENTITIES;
  const pathToEnd = objects ? END_OBJECTS : END_ENTITIES;
  return annotationRelations
    .filter(
      relation =>
        getRelationsMids(relation, pathToStart).includes(mid) ||
        getRelationsMids(relation, pathToEnd).includes(mid),
    )
    .map(relation => {
      const categories = (relation[CATEGORIES] || []).map(category => ({ name: category[NAME] }));
      return {
        categoryCode: categories[0]?.[NAME] || '',
        color: memoizedGetColorForCategories(categories, relation[JOB_NAME]),
        mid: relation[MID],
      };
    });
};

export function getRelationBadgesDataByMid(
  annotationRelations: AnnotationRelation[],
): Map<string, RelationBadgeData[]> {
  const relationBadgesDataByMid = new Map<string, RelationBadgeData[]>();

  annotationRelations.forEach(relation => {
    const categories = (relation[CATEGORIES] || []).map(category => ({ name: category[NAME] }));
    const relationBadgeData: RelationBadgeData = {
      categoryCode: categories[0]?.[NAME] || '',
      color: memoizedGetColorForCategories(categories, relation[JOB_NAME]),
      mid: relation[MID],
    };

    [
      ...getRelationsMids(relation, START_ENTITIES),
      ...getRelationsMids(relation, END_ENTITIES),
    ].forEach(mid => {
      if (!relationBadgesDataByMid.has(mid)) {
        relationBadgesDataByMid.set(mid, []);
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      relationBadgesDataByMid.get(mid)!.push(relationBadgeData);
    });
  });
  return relationBadgesDataByMid;
}
