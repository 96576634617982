import { generatePath } from 'react-router-dom';

import { ProjectListsRoutes } from '../../projects/ProjectLists/ProjectLists.route';
import { RootRoute, rootRoutes } from '../RootPaths';

type RootRoutes = typeof rootRoutes;

/**
 * Hook to indicate the current route to home. It is meant to be enlarged or reduced accordingly to the needs
 * @returns {RootRoute} the route to the homepage, acording to the flag or conditions currently in place
 */
export function useHomeRoute(): RootRoutes[keyof RootRoutes] {
  const HOME_ROUTE: RootRoute = RootRoute.PROJECT_LISTS;
  return rootRoutes[HOME_ROUTE];
}

/**
 * Hook to indicate the current route to home. It is meant to be enlarged or reduced accordingly to the needs
 * @returns {string} the path to the homepage, acording to the flag or conditions currently in place
 */
export function useHomePath() {
  const HomePath = generatePath(rootRoutes[RootRoute.PROJECT_LISTS].path, {
    listName: ProjectListsRoutes.ACTIVE,
  });

  return HomePath;
}
