import {
  annotationToKiliMarker,
  getKiliMarker,
  getLayerFromMarker,
} from './KiliMarkerLayer/KiliMarkerLayer';
import {
  annotationToKiliPolygon,
  getKiliPolygon,
  getLayerFromBoundingPoly,
} from './KiliPolygonLayer';
import {
  annotationToKiliPolyline,
  annotationToKiliVector,
  getKiliPolyline,
  getKiliVector,
  getLayerFromPolyline,
} from './KiliPolylineLayer';
import { annotationToKiliPose, getKiliPose } from './KiliPoseEstimation/poseApi';
import { annotationToKiliRectangle, getKiliRectangle } from './KiliRectangleLayer';

const kiliLayers = {
  annotationToKiliMarker,
  annotationToKiliPolygon,
  annotationToKiliPolyline,
  annotationToKiliPose,
  annotationToKiliRectangle,
  annotationToKiliVector,
  getKiliMarker,
  getKiliPolygon,
  getKiliPolyline,
  getKiliPose,
  getKiliRectangle,
  getKiliVector,
  getLayerFromBoundingPoly,
  getLayerFromMarker,
  getLayerFromPolyline,
};

export default kiliLayers;
