import { type Feature, type Geometry, type Properties } from '@turf/helpers';
import L, { type LatLng } from 'leaflet';

export const getLatLngsFromJSON = (
  json: Feature<Geometry | null, Properties>,
  depth = 1,
): LatLng[][] => {
  const geometry = json?.geometry;
  const coordinates = geometry?.coordinates;
  if (!coordinates) {
    return [];
  }
  return L.GeoJSON.coordsToLatLngs(coordinates, depth, L.GeoJSON.coordsToLatLng);
};
