import _isNumber from 'lodash/isNumber';

import { shouldAnonymizeDataForProjectUser } from './selectors';

import { xdate, dateToFormat, datePattern } from '../../helpers/xdate';
import { store } from '../../store';
import { userId as userIdSelector } from '../selectors';
import { type State } from '../types';

export function utcToLocalTime(time?: string | null, precise?: boolean): string {
  if (!time) return 'N/A';
  const dayjsTime = xdate(time);
  if (dayjsTime.isValid())
    return precise
      ? dateToFormat(time, datePattern.yearMonthDaySlashWithTime)
      : dateToFormat(time, datePattern.yearMonthDaySlash);
  return 'N/A';
}

export function secondsToHoursMinutesSeconds(
  duration: number,
  hourSeparator = 'h',
  minuteSeparator = 'm',
  secondSeparator = 's',
): string {
  if (!_isNumber(duration)) {
    return 'N/A';
  }

  const seconds = Math.floor(duration % 60);
  const minutes = Math.floor((duration / 60) % 60);
  const hours = Math.floor(duration / (60 * 60));

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  if (hours === 0 && minutes === 0) {
    return `${formattedSeconds}${secondSeparator}`;
  }
  if (hours === 0) {
    return `${formattedMinutes}${minuteSeparator}${formattedSeconds}`;
  }
  return `${formattedHours}${hourSeparator}${formattedMinutes}`;
}

export const getTodayDate = (separator?: string): string => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  return dd + (separator ?? '-') + mm + (separator ?? '-') + yyyy;
};

export const shouldAnonymizeUser = (userId: string) => {
  const reduxStore: State = store.getState();

  const isInProject = window.location.pathname.includes('/label/projects/');

  const currentUserId = userIdSelector(reduxStore);
  const shouldAnonymize = shouldAnonymizeDataForProjectUser(reduxStore);

  return isInProject && shouldAnonymize && currentUserId !== userId;
};
