import { css } from '@emotion/css';
import { HandClickingClass } from '@kili-technology/cursors';
import MuiTooltip from '@material-ui/core/Tooltip';
import { type TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { type WithStyles } from '@material-ui/core/styles';
import React from 'react';

import { TOOLTIP_POPPER_ZINDEX } from '@/constants/zIndexes';

import { type styles } from './Tooltip.style';
import TooltipTitleLink, { type TooltipTitleLinkProps } from './TooltipTitleLink';
import { ENTER_DELAY, ENTER_NEXT_DELAY } from './constants';

export type TooltipProps = Omit<MuiTooltipProps, 'children' | 'classes'> & {
  /** Custom children - not from Mui-Tooltip */
  children: React.ReactNode;
  disablePortal?: boolean;
  enableDefaultDelays?: boolean;
  overrideClasses?: boolean;
  /** Custom title - not from Mui-Tooltip */
  title: string;
  /** Turns the title into a link and makes the Tooltip not interactive */
  titleLink?: Omit<TooltipTitleLinkProps, 'title'>;
  /** Classname to override the tooltype style, instead of classes.tooltip.
   *  This is to avoid exposing the classes prop and limit the changes to
   *  this component.
   */
  tooltipClassName?: string;
  wrapperClassName?: string;
};

type TooltipPropsWithStyles = TooltipProps & Partial<WithStyles<typeof styles>>;

/**
 * This custom Tooltip uses Mui-Tooltip.
 * * It wraps its children to fix a well known MUI issue with disabled Button.
 * See https://mui.com/material-ui/react-tooltip/#disabled-elements
 * * It enables to use a link in the title without hover issue.
 *
 * ### Delay settings
 * * No delays by default.
 * * For default delays, set `enableDefaultDelays` flag.
 * * To set custom delays, simply use `enterDelay` and `enterNextDelay`.
 */
const Tooltip: React.FC<TooltipPropsWithStyles> = props => {
  const {
    children,
    disablePortal,
    enableDefaultDelays,
    enterDelay,
    enterNextDelay,
    overrideClasses = true,
    placement = 'bottom',
    title,
    titleLink,
    tooltipClassName,
    wrapperClassName,
    ...tooltipProps
  } = props;

  const tooltipStyle = tooltipClassName ? { tooltip: tooltipClassName } : undefined;

  const defaultEnterDelay = enableDefaultDelays ? ENTER_DELAY : undefined;
  const defaultEnterNextDelay = enableDefaultDelays ? ENTER_NEXT_DELAY : undefined;

  // Check if a "0" value is falsy except zero
  const enterDelayValue = enterDelay !== undefined ? enterDelay : defaultEnterDelay;
  const enterNextDelayValue = enterNextDelay !== undefined ? enterNextDelay : defaultEnterNextDelay;

  return (
    <MuiTooltip
      {...tooltipProps}
      {...(overrideClasses ? { classes: tooltipStyle } : {})}
      className={`${css`
        button:disabled {
          pointer-events: none;
        }
      `} ${HandClickingClass}`}
      enterDelay={enterDelayValue}
      enterNextDelay={enterNextDelayValue}
      interactive={!!titleLink}
      placement={placement}
      PopperProps={{
        disablePortal: disablePortal ?? true,
        style: { zIndex: TOOLTIP_POPPER_ZINDEX },
      }}
      title={
        titleLink ? (
          <TooltipTitleLink
            docUrl={titleLink.docUrl}
            plainText={titleLink.plainText}
            title={title}
          />
        ) : (
          title
        )
      }
    >
      <div className={wrapperClassName}>{children}</div>
    </MuiTooltip>
  );
};

export default Tooltip;
