import { type ObjectAnnotation } from '@kili-technology/types';
import { set, range, cloneDeep } from 'lodash/fp';
import _get from 'lodash/get';

import { interpolateKeyFrames } from './interpolate';
import {
  updateSubJobsInFrameResponse,
  getSubJobsWithKeyFramesInSelectedFrames,
  getSubJobChildren,
} from './update';

import { ANNOTATIONS, MID } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import {
  getFrameArrayFromFrameOnwards,
  getFramesContainingTheSameObject,
} from '../../components/asset-ui/Frame/helpers';
import { type ObjectDetectionAnnotations } from '../../redux/jobs/types';
import { type FrameResponses } from '../../redux/label-frames/types';
import { type FlatTree } from '../../redux/project/ontologyTree';
import { projectTreeFlat } from '../../redux/project/selectors';
import { store } from '../../store';
import { type SelectedFrames } from '../../zustand/label-frame';

export const unConvertFirstFrameToKeyFrame = (
  newFrameJsonResponse: FrameResponses,
  previousFirstFrame: number,
  jobName: string,
  mid: string,
) => {
  const firstFrameAnnotations = _get(
    newFrameJsonResponse[previousFirstFrame],
    [jobName, ANNOTATIONS],
    [],
  ) as ObjectAnnotation[];
  const newAnnotations = firstFrameAnnotations.map((annotation: ObjectAnnotation) => {
    if (annotation.mid !== mid) return annotation;

    const newAnnotation = cloneDeep(annotation);
    newAnnotation.isKeyFrame = false;
    return newAnnotation;
  });

  let newResponses = set(
    [previousFirstFrame, jobName, ANNOTATIONS],
    newAnnotations,
    newFrameJsonResponse,
  );

  /* SubJobs */
  const subJobs = newResponses[previousFirstFrame][mid];
  if (subJobs) {
    Object.entries(subJobs).forEach(([subJobName, subJob]) => {
      const newSubJob = cloneDeep(subJob);
      newSubJob.isKeyFrame = false;
      newResponses = set([previousFirstFrame, mid, subJobName], newSubJob, newResponses);
    });
  }
  return newResponses;
};

const convertFirstFrameToKeyFrame = (
  newFrameJsonResponse: FrameResponses,
  jobName: string,
  mid: string,
) => {
  const firstFrameIndexString = Object.entries(newFrameJsonResponse).find(([_, value]) =>
    (value[jobName] as ObjectDetectionAnnotations)?.annotations?.some(
      (el: ObjectAnnotation) => el.mid === mid,
    ),
  )?.[0];
  if (firstFrameIndexString === undefined) return newFrameJsonResponse;

  const firstFrameIndex = parseInt(firstFrameIndexString, 10);

  const firstFrameAnnotations = _get(
    newFrameJsonResponse[firstFrameIndex],
    [jobName, ANNOTATIONS],
    [],
  ) as ObjectAnnotation[];
  const newAnnotations = firstFrameAnnotations.map((annotation: ObjectAnnotation) => {
    if (annotation.mid !== mid) return annotation;

    const newAnnotation = cloneDeep(annotation);
    newAnnotation.isKeyFrame = true;
    return newAnnotation;
  });

  let newResponses = set(
    [firstFrameIndex, jobName, ANNOTATIONS],
    newAnnotations,
    newFrameJsonResponse,
  );

  /* SubJobs */
  const subJobs = newResponses[firstFrameIndex][mid];
  if (subJobs) {
    Object.entries(subJobs).forEach(([subJobName, subJob]) => {
      const newSubJob = cloneDeep(subJob);
      newSubJob.isKeyFrame = true;
      newResponses = set([firstFrameIndex, mid, subJobName], newSubJob, newResponses);
    });
  }
  return newResponses;
};

export const removeChildVideoJobObjectsInFramesFromFrameResponses = (
  filteredFramesArray: number[],
  jobName: string,
  responses: FrameResponses,
  jobCategoryTree: FlatTree,
) => {
  const newResponses = { ...responses };

  const subJobChildren = getSubJobChildren(jobCategoryTree, jobName);

  Object.entries(newResponses)
    .filter(([frame, _]) => filteredFramesArray.includes(parseInt(frame, 10)))
    .forEach(([frame, _]) => {
      delete newResponses[frame][jobName];

      subJobChildren.forEach(subJobChild => {
        delete newResponses[frame][subJobChild];
      });
    });

  return newResponses;
};

export const removeObjectsInFramesFromFrameResponses = (
  filteredFramesArray: number[],
  mid: string,
  jobName: string,
  frameJsonResponse: FrameResponses,
) => {
  let newFrameJsonResponse = { ...frameJsonResponse };

  const firstFrameIndexString = Object.entries(newFrameJsonResponse).find(([_, value]) =>
    (value[jobName] as ObjectDetectionAnnotations)?.annotations?.some(
      (el: ObjectAnnotation) => el.mid === mid,
    ),
  )?.[0];
  const firstFrameIndex = parseInt(firstFrameIndexString ?? '', 10);

  filteredFramesArray.forEach((frame: number) => {
    const previousResponse = frameJsonResponse[frame];
    const previousAnnotations = _get(
      previousResponse,
      [jobName, ANNOTATIONS],
      [],
    ) as ObjectAnnotation[];

    const previousAnnotationsWithOtherMid = previousAnnotations.filter(
      annotation => _get(annotation, MID) !== mid,
    );
    newFrameJsonResponse = set(
      [frame, jobName, ANNOTATIONS],
      previousAnnotationsWithOtherMid,
      newFrameJsonResponse,
    );

    /* SubJobs */
    delete newFrameJsonResponse[frame][mid];
  });

  if (filteredFramesArray.includes(firstFrameIndex))
    return convertFirstFrameToKeyFrame(newFrameJsonResponse, jobName, mid);
  return newFrameJsonResponse;
};

/* Delete annotation */
export const deleteObjectEntirelyInFrameResponses = (
  mid: string,
  jobName: string,
  frameJsonResponses: FrameResponses,
) => {
  const framesContainingTheSameObject = getFramesContainingTheSameObject([mid], frameJsonResponses);

  const newResponses = removeObjectsInFramesFromFrameResponses(
    framesContainingTheSameObject,
    mid,
    jobName,
    frameJsonResponses,
  );

  return newResponses;
};

/* Delete from this frame */
export const deleteObjectFromFrameOnwardsInFrameResponses = (
  frameToDeleteFrom: number,
  mid: string,
  jobName: string,
  frameJsonResponses: FrameResponses,
): FrameResponses => {
  const filteredFramesArray = getFrameArrayFromFrameOnwards(
    [mid],
    frameToDeleteFrom,
    frameJsonResponses,
  );
  let newResponses = removeObjectsInFramesFromFrameResponses(
    filteredFramesArray,
    mid,
    jobName,
    frameJsonResponses,
  );
  newResponses = interpolateKeyFrames(mid, jobName, frameJsonResponses, newResponses);
  return newResponses;
};

/* Cut selection */
export const deleteObjectInSelectedFramesFromFrameResponses = (
  selectedFrames: SelectedFrames,
  mid: string,
  jobName: string,
  frameJsonResponses: FrameResponses,
  maxFrame: number,
): FrameResponses => {
  const selectedFramesArray = range(selectedFrames.min, selectedFrames.max + 1);
  let newResponses = removeObjectsInFramesFromFrameResponses(
    selectedFramesArray,
    mid,
    jobName,
    frameJsonResponses,
  );

  const subJobs = getSubJobsWithKeyFramesInSelectedFrames(
    selectedFramesArray,
    mid,
    frameJsonResponses,
  );
  if (subJobs.length) {
    const treeFlat = projectTreeFlat(store.getState());

    newResponses = updateSubJobsInFrameResponse(
      { removedFrames: selectedFramesArray },
      subJobs,
      newResponses,
      maxFrame,
      treeFlat,
      null,
      mid,
    );
  }

  return interpolateKeyFrames(mid, jobName, frameJsonResponses, newResponses);
};
