import { type ImageVertices, type ObjectAnnotationMarker } from '@kili-technology/types';

export type MarkerAnnotation = Required<
  Pick<ObjectAnnotationMarker, 'categories' | 'mid' | 'isNegativeInteractiveSegmentationMarker'>
> & {
  point: ImageVertices;
};

type BaseModelPayload = {
  projectId: string;
};

export type ISMask = number[][];

type BoxInteractiveSegmentationPayload = SegmentationPayload & {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  bounding_box: ImageVertices[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  previous_mask?: ImageVertices[][] | ISMask;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type Click = { is_positive: boolean; x: number; y: number };

export type PointInteractiveSegmentationPayload = SegmentationPayload & {
  clicks: Click[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  previous_mask?: ImageVertices[][] | ISMask;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type SimilarObjectPayload = SegmentationPayload & { bounding_box: ImageVertices[] };

type SegmentationPayload = {
  assetId: string;
  isAssetServedByKili: boolean;
  rotation: number;
  url: string;
};

type VideoParams = {
  endFrame: number;
  fps: number;
  startFrame: number;
  videoUrl: string;
};

export enum TrackerType {
  Csrt = 'csrt',
  Ocean = 'ocean',
}

type TrackingPayload = {
  bbox: ImageVertices[];
  tracker: TrackerType;
  urls?: string[];
  videoParams?: VideoParams;
};

export type ModelPayload = (
  | BoxInteractiveSegmentationPayload
  | PointInteractiveSegmentationPayload
  | TrackingPayload
  | SimilarObjectPayload
) &
  BaseModelPayload;

export function fetchResponseForModel<T>(
  authenticationToken: string,
  modelEndpoint: string,
  payload: ModelPayload,
): { abortController: AbortController; fetchPromise: Promise<T> } {
  const abortController = new AbortController();
  const fetchPromise = fetch(modelEndpoint, {
    body: JSON.stringify({
      authorization: authenticationToken,
      ...payload,
    }),
    headers: {
      Authorization: authenticationToken,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    signal: abortController.signal,
  }).then(response => response.json());
  return { abortController, fetchPromise };
}
