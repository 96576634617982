import { type Modification, type SplitLabelSlice, type SplitLabelSliceValues } from './types';

import { type ZustandSlice, type ZustandStore } from '..';
import createLogger from '../helpers';

const initialState: SplitLabelSliceValues = {
  nextModifications: { annotation: {}, annotationValue: {} },
  pendingModifications: { annotation: {}, annotationValue: {} },
};

const log = createLogger('splitLabel');

const addAnnotationModification = (id: string, modification: Modification, state: ZustandStore) => {
  const { annotationValue, annotation } = state.splitLabel.nextModifications;
  if (modification === 'delete') {
    delete annotationValue[id];
  }
  if (annotation[id] === 'add') {
    if (modification === 'delete') {
      delete annotation[id];
    }
    return;
  }
  if (annotation[id] === 'delete') {
    if (modification === 'add') {
      annotation[id] = 'update';
    }
    return;
  }
  annotation[id] = modification;
};

const addAnnotationValueModification = (
  idAnnotation: string,
  idAnnotationValue: string,
  modification: Modification,
  state: ZustandStore,
) => {
  const { annotationValue, annotation } = state.splitLabel.nextModifications;
  if (annotation[idAnnotation] === 'add' || annotation[idAnnotation] === 'delete') {
    // changes will be persisted in the annotation modification
    return;
  }
  if (!annotationValue[idAnnotation]) {
    annotationValue[idAnnotation] = {};
  }
  if (annotationValue[idAnnotation][idAnnotationValue] === 'add') {
    if (modification === 'delete') {
      delete annotationValue[idAnnotation][idAnnotationValue];
    }
    return;
  }
  if (annotationValue[idAnnotation][idAnnotationValue] === 'delete') {
    if (modification === 'add') {
      annotationValue[idAnnotation][idAnnotationValue] = 'update';
    }
    return;
  }
  annotationValue[idAnnotation][idAnnotationValue] = modification;
};

export const createSplitLabelSlice: ZustandSlice<SplitLabelSlice> = set => ({
  ...initialState,
  addAnnotationModification: (id, modification) =>
    set(
      state => {
        addAnnotationModification(id, modification, state);
      },
      false,
      log('addModification'),
    ),
  addAnnotationModifications: (ids, modification) =>
    set(
      state => {
        ids.forEach(id => {
          addAnnotationModification(id, modification, state);
        });
      },
      false,
      log('addModifications'),
    ),
  addAnnotationValueModification: (idAnnotation, idAnnotationValue, modification) =>
    set(
      state => {
        addAnnotationValueModification(idAnnotation, idAnnotationValue, modification, state);
      },
      false,
      log('addValueModification'),
    ),
  addAnnotationValueModifications: (idAnnotation, idAnnotationValues, modification) =>
    set(
      state => {
        idAnnotationValues.forEach(idAnnotationValue => {
          addAnnotationValueModification(idAnnotation, idAnnotationValue, modification, state);
        });
      },
      false,
      log('addValueModification'),
    ),
  autosave: (labelId: string) =>
    set(
      state => {
        state.splitLabel.pendingModifications = state.splitLabel.nextModifications;
        state.splitLabel.nextModifications = { annotation: {}, annotationValue: {} };
        state.splitLabel.latestAutosaveId = labelId;
      },
      false,
      log('autosave'),
    ),
  initialize: () =>
    set(
      state => {
        delete state.splitLabel.latestAutosaveId;
        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;

          (state.splitLabel[keyInitialState] as unknown) = value;
        });
      },
      false,
      log('initialize'),
    ),
});

export * from './types';
