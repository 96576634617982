import { gql } from '@apollo/client';

import { PROJECT_ROLES_FRAGMENTS } from '../project/fragments';

export const GQL_APPEND_TO_ROLES = gql`
  mutation AppendToRoles($data: AppendToRolesData!, $where: ProjectWhere!) {
    frontendAppendToRoles(data: $data, where: $where) {
      ...ProjectRoles
    }
  }
  ${PROJECT_ROLES_FRAGMENTS}
`;

export const GQL_UPDATE_PROPERTIES_IN_PROJECT_USER = gql`
  mutation UpdatePropertiesInProjectUser($where: ProjectUserWhere!, $data: ProjectUserData!) {
    updatePropertiesInProjectUser(where: $where, data: $data) {
      id
      starred
      role
    }
  }
`;
