import { useQuery } from '@apollo/client';
import TextField from '@kili-technology/text-field';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { type WithStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import React, { Fragment, useState } from 'react';
import { type ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { type styles } from './ProjectsPanelPage.style';
import { type connector } from './ProjectsPanelPage.wrap';

import { type Maybe } from '../../../../../__generated__/globalTypes';
import {
  GQL_PROJECTS_FOR_PANEL_GET,
  GQL_LATEST_UPDATED_PROJECTS_GET,
} from '../../../../../graphql/projects/queries';
import { SegmentEvents } from '../../../../../pages/RootModule/helpers';
import { getQueueUrl } from '../../../../../pages/projects/ProjectMenu/routes';
import { formatProjectsForTable } from '../../../../../redux/projects/actions';

type PropsType = { handleClosePanel: () => void };
type ProjectInProjectsPanelPageType =
  | {
      name: Maybe<string> | undefined;
      projectID: string | undefined;
    }
  | { name: string; projectID: string };

export type WithReduxProps = PropsType & ConnectedProps<typeof connector>;

const first = 100;

const ProjectsPanelPage: React.FC<WithReduxProps & WithStyles<typeof styles>> = props => {
  const { classes, handleClosePanel, sendToSegment, projectID } = props;
  const history = useHistory();
  const [projectsSearch, setProjectsSearch] = useState('');

  const updateProjectsSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newProjectsSearch = event.target.value;
    setProjectsSearch(newProjectsSearch);
    sendToSegment({ event: SegmentEvents.SEARCHED_PROJECT });
  };

  const { data: dataAllProjects } = useQuery(GQL_PROJECTS_FOR_PANEL_GET, {
    context: { clientName: 'V2' },
    fetchPolicy: 'cache-first',
    variables: { first, skip: 0, where: { idNotIn: [projectID], searchQuery: projectsSearch } },
  });

  const { data: dataLatestUpdatedProjects } = useQuery(GQL_LATEST_UPDATED_PROJECTS_GET, {
    context: { clientName: 'V2' },
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 3,
      skip: 0,
      where: { idNotIn: [projectID] },
    },
  });

  const handleClick =
    (subProjectID: string | undefined) => (event: React.BaseSyntheticEvent<MouseEvent>) => {
      event.stopPropagation();
      if (subProjectID) {
        history.push(getQueueUrl(subProjectID));
        handleClosePanel();
      }
    };

  const subProjectsPanelPage = (subProjects: ProjectInProjectsPanelPageType[]) => {
    if (!subProjects.length) {
      return (
        <ListItemText className={classes.listItem}>
          <Typography className={classes.noProjectFound} variant="body2">
            No result found
          </Typography>
        </ListItemText>
      );
    }
    return subProjects.map(({ name, projectID: subProjectID }) => {
      return (
        <ListItem
          button
          classes={{ root: classes.gridContainer }}
          key={subProjectID}
          onClick={handleClick(subProjectID)}
        >
          <ListItemText className={classes.listItem}>
            <Typography className={classes.projectRenderer} variant="body2">
              {name}
            </Typography>
          </ListItemText>
        </ListItem>
      );
    });
  };

  const formatedAllProjects = formatProjectsForTable(dataAllProjects?.allProjectsArray);

  const recentsProjects = dataLatestUpdatedProjects?.latestUpdatedProjectArray || [];
  const formatedRecentProjects = formatProjectsForTable(recentsProjects);

  return (
    <>
      <Grid item sm={12}>
        <TextField
          className={classes.searchBar}
          name="find-in-projects"
          onChange={updateProjectsSearch}
          placeholder="Find in projects"
          startAdornment={<Search />}
          value={projectsSearch}
        />
      </Grid>
      <List className={classes.list} subheader={<li />}>
        {!projectsSearch && (
          <li key="Recents">
            <span className={classes.listSubheader}>RECENTS</span>
            {subProjectsPanelPage(formatedRecentProjects as ProjectInProjectsPanelPageType[])}
          </li>
        )}
        <li key="All">
          {!projectsSearch && <span className={classes.listSubheader}>ALL</span>}
          {subProjectsPanelPage(formatedAllProjects as ProjectInProjectsPanelPageType[])}
        </li>
      </List>
    </>
  );
};

export default ProjectsPanelPage;
