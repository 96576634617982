import { type ApolloClient, ApolloProvider, type NormalizedCacheObject } from '@apollo/client';
import { useEffect, useState } from 'react';
import { type ConnectedProps } from 'react-redux';

import initializeGraphQLClient from '@/apollo';
import { AUTH0_ACCESS_TOKEN_FOR_CYPRESS, isUsingAuth0, useAuth0 } from '@/auth0';
import { isTestingOldSignInOnCypress, onCypress } from '@/config';
import { sendToDatadog } from '@/datadog';
import { abortable, isAbortError } from '@/helpers/react/abortable';
import {
  addErrorNotification,
  addNotification,
  setIsValidAuthentificationToken,
  updateField as applicationUpdateField,
} from '@/redux/application/actions';
import { signOut } from '@/redux/authentication/actions';
import { store } from '@/store';
import { useOriginalUrlRedirector } from '@/url';

import { type connector } from './AuthorizedApolloProvider.wrap';

import Loader from '../Loader';

interface AuthorizedApolloProviderProps extends ConnectedProps<typeof connector> {
  children: JSX.Element;
}

export class ApolloClientProvider {
  private static client: ApolloClient<NormalizedCacheObject> | undefined;

  public static getClient() {
    return ApolloClientProvider.client;
  }

  public static setClient(client: ApolloClient<NormalizedCacheObject>): void {
    ApolloClientProvider.client = client;
  }
}

const AuthorizedApolloProvider = (props: AuthorizedApolloProviderProps): JSX.Element | null => {
  useOriginalUrlRedirector();
  const { getTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
  const { userId, token, children } = props;

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [clientReady, setClientReady] = useState(false);

  useEffect(() => {
    setClientReady(false);
    store.dispatch(setIsValidAuthentificationToken(false));
  }, [userId]);

  useEffect(() => {
    if (clientReady) {
      return;
    }
    if (isUsingAuth0 && !isAuthenticated) {
      setTimeout(() => {
        // Fix for https://community.auth0.com/t/blank-page-when-redirect-to-domain-auth0-com-u-login/44443/40
        loginWithRedirect({
          appState: { targetUrl: '/label/' },
        });
      }, 100);
    }
    const getClient = async () => {
      let tokenGenerated = null;
      if (onCypress && !isTestingOldSignInOnCypress) {
        tokenGenerated = localStorage.getItem(AUTH0_ACCESS_TOKEN_FOR_CYPRESS) || token;
      } else if (isUsingAuth0) tokenGenerated = await getTokenSilently();
      const initializedGraphQLClient = await initializeGraphQLClient(
        tokenGenerated,
        store.dispatch,
        () => {
          signOut();
          store.dispatch(
            addNotification({
              message: 'Your authentication has expired, please enter your credentials to connect',
              variant: 'warning',
            }),
          );
        },
        () => {
          store.dispatch(addErrorNotification());
        },
      );
      return initializedGraphQLClient;
    };

    const [getClientPromise, abortGetClientPromise] = abortable(getClient());

    getClientPromise
      .then(apolloClient => {
        // enable apollo cache inspector
        /* eslint no-underscore-dangle: ["error", { "allow": ["__APOLLO_CLIENT__"] }] */
        window.__APOLLO_CLIENT__ = apolloClient;
        return setClient(apolloClient);
      })
      .then(() => {
        const isClientReady = isUsingAuth0 || (!isUsingAuth0 && !!token);
        setClientReady(isClientReady);
        if (token) {
          store.dispatch(setIsValidAuthentificationToken(true));
        }
        store.dispatch(applicationUpdateField({ path: 'isGraphqlClientInitialized', value: true }));
      })
      .catch(error => {
        if (!isAbortError(error)) {
          sendToDatadog(error, null, 'auth0');
        }
      });

    return abortGetClientPromise;
  }, [clientReady, getTokenSilently, isAuthenticated, loginWithRedirect, token]);

  useEffect(() => {
    if (client) {
      ApolloClientProvider.setClient(client);
    }
  }, [client]);

  if (!clientReady && isUsingAuth0) {
    return <Loader />;
  }

  if (!client) {
    return <Loader />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
