import { type JobsSetResponsePayload, type JobsState } from './jobs/types';

import { type Label } from '../__generated__/globalTypes';
import { getFullName } from '../services/user/format';

export const responseFromResponsesToSet = (
  responsesToSet: JobsSetResponsePayload[],
  state: JobsState = {},
): JobsState =>
  responsesToSet.reduce((newState, payload) => {
    const { parentMid, jobName, jobResponse, mlTask } = payload;
    if (parentMid) {
      return {
        ...newState,
        [parentMid]: {
          ...newState?.[parentMid],
          [mlTask]: {
            ...newState?.[parentMid]?.[mlTask],
            [jobName]: jobResponse,
          },
        },
      };
    }
    return {
      ...newState,
      [mlTask]: {
        ...newState?.[mlTask],
        [jobName]: jobResponse,
      },
    };
  }, state);

export const printAuthorNameOrModelNameWhenItExists = (label: Label) => {
  const { modelName } = label;
  if (modelName) {
    return modelName;
  }
  return getFullName(label.author?.firstname, label.author?.lastname);
};
