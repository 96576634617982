/**
 * This store must contains only computed values from json objects returned by graphql.
 * It should be removed as soon as we can add local only fields on our apollo cache.
 */
import { type Draft } from 'immer';

import { type AssetsSlice, type AssetsSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: AssetsSliceValues = {
  assets: {},
};

const log = createLogger('assets');

export const createAssetsSlice: ZustandSlice<AssetsSlice> = set => ({
  ...initialState,
  initialize: () =>
    set(
      state => {
        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;

          (state.assets[keyInitialState] as unknown) = value;
        });
      },
      false,
      log('initialize'),
    ),
  updateAssetField: (assetId, payload) =>
    set(
      state => {
        const { path, value } = payload;
        const sassets = (state.assets as Draft<AssetsSliceValues>).assets;
        sassets[assetId] = { ...sassets[assetId], [path]: value };
      },
      false,
      log('updateField', payload),
    ),
});
