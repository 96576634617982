import { css } from '@emotion/css';

export const classes = {
  badge: css`
    & span {
      color: var(--color-omega-accent-0);
      background-color: var(--color-eta-accent-3);
      border: 1px solid var(--color-omega-accent-0);
      height: var(--space-4);
      width: 14px;
      font-style: Archia;
      font-size: 1Opx;
      line-height: var(--space-3);
      font-weight: 400;
      text-align: center;
    }
  `,
  container: css`
    margin-top: var(--space-2);
    margin-bottom: var(--space-2);
    margin-left: var(--space-1);
    width: var(--space-8);
    min-width: var(--space-8);
    padding: 0 0 !important;

    &.secondary-ghost:hover {
      background-color: var(--color-omega-accent-1);
    }
    &.secondary-ghost:focus {
      background-color: var(--color-omega-accent-2);
    }
  `,
};
