import { type GeoSpatialContent } from '@kili-technology/types';
import L from 'leaflet';

import { ZOOM_AFTER_LAYER_MAX } from '@/components/asset-ui/Image/Geospatial/constants';
import { type ControlLayer } from '@/components/asset-ui/Image/LeafletMapv2/OverlayLoader/context';

import {
  downloadImageAsset,
  isAssetServedByKili,
} from '../../../../../../services/assets/download';

export const getBounds = (layerBounds: [[number, number], [number, number]] | undefined) => {
  if (!layerBounds) return new L.LatLngBounds(new L.LatLng(0, 0), new L.LatLng(1, 1));

  const southWestLatLng = new L.LatLng(layerBounds[0][1], layerBounds[0][0]);
  const nortEastLatLng = new L.LatLng(layerBounds[1][1], layerBounds[1][0]);
  return new L.LatLngBounds(southWestLatLng, nortEastLatLng);
};

export const initializeLayers = (
  jsonContent: GeoSpatialContent,
  authenticationToken: string | null,
  setBaseLayers: (modificationFunc: (layer: ControlLayer[]) => ControlLayer[]) => void,
  setOverlayLayers: (modificationFunc: (layer: ControlLayer[]) => ControlLayer[]) => void,
  map: L.Map,
) => {
  const addLayer = (
    url: string,
    bounds: L.LatLngBounds,
    zoom: {
      maxNativeZoom: number | undefined;
      maxZoom: number | undefined;
      minZoom: number | undefined;
    },
    id: string,
    name: string,
    isTiledLayer = false,
    isBaseLayer = true,
    hidden = true,
  ) => {
    const layer = {
      active: !isBaseLayer || !hidden,
      hidden,
      id,
      name,
      ...(isTiledLayer
        ? {
            value: new L.TileLayerWithHeader(
              url,
              {
                bounds,
                maxNativeZoom: zoom.maxNativeZoom,
                maxZoom: zoom.maxZoom,
                minZoom: zoom.minZoom,
              },
              authenticationToken,
            ),
          }
        : {
            value: new L.ImageOverlay(url, bounds),
          }),
    };
    (isBaseLayer ? setBaseLayers : setOverlayLayers)(existing => [...existing, layer]);
    layer.value.addTo(map);
    layer.value.setOpacity(Number(!(layer.hidden || !layer.active)));
  };

  const firstLayer = { base: false, overlay: false };
  const baseLayers = jsonContent.filter(
    layer => layer.isBaseLayer || layer.isBaseLayer === undefined,
  );
  const overlayLayers = jsonContent.filter(layer => layer.isBaseLayer === false);
  const layers = [...baseLayers, ...overlayLayers];

  layers.forEach((jsonContentLayer, index) => {
    const {
      name = 'layer',
      isBaseLayer = true,
      bounds: layerBounds,
      imageUrl,
      tileLayerUrl,
      maxZoom,
      minZoom,
    } = jsonContentLayer;
    const zoom = {
      maxNativeZoom: maxZoom,
      maxZoom: (maxZoom ?? 0) + ZOOM_AFTER_LAYER_MAX,
      minZoom,
    };

    const id = `${name}-${index}`;
    const bounds = getBounds(layerBounds);
    const isTiledLayer = !imageUrl;
    const url = isTiledLayer ? tileLayerUrl : imageUrl;
    if (url === undefined) throw new Error('No url found for layer in JsonContent.');

    if (!isTiledLayer && isAssetServedByKili(url)) {
      downloadImageAsset(
        url,
        authenticationToken,
        undefined,
        payload => {
          addLayer(
            payload,
            bounds,
            zoom,
            id,
            name,
            isTiledLayer,
            isBaseLayer,
            (isBaseLayer && firstLayer.base) || (!isBaseLayer && firstLayer.overlay),
          );
          if (isBaseLayer && !firstLayer.base) firstLayer.base = true;
          if (!isBaseLayer && !firstLayer.overlay) firstLayer.overlay = true;
        },
        true,
      );
      return;
    }

    addLayer(
      url,
      bounds,
      zoom,
      id,
      name,
      isTiledLayer,
      isBaseLayer,
      (isBaseLayer && firstLayer.base) || (!isBaseLayer && firstLayer.overlay),
    );
    if (isBaseLayer && !firstLayer.base) firstLayer.base = true;
    if (!isBaseLayer && !firstLayer.overlay) firstLayer.overlay = true;
  });
};
