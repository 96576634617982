// https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
export const INSTRUCTIONS_URL_REGEXP = /^https?:\/\/(.*)/gi;
const URL_REGEXP =
  /^(ftp|data|file|http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(\.[a-z]{2,6})?\b\/([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

export const isUrl = (text: string, regex: RegExp = URL_REGEXP): RegExpMatchArray | null => {
  if (!text || !text.trim) {
    return null;
  }
  const isNotWrittenOnOneLine = text.trim().split('\n').length > 1;
  if (isNotWrittenOnOneLine) {
    return null;
  }

  const urlMatcher = new RegExp(regex);
  return text.match(urlMatcher);
};
