import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import {
  type AddNotificationPayload,
  type ApplicationState,
  type Shortcuts,
  type ShowPasswordChangeError,
  type TemporaryResponse,
} from './types';

import { UPDATE_FIELD_GENERICS } from '../../types';

function truncateString(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.slice(0, maxLength)}...`;
}

const applicationSlice = createSlice({
  initialState,
  name: 'application',
  reducers: {
    ACTIVATE_IS_LOADING(state) {
      return { ...state, isLoading: true };
    },
    ADD_NOTIFICATION(state, action: PayloadAction<AddNotificationPayload>) {
      const NUMBER_OF_CHARACTERS_LIMITATION = 1000;

      return {
        ...state,
        clearNotification: false,
        notification: {
          ...action.payload,
          message: truncateString(action.payload.message ?? '', NUMBER_OF_CHARACTERS_LIMITATION),
        },
      };
    },
    APPLICATION_ACTIVATE_LABELING_FINISHED(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        openModalLabelingFinished: action.payload ? 'FROM_REVIEW' : 'FROM_LABELING',
      };
    },
    APPLICATION_DEACTIVATE_LABELING_FINISHED(state) {
      return {
        ...state,
        openModalLabelingFinished: null,
      };
    },
    APPLICATION_INITIALIZE() {
      return initialState;
    },
    APPLICATION_UPDATE_ACCORDION(state, action: PayloadAction<{ [key: string]: boolean }>) {
      return {
        ...state,
        accordion: action.payload,
      };
    },
    APPLICATION_UPDATE_FIELD: UPDATE_FIELD_GENERICS<ApplicationState>(),
    APPLICATION_UPDATE_SEARCH_FIELD(state, action: PayloadAction<string>) {
      return {
        ...state,
        searchBarValue: action.payload,
      };
    },
    CLEAN_STATE_FOR_PROJECT_LIST(state) {
      return {
        ...state,
        temporaryJsonInterface: undefined,
        temporaryResponses: [],
      };
    },
    CLEAR_NOTIFICATION(state) {
      return {
        ...state,
        clearNotification: true,
      };
    },
    CLEAR_UPLOAD_REPORT(state) {
      return {
        ...state,
        uploadWarnings: { numberOfUploadedAssets: 0, warnings: [] },
      };
    },
    DEACTIVATE_IS_LOADING(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    FORCE_REFRESH_REVIEW_TABLE(state) {
      return {
        ...state,
        reviewForceTableUpdate: !state.reviewForceTableUpdate,
      };
    },
    HIDE_MAXIMUM_ASSETS_ERROR(state) {
      return {
        ...state,
        showMaximumAssetsError: false,
      };
    },
    INITIALIZE_TEMPORARY_RESPONSES(state) {
      return {
        ...state,
        temporaryResponses: [],
      };
    },
    SET_AUTHENTICATION_TOKEN(state, action: PayloadAction<string>) {
      return {
        ...state,
        authenticationToken: action.payload,
      };
    },
    SET_IDENTIFY_USER(state) {
      return {
        ...state,
        isUserIdentified: true,
      };
    },
    SET_IS_VALID_AUTHENTIFICATION_TOKEN(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isValidAuthentificationToken: action.payload,
      };
    },
    SET_SHORTCUTS(state, action: PayloadAction<Shortcuts>) {
      return {
        ...state,
        shortcuts: action.payload,
      };
    },
    SET_TEMPORARY_RESPONSES(state, action: PayloadAction<TemporaryResponse>) {
      return {
        ...state,
        temporaryResponses: [...(state.temporaryResponses ?? []), action.payload],
      };
    },
    SHOW_MAXIMUM_ASSETS_ERROR(state) {
      return {
        ...state,
        showMaximumAssetsError: true,
      };
    },
    SHOW_PASSWORD_CHANGE_ERROR(state, action: PayloadAction<ShowPasswordChangeError>) {
      return {
        ...state,
        passwordChangeMessage: action.payload.message,
      };
    },
  },
});

export default applicationSlice.reducer;

export const {
  ACTIVATE_IS_LOADING,
  ADD_NOTIFICATION,
  APPLICATION_ACTIVATE_LABELING_FINISHED,
  APPLICATION_DEACTIVATE_LABELING_FINISHED,
  APPLICATION_INITIALIZE,
  APPLICATION_UPDATE_ACCORDION,
  APPLICATION_UPDATE_FIELD,
  APPLICATION_UPDATE_SEARCH_FIELD,
  CLEAN_STATE_FOR_PROJECT_LIST,
  CLEAR_NOTIFICATION,
  CLEAR_UPLOAD_REPORT,
  DEACTIVATE_IS_LOADING,
  FORCE_REFRESH_REVIEW_TABLE,
  HIDE_MAXIMUM_ASSETS_ERROR,
  SHOW_MAXIMUM_ASSETS_ERROR,
  SHOW_PASSWORD_CHANGE_ERROR,
  SET_TEMPORARY_RESPONSES,
  INITIALIZE_TEMPORARY_RESPONSES,
  SET_AUTHENTICATION_TOKEN,
  SET_IS_VALID_AUTHENTIFICATION_TOKEN,
  SET_SHORTCUTS,
  SET_IDENTIFY_USER,
} = applicationSlice.actions;
