import { type ApolloClient, type InMemoryCache } from '@apollo/client';

import { type AssetWhere, Status } from '../../__generated__/globalTypes';

export type ProjectQueueCounts = {
  countHoneypot: number;
  countLabeled: number;
  countOngoing: number;
  countReviewed: number;
  countToReview: number;
  countTodo: number;
};

export type ProjectQueueSelectCounters = {
  counterHoneypot: number;
  counterLabeled: number;
  counterOngoing: number;
  counterReviewed: number;
  counterToReview: number;
  counterTodo: number;
};

export const ProjectQueueCountsType = {
  [Status.LABELED]: 'countLabeled',
  [Status.ONGOING]: 'countOngoing',
  [Status.REVIEWED]: 'countReviewed',
  [Status.TO_REVIEW]: 'countToReview',
  [Status.TODO]: 'countTodo',
};

export const ProjectQueueCountersType = {
  [Status.LABELED]: 'counterLabeled',
  [Status.ONGOING]: 'counterOngoing',
  [Status.REVIEWED]: 'counterReviewed',
  [Status.TO_REVIEW]: 'counterToReview',
  [Status.TODO]: 'counterTodo',
};

export type ProjectQueueSliceValues = Readonly<
  Partial<ProjectQueueCounts> &
    ProjectQueueSelectCounters & {
      areAllAssetsSelected: boolean;
      selectedAssetIds: string[];
      unselectedAssetIds: string[];
    }
>;

export type ProjectQueueSliceSetters = Readonly<{
  initialize: () => void;
  prioritizeAssets: (payload: PrioritizeAssetsPayload) => void;
  resetProjectInfos: (payload?: Partial<ProjectQueueSliceValues>) => void;
  selectOrUnselectAllAssets: (selectAll?: boolean) => void;
  selectOrUnselectAsset: (assetId: string, status?: Status, isHoneypot?: boolean | null) => void;
  updateCounts: (payload: Partial<ProjectQueueCounts>) => void;
  updateProjectQueueField: <T extends keyof ProjectQueueSliceValues>(_: {
    path: T;
    value: ProjectQueueSliceValues[T];
  }) => void;
  updateSelectionState: (assetId: string) => void;
}>;

export type ProjectQueueSlice = ProjectQueueSliceValues & ProjectQueueSliceSetters;

export type ProjectQueueState = Partial<ProjectQueueCounts> &
  ProjectQueueSelectCounters & {
    areAllAssetsSelected: boolean;
    selectedAssetIds: string[];
    unselectedAssetIds: string[];
  };

export type PrioritizeAssetsPayload = {
  callback: () => void;
  client: ApolloClient<InMemoryCache>;
  priority: number;
  projectID: string;
  where: AssetWhere | null;
};
