import { type ApolloClient, type InMemoryCache, useApolloClient } from '@apollo/client';
import { createContext, useContext, useMemo } from 'react';

import { buildEntityGateway, type EntityGateway } from '../../gateways';

export interface Dependencies {
  client: ApolloClient<InMemoryCache>;
  entityGateway: EntityGateway;
}

export const DependenciesContext = createContext<Dependencies>({} as Dependencies);
DependenciesContext.displayName = 'Dependencies';

export const useDependencies = () => useContext(DependenciesContext);

export default function DependenciesProvider({ children }: { children?: React.ReactNode }) {
  const apolloClient = useApolloClient() as ApolloClient<InMemoryCache>;

  const entityGateway = useMemo(() => buildEntityGateway({ client: apolloClient }), [apolloClient]);

  const dependencies = useMemo(() => {
    return {
      client: apolloClient,
      entityGateway,
    };
  }, [apolloClient, entityGateway]);

  return (
    <DependenciesContext.Provider value={dependencies}>{children}</DependenciesContext.Provider>
  );
}

DependenciesProvider.defaultProps = {
  children: null,
};
