import { type Reference } from '@apollo/client';
import { type InMemoryCache } from '@apollo/client/cache';

import { type AnnotationFragment } from '@/graphql/annotations/__generated__/fragments.graphql';
import { GQL_FRAGMENT_ANNOTATION } from '@/graphql/annotations/fragments';
import { type Annotation } from '@/graphql/annotations/types';
import { getApolloClient, getCacheIdFromGraphQLObject } from '@/graphql/helpers';
import { getLabelId } from '@/redux/asset-label/helpers/getLabelId';
import { useStore } from '@/zustand';

export function addAnnotationInCache(annotationToAdd: Annotation): void {
  const client = getApolloClient();
  const labelId = getLabelId();

  const annotationToAddRef = client.cache.writeFragment<AnnotationFragment>({
    broadcast: false,
    data: annotationToAdd,
    fragment: GQL_FRAGMENT_ANNOTATION,
  });
  (client.cache as InMemoryCache).release(getCacheIdFromGraphQLObject(annotationToAdd));

  if (annotationToAddRef === undefined) {
    throw new Error('Unable to add annotation in cache: fragment reference is undefined');
  }

  client.cache.modify({
    fields: {
      [`annotations(${JSON.stringify({ where: { labelId } })})`]: (
        existing: readonly Reference[],
      ) => existing.slice().concat(annotationToAddRef),
    },
    id: 'ROOT_QUERY',
  });

  useStore
    .getState()
    .splitLabel.addAnnotationModification(getCacheIdFromGraphQLObject(annotationToAdd), 'add');
}
