import { gql } from '@apollo/client';

import { USER_FRAGMENTS, API_KEY_FRAGMENTS } from './fragments';

export const GQL_GET_USERS_FROM_MY_ORGANIZATION = gql`
  query UsersOfMyOrganization($where: SearchUserWhere!, $first: PageSize!, $skip: Int!) {
    data: usersOfMyOrganization(where: $where, first: $first, skip: $skip) {
      activated
      email
      firstname
      id
      isServiceAccount
      lastname
      organizationRole
    }
  }
`;

export const GQL_USERS_GET = gql`
  query Users($where: UserWhere!, $first: PageSize!, $skip: Int!) {
    users(where: $where, first: $first, skip: $skip) {
      ...UserInfos
    }
  }
  ${USER_FRAGMENTS}
`;

export const GQL_PENDING_USERS_GET = gql`
  query PendingUsers($where: UserWhere!, $first: PageSize!, $skip: Int!) {
    users(where: $where, first: $first, skip: $skip) {
      id
      activated
      email
      firstname
      lastname
      isServiceAccount
      organizationRole
      status
    }
  }
`;

export const GQL_PENDING_USERS_COUNT = gql`
  query CountPendingUsers($where: UserWhere!) {
    countUsers(where: $where)
  }
`;

export const GQL_USERS_GET_ONE_ADMIN = gql`
  query UserAdmin {
    adminOfUser
  }
`;

export const GQL_USER_RIGHTS = gql`
  query GetUserRights($where: UserWhere!, $first: PageSize!, $skip: Int!) {
    users(where: $where, first: $first, skip: $skip) {
      rights
    }
  }
`;

export const GQL_USER_API_KEYS = gql`
  query GetUserApiKeys($where: ApiKeyWhere!, $first: PageSize!, $skip: Int!) {
    apiKeys(where: $where, first: $first, skip: $skip) {
      ...ApiKeyInfos
    }
  }
  ${API_KEY_FRAGMENTS}
`;

export const GQL_USER_INFOS = gql`
  query GetUserInfos($where: UserWhere!, $first: PageSize!, $skip: Int!) {
    users(where: $where, first: $first, skip: $skip) {
      id
      firstname
      lastname
      email
      organizationRole
      activated
    }
  }
`;

export const GQL_USER_PROJECTS_IN_ORG = gql`
  query GetUserProjectsInOrg($where: ProjectUserWhere!, $first: PageSize!, $skip: Int!) {
    data: projectUsers(where: $where, first: $first, skip: $skip) {
      id
      project {
        id
        title
        deletedAt
      }
      activated
      role
      status
      deletedAt
    }
    count: countProjectUsers(where: $where)
  }
`;

export const GQL_GET_VIEWER = gql`
  query Viewer {
    viewer {
      ...UserInfos
      organizations {
        id
        license {
          type
        }
      }
    }
  }
  ${USER_FRAGMENTS}
`;

export const GQL_GET_COUNT_USERS_FROM_MY_ORGANIZATION = gql`
  query CountUsersOfMyOrganization($where: SearchUserWhere!) {
    count: countUsersOfMyOrganization(where: $where)
  }
`;
