import L from 'leaflet';

import { type KiliPortalDivIcon } from './KiliDivIconPortal';

/**
 * There is one KiliFeatureGroup per annotation (even if the annotation is multi-part).
 * The KiliFeatureGroup is responsible to draw each part of the annotation and add
 * associated tags.
 * Each Kili annotation adds itself into the correct KiliFeatureGroup.
 */
class KiliFeatureGroup extends L.FeatureGroup {
  annotationMid: string;

  setOnHover?: (isHovered: boolean) => void;

  tagGroup: L.LayerGroup;

  shapeGroup: L.FeatureGroup;

  markers: L.Marker<KiliPortalDivIcon>[];

  constructor(annotationMid: string, setOnHover?: (isHovered: boolean) => void) {
    super();
    this.annotationMid = annotationMid;
    this.tagGroup = new L.LayerGroup();
    this.shapeGroup = new L.FeatureGroup();
    this.addLayer(this.tagGroup);
    this.addLayer(this.shapeGroup);
    this.setOnHover = setOnHover;
    this.markers = [];
  }

  addTag(kiliLabelTagPortalMarker: L.Marker<KiliPortalDivIcon>) {
    this.markers.push(kiliLabelTagPortalMarker);
  }

  removeTag(kiliLabelTagPortalMarker: L.Marker<KiliPortalDivIcon>) {
    this.markers = this.markers.filter(d => d !== kiliLabelTagPortalMarker);
  }

  onHover(isHovered: boolean) {
    this.setOnHover?.(isHovered);
  }
}

export default KiliFeatureGroup;
