import { type FieldMergeFunction } from '@apollo/client';

type AssetRef = { __ref: string };

export const mergeAssetsWithinCache: FieldMergeFunction<
  AssetRef[] | undefined,
  AssetRef[] | undefined
> = (existing, incoming, { args }) => {
  if (args?.where?.idIn) {
    // If query is made for issues, first and skip are always 100 and 0, thus
    // we want to merge the assets, without purging anything from the cache.
    // The query is also used for the ThumbnailCard component to refetch
    // the submitted labels (for Batch Classification)
    const merged: AssetRef[] = [...(existing ?? []), ...(incoming ?? [])];

    return [...new Set(merged.map(item => item.__ref))].map(ref => ({ __ref: ref }));
  }
  const skip = args?.skip ?? 0;
  const first = args?.first ?? 0;
  const merged = existing ? existing.slice(0) : [];
  merged.splice(skip, first, ...(incoming ?? []));
  return merged;
};

export default { mergeAssetsWithinCache };
