/* eslint-disable */
// @ts-nocheck
/**
 *  Overwrite Draw.Marker to provide a custom class to the new marker
 * https://github.com/Leaflet/Leaflet.draw/blob/d5dd11781c2e07f9e719308e504fe579000edf54/src/draw/handler/Draw.Marker.js
 */

import { PointToolClass } from '@kili-technology/cursors';
import { cx } from '@emotion/css';
import L from 'leaflet';
import 'leaflet-draw';

L.Draw.Marker.include({
  // @method addHooks(): void
  // Add listener hooks to this handler.
  addHooks() {
    L.Draw.Feature.prototype.addHooks.call(this);

    if (this._map) {
      this._tooltip.updateContent({ text: this._initialLabelText });

      // Same mouseMarker as in Draw.Polyline
      if (!this._mouseMarker) {
        this._mouseMarker = L.marker(this._map.getCenter(), {
          icon: L.divIcon({
            className: cx(PointToolClass, 'draw-cursor'),
            iconAnchor: [20, 20],
            iconSize: [40, 40],
          }),
          opacity: 0,
          zIndexOffset: this.options.zIndexOffset,
        });
      }

      this._mouseMarker.on('click', this._onClick, this).addTo(this._map);

      this._map.on('mousemove', this._onMouseMove, this);
      this._map.on('click', this._onTouch, this);
    }
  },
});
