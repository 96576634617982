import { type Draft } from 'immer';

import { type ProjectListSlice, type ProjectListSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: ProjectListSliceValues = {
  showSideMenu: true,
};

const log = createLogger('projectList');

export const createProjectListSlice: ZustandSlice<ProjectListSlice> = set => ({
  ...initialState,
  updateField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.projectList as Draft<ProjectListSliceValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
});

export * from './types';
