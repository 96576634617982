import {
  MachineLearningTask,
  type EntityRelation,
  type JobAnnotation,
  type ObjectRelation,
} from '@kili-technology/types';

import { type JobsState } from './types';

import { ANY_RELATION_VALUE } from '../../components/InterfaceBuilder/FormInterfaceBuilder/JobCategory/JobCategoryRelation';
import { ANNOTATIONS } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { type KiliAnnotationProvider, type KiliAnnotation } from '../../services/jobs/setResponse';
import {
  type JobCategoryToRelationCategories,
  type RelationCategories,
  type RelationCategoriesWithRelationMid,
  type RelationCategory,
  type RelationCategoryWithRelationMid,
} from '../project/relations';

const cleanRelationsFromEndOrStartAnnotationWhichChangedClass =
  (state: JobsState, mid: string, relationCategory: RelationCategoryWithRelationMid) =>
  (start: boolean) => {
    const {
      mlTask: relationMlTask,
      jobName: relationJobName,
      categoryCode: relationCategoryCode,
      relationMid,
    } = relationCategory;
    const relationTask = state[relationMlTask];
    if (!relationTask) return;
    const objectKey = start ? 'startObjects' : 'endObjects';
    const entityKey = start ? 'startEntities' : 'endEntities';
    (relationTask[relationJobName] as JobAnnotation)[ANNOTATIONS] = (
      relationTask[relationJobName] as JobAnnotation
    )[ANNOTATIONS].map(annotation => {
      if (
        annotation?.categories?.[0].name !== relationCategoryCode ||
        annotation.mid !== relationMid
      )
        return annotation;
      if (objectKey in annotation) {
        const newEndMids = (annotation as ObjectRelation)?.[objectKey].filter(o => o.mid !== mid);
        if (newEndMids.length === 0) return undefined;
        return {
          ...annotation,
          endObjects: newEndMids,
        };
      }
      if (entityKey in annotation) {
        const newEndMids = (annotation as EntityRelation)?.[entityKey].filter(o => o.mid !== mid);
        if (newEndMids.length === 0) return undefined;
        return {
          ...annotation,
          endEntities: newEndMids,
        };
      }
      return annotation;
    }).filter((annotation): annotation is KiliAnnotation => !!annotation);
  };

export const cleanRelationsFromAnnotationWhichChangedClass = (
  state: JobsState,
  mid: string,
  relationsCategoriesToRemoveObjectFrom: RelationCategoriesWithRelationMid,
): void => {
  relationsCategoriesToRemoveObjectFrom.start.forEach(relationCategory =>
    cleanRelationsFromEndOrStartAnnotationWhichChangedClass(state, mid, relationCategory)(true),
  );
  relationsCategoriesToRemoveObjectFrom.end.forEach(relationCategory =>
    cleanRelationsFromEndOrStartAnnotationWhichChangedClass(state, mid, relationCategory)(false),
  );
};

const getStartAndEndMids = (
  annotation: ObjectRelation | EntityRelation,
): { endMids: string[]; isObject: boolean; startMid: string } => {
  if (isRelationObjectRelation(annotation)) {
    return {
      endMids: (annotation?.endObjects ?? []).map(endObject => endObject.mid),
      isObject: true,
      startMid: annotation?.startObjects?.[0].mid,
    };
  }
  return {
    endMids: (annotation?.endEntities ?? []).map(endObject => endObject.mid),
    isObject: false,
    startMid: annotation?.startEntities?.[0].mid,
  };
};

const shouldDeleteRelation = (startMid: string, endMids: string[], midDeleted: string): boolean => {
  if (startMid === midDeleted) return true;
  if (!endMids?.length) return true;
  if (endMids.length === 1 && endMids?.[0] === midDeleted) return true;
  return false;
};

const cleanRelationFromDeletedMid = (
  annotation: ObjectRelation | EntityRelation,
  midDeleted: string,
): ObjectRelation | EntityRelation | undefined => {
  const { startMid, endMids, isObject } = getStartAndEndMids(annotation);
  if (shouldDeleteRelation(startMid, endMids, midDeleted)) return undefined;
  if (!endMids.includes(midDeleted)) {
    return annotation;
  }
  const endKey = isObject ? 'endObjects' : 'endEntities';
  return {
    ...annotation,
    [endKey]: endMids.filter(mid => mid !== midDeleted).map(m => ({ mid: m })),
  };
};

export const deleteRelationAfterObjectDeletion = (state: JobsState, mid: string): void => {
  const allRelationJobs = [state.OBJECT_RELATION, state.NAMED_ENTITIES_RELATION];
  allRelationJobs.forEach(relationJobs => {
    if (!relationJobs) return;
    Object.values(relationJobs ?? {}).forEach(relationJob => {
      // eslint-disable-next-line no-param-reassign
      relationJob.annotations = relationJob.annotations
        .map((annotation: KiliAnnotationProvider<ObjectRelation | EntityRelation>) =>
          cleanRelationFromDeletedMid(annotation, mid),
        )
        .filter(
          (
            annotation: ObjectRelation | EntityRelation | undefined,
          ): annotation is ObjectRelation | EntityRelation => !!annotation,
        );
    });
  });
};

const filterRelationCategories = ({
  position,
  newPossibleRelationCategories,
  relationCategories,
}: {
  newPossibleRelationCategories: RelationCategories;
  position: 'start' | 'end';
  relationCategories: (RelationCategory & { relationMid: string | undefined })[];
}) =>
  relationCategories.filter(
    (relationCategory): relationCategory is RelationCategoryWithRelationMid => {
      if (!relationCategory.relationMid) {
        return false;
      }

      const categories = newPossibleRelationCategories[position].map(c => c.categoryCode);
      const isCategoryCodeIncompatibleWithCategories =
        !categories.includes(relationCategory.categoryCode) &&
        !categories.includes(ANY_RELATION_VALUE);

      return isCategoryCodeIncompatibleWithCategories;
    },
  );

export const isKiliAnnotationEntityRelation = (
  annotation: KiliAnnotation,
): annotation is KiliAnnotationProvider<EntityRelation> =>
  annotation.mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION;

export const isKiliAnnotationObjectRelation = (
  annotation: KiliAnnotation,
): annotation is KiliAnnotationProvider<ObjectRelation> =>
  annotation.mlTask === MachineLearningTask.OBJECT_RELATION;

const isRelationEntityRelation = (
  relation: EntityRelation | ObjectRelation,
): relation is EntityRelation => 'startEntities' in relation;

export const isRelationObjectRelation = (
  relation: EntityRelation | ObjectRelation,
): relation is ObjectRelation => 'startObjects' in relation;

const findJobRelationAnnotation =
  ({
    annotation,
    position,
    jobName,
  }: {
    annotation: KiliAnnotation;
    jobName: string;
    position: 'start' | 'end';
  }) =>
  (jobRelationAnnotation: EntityRelation | ObjectRelation) => {
    let mids = [];
    if (isRelationEntityRelation(jobRelationAnnotation)) {
      mids =
        position === 'start'
          ? jobRelationAnnotation.startEntities
          : jobRelationAnnotation.endEntities;
    } else {
      mids =
        position === 'start'
          ? jobRelationAnnotation.startObjects
          : jobRelationAnnotation.endObjects;
    }

    return (
      jobRelationAnnotation.jobName === jobName && mids.some(({ mid }) => mid === annotation.mid)
    );
  };

const getRelationCategoriesWithRelationsMid = ({
  jobsRelationsAnnotations,
  annotation,
  position,
  relationCategories,
}: {
  annotation: KiliAnnotation;
  jobsRelationsAnnotations: (EntityRelation | ObjectRelation)[];
  position: 'start' | 'end';
  relationCategories: RelationCategory[];
}): (RelationCategory & { relationMid: string | undefined })[] => {
  const relationCategoriesToRemove: (RelationCategory & { relationMid: string | undefined })[] = [];

  relationCategories.forEach(relationCategory => {
    const jobRelationAnnotations = jobsRelationsAnnotations.filter(
      findJobRelationAnnotation({ annotation, jobName: relationCategory.jobName, position }),
    );
    relationCategoriesToRemove.push(
      ...jobRelationAnnotations.map(jobRelationAnnotation => ({
        ...relationCategory,
        relationMid: jobRelationAnnotation?.mid,
      })),
    );
  });
  return relationCategoriesToRemove;
};

const getRelationCategories =
  ({
    annotation,
    jobsRelationsAnnotations,
    previousPossibleRelationCategories,
    newPossibleRelationCategories,
  }: {
    annotation: KiliAnnotation;
    jobsRelationsAnnotations: (EntityRelation | ObjectRelation)[];
    newPossibleRelationCategories: RelationCategories;
    previousPossibleRelationCategories: RelationCategories;
  }) =>
  (position: 'start' | 'end') => {
    const relationCategoriesWithRelationsMid = getRelationCategoriesWithRelationsMid({
      annotation,
      jobsRelationsAnnotations,
      position,
      relationCategories: previousPossibleRelationCategories?.[position] ?? [],
    });

    return filterRelationCategories({
      newPossibleRelationCategories,
      position,
      relationCategories: relationCategoriesWithRelationsMid,
    });
  };

export const getRelationCategoriesWhereToPotentiallyRemoveAnnotation = ({
  annotation,
  relationCategoriesObject,
  categoryCode,
  jobsRelationsAnnotations,
}: {
  annotation: KiliAnnotation;
  categoryCode: string;
  jobsRelationsAnnotations: (EntityRelation | ObjectRelation)[];
  relationCategoriesObject: JobCategoryToRelationCategories;
}): RelationCategoriesWithRelationMid => {
  const { categories: previousCategories } = annotation;
  const previousCategoryCode = previousCategories?.[0].name;
  const previousPossibleRelationCategories = relationCategoriesObject?.[previousCategoryCode];
  const newPossibleRelationCategories = relationCategoriesObject?.[categoryCode];

  const getRelationCategoriesComputed = getRelationCategories({
    annotation,
    jobsRelationsAnnotations,
    newPossibleRelationCategories,
    previousPossibleRelationCategories,
  });

  const start = getRelationCategoriesComputed('start');

  const end = getRelationCategoriesComputed('end');

  return {
    end,
    start,
  };
};
