import { KiliLogo } from '@kili-technology/artworks';
import { HandClickingClass } from '@kili-technology/cursors';
import { type FunctionComponent, type SVGProps } from 'react';
import { type ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CustomTitle from './CustomTitle/CustomTitle';
import useStyles from './GenericLeftBar.style';
import { type connector } from './GenericLeftBar.wrap';
import ProjectsPanel from './ProjectsPanel';

import { useHomePath } from '../../../pages/RootModule/hooks/useHomePath';
import useIsInProject from '../hooks/useIsInProject';

type PropTypes = {
  closeButton?: FunctionComponent<SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  title?: string;
};

type WithReduxProps = ConnectedProps<typeof connector> & PropTypes;

export default function GenericLeftBar(props: WithReduxProps): JSX.Element {
  const HomePath = useHomePath();
  const history = useHistory();
  const { closeButton, disabled, title } = props;
  const goHome = (): void => {
    if (!disabled) history.push(HomePath);
  };
  const classes = useStyles();
  const { isInProject } = useIsInProject();
  const ComponentToUse = closeButton || KiliLogo;
  const leftPanel = isInProject ? <ProjectsPanel /> : <CustomTitle title={title} />;

  return (
    <>
      <div
        className={`${classes.subContainer} ${HandClickingClass}`}
        data-cy="kili-logo"
        onClick={goHome}
      >
        <ComponentToUse />
      </div>
      {leftPanel}
    </>
  );
}
