import { sanitizeEmail } from '@kili-technology/utilities';
import { useSelector } from 'react-redux';

import { isUsingAuth0, useAuth0 } from '../auth0';
import { authenticationEmail } from '../redux/authentication/selectors';

export function useUserEmail(): string | null {
  const { user } = useAuth0();
  const kiliEmail = useSelector(authenticationEmail);

  const auth0Email = user?.email;

  if (isUsingAuth0 && auth0Email) return sanitizeEmail(auth0Email);
  return kiliEmail;
}
