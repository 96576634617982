import type L from 'leaflet';

import layersByMid from '@/components/asset-ui/Image/LeafletMapv2/ImageAnnotations/LayerMap';
import drawSemanticLayer from '@/components/asset-ui/Image/LeafletMapv2/KiliLeafletLayers/helpers/semantic';

export const getNewLayersMidFromDrawnLayer = (layer: L.Polyline, mid: string): L.Layer[] => {
  const currentLayers = layersByMid.get(mid);
  if (layer.options.isSemantic) {
    return drawSemanticLayer(layer as unknown as L.Polygon, mid);
  }
  if (!currentLayers) return [layer];
  return [layer];
};
