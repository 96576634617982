import {
  createSlice,
  type SliceCaseReducers,
  type ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';

import { initialState as initialFlagState } from './initialState';
import { type GenericState, LoadingFlag } from './types';

import { type State } from '../../types';

export const createGenericSliceFlag = <
  T,
  Reducers extends SliceCaseReducers<GenericState<T>>,
>(options: {
  initialState: T;
  name: keyof State;
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
}) => {
  const initialState: GenericState<T> = { ...initialFlagState, ...options.initialState };

  return createSlice({
    initialState,
    name: options.name,
    reducers: {
      /* eslint-disable no-bitwise */
      setFail(state) {
        state.flags &= ~LoadingFlag.IsLoading;
        state.flags |= LoadingFlag.IsError;
        return state;
      },
      setLoading(state) {
        state.flags |= LoadingFlag.IsLoading;
        state.flags &= ~LoadingFlag.IsError;
        return state;
      },
      setReset(state) {
        state.flags &= ~LoadingFlag.IsLoading;
        state.flags &= ~LoadingFlag.IsError;
        return state;
      },
      setStopLoading(state) {
        state.flags &= ~LoadingFlag.IsLoading;
        return state;
      },
      setSuccess(state) {
        state.flags &= ~LoadingFlag.IsLoading;
        state.flags &= ~LoadingFlag.IsError;
        return state;
      },
      /* eslint-enable no-bitwise */
      ...options.reducers,
    },
  });
};
