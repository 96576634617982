import type { VideoObjectDetectionAnnotation } from '@/__generated__/globalTypes';
import { getApolloClient } from '@/graphql/helpers';
import { getLabelId } from '@/redux/asset-label/helpers/getLabelId';

import { getAnnotationFromCache } from './getAnnotationFromCache';

import type { GetAnnotationsLightQuery } from '../../__generated__/queries.graphql';
import { GQL_CACHE_GET_ANNOTATIONS_LIGHT } from '../../queries';
import type { VideoAnnotation } from '../../types';

export function getAnnotationsRelatedToMidFromCache(
  mid: string,
): [VideoObjectDetectionAnnotation, ...VideoAnnotation[]] {
  const client = getApolloClient();
  const labelId = getLabelId();
  let queryResult: GetAnnotationsLightQuery | null = null;

  try {
    queryResult = client.cache.readQuery<GetAnnotationsLightQuery>({
      query: GQL_CACHE_GET_ANNOTATIONS_LIGHT,
      variables: { where: { labelId } },
    });
  } catch {
    // Query not in cache
  }

  if (!queryResult?.annotations) {
    throw new Error('No annotation in cache');
  }

  const lightAnnotationOfMid = queryResult.annotations.find(
    annotation => (annotation as VideoObjectDetectionAnnotation).mid === mid,
  );

  if (!lightAnnotationOfMid) {
    throw new Error(`No annotation with mid ${mid} found`);
  }

  const annotationOfMid = getAnnotationFromCache(
    lightAnnotationOfMid,
  ) as VideoObjectDetectionAnnotation;

  const childAnnotations = queryResult.annotations
    .filter(lightAnnotation => lightAnnotation.path?.[0]?.[0] === annotationOfMid.id)
    .map(getAnnotationFromCache) as VideoAnnotation[];

  return [annotationOfMid, ...childAnnotations];
}
