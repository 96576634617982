import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { type Color, type HiddenEllipsis, type TemporaryResponse } from './types';

import { createDeepSelectorFunction } from '../selectors';
import { type State } from '../types';

const createDeepEqualSelector = createDeepSelectorFunction();

export const applicationState = createSelector(
  [(state: State) => state.application],
  application => application,
);

export const applicationAuthenticationToken = createSelector([applicationState], application =>
  _get(application, 'authenticationToken', ''),
);

export const applicationisGraphqlClientInitialized = createSelector(
  [applicationState],
  application => application.isGraphqlClientInitialized || false,
);

export const applicationIsLoading = createSelector(
  [applicationState],
  application => application.isLoading,
);

export const applicationMaximumAssets = createSelector(
  [applicationState],
  application => application.showMaximumAssetsError,
);

export const applicationPasswordChangeMessage = createSelector([applicationState], application =>
  _get(application, 'passwordChangeMessage', ''),
);

export const applicationSideBarOpen = createSelector(
  [applicationState],
  application => application.sideBarOpen || false,
);

export const applicationShortcuts = createSelector(
  [applicationState],
  application => application?.shortcuts,
);

export const applicationOpenModalLabelingFinished = createSelector(
  [applicationState],
  application => _get(application, 'openModalLabelingFinished', 'FROM_REVIEW'),
);

export const applicationDocumentViewerScrollOffset = createSelector(
  [applicationState],
  application => application.documentViewerScrollOffset ?? 0,
);

export const applicationProjectIsCreating = createSelector([applicationState], application =>
  _get(application, 'projectIsCreating', false),
);

export const applicationOldPassword = createSelector([applicationState], application =>
  _get(application, 'oldPassword', ''),
);

export const applicationNewPassword1 = createSelector([applicationState], application =>
  _get(application, 'newPassword1', ''),
);

export const applicationNewPassword2 = createSelector([applicationState], application =>
  _get(application, 'newPassword2', ''),
);

export const applicationInExplore = createSelector([applicationState], application =>
  _get(application, 'inExplore', false),
);

export const applicationInReview = createSelector([applicationState], application =>
  _get(application, 'inReview', false),
);

export const applicationOriginalUrl = createSelector([applicationState], application =>
  _get(application, 'originalUrl', null),
);

export const applicationSearchQuery = createSelector([applicationState], application =>
  _get(application, 'searchQuery'),
);

export const applicationSearchVisibility = createSelector([applicationState], application =>
  _get(application, 'searchVisibility'),
);

export const applicationSearchBarValue = createSelector([applicationState], application =>
  _get(application, 'searchBarValue', null),
);

export const applicationNotification = createSelector(
  [applicationState],
  application => application?.notification ?? {},
);

export const applicationClearNotification = createSelector([applicationState], application =>
  _get(application, 'clearNotification', false),
);

export const applicationReviewForceTableUpdate = createSelector([applicationState], application =>
  _get(application, 'reviewForceTableUpdate', false),
);

export const applicationColorsPart = createSelector([applicationState], application =>
  _get(application, 'colors', {}),
);

export const applicationColors = createDeepEqualSelector(
  [applicationColorsPart],
  colors => _get(colors, 'colorList', []) as Color[],
);

export const applicationIsUpdatingTools = createSelector([applicationState], application =>
  _get(application, 'isUpdatingJsonInterface', false),
);

export const applicationUploadCurrentFileIndex = createSelector([applicationState], application =>
  _get(application, 'uploadCurrentFileIndex', null),
);

export const applicationUploadCurrentFileName = createSelector([applicationState], application =>
  _get(application, 'uploadCurrentFileName'),
);

export const applicationUploadNumberOfFiles = createSelector([applicationState], application =>
  _get(application, 'uploadNumberOfFiles', null),
);

export const applicationUploadHasStarted = createSelector(
  [applicationState],
  application =>
    _get(application, 'uploadCurrentFileIndex') !== null &&
    _get(application, 'uploadCurrentFileIndex') !== undefined,
);

export const applicationShouldUpload = createSelector([applicationState], application =>
  _get(application, 'shouldUpload', false),
);

export const applicationUploadWarnings = createSelector([applicationState], application =>
  _get(application, 'uploadWarnings', { numberOfUploadedAssets: 0, warnings: [] }),
);

export const applicationAccordion = createSelector([applicationState], application =>
  _get(application, 'accordion', {} as Record<string, boolean>),
);

export const applicationHiddenEllipsis = createSelector([applicationState], application =>
  _get(application, 'hiddenEllipsis', [] as HiddenEllipsis[]),
);

export const applicationProjectIsDeleting = createSelector([applicationState], application =>
  _get(application, 'projectIsDeleting', false),
);

export const applicationDatasetIsDeleting = createSelector([applicationState], application =>
  _get(application, 'datasetIsDeleting', false),
);

export const applicationCategoriesSearchBarValue = createSelector([applicationState], application =>
  _get(application, 'categoriesSearchBarValue', ''),
);

export const applicationForceTableUpdate = createSelector([applicationState], application =>
  _get(application, 'forceTableUpdate', false),
);

export const applicationChangedLayerTool = createSelector([applicationState], application =>
  _get(application, 'changedLayerTool', null),
);

export const applicationNumberOfProjectUsers = createSelector([applicationState], application =>
  _get(application, 'numberOfProjectUsers'),
);

export const applicationLeafletScrollWheelZoomEnable = createSelector(
  [applicationState],
  application => _get(application, 'leafletScrollWheelZoomEnable', true),
);

export const applicationTemporaryResponses = createSelector(
  [applicationState],
  application => application.temporaryResponses as TemporaryResponse[],
);

export const applicationIsValidAuthentificationToken = createSelector(
  [applicationState],
  application => _get(application, 'isValidAuthentificationToken', false),
);

export const applicationIsLabelingOneAsset = createSelector([applicationState], application =>
  _get(application, 'isLabelingOneAsset', false),
);

export const applicationHomeList = createSelector([applicationState], application =>
  _get(application, 'homeList', 'projects'),
);

export const applicationCanUndo = createSelector([applicationState], application =>
  _get(application, 'canUndo', true),
);

export const applicationIsUserIdentified = createSelector(
  [applicationState],
  application => application.isUserIdentified,
);
