import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type FrameResponsesPayload } from './types';

const sliceLabelFrames = createSlice({
  initialState,
  name: 'labelFrames',
  reducers: {
    LABEL_FRAMES_INITIALIZE(_state) {
      return {
        ...initialState,
      };
    },
    LABEL_FRAMES_SET_FRAME_RESPONSES(state, action) {
      return {
        ...state,
        frameResponses: action.payload.frameResponses,
      };
    },
    LABEL_FRAMES_SET_RESPONSES(state, action: PayloadAction<FrameResponsesPayload>) {
      return {
        ...state,
        frameResponses: action.payload.frameResponses,
      };
    },
  },
});

export const {
  LABEL_FRAMES_INITIALIZE,
  LABEL_FRAMES_SET_FRAME_RESPONSES,
  LABEL_FRAMES_SET_RESPONSES,
} = sliceLabelFrames.actions;

export default sliceLabelFrames.reducer;
