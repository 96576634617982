import { type Annotation } from '@kili-technology/types';

import { type PdfAnnotation } from '../react-pdf-highlighter/types';

export type OldPdfAnnotation = Omit<PdfAnnotation, 'annotations'> & {
  annotations: (Omit<PdfAnnotation['annotations'][number], 'pageNumberArray'> & {
    pageNumber: number;
  })[];
};

export function convertOldPdfAnnotation(annotation: OldPdfAnnotation): PdfAnnotation {
  return {
    ...annotation,
    annotations: annotation.annotations.map(({ boundingPoly, pageNumber, polys }) => ({
      boundingPoly,
      pageNumberArray: polys[0].normalizedVertices.map(() => pageNumber),
      polys,
    })),
  };
}

export function isOldPdfAnnotation(annotation: Annotation): annotation is OldPdfAnnotation {
  return (annotation as OldPdfAnnotation).annotations?.[0]?.pageNumber !== undefined;
}
