import i18next from 'i18next';
import { generatePath } from 'react-router-dom';
import { StringParam } from 'use-query-params';

import { rootRoutes } from '../../RootModule/RootPaths';

export enum ProjectListsRoutes {
  ACTIVE = 'active',
  ALL = 'all',
  ARCHIVED = 'archived',
  DEMO_PROJECTS = 'demo-projects',
  FAVORITES = 'favorites',
}

export interface ListsParams {
  listName: ProjectListsRoutes;
}

type ProjectListsNavLink = {
  key: ProjectListsRoutes;
  label: string;
  path: string;
  separator?: boolean;
};

export const projectListsNavLinks: ProjectListsNavLink[] = [
  {
    key: ProjectListsRoutes.ALL,
    label: i18next.t('navigation.allProjects'),
    path: generatePath(rootRoutes.ROOT_PROJECT_LISTS.path, { listName: ProjectListsRoutes.ALL }),
  },
  {
    key: ProjectListsRoutes.FAVORITES,
    label: i18next.t('navigation.favorites'),
    path: generatePath(rootRoutes.ROOT_PROJECT_LISTS.path, {
      listName: ProjectListsRoutes.FAVORITES,
    }),
  },
  {
    key: ProjectListsRoutes.ACTIVE,
    label: i18next.t('navigation.active'),
    path: generatePath(rootRoutes.ROOT_PROJECT_LISTS.path, { listName: ProjectListsRoutes.ACTIVE }),
  },
  {
    key: ProjectListsRoutes.ARCHIVED,
    label: i18next.t('navigation.archived'),
    path: generatePath(rootRoutes.ROOT_PROJECT_LISTS.path, {
      listName: ProjectListsRoutes.ARCHIVED,
    }),
  },
  {
    key: ProjectListsRoutes.DEMO_PROJECTS,
    label: 'Demo projects',
    path: generatePath(rootRoutes.ROOT_PROJECT_LISTS.path, {
      listName: ProjectListsRoutes.DEMO_PROJECTS,
    }),
    separator: true,
  },
];

export const filterBarSearchQueryParams = {
  assetExternalId: StringParam,
  assetTypes: StringParam,
  complianceTags: StringParam,
  createdAtGte: StringParam,
  createdAtLte: StringParam,
  name: StringParam,
  progressGte: StringParam,
  progressLte: StringParam,
  tagIds: StringParam,
  updatedAtGte: StringParam,
  updatedAtLte: StringParam,
};
