import {
  type AnnotationCategory as CategoryFromJsonResponse,
  type JsonCategories as Categories,
  type Job,
  type Jobs,
  MachineLearningTask,
} from '@kili-technology/types';
import _get from 'lodash/get';

import {
  CATEGORIES,
  CONTENT,
  ML_TASK,
  RelationObjectType,
} from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';

export const retrieveAllObjectsForTask = (
  jobsList: [string, Job][],
  task: MachineLearningTask,
): Categories => {
  return jobsList
    .filter(([_, job]) => job[ML_TASK] === task && !job?.isModel)
    .map(([jobCode, job]) => {
      let categoriesObject: Categories = _get(job, [CONTENT, CATEGORIES], {});
      Object.keys(categoriesObject).forEach(category => {
        categoriesObject = {
          ...categoriesObject,
          [category]: {
            ...categoriesObject[category],
            jobCode,
          },
        };
      });
      return categoriesObject;
    })
    .reduce((accumulator, categories) => ({ ...accumulator, ...categories }), {});
};

export type JobCodesWithCategories = [string, string[]][];

export const retrieveJobCodesWithCategories = (jobs: Jobs): JobCodesWithCategories => {
  return Object.entries(jobs)
    .filter(([_, job]) => _get(job, ML_TASK) === MachineLearningTask.OBJECT_DETECTION)
    .map(([jobCode, job]) => [jobCode, Object.keys(_get(job, [CONTENT, CATEGORIES], {}))]);
};

export const getAllowedObjects = (
  allowedCategories: Categories,
  selectedCategory: CategoryFromJsonResponse,
  type: RelationObjectType,
): string[] => {
  const allowedCategory = allowedCategories[selectedCategory?.name];

  switch (type) {
    case RelationObjectType.END_ENTITIES:
      return allowedCategory?.endEntities ?? [];
    case RelationObjectType.END_OBJECTS:
      return allowedCategory?.endObjects ?? [];
    case RelationObjectType.START_ENTITIES:
      return allowedCategory?.startEntities ?? [];
    case RelationObjectType.START_OBJECTS:
      return allowedCategory?.startObjects ?? [];
    default:
      return [];
  }
};
