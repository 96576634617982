import { css } from '@emotion/css';

const useStyles = () => {
  return {
    title: css`
      padding: var(--space-2);
    `,
  };
};

export default useStyles;
