import create, { type StateCreator } from 'zustand';
import { devtools, type DevtoolsOptions, subscribeWithSelector } from 'zustand/middleware';

import { createHistorySlice, type HistorySlice } from './history';
import { type Action } from './history/types';

import { shouldAddDevTools } from '../config';

export type ZustandStore = {
  history: HistorySlice;
};

export type ZustandSlice<T> = StateCreator<
  ZustandStore,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never]],
  [],
  T
>;

const devtoolsOptions: DevtoolsOptions & { trace: boolean } = {
  enabled: shouldAddDevTools,
  name: 'zustand-history',
  serialize: {
    options: {
      map: true,
      set: true,
    },
  },
  trace: true,
};

export const useHistoryStore = create<ZustandStore>()(
  devtools(
    subscribeWithSelector((...props) => ({
      history: createHistorySlice(...props),
    })),
    devtoolsOptions,
  ),
);

export const { history } = useHistoryStore.getState();

export const addAction = (action: Action) => useHistoryStore.getState().history.addAction(action);
