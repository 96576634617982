import { type AppThunk } from '@/store';

import { LABEL_FRAMES_SET_FRAME_RESPONSES } from '../../label-frames/slice';
import { type FrameResponses } from '../../label-frames/types';

export const updateFrameResponsesWithoutCloning = ({
  frameResponses: newFrameResponses,
}: {
  frameResponses: FrameResponses;
}): AppThunk => {
  return dispatch => {
    const payload = {
      frameResponses: newFrameResponses,
    };
    return dispatch(LABEL_FRAMES_SET_FRAME_RESPONSES(payload));
  };
};
