import { DetectionTasks, MachineLearningTask, type Tool } from '@kili-technology/types';

import {
  CHILDREN,
  UNKNOWN,
  translations,
} from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';

import { type JobRendererPropsType } from './types';

export const generateDefaultJobInstruction = (
  jobName: string,
  mlTask: MachineLearningTask,
  firstTool: Tool | undefined,
  jobsNameWithoutInstructionGroupedByMlTask: Record<MachineLearningTask, string[]>,
) => {
  if (!mlTask) return translations[UNKNOWN];
  const displayNumber =
    (jobsNameWithoutInstructionGroupedByMlTask?.[mlTask]?.indexOf(jobName) ?? -1) + 1;
  const shouldDisplayTool =
    DetectionTasks.includes(mlTask) && firstTool && mlTask !== MachineLearningTask.POSE_ESTIMATION;
  return shouldDisplayTool
    ? `${translations[mlTask]} - ${displayNumber}: ${translations[firstTool]}`
    : `${translations[mlTask]} - ${displayNumber}`;
};

export const getJobIdentity = (props: JobRendererPropsType) => {
  return {
    jobName: props.job[0],
    mlTask: props.job[1].mlTask,
    parentMid: props.parentMid,
    path: props.path,
  };
};

export const getJobPath = (props: JobRendererPropsType) => {
  const jobIdentity = getJobIdentity(props);
  return jobIdentity.path
    ? jobIdentity.path.concat(...[CHILDREN, jobIdentity.jobName])
    : [jobIdentity.jobName];
};
