import {
  updateFrameSubJobInFrameResponse,
  updateSubJobsInFrameResponse,
} from '@/services/jobs/update';

import { type ChildVideoJob, type FrameResponses } from '../../label-frames/types';
import { type FlatNode, type FlatTree } from '../../project/ontologyTree';

export const updateSubJob = (
  currentFrame: number,
  jobName: string,
  subJob: ChildVideoJob | null,
  endFrame: number,
  frameResponses: FrameResponses,
  flatTree: FlatTree,
  classificationChildrenJobsToClean: FlatNode[] | null,
  parentMid?: string,
) => {
  let newResponses = updateFrameSubJobInFrameResponse(
    currentFrame,
    jobName,
    frameResponses,
    subJob,
    parentMid,
  );

  newResponses = updateSubJobsInFrameResponse(
    { updatedFrame: currentFrame },
    [jobName],
    newResponses,
    endFrame,
    flatTree,
    classificationChildrenJobsToClean,
    parentMid,
  );
  return newResponses;
};
