import { useQuery, type WatchQueryFetchPolicy } from '@apollo/client';
import { useSelector } from 'react-redux';

import { type ApiKey, OrganizationRole, type Right } from '../__generated__/globalTypes';
import {
  type GetUserRightsQuery,
  type GetUserRightsQueryVariables,
} from '../graphql/user/__generated__/queries.graphql';
import { GQL_USER_RIGHTS } from '../graphql/user/queries';
import { user as userSelector } from '../redux/selectors';
import { userName as userNameSelector } from '../redux/user/selectors';

type User = {
  apiKeys: ApiKey[];
  email: string;
  id: string;
  name: string;
  organizationId: string;
  organizationRole: string;
  rights: Right[];
};
export default function useUser(): User {
  const user = useSelector(userSelector);
  const userName = useSelector(userNameSelector);

  return {
    apiKeys: user?.apiKeys || ([] as ApiKey[]),
    email: user?.email ?? '',
    id: user?.id ?? '',
    name: userName,
    organizationId: user?.organizationId ?? '',
    organizationRole: user?.organizationRole || OrganizationRole.USER,
    rights: user?.rights || ([] as Right[]),
  };
}

export function useGlobalRights(
  userEmail: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
): Right[] {
  const { data } = useQuery<GetUserRightsQuery, GetUserRightsQueryVariables>(GQL_USER_RIGHTS, {
    context: {
      clientName: 'V2',
      headers: {
        actionId: 'getUser',
      },
    },
    fetchPolicy,
    variables: { first: 1, skip: 0, where: { email: userEmail } },
  });
  return data?.users?.[0]?.rights ?? [];
}
