import { detect } from 'detect-browser';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { addNotification } from '../redux/application/actions';

const getBrowserNameAndVersion = () => {
  const browser = detect();

  if (browser && browser.type === 'browser') {
    return {
      name: browser.name,
      version: browser.version,
    };
  }
  return {
    name: 'UnknownBrowser',
    version: '0.0.0',
  };
};

export function useVerifyBrowserVersion() {
  const dispatch = useDispatch();
  const { name, version } = getBrowserNameAndVersion();

  useEffect(() => {
    if (
      !(name === 'opera' && parseInt(version, 10) > 75) &&
      !(name === 'chrome' && parseInt(version, 10) > 66)
    ) {
      dispatch(
        addNotification({
          message: `We cannot guarantee that Kili works fine with your browser. We recommend using Chrome.`,
          variant: 'warning',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
