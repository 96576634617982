import { DetectionTasks, type JsonResponse } from '@kili-technology/types';
import _get from 'lodash/get';
import { batch } from 'react-redux';

import { InputType, type Vertice } from '@/__generated__/globalTypes';
import { ANNOTATIONS } from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { PDF_VIEWER_CONTAINER_ID } from '@/components/asset-ui/Pdf/PdfViewer/react-pdf-highlighter/components/PdfHighlighter/constants';
import { type KiliAnnotation } from '@/services/jobs/setResponse';
import { type AppThunk, store } from '@/store';
import {
  generateNewCreatingOrEditingObjectId,
  labelInterfaceUpdateField,
  removeAllSelectedObjectIds,
  replaceAllWithSelectedObjectId,
  useStore,
} from '@/zustand';
import {
  type LabelInterfaceSettingName,
  type LabelInterfaceSettings,
} from '@/zustand/label-interface';

import { toggleAccordionForEntity } from './actionsAccordion';
import {
  type LabelInterfaceHandleEscapeKeyPayload,
  type LabelInterfaceSelectChunkPayload,
  PDF_CONTAINER_ID,
  RICH_TEXT_CONTAINER_ID,
} from './types';

import { AUTOSAVE_UPDATE_LAST_SAVED_JSON } from '../autosave/slice';
import { initializeState as jobInitializeState, setSelectedCategory } from '../job/actions';
import { deleteObject } from '../jobs/actions/deleteObject';
import { getAnnotationFromMid } from '../jobs/helpers';
import { jobsSortedAnnotations, jobsResponses } from '../jobs/selectors';
import { projectInputType } from '../selectors';

export const selectChunk = (payload: LabelInterfaceSelectChunkPayload): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const responses = jobsResponses(state);
    const { mid, category } = payload;
    const annotationMid = getAnnotationFromMid(mid, responses);
    if (mid) {
      dispatch(toggleAccordionForEntity({ annotationMid: mid, categoryCode: category }));
      batch(() => {
        replaceAllWithSelectedObjectId({ mid });
        labelInterfaceUpdateField({ path: 'creatingOrEditingObjectId', value: null });
        dispatch(setSelectedCategory(annotationMid?.jobName ?? '', category));
      });
      return;
    }
    generateNewCreatingOrEditingObjectId();
  };
};

export const updateLastSavedJson = (
  payload: JsonResponse | Record<string, JsonResponse>,
): { payload: JsonResponse | Record<string, JsonResponse>; type: string } => {
  return AUTOSAVE_UPDATE_LAST_SAVED_JSON(payload);
};

export const handleEscapeKey = ({
  currentMlTask,
  wasDrawingNewPose,
  poseEstimationObjectId,
}: LabelInterfaceHandleEscapeKeyPayload): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { labelInterface, labelRichText } = useStore.getState();
    const { creatingOrEditingObjectId } = labelInterface;
    const { increaseIncrementRenderAll } = labelRichText;
    const { semanticMode } = useStore.getState().labelImageSemantic;
    const inputType = projectInputType(state);
    const isInputTypeFrameOrImage = [InputType.IMAGE, InputType.VIDEO].includes(inputType);
    batch(() => {
      if (wasDrawingNewPose && poseEstimationObjectId) {
        dispatch(deleteObject({ mid: poseEstimationObjectId }));
      }
      if (DetectionTasks.includes(currentMlTask)) {
        dispatch(jobInitializeState());
      }
      if (semanticMode !== 'view') {
        useStore.getState().labelImageSemantic.setSemanticMode('view');
      }
      if (!isInputTypeFrameOrImage && !creatingOrEditingObjectId) {
        removeAllSelectedObjectIds();
      }
      if (inputType === InputType.TEXT) {
        increaseIncrementRenderAll();
      }
    });
  };
};

const computeScrollTop = (pageElement: HTMLElement, annotationToScrollTo: KiliAnnotation) => {
  const { offsetTop, offsetHeight } = pageElement;

  const normalizedBoundingRect = _get(
    annotationToScrollTo,
    [ANNOTATIONS, 0, 'boundingPoly', 0, 'normalizedVertices', 0],
    [],
  );
  const normalizedHeight = normalizedBoundingRect.reduce((prev: Vertice, curr: Vertice) => {
    return prev.y < curr.y ? prev : curr;
  }, 0).y;

  const annotationTopOffset = normalizedHeight * offsetHeight;
  return offsetTop + annotationTopOffset - window.innerHeight / 2;
};

/**
 * Some assets are lazy-rendered, so the element we want to scroll to may not be present in the DOM yet.
 * In this case, we have to compute the position and scroll manually.
 */
const scrollToElementKnownPosition = (mid: string) => {
  const pdfContainer = document.getElementById(PDF_CONTAINER_ID);
  if (pdfContainer) {
    const sortedAnnotations = jobsSortedAnnotations(store.getState());
    const annotationToScrollTo = sortedAnnotations.find(annotation => annotation.mid === mid);

    if (annotationToScrollTo) {
      const firstPageNumber = _get(
        annotationToScrollTo,
        [ANNOTATIONS, 0, 'pageNumberArray', 0],
        '1',
      );
      const pageElement = document.querySelector<HTMLElement>(
        `[data-page-number="${firstPageNumber}"]`,
      );

      if (pageElement) pdfContainer.scrollTop = computeScrollTop(pageElement, annotationToScrollTo);
    }
  }
};

const scrollToElement = (mid: string, element: Element) => {
  if (document.getElementById(PDF_VIEWER_CONTAINER_ID)) {
    document.location.hash = `#highlight-${mid}`;
  } else if (document.getElementById(RICH_TEXT_CONTAINER_ID)) {
    const richTextContainer = document.getElementById(RICH_TEXT_CONTAINER_ID);
    const { y: annotationY } = element.getBoundingClientRect();
    if (richTextContainer) richTextContainer.scrollTop += annotationY - window.innerHeight / 2;
  }
};

export const scrollToElementInAssetRenderer = (mid: string | undefined): void => {
  if (!mid) return;

  const element = document.querySelector(`[data-mid*="${mid}"]`);

  if (!element) scrollToElementKnownPosition(mid);
  else scrollToElement(mid, element);
};

export const labelInterfaceUpdateSettings =
  <T extends LabelInterfaceSettingName>(
    settingName: T,
    settings: Partial<LabelInterfaceSettings[T]>,
  ): AppThunk =>
  (dispatch, getState) => {
    const projectId = getState().project.id;
    const userId = getState().user.id;
    if (projectId && userId) {
      useStore
        .getState()
        .labelInterface.updateSettings({ projectId, settingName, settings, userId });
    }
  };
