import { createSelector } from 'reselect';

import { type FrameResponses, type LabelFramesState } from './types';

import { type State } from '../types';

export const labelFramesState = (state: State): LabelFramesState => state.labelFrames;

export const labelFramesFrameResponses = createSelector([labelFramesState], labelFrames => {
  return (labelFrames?.frameResponses ?? {}) as FrameResponses;
});
