import { type ApplicationState } from './types';

export const initialState: ApplicationState = {
  accordion: {},
  authenticationToken: null,
  canUndo: true,
  categoriesFilter: {},
  forceTableUpdate: false,
  inExplore: false,
  inReview: false,
  isGraphqlClientInitialized: false,
  isLabelingOneAsset: false,
  isLoading: false,
  isUserIdentified: false,
  newPassword1: '',
  newPassword2: '',
  notification: {},
  oldPassword: '',
  openModalLabelingFinished: null,
  originalUrl: null,
  passwordChangeMessage: '',
  reviewForceTableUpdate: false,
  shortcuts: {},
  showMaximumAssetsError: false,
  spinner: false,
  temporaryJsonInterface: undefined,
  temporaryResponses: [],
  unexpectedError: false,
  uploaded: false,
};
