import {
  type VideoClassificationKeyAnnotation,
  type VideoTranscriptionKeyAnnotation,
} from '@/__generated__/globalTypes';
import { type VideoKeyAnnotation } from '@/graphql/annotations/types';

import { createVideoClassificationKeyAnnotation } from './createVideoClassificationKeyAnnotation';
import {
  createVideoObjectDetectionKeyAnnotation,
  type VideoObjectDetectionKeyAnnotationFactoryProperties,
} from './createVideoObjectDetectionKeyAnnotation';
import { createVideoTranscriptionKeyAnnotation } from './createVideoTranscriptionKeyAnnotation';
import { type AnnotationFactoryProperties } from './types';

import { getAnnotationIdFromVideoKeyAnnotationId } from '../helpers/getAnnotationIdFromVideoKeyAnnotationId';

type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;

export type VideoKeyAnnotationOverrideProperties = DeepPartial<
  | AnnotationFactoryProperties<VideoClassificationKeyAnnotation>
  | VideoObjectDetectionKeyAnnotationFactoryProperties
  | AnnotationFactoryProperties<VideoTranscriptionKeyAnnotation>
>;

export function duplicateVideoKeyAnnotation(
  baseKeyAnnotation: VideoKeyAnnotation,
  overrideProperties: VideoKeyAnnotationOverrideProperties,
): VideoKeyAnnotation {
  const annotationId = getAnnotationIdFromVideoKeyAnnotationId(baseKeyAnnotation.id);
  const newKeyAnnotationProperties = {
    ...baseKeyAnnotation,
    ...overrideProperties,
    annotationValue: {
      ...baseKeyAnnotation.annotationValue,
      ...overrideProperties.annotationValue,
    },
  } as VideoKeyAnnotationOverrideProperties;

  switch (baseKeyAnnotation.__typename) {
    case 'VideoClassificationKeyAnnotation':
      return createVideoClassificationKeyAnnotation(
        newKeyAnnotationProperties as AnnotationFactoryProperties<VideoClassificationKeyAnnotation>,
        annotationId,
      );

    case 'VideoObjectDetectionKeyAnnotation':
      return createVideoObjectDetectionKeyAnnotation(
        newKeyAnnotationProperties as VideoObjectDetectionKeyAnnotationFactoryProperties,
        annotationId,
      );

    case 'VideoTranscriptionKeyAnnotation':
      return createVideoTranscriptionKeyAnnotation(
        newKeyAnnotationProperties as AnnotationFactoryProperties<VideoTranscriptionKeyAnnotation>,
        annotationId,
      );

    default:
      throw new Error(
        `Unknown key annotation type: ${(baseKeyAnnotation as VideoKeyAnnotation).__typename}`,
      );
  }
}
