import _get from 'lodash/get';
import { batch } from 'react-redux';

import {
  setOrganization,
  setOrganizationSuccess,
  setOrganizationFail,
  setOrganizationLoading,
} from './slice';
import {
  type GetOrganizationPayload,
  type OrganizationState,
  type UpdatePropertiesInOrganizationPayload,
} from './types';

import { clientQuery } from '../../apollo';
import { GQL_UPDATE_PROPERTIES_IN_ORGANIZATION } from '../../graphql/organization/mutations';
import { GQL_ORGANIZATION_GET } from '../../graphql/organization/queries';
import { addNotification } from '../application/actions';
import { type AppThunk } from '../types';

export const updatePropertiesInOrganization = (
  payload: UpdatePropertiesInOrganizationPayload,
): AppThunk => {
  const actionId = `updatePropertiesInOrganization`;
  return async dispatch => {
    dispatch(setOrganizationLoading());
    const { client, data, where } = payload;

    try {
      const result = await client.mutate({
        context: {
          clientName: 'V2',
          headers: {
            actionId,
          },
        },
        mutation: GQL_UPDATE_PROPERTIES_IN_ORGANIZATION,
        variables: { data, where },
      });

      const newOrganizationState = _get(result, 'data.updatePropertiesInOrganization');
      batch(() => {
        dispatch(setOrganization(newOrganizationState));
        dispatch(setOrganizationSuccess());
      });
    } catch (error) {
      batch(() => {
        dispatch(setOrganizationFail());
        if (error instanceof Error) {
          dispatch(
            addNotification({
              message: error.message.replace('GraphQL error: ', ''),
              variant: 'info',
            }),
          );
        }
      });
    }
  };
};

export const getOrganization = (payload: GetOrganizationPayload): AppThunk => {
  const actionId = `getOrganization`;
  return async dispatch => {
    dispatch(setOrganizationLoading());
    const { client, email } = payload;

    const response = await clientQuery({
      actionId,
      client,
      clientName: 'V2',
      dispatch,
      query: GQL_ORGANIZATION_GET,
      variables: { first: 1, skip: 0, where: { user: { email } } },
      withLoader: false,
    });

    const data = _get(response, 'data');
    const newOrganization: OrganizationState = _get(data, 'data[0]');
    if (!data || !newOrganization) {
      dispatch(setOrganizationFail());
      return;
    }

    newOrganization.emails = data?.emails || [];

    batch(() => {
      dispatch(setOrganization(newOrganization));
      dispatch(setOrganizationSuccess());
    });
  };
};
