import Button from '@kili-technology/button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { type WithStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import { type RouteComponentProps } from 'react-router-dom';

import { type styles } from './PageNotFound.style';

import TopBar from '../../containers/TopBar';
import appHistory from '../../pages/RootModule/history';

export type PageNotFoundPropsType = Record<string, string | undefined>;

class PageNotFound extends PureComponent<
  RouteComponentProps<PageNotFoundPropsType> & WithStyles<typeof styles>
> {
  goBack = (): void => {
    appHistory.goBack();
  };

  render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <TopBar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Card className={classes.card}>
            <CardContent>
              <Typography component="h2" variant="h5">
                Page not found!
              </Typography>
              <Typography component="p">
                Maybe the page you are looking for has been removed, or you typed in the wrong URL.
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={this.goBack} variant="primary">
                Go back
              </Button>
            </CardActions>
          </Card>
        </main>
      </div>
    );
  }
}

export default PageNotFound;
