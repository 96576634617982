import { saveAs } from 'file-saver';

import { type Maybe } from '../../__generated__/globalTypes';
import { getProxyEndpoint, getBackendUrl } from '../../config';
import { sendToDatadog } from '../../datadog';
import { isUrl } from '../../pages/projects/view/settings/Instructions/helpers';

export type ImageCallBackFunction = (base64Image: string) => null | string | void;
type TextCallBackFunction = (text: string) => null | string | void;

export type ThumbnailContent = {
  id: string;
  thumbnail: string;
};

const NUMBER_OF_RETRY = 10;
const DELAY_BETWEEN_RETRY = 1000;

export const getProxifiedUrl = (assetContent: string): string => {
  const proxyEndpoint = getProxyEndpoint();
  return `${proxyEndpoint}?url=${encodeURIComponent(assetContent)}`;
};

const urlMatchesFromMultiFrontend = (url: string): boolean => {
  const multiFrontendRegexPattern =
    /^https:\/\/multifrontend-[\w-]+\.staging\.cloud\.kili-technology\.com/;

  if (multiFrontendRegexPattern.test(window.location.origin)) {
    const stagingFrontendRegex = /^https:\/\/staging\.cloud\.kili-technology\.com/;
    return stagingFrontendRegex.test(url);
  }
  return false;
};

export const isAssetServedByKili = (url: string): boolean =>
  !!url &&
  (url.startsWith(getBackendUrl()) || urlMatchesFromMultiFrontend(url)) &&
  !url.includes(getProxyEndpoint());

const getResponseByFetchingReadSignedUrl = async (
  url: string,
  headers: Headers,
  signal?: AbortSignal,
): Promise<Response> => {
  const assetUrl = new URL(url);
  assetUrl.searchParams.set('bucketsigned', 'true');
  assetUrl.searchParams.set('token', 'v2');
  const dataWithReadUrlResponse = await fetch(assetUrl.href, {
    headers,
    method: 'GET',
  });
  const readUrl = await dataWithReadUrlResponse.json();
  return fetch(readUrl, {
    method: 'GET',
    signal,
  });
};

export const kiliFetch = async (
  url: string,
  authorization: string | null,
  signal?: AbortSignal,
  cacheKey = '',
  cacheName = 'assets',
): Promise<Response> => {
  const headers = new Headers({
    Accept: 'application/json',
  });
  const assetIsServedByKili = isAssetServedByKili(url);

  if (assetIsServedByKili && authorization) {
    headers.set('Authorization', authorization);
  }

  let cachedResponse = await window.caches?.match?.(url, { cacheName })?.catch(() => null);
  if (!cachedResponse) {
    cachedResponse = await window.caches?.match?.(cacheKey, { cacheName })?.catch(() => null);
  }

  if (cachedResponse) {
    // This handles old cache that could be set to proxified url
    if (cacheKey && window.caches && url !== cacheKey) {
      const cachedClone = cachedResponse.clone();
      window.caches
        .open(cacheName)
        .then(cacheEl => {
          return cacheEl
            .put(cacheKey, cachedClone)
            .then(() => cacheEl.delete(url))
            .catch(() => null);
        })
        .catch(() => null);
    }
    return cachedResponse;
  }

  let response: Response;
  if (assetIsServedByKili) {
    response = await getResponseByFetchingReadSignedUrl(url, headers, signal);
  } else {
    response = await fetch(url, {
      headers,
      method: 'GET',
      signal,
    });
  }

  if (cacheKey && window.caches && response.ok) {
    const responseClone = response.clone();
    window.caches
      .open(cacheName)
      .then(assets => assets.put(cacheKey, responseClone))
      .catch(() => null);
  }

  return response;
};

const blackListedParameters = [
  'token',
  'X-Goog-Algorithm',
  'X-Goog-Credential',
  'X-Goog-Date',
  'X-Goog-Expires',
  'X-Goog-SignedHeaders',
  'X-Goog-Signature',
];

const urlToCacheKey = (stringedUrl: string) => {
  const url = new URL(stringedUrl);
  blackListedParameters.forEach(key => url.searchParams.delete(key));
  const cacheKey = url.href;
  return cacheKey;
};

export const downloadAssetContent = async (
  assetContent: string | Maybe<string> | undefined,
  authenticationToken: string | null,
  signal?: AbortSignal,
  shouldAppendToCache = true,
  cacheName = 'assets',
): Promise<Response | null> => {
  if (!assetContent || !isUrl(assetContent)) {
    return null;
  }
  return kiliFetch(
    assetContent,
    authenticationToken,
    signal,
    shouldAppendToCache ? urlToCacheKey(assetContent) : '',
    cacheName,
  )
    .catch(() =>
      kiliFetch(
        getProxifiedUrl(assetContent),
        '',
        signal,
        shouldAppendToCache ? urlToCacheKey(assetContent) : '',
        cacheName,
      ),
    )
    .then(async response => {
      if (response && response.status >= 400) {
        const content = await response.text();
        throw new Error(
          `Failed to retrieve asset, status code : ${response.status}, content: ${content}`,
        );
      }
      return response;
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        console.warn('The user aborted a request');
        return null;
      }
      sendToDatadog(error, null, 'asset');
      return null;
    });
};

export const downloadPdfAsset = async (
  assetContent: string,
  authenticationToken: string | null,
  callback: TextCallBackFunction = text => text,
  shouldAppendToCache = true,
): Promise<ReturnType<typeof callback>> =>
  downloadAssetContent(assetContent, authenticationToken, undefined, shouldAppendToCache).then(
    response => response?.text(),
  );

export const downloadTextAsset = async (
  assetContent: string,
  authenticationToken: string | null,
  shouldAppendToCache = true,
): Promise<string | null> =>
  downloadAssetContent(assetContent, authenticationToken, undefined, shouldAppendToCache).then(
    response => response && response.text(),
  );

export const downloadImageAsset = async (
  assetContent: string,
  authenticationToken: string | null,
  signal?: AbortSignal,
  callback: ImageCallBackFunction = base64Image => base64Image,
  shouldAppendToCache = true,
  cacheName = 'assets',
): Promise<ReturnType<typeof callback>> =>
  downloadAssetContent(assetContent, authenticationToken, signal, shouldAppendToCache, cacheName)
    .then(response => response && response.blob())
    .then(blob => blob && callback(URL.createObjectURL(blob)));

export const downloadVideoAsset = async (
  assetContent: string,
  authenticationToken: string | null,
  callback: (url: string | undefined) => void,
  signal?: AbortSignal,
  shouldAppendToCache = true,
): Promise<void> =>
  downloadAssetContent(assetContent, authenticationToken, signal, shouldAppendToCache)
    .then(response => response?.blob())
    .then(blob => {
      const urlObject = blob && URL.createObjectURL(blob);
      callback(urlObject);
    });

export const downloadRetry = async (
  url: string,
  authenticationToken: string | null,
  signal: AbortSignal,
  numberOfRetry: number,
  delay: number,
): Promise<Blob | undefined> => {
  try {
    const asset = await downloadAssetContent(url, authenticationToken, signal).then(response =>
      response?.blob(),
    );
    return asset;
  } catch (err) {
    if (numberOfRetry === 1) throw err;
    await setTimeout(() => undefined, delay);
    return downloadRetry(url, authenticationToken, signal, numberOfRetry - 1, delay);
  }
};

export const addUrlToCache =
  (imageCache: Cache, authenticationToken: string | null, signal: AbortSignal) =>
  async (url: string): Promise<void> => {
    const request = new Request(url, {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(authenticationToken ? { authorization: authenticationToken } : {}),
      }),
    });
    try {
      await downloadRetry(
        url,
        authenticationToken,
        signal,
        NUMBER_OF_RETRY,
        DELAY_BETWEEN_RETRY,
      ).then(blob => {
        const imageResponse = new Response(blob);
        if (imageCache.put) imageCache.put(request, imageResponse).catch(() => null);
      });
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') throw err;
    }
  };

export const downloadBucketSignedExportUrl = (url: string): void => {
  const contentType = 'application/json;charset=utf-8;';
  const urlObject = new URL(url);
  const splittedPathname = urlObject.pathname.split('/');
  const filename = decodeURIComponent(splittedPathname[splittedPathname.length - 1]);
  fetch(url)
    .then(response => response.blob())
    .then(blob => blob && saveAs(new Blob([blob], { type: contentType }), filename));
};
