import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { type ProjectsState } from './types';

const sliceProjects = createSlice({
  initialState,
  name: 'projects',
  reducers: {
    PROJECTS_INITIALIZE() {
      return initialState;
    },
    PROJECTS_UPDATE(state, action: PayloadAction<ProjectsState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { PROJECTS_INITIALIZE, PROJECTS_UPDATE } = sliceProjects.actions;

export default sliceProjects.reducer;
