import { type ProjectQueueState } from './types';

export const initialState: ProjectQueueState = {
  areAllAssetsSelected: false,
  counterHoneypot: 0,
  counterLabeled: 0,
  counterOngoing: 0,
  counterReviewed: 0,
  counterToReview: 0,
  counterTodo: 0,
  selectedAssetIds: [],
  unselectedAssetIds: [],
};
