import {
  type ClassificationAnnotation,
  type JobAnnotation,
  type JobResponse,
  type Jobs,
  type JsonResponse,
  LabelVersion,
  MachineLearningTask,
  type ObjectAnnotation2D,
  type TranscriptionAnnotation,
  Input,
} from '@kili-technology/types';
import _set from 'lodash/set';
import sortBy from 'lodash/sortBy';

import { memoizedGetColorForCategories } from '../../components/helpers';
import { generateDefaultJobInstruction } from '../../pages/projects/label/LabelDialog/LabelInterface/JobsColumn/components/JobRenderer/helpers';
import { ANNOTATION_ORDER } from '../../redux/jobs/selectors';
import { getJobNamesWithoutInstructionGroupedByMlTask } from '../../redux/project/selectors';
import { useStore } from '../../zustand';

export type ObjectPositionInfo = {
  categoryCodes: string[];
  children: Record<string, ObjectPositionInfo>;
  frames: number[];
  jobName: string;
  keyFrames: number[];
  labelVersion: LabelVersion;
  mlTask:
    | MachineLearningTask.OBJECT_DETECTION
    | MachineLearningTask.TRANSCRIPTION
    | MachineLearningTask.CLASSIFICATION;
};

export type ConnectedObject = {
  categoryName: string;
  firstFrame: number;
  keyFrames: number[];
  lastFrame: number;
};

export type VideoObject = {
  cacheId?: string;
  children: VideoObject[];
  color?: string;
  components: ConnectedObject[];
  depth: number;
  jobName: string;
  labelVersion: LabelVersion;
  mid: string;
  mlTask: MachineLearningTask;
  name: string;
  order?: number;
  rootMid?: string;
};

export type ConnectedObjectWithoutKeyFrames = Omit<ConnectedObject, 'keyFrames'>;

export const addKeyFramesToComponents = (
  componentsWithoutKeyFrames: ConnectedObjectWithoutKeyFrames[],
  keyFrames: number[],
): ConnectedObject[] => {
  if (componentsWithoutKeyFrames.length === 0) {
    return [];
  }
  const components: ConnectedObject[] = [];
  componentsWithoutKeyFrames.forEach(component => {
    const { firstFrame, lastFrame } = component;
    components.push({
      ...component,
      keyFrames: keyFrames.filter(keyFrame => keyFrame >= firstFrame && keyFrame <= lastFrame),
    });
  });
  return components;
};

export const getComponentsWithoutKeyFrames = (
  frames: number[],
  categoryName: string,
): ConnectedObjectWithoutKeyFrames[] => {
  if (frames.length === 0) {
    return [];
  }
  const components: ConnectedObjectWithoutKeyFrames[] = [];
  let firstFrame = frames[0];
  let previousFrame = frames[0];
  frames.forEach((frame, index) => {
    if (index === 0) return;
    if (frame > previousFrame + 1) {
      components.push({
        categoryName,
        firstFrame,
        lastFrame: previousFrame,
      });
      firstFrame = frame;
    }
    previousFrame = frame;
  });
  components.push({
    categoryName,
    firstFrame,
    lastFrame: previousFrame,
  });
  return components;
};

type AnnotationType = ObjectAnnotation2D | ClassificationAnnotation | TranscriptionAnnotation;

const updateObjectPositionForObjectDetection = (
  annotation: ObjectAnnotation2D,
  frameInt: number,
  jobName: string,
  midToInfo: Record<string, ObjectPositionInfo>,
  jobs: Jobs,
) => {
  const objectIdentifier = annotation.mid;
  const { isKeyFrame } = annotation;

  const currentInfo =
    midToInfo[objectIdentifier] ||
    ({
      categoryCodes: [],
      children: {},
      frames: [],
      jobName,
      keyFrames: [],
      labelVersion: annotation.labelVersion || LabelVersion.DEFAULT,
      mlTask: MachineLearningTask.OBJECT_DETECTION,
    } as ObjectPositionInfo);

  currentInfo.frames.push(frameInt);
  currentInfo.categoryCodes = [
    ...new Set(
      currentInfo.categoryCodes.concat(annotation.categories.map(category => category.name)),
    ),
  ];
  if (isKeyFrame) {
    currentInfo.keyFrames.push(frameInt);
  }

  _set(midToInfo, objectIdentifier, currentInfo);

  if (annotation.children)
    Object.entries(annotation.children).forEach(([subJobName, subJobResponse]) => {
      processJobResponse(subJobResponse, frameInt, subJobName, midToInfo, jobs, objectIdentifier);
    });
};

const updateObjectPositionForClassification = (
  annotation: ClassificationAnnotation,
  frameInt: number,
  jobName: string,
  midToInfo: Record<string, ObjectPositionInfo>,
  jobs: Jobs,
  parentIdentifier?: string,
) => {
  if ((annotation.categories || []).length === 0) return;

  const objectIdentifier = `${jobName}-${annotation.categories
    .map(category => category.name)
    .join('-')}`;
  const { isKeyFrame } = annotation;

  const currentInfo =
    midToInfo[objectIdentifier] ||
    ({
      categoryCodes: [],
      children: {},
      frames: [],
      jobName,
      keyFrames: [],
      labelVersion: LabelVersion.DEFAULT,
      mlTask: MachineLearningTask.CLASSIFICATION,
    } as ObjectPositionInfo);

  currentInfo.frames.push(frameInt);
  currentInfo.categoryCodes = [
    ...new Set(
      currentInfo.categoryCodes.concat(annotation.categories.map(category => category.name)),
    ),
  ];
  if (isKeyFrame) {
    currentInfo.keyFrames.push(frameInt);
  }

  if (parentIdentifier) {
    const sameClassificationAnnotation = midToInfo[parentIdentifier].children[objectIdentifier];
    if (sameClassificationAnnotation) {
      sameClassificationAnnotation.frames.push(frameInt);
      if (isKeyFrame) sameClassificationAnnotation.keyFrames.push(frameInt);
    } else _set(midToInfo, `${parentIdentifier}.children.${objectIdentifier}`, currentInfo);
  } else _set(midToInfo, objectIdentifier, currentInfo);

  annotation.categories.forEach(category => {
    if (category.children)
      Object.entries(category.children).forEach(([subJobName, subJobResponse]) => {
        processJobResponse(subJobResponse, frameInt, subJobName, midToInfo, jobs, objectIdentifier);
      });
  });
};

const updateObjectPositionForTranscription = (
  annotation: TranscriptionAnnotation,
  frameInt: number,
  jobName: string,
  midToInfo: Record<string, ObjectPositionInfo>,
  parentIdentifier?: string,
) => {
  if (!annotation.text) return;

  const objectIdentifier = `${jobName}-${annotation.text.replaceAll('.', '')}`;
  const { isKeyFrame } = annotation;

  const currentInfo =
    midToInfo[objectIdentifier] ||
    ({
      categoryCodes: [],
      children: {},
      frames: [],
      jobName,
      keyFrames: [],
      labelVersion: LabelVersion.DEFAULT,
      mlTask: MachineLearningTask.TRANSCRIPTION,
    } as ObjectPositionInfo);

  currentInfo.frames.push(frameInt);
  currentInfo.categoryCodes = [annotation.text];
  if (isKeyFrame) {
    currentInfo.keyFrames.push(frameInt);
  }

  if (parentIdentifier) {
    const sameTranscriptionAnnotation = midToInfo[parentIdentifier].children[objectIdentifier];
    if (sameTranscriptionAnnotation) {
      sameTranscriptionAnnotation.frames.push(frameInt);
      if (isKeyFrame) sameTranscriptionAnnotation.keyFrames.push(frameInt);
    } else _set(midToInfo, `${parentIdentifier}.children.${objectIdentifier}`, currentInfo);
  } else _set(midToInfo, objectIdentifier, currentInfo);
};

const processJobResponse = (
  jobResponse: JobResponse,
  frameInt: number,
  jobName: string,
  midToInfo: Record<string, ObjectPositionInfo>,
  jobs: Jobs,
  parentIdentifier?: string,
) => {
  const jobInterfaces = jobs?.[jobName];
  const mlTask = jobInterfaces?.mlTask;

  const threeLevelOrMoreNestedJobNames = Object.values(jobs)
    .filter(job => job.isChild)
    .map(job => job.content.categories)
    .filter(categories => categories)
    .flatMap(categories => Object.values(categories || {}))
    .flatMap(el => el.children);

  if (threeLevelOrMoreNestedJobNames.includes(jobName)) return;

  const annotations: AnnotationType[] =
    mlTask === MachineLearningTask.OBJECT_DETECTION
      ? (((jobResponse as JobAnnotation)?.annotations || []) as ObjectAnnotation2D[])
      : ([jobResponse] as (ClassificationAnnotation | TranscriptionAnnotation)[]);

  annotations.forEach(annotation => {
    switch (mlTask) {
      case MachineLearningTask.OBJECT_DETECTION:
        updateObjectPositionForObjectDetection(
          annotation as ObjectAnnotation2D,
          frameInt,
          jobName,
          midToInfo,
          jobs,
        );
        break;
      case MachineLearningTask.CLASSIFICATION:
        updateObjectPositionForClassification(
          annotation as ClassificationAnnotation,
          frameInt,
          jobName,
          midToInfo,
          jobs,
          parentIdentifier,
        );
        break;
      case MachineLearningTask.TRANSCRIPTION:
        updateObjectPositionForTranscription(
          annotation as TranscriptionAnnotation,
          frameInt,
          jobName,
          midToInfo,
          parentIdentifier,
        );
        break;
      default:
        break;
    }
  });
};

export const getObjectInfoFromResponses = (
  jsonResponses: Record<string, JsonResponse>,
  jobs: Jobs,
): Record<string, ObjectPositionInfo> => {
  const midToInfo: Record<string, ObjectPositionInfo> = {};

  Object.entries(jsonResponses).forEach(([frame, jsonResponse]) => {
    const frameInt = parseInt(frame, 10);

    Object.entries(jsonResponse).forEach(([jobName, jobResponse]) => {
      processJobResponse(jobResponse, frameInt, jobName, midToInfo, jobs);
    });
  });

  return midToInfo;
};

const getComponents = (
  frames: number[],
  keyFrames: number[],
  categoryName: string,
): ConnectedObject[] => {
  const componentsWithoutKeyFrames = getComponentsWithoutKeyFrames(frames, categoryName);
  return addKeyFramesToComponents(componentsWithoutKeyFrames, keyFrames);
};

const categoriesToName = (
  jobName: string,
  jobs: Jobs,
  mlTask: MachineLearningTask,
  categoryCodes: string[],
) => {
  const jobCategories = jobs?.[jobName]?.content?.categories;
  return mlTask === MachineLearningTask.TRANSCRIPTION
    ? [{ name: categoryCodes?.[0] }]
    : categoryCodes.map(categoryCode => jobCategories?.[categoryCode]).filter(category => category);
};

const getVideoObjectsFromObjectInfo = (
  midToInfo: Record<string, ObjectPositionInfo>,
  jobs: Jobs,
): VideoObject[] => {
  const processObjectInfo = (
    objectPositionInfo: ObjectPositionInfo,
    mid: string,
    objectIndex: number,
    depth = 0,
    rootMid: string | undefined = undefined,
    parent: ObjectPositionInfo | undefined = undefined,
  ): VideoObject => {
    const { frames, categoryCodes, jobName, keyFrames, labelVersion, mlTask } = objectPositionInfo;
    const annotationCategories = categoriesToName(jobName, jobs, mlTask, categoryCodes);

    const color =
      mlTask === MachineLearningTask.OBJECT_DETECTION
        ? annotationCategories?.[0]?.color ||
          memoizedGetColorForCategories([{ name: categoryCodes[0] }], jobName)
        : undefined;
    const categoriesFormatted = annotationCategories
      .map(category => category?.name)
      .filter((name): name is string => name !== undefined)
      .sort((a, b) => a.localeCompare(b))
      .join(' & ');

    let name;
    if (
      mlTask === MachineLearningTask.CLASSIFICATION ||
      mlTask === MachineLearningTask.TRANSCRIPTION
    ) {
      const job = jobs[jobName];
      const jobNamesGroupedByMlTask = getJobNamesWithoutInstructionGroupedByMlTask(jobs);
      const parentJob =
        parent &&
        parent.mlTask === MachineLearningTask.CLASSIFICATION &&
        jobs[parent.jobName]?.content.input === Input.CHECKBOX
          ? categoriesToName(parent.jobName, jobs, parent.mlTask, parent.categoryCodes)
              .filter(c => {
                return jobs?.[parent?.jobName]?.content?.categories?.[
                  c?.name?.toUpperCase() ?? ''
                ]?.children?.includes(jobName);
              })
              .map(category => category?.name)
          : '';
      name = `${parentJob ? `${parentJob} - ` : ''}${
        job.instruction ||
        generateDefaultJobInstruction(jobName, mlTask, job.tools?.[0], jobNamesGroupedByMlTask)
      }`;
    } else {
      const { ANNOTATION_NAMES_JOB } = useStore.getState().labelFrame.jobCategoriesToMids;
      if (ANNOTATION_NAMES_JOB[mid]) {
        name = ANNOTATION_NAMES_JOB[mid];
      } else {
        name = `${categoriesFormatted} ${objectIndex}`;
      }
    }
    let components: ConnectedObject[] = getComponents(frames, keyFrames, categoriesFormatted);
    components = components.map(component => ({ ...component, categoryName: categoriesFormatted }));

    const videoObject: VideoObject = {
      children: [],
      color,
      components,
      depth,
      jobName,
      labelVersion,
      mid,
      mlTask,
      name,
      rootMid: rootMid || mid,
    };

    return {
      ...videoObject,
      children: Object.entries(objectPositionInfo.children).map(([objectMid, child], index) =>
        processObjectInfo(
          child,
          objectMid,
          index,
          depth + 1,
          rootMid || videoObject.mid,
          objectPositionInfo,
        ),
      ),
    };
  };

  const videoObjects = Object.entries(midToInfo).map(([mid, objectPositionInfo], index) =>
    processObjectInfo(objectPositionInfo, mid, index),
  ) as VideoObject[];

  return videoObjects;
};

export const recursiveSort = (videoObjects: VideoObject[]): VideoObject[] => {
  return sortBy(
    videoObjects.map(videoObject => ({
      ...videoObject,
      children: videoObject.children ? recursiveSort(videoObject.children) : [],
      order:
        ANNOTATION_ORDER[
          videoObject.mlTask as
            | MachineLearningTask.OBJECT_DETECTION
            | MachineLearningTask.CLASSIFICATION
            | MachineLearningTask.TRANSCRIPTION
        ],
    })),
    ['order', 'mid'],
  );
};

const mergeClassificationAndTranscriptionByDepthAndJobName = (
  videoObjects: VideoObject[],
): VideoObject[] => {
  const mergedByDepthAndJobName: Record<string, VideoObject> = {};
  const nonClassificationObjects: VideoObject[] = [];

  videoObjects.forEach(videoObject => {
    // eslint-disable-next-line no-param-reassign
    videoObject.children = mergeClassificationAndTranscriptionByDepthAndJobName(
      videoObject.children,
    );

    if (
      videoObject.mlTask === MachineLearningTask.CLASSIFICATION ||
      videoObject.mlTask === MachineLearningTask.TRANSCRIPTION
    ) {
      const key = `${videoObject.depth}-${videoObject.jobName}`;
      if (!mergedByDepthAndJobName[key]) {
        mergedByDepthAndJobName[key] = videoObject;
      } else {
        mergedByDepthAndJobName[key].components = mergedByDepthAndJobName[key].components.concat(
          videoObject.components,
        );
        mergedByDepthAndJobName[key].children = mergedByDepthAndJobName[key].children.concat(
          videoObject.children,
        );
      }
    } else {
      nonClassificationObjects.push(videoObject);
    }
  });

  const mergedClassificationObjects = Object.values(mergedByDepthAndJobName);

  return [...nonClassificationObjects, ...mergedClassificationObjects];
};

export const getVideoObjectsFromFramesResponses = (
  jsonResponses: Record<string, JsonResponse>,
  jobs: Jobs,
): VideoObject[] => {
  const midToObjectPositionInfo = getObjectInfoFromResponses(jsonResponses, jobs);

  const videoObjects = getVideoObjectsFromObjectInfo(midToObjectPositionInfo, jobs);
  const mergedVideoObjects = mergeClassificationAndTranscriptionByDepthAndJobName(videoObjects);

  return recursiveSort(mergedVideoObjects);
};
