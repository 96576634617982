import create from 'zustand';

interface State {
  currentPage: number;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  setCurrentPage: (nextPage: number) => void;
  setTotalNumberOfPage: (total: number) => void;
  total: number;
}

const usePagination = create<State>(set => ({
  currentPage: 1,
  goToNextPage: () =>
    set(state => {
      const lastPage = state.total;
      const nextPage = Math.min(state.currentPage + 1, lastPage);
      return {
        currentPage: nextPage,
      };
    }),
  goToPreviousPage: () =>
    set(state => {
      const previousPage = Math.max(state.currentPage - 1, 1);
      return {
        currentPage: previousPage,
      };
    }),
  setCurrentPage: (nextPage: number) =>
    set(state => {
      const lastPage = state.total;
      const correctNextPage = Math.max(Math.min(nextPage, lastPage), 1);
      return { currentPage: correctNextPage };
    }),
  setTotalNumberOfPage: (total: number) => set({ currentPage: 1, total }),
  total: 0,
}));

export default usePagination;
