import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { type ConnectedProps, useDispatch } from 'react-redux';
import { type RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import { type connector } from './IdleTimer.wrap';

import { isUsingAuth0, useAuth0 } from '../../auth0';
import { timeBeforeInactivity } from '../../config';
import { rootRoutes } from '../../pages/RootModule/RootPaths';
import { signOut } from '../../redux/authentication/actions';
import { initializeState as initializeUserState } from '../../redux/user/actions';

type WithReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<null>;
type LogOutFunction = () => void;
const ONE_MINUTE_TIMER = 60_000;

const ActionComponent = (
  { clearNotification }: WithReduxProps,
  logoutUser: LogOutFunction,
  timeout: NodeJS.Timeout,
) => (
  <>
    <Button
      onClick={() => {
        logoutUser();
      }}
    >
      Close Kili
    </Button>
    <Button
      onClick={() => {
        clearNotification();
        clearTimeout(timeout);
      }}
    >
      Keep using Kili
    </Button>
  </>
);

const IdleTimer: React.FC<WithReduxProps> = props => {
  const { addNotification, clearNotification } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout } = useAuth0();
  let timeout: NodeJS.Timeout;

  const logoutUser = () => {
    if (isUsingAuth0) {
      logout({ returnTo: `${window.location.origin}/label/` });
    } else {
      clearNotification();
      dispatch(signOut());
      dispatch(initializeUserState());
      history.push(rootRoutes.ROOT_SIGNIN_ROUTE.path);
    }
  };

  const handleOneMinuteBeforeLogOut = () => {
    addNotification({
      action: ActionComponent(props, logoutUser, timeout),
      autoHideDuration: ONE_MINUTE_TIMER,
      message:
        "Due to inactivity your session is about to expire in 1 minute. You'll be automatically logged out.",
      variant: 'error',
    });
    timeout = setTimeout(() => logoutUser(), ONE_MINUTE_TIMER);
  };

  useIdleTimer({
    crossTab: true,
    debounce: 500,
    onAction: () => clearTimeout(timeout),
    onActive: () => clearTimeout(timeout),
    onIdle: handleOneMinuteBeforeLogOut,
    timeout: timeBeforeInactivity - ONE_MINUTE_TIMER,
  });

  return null;
};

export default withRouter(IdleTimer);
