import {
  type JsonCategories as Categories,
  isDetectionTask,
  type Jobs,
  MachineLearningTask,
  type PdfAnnotation2D,
} from '@kili-technology/types';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import fromEntries from 'object.fromentries';

import { type KiliAnnotation, type KiliAnnotationProvider } from './setResponse';

import {
  CATEGORIES,
  CONTENT,
  MID,
} from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { type ResponsesTasks } from '../../redux/jobs/types';
import roundNumberToDecimalPoint from '../utils/math';

const ORDER_SORT_PDF = ['page', 'positionY', 'positionX'];

export const sortByOrderInRichText = (
  divAnnotations: Element[],
  sortedAnnotations: KiliAnnotation[],
) => {
  const orderedMids = [] as string[];

  divAnnotations.forEach(annotation => {
    const mids = annotation.getAttribute('data-mid');
    mids?.split('_').forEach(mid => {
      if (!orderedMids.includes(mid)) {
        orderedMids.push(mid);
      }
    });
  });
  sortedAnnotations.forEach(annotation => {
    if (annotation.mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION) {
      orderedMids.push(annotation.mid);
    }
  });
  return orderedMids;
};

const sortByJobAndMid = (responses: ResponsesTasks, jobs: Jobs): KiliAnnotation[] => {
  return Object.entries(jobs).reduce((annotations, [jobName, job]) => {
    const { mlTask } = job;
    if (!isDetectionTask(mlTask)) {
      return annotations;
    }
    const jobCategories = job?.[CONTENT]?.[CATEGORIES] || ({} as Categories);
    const jobAnnotations = (responses?.[mlTask]?.[jobName] as { annotations: KiliAnnotation[] })
      ?.annotations as KiliAnnotation[];
    if (_isEmpty(jobAnnotations)) {
      return annotations;
    }
    const categoryToIndex = fromEntries(
      Object.entries(jobCategories).map(([categoryCode], index) => {
        return [categoryCode, index];
      }),
    );
    const sortedJobAnnotations = _uniqBy(
      _sortBy(jobAnnotations, [
        (annotation: KiliAnnotation) => {
          const category = annotation?.categories?.[0]?.name;
          const categoryIndex = categoryToIndex[category];
          return categoryIndex;
        },
      ]),
      MID,
    );
    return annotations.concat(sortedJobAnnotations);
  }, [] as KiliAnnotation[]);
};

export const sortByOrderInPDF = (responses: ResponsesTasks, jobs: Jobs): KiliAnnotation[] => {
  const mergedMLJobAnnotations = Object.entries(jobs)
    .reduce((annotations, [jobName, job]) => {
      const { mlTask } = job;
      if (!isDetectionTask(mlTask)) {
        return annotations;
      }
      const jobAnnotations = responses?.[mlTask]?.[jobName]
        ?.annotations as KiliAnnotationProvider<PdfAnnotation2D>[];
      if (_isEmpty(jobAnnotations)) {
        return annotations;
      }

      return annotations.concat(jobAnnotations);
    }, [] as KiliAnnotation[])
    .map(annotation => {
      const subAnnotations = 'annotations' in annotation ? annotation?.annotations : [];
      const position = (subAnnotations[0]?.polys[0]?.normalizedVertices[0] ?? [])[0];
      let positionX = (position ?? {}).x || 0;
      let positionY = (position ?? {}).y || 0;
      positionX = roundNumberToDecimalPoint(positionX, 5);
      positionY = roundNumberToDecimalPoint(positionY, 5);
      const page = (subAnnotations[0]?.pageNumberArray ?? [])[0];
      return { ...annotation, page, positionX, positionY };
    });

  const sortedJobAnnotations = _sortBy(mergedMLJobAnnotations, ORDER_SORT_PDF);

  const sortedJobAnnotationsCleaned = _uniqBy(
    sortedJobAnnotations.map(annotation => _omit(annotation, ORDER_SORT_PDF)),
    MID,
  ) as KiliAnnotation[];

  return sortedJobAnnotationsCleaned;
};

export default sortByJobAndMid;
