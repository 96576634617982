import Button from '@kili-technology/button';
import { AddMember } from '@kili-technology/icons';
import Badge from '@material-ui/core/Badge';

import { classes } from './PendingRequestIcon.style';
import { usePendingRequestIcon } from './usePendingRequestIcon';

type PropsType = {
  isBadgeInvisible?: boolean;
};

const PendingRequestIcon = ({ isBadgeInvisible = false }: PropsType) => {
  const { showButton, handleIconClick, handleIconKeyDown, numberOfPendingRequests } =
    usePendingRequestIcon();
  if (!showButton) return null;

  return (
    <Button
      className={classes.container}
      data-cy="pending-request-icon"
      isSquare
      onClick={handleIconClick}
      onKeyDown={handleIconKeyDown}
      variant="secondary-ghost"
    >
      <Badge
        badgeContent={numberOfPendingRequests}
        className={classes.badge}
        invisible={isBadgeInvisible}
        max={9}
        overlap="rectangular"
      >
        <AddMember color="grey" />
      </Badge>
    </Button>
  );
};

export default PendingRequestIcon;
