import { get as _get } from 'lodash';

import { type AppThunk } from '@/store';

import { updateFrameResponsesWithoutCloning } from './updateFrameResponsesWithoutCloning';

import { type LabelFrameInitializeResponsePayload } from '../types';

export const updateFrameResponses = (payload: LabelFrameInitializeResponsePayload): AppThunk => {
  return dispatch => {
    const frameResponses = _get(payload, 'frameResponses', {});
    dispatch(updateFrameResponsesWithoutCloning({ frameResponses }));
  };
};
