import { connect } from 'react-redux';

import StyledGenericLeftBar from './GenericLeftBar';

import { type State } from '../../../redux/types';
import { userEmail } from '../../../redux/user/selectors';

const mapStateToProps = (state: State) => ({
  email: userEmail(state),
});

const mapDispatchToProps = {};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(StyledGenericLeftBar);
