import {
  type AnnotationCategory as Category,
  type PoseEstimationPoint,
} from '@kili-technology/types';
import L from 'leaflet';

import { type KiliOptions } from '../types';

export type KiliPoseOptions = KiliOptions & {
  allPoints: PoseEstimationPoint[];
  pointIndicesDrawn: number[];
};

export type LayerPoseOptions = L.PolylineOptions & {
  allPoints: PoseEstimationPoint[];
  categories?: Category[];
  isFinished: boolean;
  isPose: boolean;
  jobName?: string;
  pointIndicesDrawn: number[];
};

class Pose extends L.Polyline {
  pose: boolean;

  declare options: LayerPoseOptions;

  constructor(latLngs: L.LatLng[], options: LayerPoseOptions) {
    super(latLngs, options);
    this.pose = true;
  }
}

export default Pose;
