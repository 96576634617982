import { gql } from '@apollo/client';

export const GQL_APPEND_MANY_TO_DATASET_FRONTEND = gql`
  mutation AppendManyToDatasetFrontend($data: AppendManyToDatasetData!, $where: ProjectWhere!) {
    appendManyToDatasetFrontend(data: $data, where: $where) {
      numberOfUploadedAssets
      warnings {
        type
        details
        externalId
        lineNumber
      }
    }
  }
`;

export const GQL_DATASET_APPEND_MANY_TO_ASYNCHRONOUSLY = gql`
  mutation AppendManyFramesToDatasetAsynchronously(
    $data: AppendManyFramesToDatasetAsynchronouslyData!
    $where: ProjectWhere!
  ) {
    appendManyFramesToDatasetAsynchronously(data: $data, where: $where) {
      id
    }
  }
`;

export const GQL_DATASET_DELETE_MANY_FROM = gql`
  mutation DeleteManyFromDataset($where: AssetWhere!) {
    deleteManyFromDataset(where: $where) {
      id
    }
  }
`;

export const GQL_APPEND_TO_LABELS = gql`
  mutation AppendToLabels($data: AppendToLabelsData!, $where: AssetWhere!) {
    frontendAppendToLabels(data: $data, where: $where) {
      id
      secondsToLabel
    }
  }
`;

export const GQL_APPEND_MANY_TO_LABELS = gql`
  mutation AppendManyToLabels($data: AppendManyLabelsData!, $where: AssetWhere!) {
    appendManyLabels(data: $data, where: $where) {
      id
    }
  }
`;

export const GQL_SKIP_ASSET = gql`
  mutation SkipAsset($where: AssetWhere!) {
    skipAsset(where: $where) {
      id
    }
  }
`;

export const GQL_UPDATE_PROPERTIES_IN_ASSETS = gql`
  mutation UpdatePropertiesInAssets($where: [AssetWhere!]!, $data: [AssetData!]!) {
    updatePropertiesInAssets(where: $where, data: $data) {
      id
    }
  }
`;

export const GQL_UPDATE_ASSET_RESOLUTION = gql`
  mutation UpdateAssetResolution($input: UpdateAssetResolutionInput!) {
    updateAssetResolution(input: $input) {
      id
    }
  }
`;

export const GQL_ADD_TO_REVIEW = gql`
  mutation AddAllLabeledAssetsToReview($where: AssetWhere!) {
    addAllLabeledAssetsToReview(where: $where) {
      id
    }
  }
`;

export const GQL_SEND_ASSETS_BACK_TO_QUEUE = gql`
  mutation SendBackAssetsToQueue($where: AssetWhere!) {
    sendBackAssetsToQueue(where: $where) {
      id
    }
  }
`;

export const GQL_ASSIGN_ASSETS = gql`
  mutation assignAssetsToLabelers($where: AssetWhere!, $userIds: [String!]!) {
    assignAssetsToLabelers(where: $where, userIds: $userIds) {
      id
    }
  }
`;

export const GQL_ANNOTATE_WITH_COPILOT = gql`
  mutation annotateWithCopilot($where: AssetWhere!, $data: AnnotateWithCopilotData!) {
    annotateWithCopilot(where: $where, data: $data)
  }
`;

export const GQL_PRIORITIZE_ASSETS = gql`
  mutation PrioritizeAssets($where: AssetWhere!, $priority: Int!) {
    prioritizeAssets(where: $where, priority: $priority) {
      id
    }
  }
`;

export const GQL_SET_HONEYPOT_ON_ASSETS = gql`
  mutation setHoneypot($data: SetHoneypotData!, $where: AssetWhere!) {
    setHoneypot(data: $data, where: $where)
  }
`;
