import {
  createStyles,
  type StyleRules,
  type Theme,
  withStyles,
} from '@material-ui/core/styles/index';

import ProjectsPanel from './ProjectsPanel';

import { TOPBAR_POPPER_ZINDEX } from '../../../../constants/zIndexes';

export const styles = (
  theme: Theme,
): StyleRules<'container' | 'label' | 'paper' | 'popper' | 'subContainer'> =>
  createStyles({
    container: {
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    paper: {
      height: '488px',
      margin: theme.spacing(0),
      overflow: 'hidden',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2.5),
      width: '305px',
    },
    popper: {
      zIndex: TOPBAR_POPPER_ZINDEX,
    },
    subContainer: {
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.omega.accent9.main,
      },
      boxSizing: 'content-box',
      display: 'flex',
      justifyContent: 'flex-start',
      textTransform: 'none',
      width: '100%',
    },
  });

export default withStyles(styles)(ProjectsPanel);
